import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Link,
  ListItem,
  OrderedList,
  Text,
  AccordionPanel,
  Icon,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

export const MagicFlowerNFTs = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        Magic Flowers are more than just digital plants—they are living entities
        with memories. These unique Magic Flowers are ERC721 NFTs, each
        representing a distinct plant with its own features, intricate history,
        and captivating story. Beyond their status as NFTs, they are also part
        of a web3 game, offering a truly one-of-a-kind experience. The rich
        tapestry of each Magic Flower's history mirrors the memories we hold
        from our own pasts. Depending on the plant's level, its owner can
        customize the background, surroundings, and add features like a plant
        spirit, allowing for endless creative expression.
      </Text>
    </AccordionPanel>
  );
};

export const MintMagicFlowers = () => {
  return (
    <AccordionPanel pb={4}>
      To revive/hybridize a Magic Flower NFT, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to{' '}
          <Link as={ReactLink} to="/magicflowers">
            Magic Flowers
          </Link>
          .
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Simply click on the 'Connect Wallet'
          button located in the top right corner, then choose your preferred
          wallet from the dropdown menu. Once connected, your wallet balance and
          address will be displayed. We utilize WalletConnect, a trusted
          protocol, for secure connections.
        </ListItem>
        <ListItem>
          To revive a plant, click the Revive Magic Flowers button or enter
          Revival Heaven. To hybridize a plant, click the Hybridize Magic
          Flowers button or enter Hybrid Paradise.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Simply
          follow the instructions provided by your wallet.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const BatchIntegrateNFTsInGame = () => {
  return (
    <AccordionPanel pb={4}>
      To seamlessly integrate multiple NFTs into a game, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to{' '}
          <Link as={ReactLink} to="/magicflowers">
            Magic Flowers
          </Link>
          .
        </ListItem>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/dreamminting">
            Dream Minting Studio
          </Link>
          , and select 'NFTs in Bulk' at the top of the form.
        </ListItem>
        <ListItem>
          All NFTs must originate from the same NFT contract. After completing
          the form for 'NFT#1,' click 'Add Token,' which will reveal a form for
          'NFT#2.' Continue this process until you've provided all the necessary
          information for each NFT.
        </ListItem>
        <ListItem>
          If you need to remove a token, simply click 'Remove this token' on the
          corresponding form.
        </ListItem>
        <ListItem>
          Finally, click 'Create' to complete the integration process. For
          further guidance, please refer to '1. How to integrate an NFT in a
          game?' in the documentation.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const UpdateGameIntegration = () => {
  return (
    <AccordionPanel pb={4}>
      To update/remove/reactivate game integration for your token, follow these
      steps:
      <OrderedList>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/dreamminting">
            Dream Minting Studio
          </Link>
          .
        </ListItem>
        <ListItem>
          Ensure your wallet is connected. Simply click on the 'Connect Wallet'
          button located in the top right corner, then choose your preferred
          wallet from the dropdown menu. Once connected, your wallet balance and
          address will be displayed. We utilize WalletConnect, a trusted
          protocol, for secure connections.
        </ListItem>
        <ListItem>
          Select 'Game Integration', then choose 'Integrate Tokens in Game'.
          From there, click on 'Update Tokens in Game'. A modal will appear with
          a form for updating game integration.
        </ListItem>
        <ListItem>
          Specify the Game Id for which your NFT integration is to be updated.
        </ListItem>
        <ListItem>
          Provide the token contract address of the NFT's origin. For NFTs
          minted in Dream Minting Studio within Magic NFT Valley, choose 'Magic
          NFT Erc721 contract' or 'Magic NFT Erc1155 contract' depending on the
          NFT type. For NFTs minted elsewhere, select 'Other contract' and input
          the contract address.
        </ListItem>
        <ListItem>Provide the NFT ID.</ListItem>
        <ListItem>
          Specify whether you want to 'Update Game Branch', 'Update Game
          Features', 'Remove Game Integration', and/or 'Reactivate Game
          Integration'. Each item you choose to update will display the
          corresponding form. Once you've completed filling out all the new
          data, click the 'Submit' button at the bottom of the form to finalize
          the update process.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Simply
          follow the instructions provided by your wallet. There is no charge
          for this action, but you may be required to cover the gas fee native
          to the Matic network.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const BatchUpdateGameIntegration = () => {
  return (
    <AccordionPanel pb={4}>
      To update game integration for multiple NFTs, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/magicflowers/allmymagicflowers">
            "My Blooming Collection"
          </Link>{' '}
          page from the menu located in the top-left corner or at the bottom of
          the screen. Make sure your wallet is connected.
        </ListItem>
        <ListItem>
          Locate the Magic Flower plant in your collection that you'd like to
          update. Click the 'Quick Action' button next to it, then select 'Dream
          Studio' from the popover menu to access the Dream Studio for that
          specific Magic Flower NFT.
        </ListItem>
        <ListItem>
          Select 'Add Gallery', then choose 'Integrate Tokens in Gallery'. From
          there, click on 'Update Tokens in Game'. A modal will appear with a
          form for updating game integration.
        </ListItem>
        <ListItem>Select 'NFTs in Bulk' at the top of the form.</ListItem>
        <ListItem>
          All NFTs must originate from the same NFT contract. After completing
          the form for 'NFT#1,' click 'Add Token,' which will reveal a form for
          'NFT#2.' Continue this process until you've provided all the necessary
          information for each NFT.
        </ListItem>
        <ListItem>
          If you need to remove a token, simply click 'Remove this token' on the
          corresponding form.
        </ListItem>
        <ListItem>
          Finally, click 'Submit' to complete the integration process. For
          further guidance, please refer to '4. How to update/remove/reactivate
          game integration for an NFT?' in the documentation.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const EvolvePlant = () => {
  return (
    <AccordionPanel pb={4}>
      Your Magic Flower NFT is not static; it has the potential to evolve and
      transform over time. Follow these steps to initiate the evolution process:
      <OrderedList>
        <ListItem>
          Navigate to your Magic Flower NFT page. Find the star fallen from
          yesterday(sometimes it is hidden, for example, behind a rock). Click
          on it to enter the realm of evolving Magic Flower plants.
        </ListItem>
        <ListItem>
          Within this realm, your plant spirit resides within a mesmerizing
          aura, poised to embark on its evolutionary journey. Beneath it, three
          dice await, each holding the fate of your Magic Flower.
        </ListItem>
        <ListItem>
          Ensure your wallet is connected, then proceed to click the 'Evolve'
          button.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Simply
          follow the instructions provided. There is no fee for this action,
          although you may need to cover the gas fee native to the Matic
          network.
        </ListItem>
        <ListItem>
          As the three dice spin, anticipation builds until they come to a stop,
          revealing their numbers. The sum of these numbers determines the
          outcome of your plant's evolution.
        </ListItem>
        <ListItem>
          Click on 'Last Evolve Outcome' to view the final dice number and the
          resulting evolution of your plant. It may have adopted a new
          appearance, or, unfortunately, reverted to a previous state, erasing
          some of its history.
        </ListItem>
        <ListItem>
          If your Magic Flower has evolved, you can witness its new appearance
          by returning to its page.
        </ListItem>
        <ListItem>
          Please note that progenitor plants have limited fast-evolving
          abilities unless they undergo rare mutations. Typically, a progenitor
          plant can only fast-evolve once before producing any offspring.
          However, after each new offspring is produced, the progenitor plant
          gains the ability to fast-evolve again. In rare cases, a mutation can
          grant a progenitor plant unlimited fast-evolving abilities. Hybrids,
          including vigors, can fast-evolve as many times as you wish.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const AccessHistory = () => {
  return (
    <AccordionPanel pb={4}>
      To view the evolutionary journey of your Magic Flower NFT, follow these
      steps:
      <OrderedList>
        <ListItem>
          Navigate to your Magic Flower NFT's individual page.
        </ListItem>
        <ListItem>
          Locate and click the 'Evolving History' button. A modal will appear,
          presenting a chronological list of your plant's evolutionary
          milestones, along with the timestamps of each transformation.
        </ListItem>
        <ListItem>
          Select any entry from the evolving history list to view it. The Magic
          Flower plant will transition to its appearance during the specified
          time period associated with that evolutionary event.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const AccessMagicFlower = () => {
  return (
    <AccordionPanel pb={4}>
      To find a specific individual Magic Flower NFT, follow these steps:
      <OrderedList>
        <ListItem>Navigate to the Magic Flowers page.</ListItem>
        <ListItem>
          Enter the Magic Flower NFT Id after the search icon{' '}
          <Icon as={SearchIcon} />.
        </ListItem>
        <ListItem>
          A link will appear. Click on this link to be directed to the page of
          the specific individual Magic Flower NFT.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const AccessAllMyMagicFlower = () => {
  return (
    <AccordionPanel pb={4}>
      You can access all your Magic Flowers NFTs through either of the following
      methods:
      <OrderedList>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/magicflowers/allmymagicflowers">
            "My Blooming Collection"
          </Link>{' '}
          page from the menu in the top left corner or at the bottom of the
          screen.
        </ListItem>
        <ListItem>
          Alternatively, go to the{' '}
          <Link as={ReactLink} to="/magicflowers">
            "Magic Flowers"
          </Link>{' '}
          page and click on "My Blooming Collection" to be redirected.
        </ListItem>
      </OrderedList>
      If you're unable to view all your Magic Flowers, please ensure your wallet
      is connected.
    </AccordionPanel>
  );
};

export const SellMyMagicFlower = () => {
  return (
    <AccordionPanel pb={4}>
      To sell or auction your Magic Flower NFT, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to the "My Blooming Section" page from the menu in the top
          left corner. Make sure your wallet is connected.
        </ListItem>
        <ListItem>
          Before selling or auctioning your Magic Flower NFT, update any
          metadata such as name or description if needed. You can refer to '9.
          How to update metadata for my Magic Flower NFT?' in the documentation
          if you're unsure how to do this.
        </ListItem>
        <ListItem>
          Locate the Magic Flower NFT you want to sell from the list of all your
          Magic Flowers NFTs, and click the 'Sell / Auction' button. A modal
          containing the form for selling or auctioning Magic Flowers NFTs will
          appear.
        </ListItem>
        <ListItem>
          Specify whether you want to sell or auction your Magic Flower NFT.
        </ListItem>
        <ListItem>
          Specify if your Magic Flower NFT has any complimentary tokens for this
          sale.
        </ListItem>
        <ListItem>
          If your Magic Flower NFT is to be listed for sale, fill in the price.
          If it is to be listed for auction, fill in the Starting Price, Minimum
          Increment, Bid Waiting Time, and Bidding Bonus Percentage. You can
          refer to the Auction House section in the FAQ if you're unsure about
          Bid Waiting Time or Bidding Bonus Percentage.
        </ListItem>
        <ListItem>
          If your Magic Flower NFT has complimentary tokens for this sale, fill
          out the Complimentary Tokens Form. You can refer to the Complimentary
          Tokens section in the FAQ if you're unsure about complimentary tokens.
        </ListItem>
        <ListItem>
          Click the 'Submit' button. Your wallet will prompt you to sign or
          confirm the transaction. Simply follow the instructions provided.
          There is no fee for this action, although you may need to cover the
          gas fee native to the Matic network.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const UpdateMagicFlowerMeta = () => {
  return (
    <AccordionPanel pb={4}>
      To update the metadata of your Magic Flower NFT, follow these steps:
      <OrderedList>
        <ListItem>
          Navigate to the{' '}
          <Link as={ReactLink} to="/magicflowers/allmymagicflowers">
            "My Blooming Collection"
          </Link>{' '}
          page from the menu located in the top-left corner or at the bottom of
          the screen. Make sure your wallet is connected.
        </ListItem>
        <ListItem>
          Locate the Magic Flower plant in your collection that you'd like to
          update. Click the 'Quick Action' button next to it, then select 'Dream
          Studio' from the popover menu to access the Dream Studio for that
          specific Magic Flower NFT.
        </ListItem>
        <ListItem>
          Enter the URL for the metadata of this Magic Flower NFT. If you're
          unsure about how to create metadata, please refer to the FAQ section
          on Metadata Creation for detailed guidance.
        </ListItem>
        <ListItem>
          After filling out the form, click the "Update Metadata" button at the
          bottom. Your wallet will prompt you to sign or confirm the
          transaction. Follow the instructions provided. While there is no fee
          for updating the metadata, you may need to cover the gas fee on the
          Matic network, which is typically minimal.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const GalleryIntro = () => {
  return (
    <AccordionPanel pb={4}>
      Clicking on `Magic Garden` from an individual Magic Flower NFT page will
      direct you to a dedicated gallery page for that specific NFT, where you'll
      find:
      <OrderedList>
        <ListItem>
          `Default Artworks`: This section contains all the NFTs that the owner
          of the Magic Flower NFT has set as default within the associated Magic
          Flower NFT. It's important to note that these NFTs are already
          integrated into the Magic Flower NFT, and the owner of these NFTs must
          also be the owner of the Magic Flower NFT.
        </ListItem>
        <ListItem>
          `All Artworks`: This section includes all the NFTs integrated into the
          Magic Flower NFT. Similar to the `Default Artworks` section, the owner
          of these NFTs must also be the owner of the Magic Flower NFT.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const SetNftAsDefault = () => {
  return (
    <AccordionPanel pb={4}>
      When you click on `Magic Garden` from your individual Magic Flower NFT
      page, you'll be taken to a dedicated gallery page for that specific NFT.
      Make sure your wallet is connected before proceeding.
      <OrderedList>
        <ListItem>
          To check if the desired NFT is already set as default, click on
          `Default Artworks`. If it's already listed there, you don't need to
          set it again. If not, proceed to the next step.
        </ListItem>
        <ListItem>
          Click on `All Artworks` to view a list of all NFTs integrated into
          this Magic Flower NFT. Locate the desired NFT to set as default and
          click on `Set as default` below it.
        </ListItem>
        <ListItem>
          After setting the desired NFT as default, return to `Default Artworks`
          to confirm the change. Repeat this process if you want to set
          additional NFTs as default. Once you've finalized your selections,
          click on the `Update Default Artworks` button to complete the update
          on the blockchain.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Follow
          the provided instructions. While there's no fee for this action, you
          may need to cover the gas fee native to the Matic network.
        </ListItem>
      </OrderedList>
      Please note that for features like PlantSpirit, only one NFT can be set as
      default. If you set another NFT as default, any previously set default NFT
      for that feature will be replaced.
    </AccordionPanel>
  );
};

export const CannotSeeNftAllArtworks = () => {
  return (
    <AccordionPanel pb={4}>
      If you're unable to see an NFT that has been integrated into your Magic
      Flower NFT in the `All Artworks` section of the `Gallery`, there could be
      several reasons:
      <OrderedList>
        <ListItem>
          Weak or slow network connection may be causing a delay in retrieving
          data stored on the blockchain. If this is the case, simply refresh
          your page.
        </ListItem>
        <ListItem>
          Ensure that the ownership of the NFT hasn't changed to another
          account. Make sure the wallet you're connected to is the rightful
          holder of the NFT.
        </ListItem>
        <ListItem>
          It's possible that the NFT has been integrated into another Magic
          Flower NFT. In this case, update the `branch` to the Magic Flower ID
          of the current Magic Flower NFT. Refer to question '20. How to update
          branch for an NFT integrated in Magic Flowers Game?' in the
          documentation for guidance.
        </ListItem>
        <ListItem>
          The image or other content of the NFT may be corrupted. Verify the
          token on the `Verify Token` page to confirm.
        </ListItem>
      </OrderedList>
      If you've tried all of the above and still cannot locate the missing NFT,
      please don't hesitate to contact us for assistance.
    </AccordionPanel>
  );
};

export const IntegrateNFTinMagicFlower = () => {
  return (
    <AccordionPanel pb={4}>
      To integrate existing NFT(s) into your Magic Flower NFT, follow these
      steps:
      <OrderedList>
        <ListItem>
          Ensure your wallet is connected. Click on the 'Connect Wallet' button
          located in the top right corner, then select your preferred wallet
          from the dropdown menu. Once connected, your wallet balance and
          address will be displayed. We use WalletConnect, a trusted protocol,
          for secure connections.
        </ListItem>
        <ListItem>
          Navigate to{' '}
          <Link as={ReactLink} to="/allmymagicflowers">
            My Blooming Collection
          </Link>
          , find the Magic Flower NFT you wish to integrate, and click the
          'Quick Action ' Button and then the 'Dream Studio' button from the
          popover menu. This will redirect you to the page where you can
          integrate NFT(s) into this specific Magic Flower. Alternatively, you
          can reach this page by visiting the individual Magic Flower NFT page
          and clicking on 'Add Gallery' at the bottom.
        </ListItem>
        <ListItem>
          Click the 'Add Gallery' button, and then the 'Integrate Tokens in
          Gallery' button, and a modal will appear, containing the form for
          integrating existing NFT(s).
        </ListItem>
        <ListItem>
          Select 'NFTs in Bulk' if you have more than one NFT to integrate into
          this Magic Flower.
        </ListItem>
        <ListItem> Select 'Yes' for 'Game Integration'.</ListItem>
        <ListItem>
          Choose between 'For External Integration' or 'Reserved for Game Owner
          / Game Admin'.
        </ListItem>
        <ListItem>
          Provide the token contract address of the NFT's origin. For NFTs
          minted in Dream Minting Studio within Magic NFT Valley, select 'Magic
          NFT Erc721 contract' or 'Magic NFT Erc1155 contract' depending on the
          NFT type. For NFTs minted elsewhere, choose 'Other contract' and input
          the contract address.
        </ListItem>
        <ListItem>
          Specify the game branch. If unsure, refer to the game-specific
          instructions on the game showcase page.
        </ListItem>
        <ListItem>
          Click 'Show list of game features', and a list of available game
          features for integration will appear. Select the feature(s) you wish
          to integrate with.
        </ListItem>
        <ListItem>Fill out the form below with the NFT ID.</ListItem>
        <ListItem>
          If you are integrating multiple NFTs, click the 'Add Token' button and
          fill out the data for each additional NFT. Repeat until data for all
          NFTs have been provided.
        </ListItem>
        <ListItem>
          Your wallet will prompt you to sign or confirm the transaction. Follow
          the instructions provided by your wallet. There is no charge for this
          action, but you may be required to cover the gas fee native to the
          Matic network.
        </ListItem>
      </OrderedList>
    </AccordionPanel>
  );
};

export const PlatformFees = () => {
  return (
    <AccordionPanel pb={4}>
      <Text>
        There is a 2.5% transaction fee for successful sales in Marketplace and
        Auction House based on the final selling price. There is no fee charged
        for lising Magic Flowers in the Magic Flowers Leasing Market, however, a
        2.5% transaction fee is charged on for successful leasing of Magic
        Flowers based on their Leasing Price. Please note only the seller is
        charged of a transaction fee for a successful sale, auction, or leasing
        of Magic Flowers.
      </Text>
    </AccordionPanel>
  );
};
