// flowerType: 134, 135, 136
const getPetalNumber = flowerType => {
  const petalMap = {
    134: 3,
    135: 4,
    136: 5,
  };
  return petalMap[flowerType];
};

// plant 1
export class FlowerGenus8plant1 {
  constructor(
    p5,
    treeCanvas,
    flowerCanvas,
    flowerParam,
    flowerType,
    plantType
  ) {
    this.p5 = p5;
    this.treeCanvas = treeCanvas;
    this.flowerCanvas = flowerCanvas;
    this.plantType = plantType;
    this.rand = this.p5.random(0.58, 1);
    this.posX = 100;
    this.posY = 100;
    this.fx = 0;
    this.fy = 0;
    this.fy1 = this.fy;
    this.vy = 0; // velocity of y-axis
    this.vx = 0; // velocity of x-axis
    this.gravity = 0.3;
    this.wind = 0.005;
    this.amplitude = 0.18;
    this.frequency = 0.0058;
    this.numPetals = getPetalNumber(flowerType); //3; //p5.int(p5.random(4, 8));
    this.controlY = p5.random(-90, -20);
    this.controlX = p5.random(-30, -10);
    if (this.controlX < this.controlY / 2) this.controlX = this.controlY / 2;
    if (this.controlX > -10) this.controlX = -10;
    this.spin = 0;
    this.spinRate = p5.random(-0.000018, 0.000018);

    // do {
    //   this.spinRate = p5.random(-0.0015, 0.0015);
    // } while (p5.abs(this.spinRate) < 0.0005);

    if (this.plantType === 1) {
      if (this.flowerCanvas.random() >= this.rand) {
        this.fch = this.flowerCanvas.random(360);
        this.fcs = this.flowerCanvas.random(88, 100);
        this.fcb = this.flowerCanvas.random(88, 100);
      } else {
        this.fch = flowerParam.fc.h;
        this.fcs = flowerParam.fc.s;
        this.fcb = flowerParam.fc.b;
      }
    } else {
      this.fch = this.flowerCanvas.random(360);
      this.fcs = this.flowerCanvas.random(88, 100);
      this.fcb = this.flowerCanvas.random(88, 100);
      // this.fch = flowerParam.fc.h;
      // this.fcs = flowerParam.fc.s;
      // this.fcb = flowerParam.fc.b;
    }

    this.centreSize = p5.random(10, 20);
  }

  displayFlowerCanvas(_x, _y) {
    if (this.fx === 0) {
      this.fx = _x;
    }

    if (this.fy === 0) {
      this.fy = _y;
    }

    this.treeCanvas.image(
      this.flowerCanvas,
      this.fx,
      this.fy,
      800 / 4,
      600 / 4
    );
  }

  updateFlowerCanvas(p5) {
    //this.vy += this.gravity;
    this.fy += this.gravity;
    this.vx += this.wind; // add wind to velocity
    let x_offset = p5.sin(p5.frameCount * this.frequency) * this.amplitude; // calculate the horizontal offset
    this.fx += x_offset;
    // this.spin += this.spinRate;
  }

  spinFlower() {
    this.spin += this.spinRate;
  }

  drawFlower(p5) {
    // centre drawing
    this.flowerCanvas.push();

    // p5.scale(0.25);
    this.flowerCanvas.translate(this.posX, this.posY);
    if (this.flowerType === 1) {
      this.flowerCanvas.fill(this.fch, this.fcs, this.fcb, 0.288);
    } else {
      this.flowerCanvas.fill(this.fch, this.fcs, this.fcb, 0.00888);
    }
    
    for (let i = 0; i < this.numPetals; i++) {
      this.flowerCanvas.push();
      this.flowerCanvas.rotate(p5.radians((i * 360) / this.numPetals));
      if (this.flowerType === 1) {
        this.flowerCanvas.rotate(p5.random(1));
      } else {
        this.flowerCanvas.rotate(p5.random(0.1));
      }

      this.flowerCanvas.bezier(
        0,
        -100,
        this.controlX,
        this.controlY,
        this.controlX,
        this.controlY,
        0,
        0
      );
      this.flowerCanvas.bezier(
        0,
        -100,
        -this.controlX,
        this.controlY,
        -this.controlX,
        this.controlY,
        0,
        0
      );
      this.flowerCanvas.pop();
    }
    this.flowerCanvas.fill(180, 180, 80, 255);
    this.flowerCanvas.stroke(150, 150, 60);
    this.flowerCanvas.ellipse(0, 0, this.centreSize, this.centreSize);
    this.flowerCanvas.noStroke();
    //reset
    this.flowerCanvas.translate(-this.posX, -this.posY);
    this.flowerCanvas.pop();
  }
} //end class

// plant 2 and 3
export class FlowerGenus8 {
  constructor(p5, flowerCanvas, flowerParam, flowerType) {
    this.p5 = p5;
    this.flowerCanvas = flowerCanvas;
    this.rand = flowerParam.sc.h;
    this.posX = 100;
    this.posY = 100;
    this.fx = 0;
    this.fy = 0;
    this.fy1 = this.fy;
    this.vy = 0; // velocity of y-axis
    this.vx = 0; // velocity of x-axis
    this.gravity = 0.3;
    this.wind = 0.005;
    this.amplitude = 0.18;
    this.frequency = 0.0058;
    this.numPetals = getPetalNumber(flowerType); //3; //p5.int(p5.random(4, 8));
    this.controlY = this.p5.random(-90, -20);
    this.controlX = this.p5.random(-30, -10);
    if (this.controlX < this.controlY / 2) this.controlX = this.controlY / 2;
    if (this.controlX > -10) this.controlX = -10;
    this.spin = 0;
    this.spinRate = this.p5.random(-0.000018, 0.000018);

    // do {
    //   this.spinRate = p5.random(-0.0015, 0.0015);
    // } while (p5.abs(this.spinRate) < 0.0005);

    // if (this.flowerCanvas.random() >= this.rand) {
    //   this.flowerCol = this.p5.color(
    //     100 + this.p5.random(150),
    //     100 + this.p5.random(150),
    //     100 + this.p5.random(150)
    //   );
    // } else {
    //   this.flowerCol = this.p5.color(
    //     100 + flowerParam.fc.h,
    //     100 + flowerParam.fc.s,
    //     100 + flowerParam.fc.b
    //   );
    // }
    if (this.flowerCanvas.random() >= this.rand) {
      this.fch = this.flowerCanvas.random(360);
      this.fcs = this.flowerCanvas.random(88, 100);
      this.fcb = this.flowerCanvas.random(88, 100);
    } else {
      this.fch = flowerParam.fc.h;
      this.fcs = flowerParam.fc.s;
      this.fcb = flowerParam.fc.b;
    }

    this.centreSize = this.flowerCanvas.random(10, 20);
  }

  displayFlowerCanvas(_x, _y) {
    if (this.fx === 0) {
      this.fx = _x;
    }

    if (this.fy === 0) {
      this.fy = _y;
    }

    this.p5.image(this.flowerCanvas, this.fx, this.fy, 800 / 4, 600 / 4);
  }

  updateFlowerCanvas() {
    //this.vy += this.gravity;
    this.fy += this.gravity;
    this.vx += this.wind; // add wind to velocity
    let x_offset =
      this.p5.sin(this.p5.frameCount * this.frequency) * this.amplitude; // calculate the horizontal offset
    this.fx += x_offset;
    // this.spin += this.spinRate;
  }

  spinFlower() {
    this.spin += this.spinRate;
  }

  drawFlower() {
    // centre drawing
    this.flowerCanvas.push();

    // p5.scale(0.25);
    this.flowerCanvas.translate(this.posX, this.posY);
    this.flowerCanvas.fill(this.fch, this.fcs, this.fcb, 0.018);
    for (let i = 0; i < this.numPetals; i++) {
      this.flowerCanvas.push();
      this.flowerCanvas.rotate(this.p5.radians((i * 360) / this.numPetals));
      this.flowerCanvas.rotate(this.p5.random(1));
      this.flowerCanvas.bezier(
        0,
        -100,
        this.controlX,
        this.controlY,
        this.controlX,
        this.controlY,
        0,
        0
      );
      this.flowerCanvas.bezier(
        0,
        -100,
        -this.controlX,
        this.controlY,
        -this.controlX,
        this.controlY,
        0,
        0
      );
      this.flowerCanvas.pop();
    }
    this.flowerCanvas.fill(180, 180, 80, 255);
    this.flowerCanvas.stroke(150, 150, 60);
    this.flowerCanvas.ellipse(0, 0, this.centreSize, this.centreSize);
    this.flowerCanvas.noStroke();
    //reset
    this.flowerCanvas.translate(-this.posX, -this.posY);
    this.flowerCanvas.pop();
  }
} //end class
