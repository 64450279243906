export class Star1 {
  constructor(p5, canvas, x, y) {
    this.p5 = p5;
    this.canvas = canvas;
    this.x = x;
    this.y = y;
    this.r = this.p5.random(2, 5);
  }

  display() {
    this.canvas.push();
    this.canvas.stroke(33, 121, 145);
    this.canvas.fill(247, 255, 196);
    this.rc = this.p5.constrain(this.r, 0, 8);
    this.canvas.ellipse(this.x, this.y, this.rc, this.rc);
    this.canvas.pop();
  }

  twinkle() {
    if (this.r < 3) {
      this.r += this.p5.random(-0.5, 1.5);
    } else if (this.r >= 3 && this.r < 6) {
      this.r += this.p5.random(-1, 1);
    } else if (this.r >= 6 && this.r <= 9) {
      this.r += this.p5.random(-1.5, 0.5);
    }
  }
}

export function addStars(p5, canvas, nStars, stars, lStarsCanvas) {
  for (var i = 0; i < nStars; i++) {
    stars.push(
      new Star1(
        p5,
        canvas,
        p5.random(lStarsCanvas.width / 18, lStarsCanvas.width / 1.18),
        p5.random(lStarsCanvas.height / 18, lStarsCanvas.height / 1.18)
      )
    );
  }
}
