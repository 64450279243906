export function moon(p5, canvas) {
  // let wm =  6;
  let w = canvas.width/2
  let h = canvas.height / 8;
  for (var moonlight = 100; moonlight > 0; moonlight = moonlight - 1) {
    var ls = p5.map(moonlight, 100, 0, canvas.width / 2, canvas.width / 10);
    canvas.push();
    canvas.noStroke();
    canvas.fill(
      canvas.lerpColor(
        canvas.color(0),
        canvas.color(0, 126, 255),
        1 - moonlight / 100
      )
    );
    canvas.ellipse(w-5.8, h, ls / 1.88, ls / 1.88);
    canvas.pop();
  }

  for (var moon = 100; moon > 0; moon = moon - 1) {
    var ms = p5.map(moon, 100, 0, canvas.width / 3.571428, 0);
    canvas.push();
    canvas.noStroke();
    canvas.fill(
      canvas.lerpColor(
        canvas.color(0, 182, 255),
        canvas.color(255),
        1 - (((moon * 9) / 10 / 100) * 1) / 3
      )
    );
    canvas.ellipse(w, h, ms / 2.88, ms / 2.88);
    canvas.pop();
  }
}

export function moon1(p5, canvas) {
  // let wm =  6;
  let w = canvas.width/2
  let h = canvas.height / 8;
  for (var moonlight = 100; moonlight > 0; moonlight = moonlight - 1) {
    var ls = p5.map(moonlight, 100, 0, canvas.width / 2, canvas.width / 10);
    canvas.push();
    canvas.noStroke();
    canvas.fill(
      canvas.lerpColor(
        canvas.color(0, 0, 102),
        canvas.color(0, 126, 255),
        1 - moonlight / 100
      )
    );
    canvas.ellipse(w-5.8, h, ls / 1.08, ls / 1.08);
    canvas.pop();
  }

  for (var moon = 100; moon > 0; moon = moon - 1) {
    var ms = p5.map(moon, 100, 0, canvas.width / 3.571428, 0);
    canvas.push();
    canvas.noStroke();
    canvas.fill(
      canvas.lerpColor(
        canvas.color(0, 182, 255),
        canvas.color(255),
        1 - (((moon * 9) / 10 / 100) * 1) / 3
      )
    );
    canvas.ellipse(w, h, ms / 2.58, ms / 2.58);
    canvas.pop();
  }
}

export function moon2(p5, canvas) {
  // let wm =  6;
  let w = canvas.width/2
  let h = canvas.height / 8;
  for (var moonlight = 100; moonlight > 0; moonlight = moonlight - 1) {
    var ls = p5.map(moonlight, 100, 0, canvas.width / 2, canvas.width / 10);
    canvas.push();
    canvas.noStroke();
    canvas.fill(
      canvas.lerpColor(
        canvas.color(0, 0, 102),
        canvas.color(0, 126, 255),
        1 - moonlight / 100
      )
    );
    canvas.ellipse(w-5.8, h/0.88, ls / 0.958, ls / 0.958);
    canvas.pop();
  }

  for (var moon = 100; moon > 0; moon = moon - 1) {
    var ms = p5.map(moon, 100, 0, canvas.width / 3.571428, 0);
    canvas.push();
    canvas.noStroke();
    canvas.fill(
      canvas.lerpColor(
        canvas.color(0, 182, 255),
        canvas.color(255),
        1 - (((moon * 9) / 10 / 100) * 1) / 3
      )
    );
    canvas.ellipse(w, h/0.88, ms / 2.18, ms / 2.18);
    canvas.pop();
  }
}

