import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  IconButton,
  Input,
} from '@chakra-ui/react';
import { FaInfo } from 'react-icons/fa';
import { ethers } from 'ethers';
import MagicFlowers from '../../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';

const MintMagicFlowerByAdmin = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log('address:', address);
  console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [plantType, setPlantType] = useState('');
  const [users, setUsers] = useState('');
  const stringToArray = str => {
    let array = str.split(',').map(item => item.trim());
    return array;
  };

  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('signer:', signer);

    try {
      const magicFlowers = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_ADDRESS,
        MagicFlowers.abi,
        signer
      );

      //   const mintingFee = magicFlowers.getMagicFlowersMintingFee(); // 0.5 matic
      const _plantTypes = stringToArray(plantType);
      const _users = stringToArray(users);
      const transaction = await magicFlowers.mintTreesByAdmin(
        _plantTypes,
        _users
      );

      await transaction.wait();

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Mint
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Mint
        </Button>
      );
    }
  };

  return (
    <>
      <>
        <Button
          onClick={onOpen}
          size="lg"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Mint Magic Flowers
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Mint Magic Flowers</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box style={{ marginBottom: '20px' }}>
                <FormControl isRequired>
                  <FormLabel as="legend">
                    Plant Types:
                    <Popover>
                      <PopoverTrigger>
                        <IconButton
                          variant="outline"
                          colorScheme="green"
                          aria-label="Get Info"
                          size="xs"
                          icon={<FaInfo boxSize={8} />}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          Separate plant types with comma, for example: 1, 2, 1,
                          2, 2
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </FormLabel>
                  <Input
                    value={plantType}
                    onChange={e => setPlantType(e.target.value)}
                    autoComplete="off"
                    placeholder="Plant Types"
                    size="sm"
                  />
                </FormControl>
              </Box>

              <Box style={{ marginBottom: '20px' }}>
                <FormControl isRequired>
                  <FormLabel as="legend">
                    Users:
                    <Popover>
                      <PopoverTrigger>
                        <IconButton
                          variant="outline"
                          colorScheme="green"
                          aria-label="Get Info"
                          size="xs"
                          icon={<FaInfo boxSize={8} />}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          Separate different user addresses with comma, for
                          example: 0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266,
                          0x70997970C51812dc3A010C7d01b50e0d17dc79C8,
                          0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </FormLabel>
                  <Input
                    value={users}
                    onChange={e => setUsers(e.target.value)}
                    autoComplete="off"
                    placeholder="User addresses"
                    size="sm"
                  />
                </FormControl>
              </Box>
            </ModalBody>

            <ModalFooter>
              <RenderButton />
              <Button
                variant="ghost"
                colorScheme="blue"
                ml={4}
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </>
  );
};

export default MintMagicFlowerByAdmin;
