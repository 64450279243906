import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  Card,
  CardBody,
  VStack,
  HStack,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { FaInfo } from 'react-icons/fa';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { makeGameMetaUrl } from '../../../../utils/mintHelpers';
import Games from '../../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import {
  UrlPrefixWarning,
  urlPrefix,
} from '../../../../views/NFTs/elements/FormElements';
import getGameTokenInfo from '../../../../views/NFTs/elements/getGameTokenInfo';
import SwitchForm from '../../../../formComponents/SwtichForm';
import AddFeaturesForm from '../../../../formComponents/AddFeaturesForm';
import SocialMediaForm from '../../../../formComponents/SocialMediaForm';
import ExistingGameProperty from '../../../../views/NFTs/games/ExistingGameProperty';
import GamePosterVideoForm from '../../../../formComponents/GamePosterVideoForm';
import convertFeatureStringToArray from '../../../../utils/convertFeatureStringToArray';
import { REACT_APP_GAMES_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';
const UpdateGame = ({ gameId }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  useEffect(() => {
    getItem();
  }, []);
  const [updateName, setUpdateName] = useState(false);
  const [updateGameImage, setUpdateGameImage] = useState(false);
  const [updateAllowExternalCreation, setUpdateAllowExternalCreation] =
    useState(false);

  const [updateFeatures, setUpdateFeatures] = useState(false);
  const [updateDescription, setUpdateDescription] = useState(false);
  const [updateTags, setUpdateTags] = useState(false);
  const [updateExternalUrlToGame, setUpdateExternalUrlToGame] = useState(false);
  const [updateTrialUrl, setUpdateTrailUrl] = useState(false);
  const [updateX, setUpdateX] = useState(false);
  const [updateFacebook, setUpdateFacebook] = useState(false);
  const [updateYoutube, setUpdateYoutube] = useState(false);
  const [allowExternalIntegration, setAllowExternalIntegration] =
    useState(false);
  const [gameFeature, setGameFeature] = useState('');
  const [existingGameFeature, setExistingGameFeature] = useState('');
  const [meta, setMeta] = useState({
    posterUrl: '',
    videoUrl: '',
    name: '',
    description: '',
    tags: '',
    externalUrlToGame: '',
    trialUrl: '',
    x: '',
    facebook: '',
    youtube: '',
  });

  const [loading, setLoading] = useState(false);

  const handleMeta = (field, value) => {
    // Create a new copy of the meta object
    const updatedMeta = { ...meta };
    updatedMeta[field] = value;

    // Update the state with the new object
    setMeta(updatedMeta);
  };

  const getItem = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const gameInfo = await getGameTokenInfo(provider, gameId);
    console.log('gameInfo:', gameInfo);
    // setGameInfo(_gameInfo)
    setAllowExternalIntegration(gameInfo.allowExternalIntegration);

    setExistingGameFeature(gameInfo.newItem.gameFeatures.join(', '));

    const metaInfo = gameInfo.newItem;
    // console.log('metaInfo', metaInfo);
    setMeta({
      posterUrl: metaInfo.posterUrl,
      videoUrl: metaInfo.videoUrl,
      name: metaInfo.gameName,
      description: metaInfo.description,
      tags: metaInfo.tags,
      externalUrlToGame: metaInfo.externalUrlToGame,
      trialUrl: metaInfo.trialUrl,
      x: metaInfo.x,
      facebook: metaInfo.facebook,
      youtube: metaInfo.youtube,
    });
  };

  const doesUpdateGameUrl = () => {
    if (
      updateName ||
      updateGameImage ||
      updateDescription ||
      updateTags ||
      updateExternalUrlToGame ||
      updateTrialUrl ||
      updateX ||
      updateFacebook ||
      updateYoutube
    ) {
      return true;
    }
    return false;
  };

  const create = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const updateGameUrl = doesUpdateGameUrl();

      let _cid = '';
      if (updateGameUrl) {
        _cid = await makeGameMetaUrl(meta);
      }

      //   console.log('_cid:', _cid);

      const games = new ethers.Contract(
        REACT_APP_GAMES_ADDRESS,
        Games.abi,
        signer
      );

      const _gameFeature = convertFeatureStringToArray(gameFeature, 'original');
      // console.log('_gameFeature:', _gameFeature);

      const _param = {
        gameId,
        updateGameUrl,
        updateAllowExternalCreation,
        updateFeatures,
        gameUrl: _cid,
        allowExternalCreation: allowExternalIntegration,
        features: _gameFeature,
      };
      console.log('_param:', _param);
      let transactionList;

      transactionList = await games.updateGameProperties(_param);

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (
      updateName ||
      updateGameImage ||
      updateAllowExternalCreation ||
      updateFeatures
    ) {
      if (loading === true) {
        return (
          <Button
            isLoading
            loadingText="Submitting"
            colorScheme="green"
            size="lg"
            variant="outline"
          >
            Update Game
          </Button>
        );
      } else {
        return (
          <Button
            onClick={create}
            colorScheme="green"
            size="lg"
            variant="outline"
          >
            Update Game
          </Button>
        );
      }
    }
  };

  return (
    <div>
      <VStack>
        <Box>
          <Text
            fontSize={36}
            color="teal.500"
            style={{ fontFamily: 'Lobster' }}
          >
            Update:
          </Text>
        </Box>

        <Card maxW="sm">
          <CardBody>
            <Box style={{ marginBottom: '20px' }}>
              <SwitchForm
                item={updateName}
                setItem={setUpdateName}
                label="Game Name"
              />

              {updateName && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <FormControl isRequired>
                    <FormLabel as="legend">Name:</FormLabel>

                    <Input
                      name="name"
                      value={meta.name}
                      onChange={e => handleMeta('name', e.target.value)}
                      placeholder="Game Name"
                      size="sm"
                    />
                  </FormControl>
                </Box>
              )}

              <SwitchForm
                item={updateAllowExternalCreation}
                setItem={setUpdateAllowExternalCreation}
                label="Whether external integration is allowed:"
              />

              {updateAllowExternalCreation && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <SwitchForm
                    item={allowExternalIntegration}
                    setItem={setAllowExternalIntegration}
                    label="Allow external integration ? "
                    colorScheme="green"
                  />
                </Box>
              )}

              <SwitchForm
                item={updateFeatures}
                setItem={setUpdateFeatures}
                label="Game Features:"
              />

              {updateFeatures && (
                <>
                  <Box
                    mb="20px"
                    mt="20px"
                    boxShadow="dark-lg"
                    p="6"
                    rounded="md"
                  >
                    <ExistingGameProperty
                      pContent={existingGameFeature}
                      pLabel="Features"
                    />

                    <Box>
                      <AddFeaturesForm
                        gameFeature={gameFeature}
                        setGameFeature={setGameFeature}
                      />
                    </Box>
                  </Box>
                </>
              )}

              <SwitchForm
                item={updateGameImage}
                setItem={setUpdateGameImage}
                label="Game Image:"
              />

              {updateGameImage && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <GamePosterVideoForm meta={meta} handleMeta={handleMeta} />
                </Box>
              )}

              <SwitchForm
                item={updateDescription}
                setItem={setUpdateDescription}
                label="Game Description:"
              />

              {updateDescription && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <FormControl>
                    <FormLabel as="legend">Description:</FormLabel>

                    <Input
                      name="description"
                      value={meta.description}
                      onChange={e => handleMeta('description', e.target.value)}
                      placeholder="NFT description"
                      size="sm"
                    />
                  </FormControl>
                </Box>
              )}

              <SwitchForm
                item={updateTags}
                setItem={setUpdateTags}
                label="Tags:"
              />

              {updateTags && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <FormControl>
                    <FormLabel as="legend">
                      Tags:
                      <Popover>
                        <PopoverTrigger>
                          <IconButton
                            variant="outline"
                            colorScheme="green"
                            aria-label="Get Info"
                            size="xs"
                            icon={<FaInfo boxSize={8} />}
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverBody>
                            Separate tags with comma, for example: fairy,
                            beautiful, romantic, ...
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </FormLabel>
                    <Input
                      name="tags"
                      value={meta.tags}
                      onChange={e => handleMeta('tags', e.target.value)}
                      placeholder="Tags"
                      size="sm"
                    />
                  </FormControl>
                </Box>
              )}

              <SwitchForm
                item={updateExternalUrlToGame}
                setItem={setUpdateExternalUrlToGame}
                label="External Url To Game:"
              />

              {updateExternalUrlToGame && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <FormControl>
                    <FormLabel as="legend">
                      External Url for This Game:
                    </FormLabel>

                    <UrlPrefixWarning urlType="general" />

                    <Input
                      name="externalNftUrl"
                      value={meta.externalUrlToGame}
                      onChange={e =>
                        handleMeta('externalUrlToGame', e.target.value)
                      }
                      placeholder={
                        'External Url must start with ' + urlPrefix['general']
                      }
                      size="sm"
                    />
                  </FormControl>
                </Box>
              )}

              <SwitchForm
                item={updateTrialUrl}
                setItem={setUpdateTrailUrl}
                label="Url to Game Trial:"
              />

              {updateTrialUrl && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <FormControl>
                    <FormLabel as="legend">
                      NFT Trial Url for This Game:
                    </FormLabel>
                    <HStack>
                      <InfoIcon />
                      <Text as="i" fontSize="xs">
                        The NFT trial URL and the external URL must come from
                        the same domain. If they are not from the same domain, a
                        warning will be displayed for this game.
                      </Text>
                    </HStack>

                    <Input
                      name="trialUrl"
                      value={meta.trialUrl}
                      onChange={e => handleMeta('trialUrl', e.target.value)}
                      placeholder="Trial Url and the game url must come from the same domain "
                      size="sm"
                    />
                  </FormControl>
                </Box>
              )}

              <SwitchForm
                item={updateX}
                setItem={setUpdateX}
                label="Url to X:"
              />

              {updateX && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <SocialMediaForm
                    socialMediaFields={['x']}
                    meta={meta}
                    handleMeta={handleMeta}
                  />
                </Box>
              )}

              <SwitchForm
                item={updateFacebook}
                setItem={setUpdateFacebook}
                label="Url to Facebook:"
              />

              {updateFacebook && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <SocialMediaForm
                    socialMediaFields={['Facebook']}
                    meta={meta}
                    handleMeta={handleMeta}
                  />
                </Box>
              )}

              <SwitchForm
                item={updateYoutube}
                setItem={setUpdateYoutube}
                label="Url to Youtube:"
              />

              {updateYoutube && (
                <Box
                  style={{ marginBottom: '20px' }}
                  boxShadow="dark-lg"
                  p="6"
                  rounded="md"
                >
                  <SocialMediaForm
                    socialMediaFields={['Youtube']}
                    meta={meta}
                    handleMeta={handleMeta}
                  />
                </Box>
              )}
            </Box>
          </CardBody>
        </Card>

        <RenderButton />
      </VStack>
    </div>
  );
};

export default UpdateGame;
