import {
  Box,
  Input,
  FormControl,
  FormLabel,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { FaInfo } from 'react-icons/fa';
const MagicFlowerMetaForm = ({ metadata, setMetadata }) => {
  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <FormControl>
          <FormLabel as="legend">Url to Metadata:</FormLabel>

          <Input
            name="metadata"
            value={metadata}
            onChange={e => setMetadata(e.target.value)}
            placeholder="Url to Metadata"
            size="sm"
          />
        </FormControl>
      </Box>
    </>
  );
};
export default MagicFlowerMetaForm;
