import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import MagicFlowers from '../../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import MagicFlowersSupport from '../../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS, REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';

const HybridizeMagicFlowerButton = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [plant1, setPlant1] = useState(0);
  const [plant2, setPlant2] = useState(0);
  const [areValidParents, setAreValidParents] = useState(false);
  const [showValidity, setShowValidity] = useState(false);
  const [rightToUse, setRightToUse] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const validateParents = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);

    try {
      const magicFlowersSupport = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
        MagicFlowersSupport.abi,
        provider
      );

      const _areValidParents = await magicFlowersSupport.areValidParents(
        plant1,
        plant2
      );
      // console.log('_areValidParents:', _areValidParents);
      setAreValidParents(_areValidParents);
      setShowValidity(true);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderRightToUse = () => {
    if (rightToUse) {
      return (
        <Alert status="success">
          <AlertIcon />
          Rights are confirmed!
        </Alert>
      );
    } else {
      return (
        <Alert status="error">
          <AlertIcon />
          You must either own the plant or have a valid lease to use it.
        </Alert>
      );
    }
  };

  const haveRightToUse = (isOwner1, isOwner2, isValidLease1, isValidLease2) => {
    if (isOwner1 && isOwner2) {
      return true;
    }
    if (isValidLease1 && isValidLease2) {
      return true;
    }
    if (isOwner1 && isValidLease2) {
      return true;
    }
    if (isOwner2 && isValidLease1) {
      return true;
    }
    return false;
  };

  const checkRightToUse = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);

    try {
      const magicFlowersSupport = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
        MagicFlowersSupport.abi,
        provider
      );

      const magicFlowers = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_ADDRESS,
        MagicFlowers.abi,
        provider
      );

      const _ownerPlant1 = await magicFlowers.ownerOf(plant1);
      const _ownerPlant2 = await magicFlowers.ownerOf(plant2);
      const _isValidLease1 = await magicFlowersSupport.isValidRenting(
        plant1,
        address
      );
      const _isValidLease2 = await magicFlowersSupport.isValidRenting(
        plant2,
        address
      );
      const _isOwner1 = address === _ownerPlant1 ? true : false;
      const _isOwner2 = address === _ownerPlant2 ? true : false;
      const _haveRight = haveRightToUse(
        _isOwner1,
        _isOwner2,
        _isValidLease1,
        _isValidLease2
      );
      setRightToUse(_haveRight);
      setShowRight(true);
    } catch (error) {
      console.log(error);
    }
  };

  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('signer:', signer);

    try {
      const magicFlowers = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_ADDRESS,
        MagicFlowers.abi,
        signer
      );

      const mintingFee = magicFlowers.getMagicFlowersHybridizationFee(false); // 0.5 matic

      const transaction = await magicFlowers.hybridize(plant1, plant2, {
        value: mintingFee,
      });

      await transaction.wait();

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Hybridize
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Hybridize
        </Button>
      );
    }
  };

  const RenderParentsValidity = () => {
    // console.log('are valid parents:', areValidParents);
    if (areValidParents) {
      return (
        <VStack>
          <Alert status="success">
            <AlertIcon />
            These 2 plants can be hybridized!
          </Alert>

          <Alert status="info">
            <AlertIcon />
            Parents Validity only show whether these 2 plants can be hybridized
            together. You also need to be either the owner or have valid leasing
            to use them. If you are not sure, check your right to use them.
          </Alert>

          <Box>
            <Button
              onClick={checkRightToUse}
              size="lg"
              colorScheme="green"
              variant="outline"
              mr={3}
            >
              Check Usage Rights
            </Button>
          </Box>
          {showRight && <RenderRightToUse />}
        </VStack>
      );
    } else {
      return (
        <VStack>
          <Alert status="error">
            <AlertIcon />
            These 2 plants cannot be hybridized!
          </Alert>
        </VStack>
      );
    }
  };

  return (
    <VStack>
      <Box>
        <Button
          onClick={onOpen}
          size="lg"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Hybridize Magic Flowers
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Hybridize Magic Flowers</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                <Box style={{ marginBottom: '20px' }}>
                  <FormControl isRequired>
                    <FormLabel as="legend">
                      Magic Flower Id for Plant 1
                    </FormLabel>

                    <NumberInput
                      min={0}
                      value={plant1}
                      onChange={e => setPlant1(e)}
                      placeholder="Magic Flower NFT Id for Plant 1"
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </Box>
                <Box style={{ marginBottom: '20px' }}>
                  <FormControl isRequired>
                    <FormLabel as="legend">
                      Magic Flower Id for Plant 2
                    </FormLabel>

                    <NumberInput
                      min={0}
                      value={plant2}
                      onChange={e => setPlant2(e)}
                      placeholder="Magic Flower NFT Id for Plant 1"
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </Box>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <VStack>
                <HStack>
                  <Box>
                    <Button
                      onClick={validateParents}
                      size="lg"
                      colorScheme="green"
                      variant="outline"
                      mr={3}
                    >
                      Validate Parents
                    </Button>
                  </Box>

                  <RenderButton />
                </HStack>
                {showValidity && <RenderParentsValidity />}
              </VStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </VStack>
  );
};

export default HybridizeMagicFlowerButton;
