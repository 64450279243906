import { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  VStack,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import Games from '../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import { RenderShowGameFeatureButton } from '../views/NFTs/elements/FormElements';
import {
  REACT_APP_GAMES_ADDRESS
} from '../views/cozyGames/constants/ContractAddress';

const ChooseFeatureForm = ({
  index,
  gameTokenId,
  checkedItems,
  setCheckedItems,
  listOfGameFeatures,
  setListOfGameFeatures,
  showGameFeatures,
  setShowGameFeatures,
  tokenContract,
  tokenId,
  label,
  onlyReserved = false,
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  // console.log('tokenContract:', tokenContract);
  const [gameAuthorized, setGameAuthorized] = useState(false);
  const allChecked = cardIndex => checkedItems[cardIndex].every(Boolean);
  const isIndeterminate = cardIndex =>
    checkedItems[cardIndex].some(Boolean) && !allChecked(cardIndex);

  const handleCheckedItems = (e, cardIndex, itemIndex) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[cardIndex][itemIndex] = e.target.checked;
    setCheckedItems(updatedCheckedItems);
    // console.log(
    //   `Updated checked items for card ${cardIndex}:`,
    //   updatedCheckedItems[cardIndex]
    // );
  };

  const RenderFeatureCheckbox = props => {
    return (
      <>
        <Checkbox
          isChecked={allChecked(props.cardIndex)}
          isIndeterminate={isIndeterminate(props.cardIndex)}
          // onChange={e => setCheckedItems([e.target.checked, e.target.checked])}
          onChange={() => {
            const updatedCheckedItems = checkedItems.map(innerArray =>
              innerArray.map(() => true)
            );
            setCheckedItems(updatedCheckedItems);
          }}
        >
          Available game features to {label}
        </Checkbox>
        <Stack pl={6} mt={1} spacing={1}>
          {checkedItems[props.cardIndex].map((item, index) => (
            <Checkbox
              isChecked={checkedItems[props.cardIndex][index]}
              onChange={e => handleCheckedItems(e, props.cardIndex, index)}
            >
              <RenderFeatureName index={index} />
            </Checkbox>
          ))}
        </Stack>
      </>
    );
  };

  const RenderFeatureName = props => {
    return <>{listOfGameFeatures[props.index].name}</>;
  };

  const handleShowGameFeatures = async () => {
    if (!showGameFeatures) {
      await getListOfGameFeatures();
      setShowGameFeatures(true);
    }
  };

  const getListOfGameFeatures = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    const signer_address = await signer.getAddress();
    console.log('signer:', signer_address);
    const games = new ethers.Contract(
      REACT_APP_GAMES_ADDRESS,
      Games.abi,
      provider
    );

    // const gameTokenGallery = new ethers.Contract(
    //   REACT_APP_GAME_TOKEN_GALLERY_ADDRESS,
    //   GameTokenGallery.abi,
    //   provider
    // );

    if (gameTokenId > 0) {
      if (onlyReserved) {
        let _gameAuthorized = await games.gameOwnerOrAdmin(
          signer_address,
          gameTokenId
        );
        if (!_gameAuthorized) {
          return;
        } else {
          setGameAuthorized(true);
        }
      }

      let _allFeatures = [];
      // let _allFeaturesAdded = [];
      // let data = [];

      const allFeatures = await games.getAllFeaturesByGame(gameTokenId);
      console.log('allFeatures:', allFeatures);
      _allFeatures = onlyReserved
        ? allFeatures.featuresReserved
        : allFeatures.featuresExternal;

      console.log('_allFeatures:', _allFeatures);

      // const allFeaturesAdded =
      //   await gameTokenGallery.getGameFeatureAddedByToken(
      //     tokenContract,
      //     tokenId,
      //     gameTokenId
      //   );
      // console.log('allFeautresAdded:', allFeaturesAdded);
      // _allFeaturesAdded = onlyReserved
      //   ? allFeaturesAdded._featuresReserved
      //   : allFeaturesAdded._featuresExternal;
      // console.log('_allFeautresAdded:', _allFeaturesAdded);
      // if (label === 'Remove') {
      //   data = _allFeaturesAdded;
      // } else {
      //   data = excludeElementsInArr2(_allFeatures, _allFeaturesAdded);
      // }

      // console.log('data:', data);
      // let _featureFees = await games.getFeeForFeatures(
      //   gameTokenId,
      //   data
      // );
      // console.log('_featureFees:', _featureFees);
      // _featureFees = _featureFees.map(fee =>
      //   Number(fee.toString()) === 0
      //     ? 'Free'
      //     : ethers.utils.formatUnits(fee.toString(), 'ether') + ' Matic'
      // );

      let _data = [];
      for (let i = 0; i < _allFeatures.length; i++) {
        let _feature = {
          name: _allFeatures[i],
          // fee: _featureFees[i],
        };
        _data.push(_feature);
      }

      setListOfGameFeatures(_data);
      const n = _allFeatures.length; // Replace with the desired length
      const _item = false;
      const newArray = Array.from({ length: n }, () => _item);
      // console.log('newArray', newArray);
      let _checkedItems = [];
      _checkedItems.push(newArray);
      console.log('_checkedItems:', _checkedItems);
      setCheckedItems(_checkedItems);
    }
  };

  const RenderForm = () => {
    if (onlyReserved) {
      return (
        <Box style={{ marginBottom: '20px' }}>
          <FormControl isRequired>
            <FormLabel as="legend">{label} Game features:</FormLabel>
          </FormControl>
          <VStack>
            <RenderShowGameFeatureButton onChange={handleShowGameFeatures} />
            {showGameFeatures && gameAuthorized && (
              <>
                <RenderFeatureCheckbox cardIndex={index} />
              </>
            )}
            {showGameFeatures && !gameAuthorized && (
              <Text>
                Those features are only reserved for game owner or game admin
              </Text>
            )}
          </VStack>
        </Box>
      );
    } else {
      return (
        <Box style={{ marginBottom: '20px' }}>
          <FormControl isRequired>
            <FormLabel as="legend">{label} Game features:</FormLabel>
          </FormControl>
          <VStack>
            <RenderShowGameFeatureButton onChange={handleShowGameFeatures} />
            {showGameFeatures && (
              <>
                <RenderFeatureCheckbox cardIndex={index} />
              </>
            )}
          </VStack>
        </Box>
      );
    }
  };

  return (
    <>
      <RenderForm />
    </>
  );
};

export default ChooseFeatureForm;
