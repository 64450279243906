import { Text, VStack } from '@chakra-ui/react';
import Blackboard1 from '../../../../../resources/images/frames/blackboard1.png';
import BackLink from './BackLink';
const DiceOutcomeBoard = ({ lastDiceOutcome, t, magicflowerId, magicflowerName }) => {
  return (
    <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
      <img src={Blackboard1} alt="blackboard" style={{ width: '385px' }} />

      <div
        style={{
          position: 'absolute',
          top: '43%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <VStack spacing={6} mt='8px'>
          <Text
            color="white.500"
            fontSize="xl"
            style={{ fontFamily: 'Lobster' }}
          >
            Last dice outcome:
          </Text>
          <Text
            color="white.500"
            fontSize="xl"
            style={{ fontFamily: 'Lobster' }}
          >
            {lastDiceOutcome}
          </Text>
          <Text
            color="white.500"
            fontSize="xl"
            style={{ fontFamily: 'Lobster' }}
          >
            {t}
          </Text>
          <BackLink magicflowerId={magicflowerId} />
        </VStack>
      </div>
    </div>
  );
};

export default DiceOutcomeBoard;
