import MagicFlowers from '../../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import MagicFlowersSupport from '../../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import MagicFlowersFull from '../../../../../contracts/MagicFlowers.sol/MagicFlowers.json';
import { ethers } from 'ethers';
import axios from 'axios';
import getDefaultData from './getDefaultData';
import getStone from './utils/getStone';
import {
  REACT_APP_MAGIC_FLOWERS_ADDRESS,
  REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
} from '../../constants/ContractAddress';

const getMagicFlowerDetail = async (provider, magicflowerId) => {
  const magicFlowers = new ethers.Contract(
    REACT_APP_MAGIC_FLOWERS_ADDRESS,
    MagicFlowersFull.abi,
    provider
  );

  const magicFlowersSupport = new ethers.Contract(
    REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
    MagicFlowersSupport.abi,
    provider
  );

  const _magicFlowerOwner = await magicFlowers.ownerOf(magicflowerId);
  // console.log('_magicFlowerOwner:', _magicFlowerOwner)

  const data = await magicFlowersSupport.getTokenProperties(magicflowerId);
  // console.log('data:', data);

  let nameMeta = {};

  const _nameMeta = await magicFlowers.magicFlowersMeta(magicflowerId);
  if (_nameMeta.length > 0) {
    nameMeta = await axios.get(_nameMeta);
    nameMeta = nameMeta.data;
  }

  let metadata = {};

  let _metaUri = await magicFlowers.tokenURI(magicflowerId);
  // console.log('_meta:', _meta);
  if (_metaUri.length > 0) {
    const metadataUri = await axios.get(_metaUri);
    _metaUri = metadataUri.data;
  }

  let _metadata = await magicFlowersSupport.getTokenProperties(magicflowerId);
  // console.log('_meatadata:', _metadata);

  metadata = {
    metaUri: _metaUri,
    plantType: Number(_metadata.plantType.toString()),
    birthdate: _metadata.genes[0].timestamp.toString(),
    sex: Number(_metadata.sex.toString()),
    totalOffsprings: Number(_metadata.totalOffsprings.toString()),
    isHybridization: _metadata.isHybridization,
    isVigor: _metadata.isVigor,
    infiniteEvolve: _metadata.infiniteEvolve,
    evolvedTimes: _metadata.genes.length - 1,
  };

  // console.log('meta:', _meta);

  const _userDefault = await getDefaultData(
    provider,
    magicflowerId,
    _magicFlowerOwner
  );
  // console.log('_userDefault:', _userDefault);

  let _userDefaultBgs = [];
  _userDefaultBgs.push(
    typeof _userDefault.BackgroundDeepNight === 'undefined'
      ? []
      : _userDefault.BackgroundDeepNight
  );
  _userDefaultBgs.push(
    typeof _userDefault.BackgroundDawn === 'undefined'
      ? []
      : _userDefault.BackgroundDawn
  );
  _userDefaultBgs.push(
    typeof _userDefault.BackgroundMorning === 'undefined'
      ? []
      : _userDefault.BackgroundMorning
  );
  _userDefaultBgs.push(
    typeof _userDefault.BackgroundAfternoon === 'undefined'
      ? []
      : _userDefault.BackgroundAfternoon
  );
  _userDefaultBgs.push(
    typeof _userDefault.BackgroundEvening === 'undefined'
      ? []
      : _userDefault.BackgroundEvening
  );
  _userDefaultBgs.push(
    typeof _userDefault.BackgroundNight === 'undefined'
      ? []
      : _userDefault.BackgroundNight
  );

  // console.log('_userDefaultBgs:', _userDefaultBgs);
  let _plantSeed = Number(data.plantSeed.toString());
  let stoneIndex = getStone(_plantSeed);
  let _features = {};
  _features.plantType = Number(data.plantType.toString());
  _features.stoneIndex = stoneIndex;
  _features.plantSeed = _plantSeed;
  _features.removeDefaultFairy = false;

  let _allGenes = [];
  let _evolvingPoints = [];

  for (let i = 0; i < data.genes.length; i++) {
    let _g = {};
    let _gene = data.genes[i];
    _g.timestamp = Number(_gene.timestamp.toString());
    _g.seed = Number(_gene.seed.toString());

    _allGenes.push(_g);
    _evolvingPoints.push(Number(data.genes[i].timestamp.toString()));
  }

  // console.log('_allGenes:', _allGenes);
  // console.log('_evolvingPoints:', _evolvingPoints);
  let lastDiceOutcomes = [];
  if (data.diceOutcomes.length > 0) {
    let _lastDiceOutcomes = data.diceOutcomes[data.diceOutcomes.length - 1];
    lastDiceOutcomes = _lastDiceOutcomes.map(d => Number(d.toString()));

    // console.log('lastDiceOutcomes:', lastDiceOutcomes);
  }

  let lease = { hasLease: false, magicflowerId, leasePrice: 0 };
  let leaseListingId =
    await magicFlowersSupport.getLeaseListingIdByMagicflowerId(magicflowerId);
  if (Number(leaseListingId.toString()) > 0) {
    let leaseData = await magicFlowersSupport.getMagicFlowerLease(
      magicflowerId
    );
    // console.log('leaseData:', leaseData);
    lease = {
      hasLease: true,
      magicflowerId,
      leasePrice: ethers.utils.formatUnits(leaseData.price.toString(), 'ether'),
    };
  }

  const res = {
    magicFlowerOwner: _magicFlowerOwner,
    userDefault: _userDefault,
    userDefaultBgs: _userDefaultBgs,
    metadata,
    nameMeta,
    plantFeatures: _features,
    allGenes: _allGenes,
    displayTimePoint: _allGenes.length - 1,
    evolvingPoints: _evolvingPoints,
    lastDiceOutcomes,
    lease,
  };
  // console.log('res:', res);
  return res;
};

export default getMagicFlowerDetail;
