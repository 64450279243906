export function leaf1(p5, leaf_color) {
  p5.noStroke();
  p5.fill(leaf_color[0], leaf_color[1], leaf_color[2], 160);
  let pts = [
    p5.createVector(0, -9), //top
    p5.createVector(-6, 0), //left
    p5.createVector(0, 9), //bottom
    p5.createVector(6, 0), //right
  ];

  p5.curveTightness(-0.2);
  let p1 = p5.constructor.Vector.lerp(pts[1], pts[2], 0.5);
  let p1_reverse = p5.constructor.Vector.lerp(pts[3], pts[2], 0.5);
  let p2 = p5.constructor.Vector.lerp(pts[0], pts[2], 0.95);
  let p23 = p5.constructor.Vector.lerp(pts[2], pts[3], 0.75);
  let p23_reverse = p5.constructor.Vector.lerp(pts[2], pts[1], 0.75);
  let p3 = p5.constructor.Vector.lerp(pts[1], p23, 0.95);
  let p3_reverse = p5.constructor.Vector.lerp(pts[3], p23_reverse, 0.95);
  let p4 = p5.constructor.Vector.lerp(pts[3], pts[0], 0.8);
  let p4_reverse = p5.constructor.Vector.lerp(pts[1], pts[0], 0.8);
  let p_5 = p5.constructor.Vector.lerp(p4, pts[1], 0.09);
  let p_5_reverse = p5.constructor.Vector.lerp(p4_reverse, pts[3], 0.09);
  let p6 = p5.constructor.Vector.lerp(pts[0], pts[2], 0.05);

  p5.beginShape();
  p5.curveVertex(p1.x, p1.y);
  p5.curveVertex(p2.x, p2.y);
  p5.curveVertex(p3.x, p3.y);
  p5.curveVertex(p_5.x, p_5.y);
  p5.vertex(p6.x, p6.y);
  p5.curveVertex(p_5_reverse.x, p_5_reverse.y);
  p5.curveVertex(p3_reverse.x, p3_reverse.y);
  p5.curveVertex(p2.x, p2.y);
  p5.curveVertex(p1_reverse.x, p1_reverse.y);
  p5.endShape();

  p5.stroke(leaf_color[3], leaf_color[4], leaf_color[5], 160);
  p5.strokeWeight(0.8);
  p5.line(0, 5, 0, 0);
}

export function leaf1_treeCanvas(p5, treeCanvas, leaf_color) {
  treeCanvas.noStroke();
  treeCanvas.fill(leaf_color.lc.h, leaf_color.lc.s, leaf_color.lc.b, 160);
  let pts = [
    p5.createVector(0, -9), //top
    p5.createVector(-6, 0), //left
    p5.createVector(0, 9), //bottom
    p5.createVector(6, 0), //right
  ];

  treeCanvas.curveTightness(-0.2);
  let p1 = p5.constructor.Vector.lerp(pts[1], pts[2], 0.5);
  let p1_reverse = p5.constructor.Vector.lerp(pts[3], pts[2], 0.5);
  let p2 = p5.constructor.Vector.lerp(pts[0], pts[2], 0.95);
  let p23 = p5.constructor.Vector.lerp(pts[2], pts[3], 0.75);
  let p23_reverse = p5.constructor.Vector.lerp(pts[2], pts[1], 0.75);
  let p3 = p5.constructor.Vector.lerp(pts[1], p23, 0.95);
  let p3_reverse = p5.constructor.Vector.lerp(pts[3], p23_reverse, 0.95);
  let p4 = p5.constructor.Vector.lerp(pts[3], pts[0], 0.8);
  let p4_reverse = p5.constructor.Vector.lerp(pts[1], pts[0], 0.8);
  let p_5 = p5.constructor.Vector.lerp(p4, pts[1], 0.09);
  let p_5_reverse = p5.constructor.Vector.lerp(p4_reverse, pts[3], 0.09);
  let p6 = p5.constructor.Vector.lerp(pts[0], pts[2], 0.05);

  treeCanvas.beginShape();
  treeCanvas.curveVertex(p1.x, p1.y);
  treeCanvas.curveVertex(p2.x, p2.y);
  treeCanvas.curveVertex(p3.x, p3.y);
  treeCanvas.curveVertex(p_5.x, p_5.y);
  treeCanvas.vertex(p6.x, p6.y);
  treeCanvas.curveVertex(p_5_reverse.x, p_5_reverse.y);
  treeCanvas.curveVertex(p3_reverse.x, p3_reverse.y);
  treeCanvas.curveVertex(p2.x, p2.y);
  treeCanvas.curveVertex(p1_reverse.x, p1_reverse.y);
  treeCanvas.endShape();

  treeCanvas.stroke(leaf_color.vc.h, leaf_color.vc.s, leaf_color.vc.b, 160);
  treeCanvas.strokeWeight(0.8);
  treeCanvas.line(0, 5, 0, 0);
}
