import { ethers } from 'ethers';
import GameTokenGallery from '../../../../../contracts/APIs/GameTokenGalleryInterface.sol/GameTokenGalleryInterface.json';
import Games from '../../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import { removeZeroFromArray } from '../../../../utils/helpers';
import { getGameInfo, getGameName } from './getGameInfo';
import {
  REACT_APP_GAME_TOKEN_GALLERY_ADDRESS,
  REACT_APP_GAMES_ADDRESS,
} from '../../../cozyGames/constants/ContractAddress';

export const getIntegratedGames = async (
  provider,
  tokenContract,
  tokenId,
  player
) => {
  const gameTokenGallery = new ethers.Contract(
    REACT_APP_GAME_TOKEN_GALLERY_ADDRESS,
    GameTokenGallery.abi,
    provider
  );

  const _gamesIntegrated = await gameTokenGallery.getIntegratedGames(
    player,
    tokenContract,
    tokenId
  );
  // console.log('_gamesIntegrated:', _gamesIntegrated);

  let integratedGameTokenIds = [];
  if (_gamesIntegrated.length > 0) {
    for (let i = 0; i < _gamesIntegrated.length; i++) {
      if (!_gamesIntegrated[i].removed) {
        integratedGameTokenIds.push(
          Number(_gamesIntegrated[i].gameId.toString())
        );
      }
    }

    integratedGameTokenIds = removeZeroFromArray(integratedGameTokenIds);
  }

  let gameNames = [];

  if (integratedGameTokenIds.length > 0) {
    gameNames = await getGameName(provider, integratedGameTokenIds);
  }

  const res = {
    gameIds: integratedGameTokenIds,
    gameNames,
  };

  return res;
};

export const getIntegratedGamesDetailed = async (
  provider,
  tokenContract,
  tokenId,
  player
) => {
  const gameTokenGallery = new ethers.Contract(
    REACT_APP_GAME_TOKEN_GALLERY_ADDRESS,
    GameTokenGallery.abi,
    provider
  );

  const games = new ethers.Contract(
    REACT_APP_GAMES_ADDRESS,
    Games.abi,
    provider
  );

  const _gamesIntegrated = await gameTokenGallery.getIntegratedGames(
    player,
    tokenContract,
    tokenId
  );
  // console.log('_gamesIntegrated:', _gamesIntegrated);

  let integratedGameTokenIds = [];
  let featuresExternalAll = [];
  let featuresReservedAll = [];
  if (_gamesIntegrated.length > 0) {
    for (let i = 0; i < _gamesIntegrated.length; i++) {
      if (!_gamesIntegrated[i].removed) {
        integratedGameTokenIds.push(
          Number(_gamesIntegrated[i].gameId.toString())
        );
        let _externalFeatureIds = _gamesIntegrated[i].featuresExternal;

        _externalFeatureIds = _externalFeatureIds.map(id =>
          Number(id.toString())
        );
        // console.log('_externalFeatureIds:', _externalFeatureIds);
        let _reservedFeatureIds = _gamesIntegrated[i].featuresReserved;
        _reservedFeatureIds = _reservedFeatureIds.map(id =>
          Number(id.toString())
        );
        // console.log('_reservedFeatureIds:', _reservedFeatureIds);
        let _featuresNames = await games.getGameFeaturesByFeatureId(
          Number(_gamesIntegrated[i].gameId.toString()),
          _externalFeatureIds,
          _reservedFeatureIds
        );
        // console.log('_featuresNames:', _featuresNames);
        featuresExternalAll.push(_featuresNames._featuresExternal);
        featuresReservedAll.push(_featuresNames._featuresReserved);
      }
    }

    // console.log('integratedGameTokenIds:', integratedGameTokenIds);
    // console.log('featuresExternalAll:', featuresExternalAll);
    // console.log('featuresReservedAll:', featuresReservedAll);

    integratedGameTokenIds = removeZeroFromArray(integratedGameTokenIds);
  }

  let _integratedGames = [];

  if (integratedGameTokenIds.length > 0) {
    let _resIntegrated = await getGameInfo(
      provider,
      integratedGameTokenIds,
      false
    );
    _integratedGames = _resIntegrated[0];
    // console.log('_integratedGames:', _integratedGames);
    for (let j = 0; j < _gamesIntegrated.length; j++) {
      _integratedGames[j].featuresExternal = featuresExternalAll[j];
      _integratedGames[j].featuresReserved = featuresReservedAll[j];
      _integratedGames[j].features = featuresExternalAll[j].concat(
        featuresReservedAll[j]
      );
      _integratedGames[j].branch = _gamesIntegrated[j].branch;
    }
  }
  // console.log('_integratedGames:', _integratedGames);
  return _integratedGames;
};

export const getGameFeaturesByFeatureIds = async (
  games,
  _gameId,
  _externalFeatureIds,
  _reservedFeatureIds
) => {
  _externalFeatureIds = _externalFeatureIds.map(id => Number(id.toString()));
  // console.log('_externalFeatureIds:', _externalFeatureIds);

  _reservedFeatureIds = _reservedFeatureIds.map(id => Number(id.toString()));
  // console.log('_reservedFeatureIds:', _reservedFeatureIds);
  let _featuresNames = await games.getGameFeaturesByFeatureId(
    _gameId,
    _externalFeatureIds,
    _reservedFeatureIds
  );
  // console.log('_featuresNames:', _featuresNames);

  return _featuresNames;
};
