export class FlowerGenus4treeCanvas {
  constructor(p5, treeCanvas, numPetals, _r) {
    this.p5 = p5;
    this.treeCanvas = treeCanvas;
    this.x = 0;
    this.y = 0;
    this.numPetals = numPetals;
    this.R = _r;
    this.startHue = this.p5.random(360);
    this.contrast = 30;
    this.rainbowColors = [
      [0, 100, 100], // red
      [30, 100, 100], // orange
      [60, 100, 100], // yellow
      [120, 100, 100], // green
      [240, 100, 100], // blue
      [270, 100, 100], // indigo
      [300, 100, 100], // violet
    ];
    this.rainbowVariation = this.getRainbowVariation();
    let sevenColors = this.getPetalColors();
    let oneColor = Array(7).fill([this.p5.int(this.p5.random(360)), 100, 100]);

    if (this.p5.random() > 0.58) {
      this.petalColors = oneColor;
    } else {
      if (this.p5.random() > 0.988) {
        if (this.p5.random() > 0.988) {
          this.petalColors = this.rainbowColors;
        } else {
          this.petalColors = this.rainbowVariation;
        }
      } else {
        if (this.p5.random() > 0.5) {
          this.petalColors = sevenColors[0];
        } else {
          this.petalColors = sevenColors[1];
        }
      }
    }
  }
  display(x, y) {
    this.x = x;
    this.y = y;
    this.treeCanvas.push();
    this.treeCanvas.noStroke();
    this.treeCanvas.translate(this.x, this.y);
    this.treeCanvas.rotate(this.p5.radians((1 * 45) / 2));
    for (let i = 0; i < this.numPetals; i++) {
      this.treeCanvas.fill(
        this.petalColors[i][0],
        this.petalColors[i][1],
        this.petalColors[i][2],
        0.88
      );

      this.treeCanvas.push();
      this.treeCanvas.translate(
        this.R * this.p5.cos(this.p5.radians((360 * i) / this.numPetals)),
        this.R * this.p5.sin(this.p5.radians((360 * i) / this.numPetals))
      );
      this.treeCanvas.rotate(this.p5.radians((360 * i) / this.numPetals + 180));
      this.treeCanvas.beginShape();
      for (let t = 0; t < this.p5.TWO_PI; t += 0.1) {
        let r = 1 / (5 * this.p5.sin(t / 2) + 1);
        this.treeCanvas.vertex(
          this.R * r * this.p5.cos(t),
          this.R * r * this.p5.sin(t)
        ); //, 5, 5);
      }
      this.treeCanvas.endShape(this.p5.CLOSE);
      this.treeCanvas.pop();
    }
    this.treeCanvas.pop();
  }

  getPetalColors() {
    let _petalColors = [];
    for (let i = 0; i < this.numPetals; i++) {
      // Calculate the hue for the current petal
      const hue = (this.startHue + i * this.contrast) % 360;
      // Calculate the hue of the complementary color
      const compHue = (hue + 180) % 360;
      // Shift the hue slightly to avoid selecting similar colors
      const petalHue = this.p5.int((hue + this.p5.random(-10, 10) + 360) % 360);
      const compPetalHue = this.p5.int(
        (compHue + this.p5.random(-10, 10) + 360) % 360
      );
      _petalColors.push(
        [petalHue, this.p5.random(88, 100), this.p5.random(88, 100)],
        [compPetalHue, this.p5.random(88, 100), this.p5.random(88, 100)]
      );
    }

    let arr1 = _petalColors.slice(0, 7);
    let arr2 = _petalColors.slice(7);
    return [arr1, arr2];
  }

  getRainbowVariation() {
    const colorVariations = [];
    for (let i = 0; i < 7; i++) {
      const h = this.rainbowColors[i][0] + this.p5.int(this.p5.random(-8, 8));
      colorVariations.push([h, 100, 100]);
    }
    return colorVariations;
  }
}
