import { NFTStorage, File, Blob } from 'nft.storage';

// export const client = new Web3Storage({
//   token: process.env.REACT_APP_WEB3_STORAGE_KEY,
// });

export const clientNFT = new NFTStorage({
  token: process.env.REACT_APP_NFT_STORAGE_KEY,
});

export const storeFiles = async file => {
  console.log('file:', file);
  const fileName = file.name.split(' ').join('');
  const image = new File([file], fileName, { type: file.type });
  return image;
};

export const deleteFile = async _cid => {
  await clientNFT.delete(_cid)
}

export const makeMagicFlowerMetaUrl = async (meta, magicflowerId) => {
  const obj = {
    magicflowerId,
    image: meta.imageUrl,
    name: meta.name,
    description: meta.description,
    spiritName: meta.spiritName,
    spiritDescription: meta.spiritDescription,
    tags: meta.tags,
  };
  const blob = new Blob([JSON.stringify(obj)], {
    type: 'application/json',
  });

  const cid = await clientNFT.storeBlob(blob);
  console.log('cid:', cid);
  return cid;

  // const metadataURI = `https://${cid}.ipfs.nftstorage.link/`;
  // return metadataURI;
};

export const makeTokenUrl = async (meta, attributes) => {
  const obj = {
    image: meta.imageUrl,
    name: meta.name,
    description: meta.description,
    category: meta.category,
    external_url: meta.externalNftUrl,
    youtube_url: meta.youtube,
    tags: meta.tags,
    usePreview: meta.usePreview,
    previewUrl: meta.previewUrl,
    attributes: attributes,
  };
  const blob = new Blob([JSON.stringify(obj)], {
    type: 'application/json',
  });

  const cid = await clientNFT.storeBlob(blob);
  console.log('cid:', cid);
  return cid;

  // const metadataURI = `https://${cid}.ipfs.nftstorage.link/`;
  // return metadataURI;
};

export const makeGameMetaUrl = async meta => {
  const blob = new Blob([JSON.stringify(meta)], {
    type: 'application/json',
  });

  const cid = await clientNFT.storeBlob(blob);
  console.log('cid:', cid);
  return cid;

  // const metadataURI = `https://${cid}.ipfs.nftstorage.link/`;
  // return metadataURI;
};

export const makeOtherCid = async otherMeta => {
  const blob = new Blob([JSON.stringify(otherMeta)], {
    type: 'application/json',
  });

  const cid = await clientNFT.storeBlob(blob);
  console.log('cid:', cid);

  return cid;

  // const metadataURI = `https://${cid}.ipfs.nftstorage.link/`;
  // return metadataURI;
};

export const updateMetaInUrl = async (_imageCid, _musicCid, meta) => {
  const obj = {
    imageUrl: _imageCid,
    musicUrl: _musicCid,
    meta,
  };
  const blob = new Blob([JSON.stringify(obj)], {
    type: 'application/json',
  });

  const cid = await clientNFT.storeBlob(blob);
  console.log('cid:', cid);

  return cid;

  // const metadataURI = `https://${cid}.ipfs.nftstorage.link/`;
  // return metadataURI;
};

export const addCollectionUrl = async (collectionImageUrl, meta) => {
  const obj = {
    collectionImageUrl,
    meta,
  };
  const blob = new Blob([JSON.stringify(obj)], {
    type: 'application/json',
  });

  const cid = await clientNFT.storeBlob(blob);
  console.log('cid:', cid);

  return cid;
};

export const addGamesCompatibleUrl = async gamesCompatible => {
  const obj = {
    gamesCompatible,
  };
  const blob = new Blob([JSON.stringify(obj)], {
    type: 'application/json',
  });

  const cid = await clientNFT.storeBlob(blob);
  console.log('cid:', cid);

  return cid;
};
