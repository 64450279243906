import {
  Text,
  VStack,
  Box,
  Icon,
  HStack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
} from '@chakra-ui/react';
import { TiArrowDownThick } from 'react-icons/ti';
import { FaHourglassHalf, FaClipboard } from 'react-icons/fa';
import { GiFireworkRocket } from 'react-icons/gi';

function RenderProjectTimeline() {
  const timelineTexts = [
    {
      time: 'August 2023 to September 2023',
      title: 'Idea Generation & Project Planning',
      description:
        'Initiated the project with brainstorming sessions and detailed planning.',
      status: 'Completed',
    },
    {
      time: 'October 2023 to 30 June 2024',
      title: 'UI Development & Smart Contracts for Blockchain Games',
      description:
        'Focused on building and refining smart contracts along with developing a user-friendly interface.',
      status: 'Completed',
    },
    {
      time: '01 July 2024 to 29 September 2024',
      title: 'UI Integration with Polygon Amoy Testnet',
      description:
        'Integrating our UI with the Polygon Amoy Testnet for comprehensive testing, while continuously improving our smart contracts and interface.',
      status: 'In Progress',
    },
    {
      time: '30 September 2024 to 18 October 2024',
      title: 'UI Integration with Polygon Mainnet',
      description:
        'Deploying smart contracts on the Polygon Mainnet and connecting our UI for public access. Ensuring both frontend and backend are ready for launch.',
      status: 'Not Started',
    },
    {
      time: '18 October 2024 and onward',
      title: 'Continuous Creation of Cozy Games',
      description:
        'Ongoing development of new cozy games, enhancing Cozy Games XYZ, and continuously improving based on user feedback.',
      status: 'Not Started',
    },
  ];

  const bgGradients = [
    'linear-gradient(132deg, rgb(2, 28, 122) 0.00%, rgb(36, 83, 255) 100.00%)',
  ];

  return (
    <VStack spacing="10px">
      <Box>
        <Text
          color="green.400"
          fontSize={[26, 36, 47]}
          style={{ fontFamily: 'Lobster' }}
        >
          Project Timeline
        </Text>
      </Box>
      {timelineTexts.map((timelineText, i) => (
        <VStack key={i}>
          <Icon as={TiArrowDownThick} boxSize="74px" color="green.300" />

          <Card
            bgGradient={bgGradients[0]}
            boxShadow="2xl"
            p="6"
            rounded="lg"
            maxW="lg"
          >
            <CardHeader>
              <Heading size="md">
                {' '}
                <Text color="white">{timelineText.title}</Text>
              </Heading>
            </CardHeader>
            <CardBody>
              <Text color="white">{timelineText.description}</Text>
            </CardBody>
            <CardFooter>
              <HStack spacing={8}>
                <Box>
                  <Text color="white">{timelineText.time}</Text>
                </Box>
                <Box>
                  {timelineText.status === 'In Progress' && (
                    <FaHourglassHalf w={8} h={8} color="green" />
                  )}
                  {timelineText.status === 'Completed' && (
                    <GiFireworkRocket boxSize="36px" color="yellow" />
                  )}
                  {timelineText.status === 'Not Started' && (
                    <FaClipboard boxSize="28px" color="white" />
                  )}
                </Box>
                <Box>
                  <Text fontSize="sm" color="white">
                    {timelineText.status}
                  </Text>
                </Box>
              </HStack>
            </CardFooter>
          </Card>
        </VStack>
      ))}
    </VStack>
  );
}

export default RenderProjectTimeline;
