import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import MagicFlowersSupport from '../../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';

const LeaseMagicFlower = ({ specificMagicflowerId = 0 }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [magicflowerId, setMagicflowerId] = useState(0);
  const [totalNumberOfUsage, setTotalNumberOfUsage] = useState(0);

  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('signer:', signer);

    try {
      const magicFlowersSupport = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
        MagicFlowersSupport.abi,
        signer
      );

      const _magicflowerId =
        specificMagicflowerId > 0 ? specificMagicflowerId : magicflowerId;

      const leasingFee = await magicFlowersSupport.getLeasingFee(
        _magicflowerId,
        totalNumberOfUsage
      );

      const transaction = await magicFlowersSupport.leaseMaigcFlowerPlant(
        _magicflowerId,
        totalNumberOfUsage,
        { value: leasingFee }
      );
      await transaction.wait();

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Lease
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Lease
        </Button>
      );
    }
  };

  return (
    <>
      <>
        <Button
          onClick={onOpen}
          size="lg"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Lease Magic Flowers
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Lease Magic Flower NFT for Hybridization</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {specificMagicflowerId === 0 && (
                <Box style={{ marginBottom: '20px' }}>
                  <FormControl isRequired>
                    <FormLabel as="legend">Magic Flower NFT Id</FormLabel>

                    <NumberInput
                      min={0}
                      value={magicflowerId}
                      onChange={e => setMagicflowerId(e)}
                      placeholder="Magic Flower NFT Id"
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </Box>
              )}

              <Box style={{ marginBottom: '20px' }}>
                <FormControl isRequired>
                  <FormLabel as="legend">
                    Total Number of Uses to Lease
                  </FormLabel>

                  <NumberInput
                    min={0}
                    value={totalNumberOfUsage}
                    onChange={e => setTotalNumberOfUsage(e)}
                    placeholder="Enter total number of uses"
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Box>
              <Alert status="info">
                <AlertIcon />
                Leasing price is for a single use.
              </Alert>
            </ModalBody>

            <ModalFooter>
              <RenderButton />
              <Button
                variant="ghost"
                colorScheme="blue"
                ml={4}
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </>
  );
};

export default LeaseMagicFlower;
