import { Link as ReactLink } from 'react-router-dom';
import {
  Icon,
  Text,
  Link,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
} from '@chakra-ui/react';
import { GiButterfly } from 'react-icons/gi';

// NavBreadCrumb for Individual Games

const NavBreadCrumbGame = () => {
  return (
    <Box>
      <Breadcrumb
        separator={<Icon as={GiButterfly} w={6} h={6} color="green.300" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/">
              <Text as="i" fontSize="sm">
                Home
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers">
              <Text as="i" fontSize="sm">
                Magic Flowers
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers/about">
              <Text as="i" fontSize="sm">
                About Game
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers/allmymagicflowers">
              <Text as="i" fontSize="sm">
                My Blooming Collection
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers/revive">
              <Text as="i" fontSize="sm">
                Revival Heaven
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers/hybridize">
              <Text as="i" fontSize="sm">
                Hybrid Paradise
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers/marketplace">
              <Text as="i" fontSize="sm">
                Marketplace
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers/auctions">
              <Text as="i" fontSize="sm">
                Auction House
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers/leasingmarket">
              <Text as="i" fontSize="sm">
                Leasing Market
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers/faq">
              <Text as="i" fontSize="sm">
                Game FAQ
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/user">
              <Text as="i" fontSize="sm">
                User
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/jsoncreator">
              <Text as="i" fontSize="sm">
                Json Creator
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
};

export default NavBreadCrumbGame;
