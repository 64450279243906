const getProgenitorsRevived = async (magicFlowers) => {

  const _totalPlantsRevived = await magicFlowers.totalProgenitorPlantsRevived();
  const totalPlantsRevived = {
    male: Number(_totalPlantsRevived._male.toString()),
    female: Number(_totalPlantsRevived._female.toString()),
    hermaphrodite: Number(_totalPlantsRevived._hermaphrodite.toString()),
  };

  return totalPlantsRevived;
};

export default getProgenitorsRevived;
