import { useState } from 'react';
import '../../../styles/Fonts.css';
import {
  Box,
  Text,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  List,
  ListItem,
  ListIcon,
  Container,
  HStack,
  Icon,
  Wrap,
  WrapItem,
  Button,
} from '@chakra-ui/react';
// import { ReactP5Wrapper } from '@p5-wrapper/react';
// import SketchCreate from '../../../components/sketches/SketchCreate';
import { GiCutDiamond, GiButterfly } from 'react-icons/gi';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import UpdateTokensInGame from '../../../components/actions/NFTs/gameIntegration/UpdateTokensInGame';
import AddGameIntegration from '../../../components/actions/NFTs/gameIntegration/AddGameIntegration';
import CreateNewCollectionAndAddTokens from '../../../components/actions/NFTs/collections/CreateNewCollectionAndAddTokens';
import UpdateTokensInCollection from '../../../components/actions/NFTs/collections/UpdateTokensInCollection';
import MintingFrame from '../../../resources/images/dreamscapestudio/woodsigns/1.png';
import GameIntegrationFrame from '../../../resources/images/dreamscapestudio/woodsigns/2.png';
import FeaturingFrame from '../../../resources/images/dreamscapestudio/woodsigns/4.png';
import CollectionFrame from '../../../resources/images/dreamscapestudio/woodsigns/3.png';
// import {
//   CollectionFrame,
//   FeaturingFrame,
//   GamesFrame,
//   MintingFrame,
// } from '../../../components/views/NFTs/elements/frames/dreamMintingStuio/studioFrames';
import AddGameModal from '../../../components/actions/NFTs/gameIntegration/AddGameModal';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';

function DreamMintingStudio() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const [showMinting, setShowMinting] = useState(false);
  const [showGaming, setShowGaming] = useState(false);
  const [showGameIntegration, setShowGameIntegration] = useState(false);
  const [showOther, setShowOther] = useState(false);

  const handleShowMinting = () => {
    setShowMinting(true);
    setShowGaming(false);
    setShowOther(false);
  };

  const handleShowGaming = () => {
    setShowMinting(false);
    setShowGaming(true);
    setShowOther(false);
  };

  const handleShowFeaturing = () => {
    setShowMinting(false);
    setShowGaming(false);
    setShowOther(false);
  };

  const handleShowOther = () => {
    setShowMinting(false);
    setShowGaming(false);
    setShowOther(true);
  };

  const RenderMintingActions = () => {
    return (
      <VStack spacing={3}>
        <List spacing={3}>
          <ListItem>
            <Link href="/create/mintErc721">
              <ListIcon as={GiCutDiamond} color="green.300" />
              Mint Erc721 NFT
            </Link>
          </ListItem>
          <ListItem>
            <Link href="/create/mintErc1155">
              <ListIcon as={GiCutDiamond} color="green.300" />
              Mint Erc1155 NFT
            </Link>
          </ListItem>
          <ListItem>
            <Link href="/create/listErc721">
              <ListIcon as={GiCutDiamond} color="green.300" />
              Sell / Auction Erc721 NFT
            </Link>
          </ListItem>
          <ListItem>
            <Link href="/create/listErc1155">
              <ListIcon as={GiCutDiamond} color="green.300" />
              Sell Erc1155 NFT
            </Link>
          </ListItem>
        </List>
      </VStack>
    );
  };

  const GameIntegrationButton = () => {
    return (
      <>
        <HStack>
          <Icon as={GiCutDiamond} color="green.300" />
          <Button
            onClick={() => setShowGameIntegration(true)}
            colorScheme="green"
            size="md"
            variant="ghost"
          >
            Integrate Tokens in Game
          </Button>
        </HStack>
      </>
    );
  };

  const GameIntegrationActions = () => {
    return (
      <>
        <Container>
          <HStack>
            <Icon as={GiButterfly} color="green.300" />
            <AddGameIntegration tokenType="Erc721" />
          </HStack>

          <HStack>
            <Icon as={GiButterfly} color="green.300" />
            <AddGameIntegration tokenType="Erc1155" />
          </HStack>
        </Container>
      </>
    );
  };

  const RenderGamingActions = () => {
    return (
      <VStack>
        <List spacing={3}>
          <ListItem>
            <HStack>
              <Icon as={GiCutDiamond} color="green.300" />
              <AddGameModal />
            </HStack>
          </ListItem>
          <ListItem>
            <GameIntegrationButton />
            {showGameIntegration && <GameIntegrationActions />}
          </ListItem>

          <ListItem>
            <HStack>
              <Icon as={GiCutDiamond} color="green.300" />
              <UpdateTokensInGame />
            </HStack>
          </ListItem>
        </List>
      </VStack>
    );
  };

  const RenderOtherActions = () => {
    return (
      <VStack spacing={3}>
        <List spacing={3}>
          <ListItem>
            <HStack>
              <ListIcon as={GiCutDiamond} color="green.300" />
              <CreateNewCollectionAndAddTokens />
            </HStack>
          </ListItem>
          <ListItem>
            <HStack>
              <ListIcon as={GiCutDiamond} color="green.300" />
              <UpdateTokensInCollection updateType="Add" />
            </HStack>
          </ListItem>
          <ListItem>
            <HStack>
              <ListIcon as={GiCutDiamond} color="green.300" />
              <UpdateTokensInCollection updateType="Remove" />
            </HStack>
          </ListItem>
        </List>
      </VStack>
    );
  };

  const RenderActions = () => {
    const _style = {
      display: 'grid',
      minHeight: '200px',
      border: '8px solid',
      padding: '1rem',
      borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
      marginTop: '20px',
    };
    if (showMinting) {
      return (
        <Box style={_style}>
          <VStack spacing={6}>
            <Text
              color="green.300"
              fontSize="3xl"
              style={{ fontFamily: 'Lobster' }}
            >
              Minting / Selling / Auction
            </Text>
            <RenderMintingActions />
          </VStack>
        </Box>
      );
    }

    if (showGaming) {
      return (
        <Box style={_style}>
          <VStack spacing={6}>
            <Text
              color="green.300"
              fontSize="3xl"
              style={{ fontFamily: 'Lobster' }}
            >
              Game Integration/Compatibility
            </Text>
            <RenderGamingActions />
          </VStack>
        </Box>
      );
    }

    if (showOther) {
      return (
        <Box style={_style}>
          <VStack spacing={6}>
            <Text
              color="green.300"
              fontSize="3xl"
              style={{ fontFamily: 'Lobster' }}
            >
              Collection
            </Text>
            <RenderOtherActions />
          </VStack>
        </Box>
      );
    }
  };

  return (
    <Box>
      <VStack>
        <Box>
          <HomeIcon />
        </Box>
        <Box>
          <img
            src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1721917165/dreamscapestudio_s2zfjs.gif"
            style={{ width: '100%' }}
          />
        </Box>
        <Box mb="20px">
          <Text
            color="green.400"
            fontSize={['xl', '2xl', '3xl', '5xl']}
            style={{ fontFamily: 'Lobster' }}
          >
            Dreamscape Studio
          </Text>
        </Box>

        <Box mt={8}>
          <RenderActions />
        </Box>

        <Box m={6}>
          <Wrap>
            <WrapItem>
              <Box as="button" onClick={handleShowMinting}>
                <img src={MintingFrame} alt="Mint/Sell NFT" />
              </Box>
            </WrapItem>

            <WrapItem>
              <Box as="button" onClick={handleShowGaming}>
                <img src={GameIntegrationFrame} alt="Game Integration" />
              </Box>
            </WrapItem>

            <WrapItem>
              <Box as="button" onClick={handleShowFeaturing}>
                <Link href="/create/featuringaction">
                  <img src={FeaturingFrame} alt="Featuring NFT" />
                </Link>
              </Box>
            </WrapItem>

            <WrapItem>
              <Box as="button" onClick={handleShowOther}>
                <img src={CollectionFrame} alt="Collection" />
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      </VStack>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default DreamMintingStudio;
