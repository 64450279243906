import { kFactors } from './kFactors';
function get_rose_coord(p5, r, angle, K) {
  let t = p5.radians(angle);
  let s = r * p5.cos(K * t);
  return { x: s * p5.cos(t), y: s * p5.sin(t) };
}

function get_circle_coord(p5, r, angle) {
  let t = p5.radians(angle);
  return { x: r * p5.cos(t), y: r * p5.sin(t) };
}

/**
 * plantType 2 & 3
 * @param {*} p5
 * @param {*} x
 * @param {*} y
 * @param {*} r
 * @param {*} angle
 * @param {*} K
 * @param {*} petals_color
 * @param {*} STAMENS_COUNT
 * @param {*} stamens_color
 */

function flower_draw_blossom(
  p5,
  x,
  y,
  r,
  angle,
  K,
  petals_color,
  STAMENS_COUNT,
  stamens_color
) {
  p5.fill(petals_color);
  p5.noStroke();

  p5.push(); // save the current drawing state
  p5.translate(x, y); // move the origin to the center of the flower
  p5.rotate(p5.radians(angle)); // rotate the flower by the specified angle

  p5.beginShape();
  for (let i = 0; i < 360 * 2; i++) {
    let p = get_rose_coord(p5, r, i, K);
    p5.vertex(p.x, p.y);
  }
  p5.endShape(p5.CLOSE);
  p5.push();

  let rs = r / 3;
  let rrs = r / 23.3;
  let dS = 360 / STAMENS_COUNT;
  p5.strokeWeight(0.18);
  p5.stroke(stamens_color);
  p5.fill(stamens_color);
  for (let i = 0; i < STAMENS_COUNT; i++) {
    let p = get_circle_coord(p5, rs * (i % 2 == 0 ? 1 : 0.5), i * dS);
    p5.line(0, 0, p.x, p.y);
    p5.circle(p.x, p.y, rrs);
  }
  p5.pop();

  p5.pop(); // restore the previous drawing state
}

function _drawFlower(p5, x, y, flowerParam, _kFactors) {
  // let flower4_N = 5;
  // let flower4_D = 3;
  let K = _kFactors[0] / _kFactors[1];
  let STAMENS_COUNT = 10;
  let petals_color = p5.color(
    flowerParam.fc.h,
    flowerParam.fc.s,
    flowerParam.fc.b,
    0.58
  ); //color(255, 116, 140);
  let stamens_color = p5.color(
    flowerParam.sc.h,
    flowerParam.sc.s,
    flowerParam.sc.b
  ); //p5.color(0, 0, 100, 0.88);
  let r = p5.random(18, 20);
  let angle = p5.random(0, 360);
  flower_draw_blossom(
    p5,
    x,
    y,
    r,
    angle,
    K,
    petals_color,
    STAMENS_COUNT,
    stamens_color
  );
}

export function drawFlowerGenus1(p5, x, y, flowerParam, flowerType) {
  switch (flowerType) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 30:
    case 31:
    case 32:
    case 33:
    case 34:
    case 35:
    case 36:
    case 37:
    case 38:
    case 39:
    case 40:
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
    case 46:
    case 47:
    case 48:
    case 49:
    case 50:
    case 51:
    case 52:
    case 53:
    case 54:
    case 55:
    case 56:
    case 57:
    case 58:
    case 59:
    case 60:
    case 61:
    case 62:
    case 63:
    case 64:
    case 65:
    case 66:
    case 67:
    case 68:
    case 69:
    case 70:
    case 71:
    case 72:
    case 73:
    case 74:
    case 75:
    case 76:
    case 77:
    case 78:
    case 79:
    case 80:
    case 81:
    case 82:
    case 83:
    case 84:
    case 85:
    case 86:
    case 87:
    case 88:
    case 89:
    case 90:
    case 91:
    case 92:
    case 93:
    case 94:
    case 95:
    case 96:
    case 97:
    case 98:
    case 99:
    case 100:
    case 101:
    case 102:
    case 103:
    case 104:
    case 105:
    case 106:
    case 107:
    case 108:
      _drawFlower(p5, x, y, flowerParam, kFactors['flower' + flowerType]);
      // _drawFlower(p5, x, y, flowerParam, kFactors['flower82']);
      break;
  }
}

/**
 * plantType 1
 * @param {*} p5
 * @param {*} treeCanvas
 * @param {*} x
 * @param {*} y
 * @param {*} r
 * @param {*} angle
 * @param {*} K
 * @param {*} flowerParam
 * @param {*} STAMENS_COUNT
 */
function flower_draw_blossom_plant1(
  p5,
  treeCanvas,
  x,
  y,
  r,
  angle,
  K,
  flowerParam,
  STAMENS_COUNT
) {
  treeCanvas.fill(flowerParam.fc.h, flowerParam.fc.s, flowerParam.fc.b, 0.58);
  treeCanvas.noStroke();

  treeCanvas.push(); // save the current drawing state
  treeCanvas.translate(x, y); // move the origin to the center of the flower
  treeCanvas.rotate(p5.radians(angle)); // rotate the flower by the specified angle

  treeCanvas.beginShape();
  for (let i = 0; i < 360 * 2; i++) {
    let p = get_rose_coord(p5, r, i, K);
    treeCanvas.vertex(p.x, p.y);
  }
  treeCanvas.endShape(p5.CLOSE);
  treeCanvas.push();

  let rs = r / 3;
  let rrs = r / 23.3;
  let dS = 360 / STAMENS_COUNT;
  treeCanvas.strokeWeight(0.18);
  treeCanvas.stroke(flowerParam.sc.h, flowerParam.sc.s, flowerParam.sc.b);
  treeCanvas.fill(flowerParam.sc.h, flowerParam.sc.s, flowerParam.sc.b);
  for (let i = 0; i < STAMENS_COUNT; i++) {
    let p = get_circle_coord(p5, rs * (i % 2 == 0 ? 1 : 0.5), i * dS);
    treeCanvas.line(0, 0, p.x, p.y);
    treeCanvas.circle(p.x, p.y, rrs);
  }
  treeCanvas.pop();

  treeCanvas.pop(); // restore the previous drawing state
}

function _drawFlowerPlant1(p5, treeCanvas, x, y, flowerParam, _kFactors) {
  let K = _kFactors[0] / _kFactors[1];
  let STAMENS_COUNT = 10;
  let r = treeCanvas.random(18, 20);
  let angle = treeCanvas.random(0, 360);
  flower_draw_blossom_plant1(
    p5,
    treeCanvas,
    x,
    y,
    r,
    angle,
    K,
    flowerParam,
    STAMENS_COUNT
  );
}

export function drawFlowerGenus1_treeCanvas(
  p5,
  treeCanvas,
  x,
  y,
  flowerParam,
  flowerType
) {
  switch (flowerType) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 30:
    case 31:
    case 32:
    case 33:
    case 34:
    case 35:
    case 36:
    case 37:
    case 38:
    case 39:
    case 40:
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
    case 46:
    case 47:
    case 48:
    case 49:
    case 50:
    case 51:
    case 52:
    case 53:
    case 54:
    case 55:
    case 56:
    case 57:
    case 58:
    case 59:
    case 60:
    case 61:
    case 62:
    case 63:
    case 64:
    case 65:
    case 66:
    case 67:
    case 68:
    case 69:
    case 70:
    case 71:
    case 72:
    case 73:
    case 74:
    case 75:
    case 76:
    case 77:
    case 78:
    case 79:
    case 80:
    case 81:
    case 82:
    case 83:
    case 84:
    case 85:
    case 86:
    case 87:
    case 88:
    case 89:
    case 90:
    case 91:
    case 92:
    case 93:
    case 94:
    case 95:
    case 96:
    case 97:
    case 98:
    case 99:
    case 100:
    case 101:
    case 102:
    case 103:
    case 104:
    case 105:
    case 106:
    case 107:
    case 108:
      _drawFlowerPlant1(
        p5,
        treeCanvas,
        x,
        y,
        flowerParam,
        kFactors['flower' + flowerType]
      );
      // _drawFlower(p5, x, y, flowerParam, kFactors['flower82']);
      break;
  }
}
