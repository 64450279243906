export function skyDawn(p5, canvas) {
  let c1 = p5.color(0, 0, 255);
  let c2 = p5.color(250, 183, 195);

  for (let y = 0; y < canvas.height; y++) {
    // Calculate the lerp amount based on the current row
    let lerpAmount = p5.map(y, 0, canvas.height, 0.218, 1); //y / canvas.height * 0.2;
    // Use lerpColor to get a color between the start and end colors
    let currentColor = canvas.lerpColor(c1, c2, lerpAmount);

    // Set the color of the current row
    canvas.push();
    canvas.stroke(currentColor);
    canvas.line(0, y, canvas.width, y);
    canvas.pop();
  }
}

export function skyDawn1(p5, canvas) {
  let c2 = p5.color(0, 0, 255);
  let c1 = p5.color(250, 230, 234);

  for (let y = 0; y < canvas.height; y++) {
    // Calculate the lerp amount based on the current row
    let lerpAmount = p5.map(y, 0, canvas.height, 0.218, 1); //y / canvas.height * 0.2;
    // Use lerpColor to get a color between the start and end colors
    let currentColor = canvas.lerpColor(c1, c2, lerpAmount);

    // Set the color of the current row
    canvas.push();
    canvas.stroke(currentColor);
    canvas.line(0, y, canvas.width, y);
    canvas.pop();
  }
}
