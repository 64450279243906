function getH(_str, p5) {
  let _h;
  switch (_str) {
    case '24-Hour Natural Cycle':
      _h = p5.hour();
      break;
    case 'Deep Night':
      _h = 2;
      break;
    case 'Dawn':
      _h = 5;
      break;
    case 'Morning':
      _h = 8;
      break;
    case 'Afternoon':
      _h = 15;
      break;
    case 'Evening':
      _h = 18;
      break;
    case 'Night':
      _h = 22;
      break;
    default:
      _h = p5.hour();
  }
  return _h;
}

export default getH;
