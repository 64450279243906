import React, { useState } from 'react';
import { Box, Stack, VStack, Grid, RadioGroup, Radio } from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
// import CreateNFTandAuction from '../../../components/actions/NFTs/auctions/CreateNFTandAuction';
// import CreateAuction from '../../../components/actions/NFTs/auctions/CreateAuction';
// import CreateNFT from '../../../components/actions/NFTs/CreateNFT';

import ValleyItemList from '../../../components/views/NFTs/elements/valleyItems/ValleyItemList';
import ValleyItemListGames from '../../../components/views/NFTs/elements/valleyItems/ValleyItemListGames';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';

import FeaturedGalleryScene from '../../../components/views/NFTs/featured/FeaturedGalleryScene';

function AuctionHouse() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const [value, setValue] = useState('1');

  const DisplayList = () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    if (value === '1') {
      return (
        <ValleyItemList
          provider={provider}
          saleType="Auction"
          marketplaceIds={[]}
          auctionIds={[]}
        />
      );
    } else if (value === '2') {
      return <ValleyItemListGames provider={provider} saleType="Auction" />;
    } else {
      return <ValleyItemList provider={provider} saleType="Auction" />;
    }
  };

  return (
    <Box textAlign="center">
      <FeaturedGalleryScene
        label="Auction House"
        domeImage="./textures/artgallery/artgallery2.png"
        featurePage="auctionHouse"
      />

      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <VStack>
            <img
              src="/images/auction.png"
              style={{ width: '88px', height: 'auto' }}
            />

            <Box style={{ marginTop: '58px' }}>
              <RadioGroup onChange={setValue} value={value}>
                <Stack direction="row">
                  <Radio value="1" colorScheme="green">
                    Auctions
                  </Radio>
                  <Radio value="2" colorScheme="green">
                    NFTs for Games in Auction House
                  </Radio>
                  <Radio value="3" colorScheme="green">
                    Magic Flowers NFTs in Auction House
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>

            <DisplayList />
          </VStack>
        </Grid>
      </Box>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default AuctionHouse;
