export function skyAfternoon(p5, canvas) {
  let c1 = p5.color(255, 179, 193);
  let c2 = p5.color(0, 0, 255);
  canvas.push();

  for (let y = 0; y < canvas.height; y++) {
    // Calculate the lerp amount based on the current row
    let lerpAmount = p5.map(y, 0, canvas.height, 0, 0.888); //y / canvas.height * 0.2;
    // Use lerpColor to get a color between the start and end colors
    let currentColor = canvas.lerpColor(c1, c2, lerpAmount);

    // Set the color of the current row

    canvas.stroke(currentColor);
    canvas.line(0, y, canvas.width, y);
  }
  canvas.pop();
}

export function skyAfternoon1(p5, canvas) {
  let c2 = p5.color(255, 179, 193);
  let c1 = p5.color(0, 0, 255);
  canvas.push();

  for (let y = 0; y < canvas.height; y++) {
    // Calculate the lerp amount based on the current row
    let lerpAmount = p5.map(y, 0, canvas.height, 0, 0.888); //y / canvas.height * 0.2;
    // Use lerpColor to get a color between the start and end colors
    let currentColor = canvas.lerpColor(c1, c2, lerpAmount);

    // Set the color of the current row

    canvas.stroke(currentColor);
    canvas.line(0, y, canvas.width, y);
  }
  canvas.pop();
}
