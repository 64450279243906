import MagicFlowersSupport from '../../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import { ethers } from 'ethers';
import { REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS } from '../../constants/ContractAddress';

const getParentPlantLeaseList = async provider => {
  const magicFlowersSupport = new ethers.Contract(
    REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
    MagicFlowersSupport.abi,
    provider
  );

  const data = await magicFlowersSupport.getMagicFlowerLeaseList();
  // console.log('data:', data);

  let newItems = [];

  if (data.length > 0) {
    // Filter out removed items
    const filteredData = data.filter(d => !d.removed);

    newItems = await Promise.all(
      filteredData.map(async (d, index) => {
        const res = {
          listingId: index + 1, // Index is now based on filteredData
          magicflowerId: Number(d.magicflowerId.toString()),
          price: ethers.utils.formatUnits(d.price.toString(), 'ether'),
          metadata: {},
        };

        console.log('res:', res);

        return res;
      })
    );

    for (let i = 0; i < newItems.length; i++) {
      let _metadata = await magicFlowersSupport.getTokenProperties(
        newItems[i].magicflowerId
      );
      console.log('_meatadata:', _metadata);

      let _meta = {
        plantType: Number(_metadata.plantType.toString()),
        birthdate: _metadata.genes[0].timestamp.toString(),
        sex: Number(_metadata.sex.toString()),
        totalOffsprings: Number(_metadata.totalOffsprings.toString()),
        isVigor: _metadata.isVigor,
        infiniteEvolve: _metadata.infiniteEvolve,
        evolvedTimes: _metadata.genes.length - 1,
      };

      newItems[i].metadata = _meta;
    }
  }

  return newItems;
};

export default getParentPlantLeaseList;
