import { Link as ReactLink } from 'react-router-dom';
import { Link, Button } from '@chakra-ui/react';

const AddGalleryButton = ({ magicflowerId, variantType = 'outline' }) => {
  const toLink = `/magicflowers/owneractions/${magicflowerId}`;
  return (
    <>
      <Link as={ReactLink} to={toLink}>
        <Button colorScheme="green" variant={variantType}>
          Add Gallery
        </Button>
      </Link>
    </>
  );
};

export default AddGalleryButton;
