import { ReactP5Wrapper } from '@p5-wrapper/react';
import MagicFlowerTree from '../../../../components/sketches/games/magicFlowersSketches/plants/MagicFlowerTree';
import { useEffect, useState } from 'react';
import {
  Link,
  VStack,
  HStack,
  Box,
  Button,
  Text,
  Wrap,
  WrapItem,
  Card,
  CardBody,
  CardFooter,
  Divider,
  ButtonGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import MagicFlowers from '../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import MagicFlowersSupport from '../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import SkyFlower from '../../../../resources/images/games/magicflowers/flower.png';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import {
  REACT_APP_MAGIC_FLOWERS_ADDRESS,
  REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
} from '../../../../components/views/cozyGames/constants/ContractAddress';
import getMagicFlowerDetail from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getMagicFlowerDetail';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import GameFooter from '../../../../components/views/GameFooter';
import RenderMagicFlowerMeta from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/RenderMagicFlowerMeta';
import DetailButton from '../../../../components/views/NFTs/elements/DetailButton';
function HybridizePlants() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const navigate = useNavigate();

  //   useEffect(() => {
  //     // Call getItem when address changes
  //     getItem();

  //     // Specify address as a dependency
  //   }, [address]);

  const [plant1, setPlant1] = useState(0);
  const [plant2, setPlant2] = useState(0);

  const [hideQuickView, setHideQuickView] = useState(false);
  const [magicflowerId, setMagicflowerId] = useState(0);
  const [plantIndex, setPlantIndex] = useState(null);
  const [plantOwner, setPlantOwner] = useState('');
  const [allGenes, setAllGenes] = useState([]);
  const [plantFeatures, setPlantFeatures] = useState({});
  const [evolvingPoints, setEvolvingPoints] = useState([]);
  const [displayTimePoint, setDisplayTimePoint] = useState(0);

  const [meta1, setMeta1] = useState({});
  const [meta2, setMeta2] = useState({});
  const [leaseDetail1, setLeaseDetail1] = useState({});
  const [leaseDetail2, setLeaseDetail2] = useState({});
  const [showMeta, setShowMeta] = useState([false, false]);
  const [userDefault, setUserDefault] = useState({});
  const [userDefaultBgs, setUserDefaultBgs] = useState([]);

  const [loading, setLoading] = useState(false);
  const [areValidParents, setAreValidParents] = useState(false);
  const [showValidity, setShowValidity] = useState(false);
  const [rightToUse, setRightToUse] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const getPlantDetail = async (magicflowerId, isPlant1, isMeta) => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);

    const data = await getMagicFlowerDetail(provider, magicflowerId);

    if (isMeta) {
      if (isPlant1) {
        setMeta1(data.metadata);
        setLeaseDetail1(data.lease);
        console.log('data.lease:', data.lease);
      } else {
        setMeta2(data.metadata);
        setLeaseDetail2(data.lease);
      }
    } else {
      setMagicflowerId(magicflowerId);

      setUserDefault(data.userDefault);
      setUserDefaultBgs(data.userDefaultBgs);

      setPlantFeatures(data.plantFeatures);
      setAllGenes(data.allGenes);
      setDisplayTimePoint(data.displayTimePoint);
      setEvolvingPoints(data.evolvingPoints);
    }
  };

  const getQuickView = async (key, magicflowerId) => {
    console.log('key:', key);

    if (hideQuickView) {
      setHideQuickView(false);
    }

    setPlantIndex(key);

    const isPlant1 = key === 0 ? true : false;

    await getPlantDetail(magicflowerId, isPlant1, false);
  };

  const getQuickInfo = async (key, magicflowerId) => {
    const isPlant1 = key === 0 ? true : false;
    await getPlantDetail(magicflowerId, isPlant1, true);
    showMeta[key] = true;
  };

  const QuickViewButton = ({ idx, magicflowerId }) => {
    return (
      <Button
        size="md"
        colorScheme="green"
        variant="outline"
        onClick={() => getQuickView(idx, magicflowerId)}
      >
        Quick View
      </Button>
    );
  };

  const QuickInfoButton = ({ idx, magicflowerId }) => {
    return (
      <Button
        size="md"
        colorScheme="green"
        variant="outline"
        onClick={() => getQuickInfo(idx, magicflowerId)}
      >
        Quick Info
      </Button>
    );
  };

  const ShowAllMyTokens = () => {
    let allTokensOwned = [0, 0];
    if (plant1 > 0) {
      allTokensOwned[0] = plant1;
    }
    if (plant2 > 0) {
      allTokensOwned[1] = plant2;
    }

    if (loading) {
      return (
        <>
          <img
            src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1722016426/butterfly-magic_xwhj6k.gif"
            alt="Magic Butterflies"
          />
        </>
      );
    } else {
      return (
        <Wrap spacing={6}>
          {allTokensOwned.length &&
            allTokensOwned.map((t, key) => (
              <WrapItem key={key}>
                <Card maxW="sm">
                  <CardBody>
                    <VStack>
                      <Box sx={basicBoxStyles} filter="grayscale(10%)">
                        <VStack>
                          <Text fontSize="sm" as="i">
                            NFT ID:
                          </Text>
                          <Text
                            fontSize="md"
                            as="b"
                            style={{ fontFamily: 'Lobster' }}
                          >
                            {t}
                          </Text>
                          <Link
                            as={ReactLink}
                            to={`/magicflowers/magicflower/${t}`}
                          >
                            <DetailButton />
                          </Link>
                        </VStack>
                      </Box>
                      {/* <Box sx={outerBoxStyles} rounded="xl">
                        <Box
                          sx={innerBoxStyles}
                          backdropFilter="auto"
                          backdropBlur="8px"
                        >
                          <VStack>
                            <Text fontSize="xs" as="i">
                              NFT ID:
                            </Text>
                            <Text
                              fontSize="md"
                              style={{ fontFamily: 'Lobster' }}
                            >
                              {t}
                            </Text>
                            <QuickViewButton idx={key} magicflowerId={t} />
                          </VStack>
                        </Box>
                      </Box> */}
                    </VStack>
                  </CardBody>
                  <Divider />
                  <CardFooter>
                    <VStack>
                      <ButtonGroup>
                        <QuickViewButton idx={key} magicflowerId={t} />
                        <QuickInfoButton idx={key} magicflowerId={t} />
                      </ButtonGroup>
                      {showMeta[key] && (
                        <RenderMagicFlowerMeta
                          meta={key === 0 ? meta1 : meta2}
                          leaseDetail={key === 0 ? leaseDetail1 : leaseDetail2}
                        />
                      )}
                    </VStack>
                  </CardFooter>
                </Card>
              </WrapItem>
            ))}
        </Wrap>
      );
    }
  };

  const basicBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: '250px',
    color: 'white',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
    px: 4,
    background: `url(${SkyFlower}) center/cover no-repeat`,
  };

  const RenderSketch = () => {
    return (
      <ReactP5Wrapper
        sketch={MagicFlowerTree}
        magicflowerId={magicflowerId}
        plantFeatures={plantFeatures}
        genes={allGenes[displayTimePoint]}
        userDefault={userDefault}
        userDefaultBgs={userDefaultBgs}
      />
    );
  };

  const validateParents = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);

    try {
      const magicFlowersSupport = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
        MagicFlowersSupport.abi,
        provider
      );

      const _areValidParents = await magicFlowersSupport.areValidParents(
        plant1,
        plant2
      );
      console.log('_areValidParents:', _areValidParents);
      setAreValidParents(_areValidParents);
      setShowValidity(true);
    } catch (error) {
      console.log(error);
    }
  };

  const haveRightToUse = (isOwner1, isOwner2, isValidLease1, isValidLease2) => {
    if (isOwner1 && isOwner2) {
      return true;
    }
    if (isValidLease1 && isValidLease2) {
      return true;
    }
    if (isOwner1 && isValidLease2) {
      return true;
    }
    if (isOwner2 && isValidLease1) {
      return true;
    }
    return false;
  };

  const checkRightToUse = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);

    try {
      const magicFlowersSupport = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
        MagicFlowersSupport.abi,
        provider
      );

      const magicFlowers = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_ADDRESS,
        MagicFlowers.abi,
        provider
      );

      const _ownerPlant1 = await magicFlowers.ownerOf(plant1);
      const _ownerPlant2 = await magicFlowers.ownerOf(plant2);
      const _isValidLease1 = await magicFlowersSupport.isValidRenting(
        plant1,
        address
      );
      const _isValidLease2 = await magicFlowersSupport.isValidRenting(
        plant2,
        address
      );
      const _isOwner1 = address === _ownerPlant1 ? true : false;
      const _isOwner2 = address === _ownerPlant2 ? true : false;
      const _haveRight = haveRightToUse(
        _isOwner1,
        _isOwner2,
        _isValidLease1,
        _isValidLease2
      );
      setRightToUse(_haveRight);
      setShowRight(true);
    } catch (error) {
      console.log(error);
    }
  };

  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('signer:', signer);

    try {
      const magicFlowers = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_ADDRESS,
        MagicFlowers.abi,
        signer
      );

      const mintingFee = magicFlowers.getMagicFlowersHybridizationFee(false); // 0.5 matic

      const transaction = await magicFlowers.hybridize(plant1, plant2, {
        value: mintingFee,
      });

      await transaction.wait();
      // Create a promise to wait for the event
      const eventPromise = new Promise((resolve, reject) => {
        magicFlowers.once(
          'Hybridized',
          (magicflowerId, magicflowerOwner, event) => {
            // console.log(`Plant revived by: ${magicflowerOwner}`);
            // console.log(`Plant ID: ${magicflowerId}`);
            // console.log(`Event: ${event}`);
            resolve(magicflowerId); // Resolve the promise with the plant ID
          }
        );

        // Set a timeout to reject the promise if the event is not received in a reasonable time
        setTimeout(() => {
          reject(new Error('Event timeout'));
        }, 60000); // 60 seconds timeout, adjust as needed
      });

      // Wait for the event to be captured
      const magicflowerId = await eventPromise;

      const navigateUrl = `/magicflowers/magicflower/${magicflowerId}`;

      setLoading(false);
      setTimeout(() => {
        navigate(navigateUrl);
      }, 100);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          size="lg"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Hybridize
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          size="lg"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Hybridize
        </Button>
      );
    }
  };

  const RenderRightToUse = () => {
    if (rightToUse) {
      return (
        <Alert status="success">
          <AlertIcon />
          Rights are confirmed!
        </Alert>
      );
    } else {
      return (
        <Alert status="error">
          <AlertIcon />
          You must either own the plant or have a valid lease to use it.
        </Alert>
      );
    }
  };

  const RenderParentsValidity = () => {
    // console.log('are valid parents:', areValidParents);
    if (areValidParents) {
      return (
        <VStack>
          <Alert status="success">
            <AlertIcon />
            These 2 plants can be hybridized!
          </Alert>

          <Alert status="info">
            <AlertIcon />
            Parents Validity only show whether these 2 plants can be hybridized
            together. You also need to be either the owner or have valid leasing
            to use them. If you are not sure, check your right to use them.
          </Alert>

          <Box>
            <Button
              onClick={checkRightToUse}
              size="lg"
              colorScheme="green"
              variant="outline"
              mr={3}
            >
              Check Usage Rights
            </Button>
          </Box>

          {showRight && <RenderRightToUse />}
        </VStack>
      );
    } else {
      return (
        <VStack>
          <Alert status="error">
            <AlertIcon />
            These 2 plants cannot be hybridized!
          </Alert>
        </VStack>
      );
    }
  };

  return (
    <VStack spacing={6}>
      <MagicFlowerTopGrid />
      <Text
        color="green.300"
        fontSize={[36, 42, 58]}
        style={{ fontFamily: 'Lobster' }}
      >
        Hybridize Magic Flowers
      </Text>

      {plantIndex !== null && !hideQuickView && (
        <VStack>
          <RenderSketch />
          <Text
            color="green.300"
            fontSize={[16, 18]}
            style={{ fontFamily: 'Lobster' }}
          >
            Magic Flowers ID: {magicflowerId}
          </Text>
          <Box>
            <Button
              colorScheme="green"
              variant="outline"
              onClick={() => setHideQuickView(true)}
            >
              Hide Quick View
            </Button>
          </Box>
        </VStack>
      )}

      {(plant1 > 0 || plant2 > 0) && <ShowAllMyTokens />}
      <Card boxShadow="2xl" p="6" rounded="md" maxW="md">
        <CardBody>
          <Box maxW="sm">
            <FormControl>
              <FormLabel>Magic Flower Id for Plant 1</FormLabel>
              <NumberInput
                defaultValue={0}
                min={0}
                onChange={e => setPlant1(e)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </Box>
          <Box maxW="sm">
            <FormControl>
              <FormLabel>Magic Flower Id for Plant 2</FormLabel>
              <NumberInput
                defaultValue={0}
                min={0}
                onChange={e => setPlant2(e)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </Box>
        </CardBody>
        <CardFooter>
          <VStack>
            <HStack>
              <Box>
                <Button
                  onClick={validateParents}
                  size="lg"
                  colorScheme="green"
                  variant="outline"
                  mr={3}
                >
                  Validate Parents
                </Button>
              </Box>

              <RenderButton />
            </HStack>
            {showValidity && <RenderParentsValidity />}
          </VStack>
        </CardFooter>
      </Card>

      <div style={{ marginTop: '58px' }}>
        <GameFooter />
      </div>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default HybridizePlants;
