import React, { useState } from 'react';
import {
  Text,
  VStack,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
} from '@chakra-ui/react';
import '../../styles/DashedBorder.css';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
const GamesCompatibleMetaJsonCreator = () => {
  const [nftData, setNftData] = useState({
    gamesCompatible: [],
  });

  const handleAddAttribute = () => {
    // Add an empty attribute object to gamesCompatible array
    setNftData({
      ...nftData,
      gamesCompatible: [...nftData.gamesCompatible, { cozyGameId: '', feature: '' }],
    });
  };

  const handleRemoveAttribute = indexToRemove => {
    // Remove attribute at specific index from gamesCompatible array
    const updatedgamesCompatible = [...nftData.gamesCompatible];
    updatedgamesCompatible.splice(indexToRemove, 1);
    setNftData({
      ...nftData,
      gamesCompatible: updatedgamesCompatible,
    });
  };

  const handleJsonSubmit = () => {
    // Convert nftData object to JSON string
    const jsonContent = JSON.stringify(nftData, null, 2);

    // Create blob with JSON content
    const blob = new Blob([jsonContent], { type: 'application/json' });

    // Create URL for blob
    const url = URL.createObjectURL(blob);

    // Create temporary <a> element to trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'myGamesCompatibleMetadata.json';
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <VStack>
      <HomeIcon />
      <Text
        color="green.400"
        fontSize={['lg', 'xl', '2xl', '3xl']}
        style={{ fontFamily: 'Lobster' }}
      >
        Games Compatible meta
      </Text>
      <Text
        color="green.400"
        fontSize={['lg', 'xl', '2xl', '3xl']}
        style={{ fontFamily: 'Lobster' }}
      >
        Json Creator
      </Text>
      <Card>
        <CardBody>
          <Box mt={3}>
            <Text fontSize="xl" as="i">
              Games Compatible:
            </Text>
            <VStack>
              <Button onClick={handleAddAttribute}>Add Game Compatible</Button>
              <List>
                {nftData.gamesCompatible.map((attribute, index) => (
                  <ListItem key={index}>
                    <Box className="dashed" p={6} mb={3}>
                      <Box mt={3} mb={3}>
                        <FormControl>
                          <FormLabel>Cozy Game Id</FormLabel>
                          <Input
                            type="text"
                            value={attribute.cozyGameId}
                            placeholder="Cozy Game Id"
                            onChange={e => {
                              const updatedgamesCompatible = [...nftData.gamesCompatible];
                              updatedgamesCompatible[index].cozyGameId =
                                e.target.value;
                              setNftData({
                                ...nftData,
                                gamesCompatible: updatedgamesCompatible,
                              });
                            }}
                          />{' '}
                        </FormControl>
                      </Box>
                      <Box mt={3} mb={3}>
                        <FormControl>
                          <FormLabel>Game Features</FormLabel>
                          <Input
                            type="text"
                            value={attribute.feature}
                            placeholder="Features"
                            onChange={e => {
                              const updatedgamesCompatible = [...nftData.gamesCompatible];
                              updatedgamesCompatible[index].feature = e.target.value;
                              setNftData({
                                ...nftData,
                                gamesCompatible: updatedgamesCompatible,
                              });
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Button onClick={() => handleRemoveAttribute(index)}>
                        Remove
                      </Button>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </VStack>
          </Box>
        </CardBody>
        <CardFooter>
          <Box>
            <Button colorScheme="green" onClick={handleJsonSubmit}>
              Download Metadata
            </Button>
          </Box>
        </CardFooter>
      </Card>
    </VStack>
  );
};

export default GamesCompatibleMetaJsonCreator;
