import React from 'react';
import { VStack, HStack, Container, Icon, Text } from '@chakra-ui/react';
import { FaTwitter, FaYoutube, FaTelegram, FaFacebook } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

function Footer() {
  return (
    <VStack mt='18px'>
      <HStack>
        <Icon as={FaTelegram} boxSize={4} color="green.300" />
        <Icon as={FaYoutube} boxSize={4} color="green.300" />
        <Icon as={MdEmail} boxSize={4} color="green.300" />
        <Icon as={FaTwitter} boxSize={4} color="green.300" />
        <Icon as={FaFacebook} boxSize={4} color="green.300" />
      </HStack>
      <Text as="i" color="teal.500" fontSize="xs">
        Copyright &copy; 2024 cozygames.xyz
      </Text>
    </VStack>
  );
}

export default Footer;
