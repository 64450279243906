import { useEffect, useState } from 'react';
import RenderValleyItems from './RenderValleyItems';
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import getAllValleyItems from './getAllValleyItems';
import SearchByTag from '../../../../searches/SearchByTag';
import SearchValleyItemsBySeller from '../../../../searches/SearchValleyItemsBySeller';
import SearchByGameName from '../../../../searches/SearchByGameName';
import { zeroAddress } from '../../../../utils/constants';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../cozyGames/constants/ContractAddress';
const ValleyItemListBase = ({
  provider,
  tokenType,
  saleType,
  marketplaceIds,
  auctionIds,
  byUser,
  userAddr,
  forGame,
  forExhibition,
  fromSpecificContract = false,
  specificTokenContract = { zeroAddress },
  forSpecificGame = false,
  specificGameTokenId = 0,
  isMagicflowers = false,
}) => {
  const [allItems, setAllItems] = useState([]);
  const [artItems, setArtItems] = useState([]);
  const [musicItems, setMusicItems] = useState([]);
  const [otherItems, setOtherItems] = useState([]);
  const [itemsFromSpecificContract, setItemsFromSpecificContract] = useState(
    []
  );
  const [itemsForSpecificGame, setItemsForSpecificGame] = useState([]);

  const [itemsMagicflowers, setItemsMagicflowers] = useState([]);

  useEffect(() => {
    getItems();
  }, []);

  const isArt = val => {
    return val.category === 'Art';
  };

  const isMusic = val => {
    return val.category === 'Music';
  };

  const isOthers = val => {
    return val.category === 'Others';
  };

  const isSeller = val => {
    return val.seller === userAddr;
  };

  const notCancelled = val => {
    return val.isCancelled !== true;
  };

  const isForGame = val => {
    return val.games.gameIds.length > 0;
  };

  const isFromSpecificContract = val => {
    return val.tokenContract == specificTokenContract;
  };

  const isForSpecificGame = val => {
    // console.log('val:', val);
    return val.games.gameIds.includes(specificGameTokenId);
  };

  const isForMagicflowers = val => {
    return val.tokenContract == REACT_APP_MAGIC_FLOWERS_ADDRESS;
  };

  const excludeMagicflowers = val => {
    return val.tokenContract !== REACT_APP_MAGIC_FLOWERS_ADDRESS;
  };

  const getItems = async () => {
    let newItems = await getAllValleyItems(
      provider,
      tokenType,
      saleType,
      marketplaceIds,
      auctionIds,
      forExhibition
    );

    let allNewItems = newItems.filter(notCancelled);
    newItems = allNewItems.filter(excludeMagicflowers);

    if (forGame) {
      newItems = newItems.filter(isForGame);
    }

    if (isMagicflowers) {
      const _magicflowersItems = allNewItems.filter(isForMagicflowers);
      setItemsMagicflowers(_magicflowersItems);
    }

    if (fromSpecificContract) {
      const _itemsSpecificContract = newItems.filter(isFromSpecificContract);
      setItemsFromSpecificContract(_itemsSpecificContract);
    } else if (forSpecificGame) {
      const _itemsForSpecificGame = newItems.filter(isForSpecificGame);
      setItemsForSpecificGame(_itemsForSpecificGame);
    } else {
      const _artItems = newItems.filter(isArt);
      const _musicItems = newItems.filter(isMusic);
      const _otherItems = newItems.filter(isOthers);
      console.log('newItems:', newItems);
      setAllItems(newItems);
      setArtItems(_artItems);
      setMusicItems(_musicItems);
      setOtherItems(_otherItems);
    }
  };

  const RenderMagicItems = () => {
    return (
      <>
        <Tabs variant="soft-rounded" colorScheme="green" isLazy>
          <TabList>
            <Tab>All</Tab>

            <Tab>Search By Tag</Tab>
            <Tab>Search By Seller</Tab>
            <Tab>Search By Game</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <RenderValleyItems
                items={allItems}
                byUser={byUser}
                tokenType={tokenType}
              />
            </TabPanel>
            {/* <TabPanel>
              <RenderValleyItems
                items={artItems}
                byUser={byUser}
                tokenType={tokenType}
              />
            </TabPanel>
            <TabPanel>
              <RenderValleyItems
                items={musicItems}
                byUser={byUser}
                tokenType={tokenType}
              />
            </TabPanel> */}
            {/* <TabPanel>
              <RenderValleyItems
                items={otherItems}
                byUser={byUser}
                tokenType={tokenType}
              />
            </TabPanel> */}
            <TabPanel>
              <SearchByTag
                allItems={allItems}
                byUser={byUser}
                tokenType={tokenType}
              />
            </TabPanel>
            <TabPanel>
              <SearchValleyItemsBySeller
                allItems={allItems}
                byUser={byUser}
                tokenType={tokenType}
              />
            </TabPanel>
            <TabPanel>
              <SearchByGameName
                allItems={allItems}
                byUser={byUser}
                tokenType={tokenType}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    );
  };

  const RenderItemsFromSpecificContract = () => {
    return (
      <>
        <RenderValleyItems
          items={itemsFromSpecificContract}
          byUser={byUser}
          tokenType={tokenType}
        />
      </>
    );
  };

  const RenderItemsForSpecificGame = () => {
    // console.log('itemsForSpecificGame:', itemsForSpecificGame);
    return (
      <>
        <RenderValleyItems
          items={itemsForSpecificGame}
          byUser={byUser}
          tokenType={tokenType}
        />
      </>
    );
  };

  const RenderItemsMagicflowers = () => {
    return (
      <>
        <RenderValleyItems
          items={itemsMagicflowers}
          byUser={byUser}
          tokenType="Erc721"
        />
      </>
    );
  };

  const RenderItems = () => {
    if (isMagicflowers) {
      return <RenderItemsMagicflowers />;
    } else if (fromSpecificContract) {
      return <RenderItemsFromSpecificContract />;
    } else if (forSpecificGame) {
      return <RenderItemsForSpecificGame />;
    } else {
      return <RenderMagicItems />;
    }
  };

  return (
    <VStack>
      <RenderItems />
    </VStack>
  );
};

export default ValleyItemListBase;
