import { system } from '../misc/Lsystem';
import { leaf1_treeCanvas } from '../leaves/treeLeaves/leaf1';
import { drawFlowerGenus1_treeCanvas } from '../flowers/flowerGenus1';
import { drawFlowerGenus2_treeCanvas } from '../flowers/flowerGenus2';
import { drawFlowerGenus5_treeCanvas } from '../flowers/flowerGenus5';

export class Plant2 {
  constructor(p5, treeCanvas, flowerCanvas, genes, flowerType) {
    this.p5 = p5;
    this.treeCanvas = treeCanvas;
    this.flowerCanvas = flowerCanvas;
    this.genes = genes;
    this.flowerType = flowerType;
    this.flowerParam = {
      fc: {
        h: this.p5.random(360),
        s: this.p5.random(88, 100),
        b: this.p5.random(88, 100),
      },
      sc: {
        h: this.p5.random(360),
        s: this.p5.random(88, 100),
        b: this.p5.random(88, 100),
      },
    };
    this.th = this.p5.int(this.treeCanvas.random(60, 180));
    this.ts = this.treeCanvas.random(20, 60);
    this.tb = this.treeCanvas.random(20, 70);

    this.rootL = 0;
    this.sentence = 'X';
    this.len = 188;
    this.deapth = 0;
    this.angleOrigin = 0;
    this.angle = 0;
    this.system = system;
    this.rules = this.system.rules;

    this.fruitColor = {
      h: this.p5.random(360),
      s: this.p5.random(88, 100),
      b: this.p5.random(88, 100),
    };

    this.leafColor = {
      lc: {
        h: this.p5.random(360),
        s: this.p5.random(88, 100),
        b: this.p5.random(88, 100),
      },
      vc: {
        h: this.p5.random(360),
        s: this.p5.random(88, 100),
        b: this.p5.random(88, 100),
      },
    };

    this.leafScale = 0.358;
    this.leafType = this.p5.floor(this.p5.random(1, 3));
    // console.log('leafType:', this.leafType);

    this.randomIntegers = this.generateShuffledArray(300);
  }

  generateShuffledArray(n) {
    const array = Array.from({ length: n }, (_, index) => index);
    return array.sort(() => Math.random() - 0.5);
  }

  new_gen() {
    this.sentence = this.system.axiom;
    this.len = 128;
    this.angle = this.p5.radians(
      this.treeCanvas.random(this.system.angle_min, this.system.angle_max)
    );
    this.angleOrigin = this.angle;

    for (var i = 0; i < 6; ++i) {
      this.generate();
    }

    this.rootL = this.findFirstFSubstring(this.sentence);
    // console.log('rootL:', this.rootL);
    if (this.rootL > 16) {
      this.sentence = this.trimRoot(this.sentence, this.rootL - 16);
    }
    // console.log('this.sentence:', this.sentence);
  }

  trimRoot(str, n) {
    if (n <= 0) {
      return str;
    } else if (n >= str.length) {
      return '';
    } else {
      return str.slice(n);
    }
  }

  findFirstFSubstring(str) {
    let fSubstring = '';
    for (let i = 0; i < str.length; i++) {
      if (str[i] === 'F') {
        fSubstring += 'F';
      } else {
        break;
      }
    }
    return fSubstring.length;
  }

  generate() {
    var next_sentence = '';
    for (var i = 0; i < this.sentence.length; ++i) {
      var current = this.sentence.charAt(i);
      var found = false;
      for (var j = 0; j < this.rules.length; ++j) {
        if (current == this.rules[j][0]) {
          found = true;
          next_sentence += this.treeCanvas.random(this.rules[j][1]);
          break;
        }
      }
      if (!found) {
        next_sentence += current;
      }
    }
    this.sentence = next_sentence;
    this.len *= 0.5899;
  }

  display(flower, flowers) {
    this.treeCanvas.resetMatrix();
    this.treeCanvas.translate(this.p5.width / 2, this.p5.height);
    this.treeCanvas.strokeWeight(3);
    this.treeCanvas.stroke(this.th, this.ts, this.tb);
    for (var i = 0; i < this.sentence.length; i++) {
      var c = this.sentence.charAt(i);
      if (c == 'F') {
        if (i < this.rootL) {
          this.treeCanvas.strokeWeight(5.88);
        } else if (i >= this.rootL && i < 25) {
          let thickness = this.p5.map(i, this.rootL, 25, 5.88, 2.88);
          this.treeCanvas.strokeWeight(thickness);
        } else {
          this.treeCanvas.strokeWeight(2.58);
        }
        this.treeCanvas.line(0, 0, 0, -this.len);
        this.treeCanvas.translate(0, -this.len);
      } else if (c == 'X' || c == 'Y') {
        // leaf
        if (this.treeCanvas.random() > 0.888) {
          this.treeCanvas.push();
          this.treeCanvas.translate(0, -4);
          if (this.leafType === 2) {
            this.treeCanvas.noStroke();
            this.treeCanvas.fill(
              this.leafColor.lc.h,
              this.leafColor.lc.s,
              this.leafColor.lc.b
            );
            this.treeCanvas.ellipse(
              0,
              0,
              this.treeCanvas.random(1 * this.leafScale, 20 * this.leafScale),
              this.treeCanvas.random(10 * this.leafScale, 50 * this.leafScale)
            );
          } else {
            leaf1_treeCanvas(this.p5, this.treeCanvas, this.leafColor);
          }
          this.treeCanvas.pop();
        }

        if (this.treeCanvas.random() > 0.9) {
          this.treeCanvas.push();
          this.treeCanvas.noStroke();
          this.treeCanvas.fill(
            this.fruitColor.h, //this.genes.fruitColor[0],
            this.fruitColor.s, //this.genes.fruitColor[1],
            this.fruitColor.b //this.genes.fruitColor[2]
          );
          this.treeCanvas.ellipse(0, 0, 8, 8);
          this.treeCanvas.pop();
        }

        if (this.treeCanvas.random() <= 0.018) {
          // p5.translate(0, -4);
          // flower1(p5, flower1_color, flower2_color);
          let flowerPos = this.p5.createVector(0, -4);
          if (this.flowerType <= 108) {
            this.p5.push();
            drawFlowerGenus1_treeCanvas(
              this.p5,
              this.treeCanvas,
              flowerPos.x,
              flowerPos.y,
              this.flowerParam,
              this.flowerType
            );
            this.p5.pop();
          } else if (this.flowerType > 108 && this.flowerType < 115) {
            this.p5.push();
            drawFlowerGenus2_treeCanvas(
              this.p5,
              this.treeCanvas,
              flowerPos.x,
              flowerPos.y,
              this.flowerParam,
              this.flowerType
            );
            this.p5.pop();
          } else if (this.flowerType >= 115 && this.flowerType <= 128) {
            this.treeCanvas.image(this.flowerCanvas, flowerPos.x, flowerPos.y);
          } else if (this.flowerType === 129) {
            this.p5.push();
            flower.display(flowerPos.x, flowerPos.y);
            this.p5.pop();
          } else if (this.flowerType === 130) {
            this.p5.push();
            drawFlowerGenus5_treeCanvas(
              this.p5,
              this.treeCanvas,
              flowerPos.x,
              flowerPos.y,
              this.flowerParam
            );
            this.p5.pop();
          } else if (
            this.flowerType === 133 ||
            this.flowerType === 132 ||
            this.flowerType === 131
          ) {
            this.treeCanvas.push();
            flower.update(flowerPos.x, flowerPos.y);
            this.treeCanvas.pop();
          }

          if (this.flowerType >= 134 && this.flowerType <= 136) {
            let flowerIdx = this.p5.int(this.p5.random(flowers.length));
            // positions.push([this.position.x, this.position.y]);

            this.flowerCanvas.push();
            // console.log('flower:', flowers);

            flowers[flowerIdx].drawFlower(this.p5);
            this.flowerCanvas.pop();
            this.flowerCanvas.push();

            // flowers[flowerIdx].spinFlower();
            this.flowerCanvas.pop();
            this.treeCanvas.push();

            this.treeCanvas.image(
              this.flowerCanvas,
              flowerPos.x,
              flowerPos.y,
              800 / 5.8,
              600 / 5.8
            );
            this.treeCanvas.pop();
          }

          if (this.flowerType === 8888) {
            this.treeCanvas.push();

            flower.resize(18, 0);

            this.treeCanvas.image(flower, flowerPos.x, flowerPos.y);
            this.treeCanvas.pop();
          }
        }
      } else if (c == '-') {
        this.treeCanvas.rotate(-this.angle + this.deapth / 75);
      } else if (c == '+') {
        this.treeCanvas.rotate(this.angle + this.deapth / 75);
      } else if (c == '[') {
        this.deapth += 1;
        this.treeCanvas.push();
      } else if (c == ']') {
        this.deapth -= 1;
        this.treeCanvas.pop();
      }
    }
  }

  // wave(windFactor) {
  //   this.angle =
  //     this.angleOrigin + windFactor * this.p5.sin(this.p5.frameCount / 108);
  // }
  wave(windFactor) {
    const waveSpeed = 0.018; // Adjust this value to control the waving speed
    this.angle =
      this.angleOrigin +
      windFactor * this.p5.sin(this.p5.frameCount * waveSpeed);
  }
}
