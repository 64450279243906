let palette = ['#0b132b', '#fed656', '#fe56d2'];
export class ShootingStar2 {
  constructor(p5) {
    this.p5 = p5;
    // this._x = p5.random(0, p5.width);
    // this._y = p5.random(p5.height - 300, p5.height);
    this._x = Math.random() * p5.width; // Using Math.random() for independent random values
    // this._y = Math.random() * (p5.height - 100) + 100; // Using Math.random() for independent random values
    this._y = p5.height - Math.random() * 58;
    this._r = p5.random(8, 18);
    this._a = p5.random(p5.TWO_PI);
    this._c = palette[p5.random() < 0.85 ? 1 : 2];
     // Add a property to keep track of whether the star is clicked
     this.clicked = false;
  }

  drawStar() {
    let N = 4;
    let angle = this.p5.TWO_PI / (N * 2);
    this.p5.beginShape();
    for (let i = 0; i < N * 2; i++) {
      let a = this._a + (i - (N % 2 === 1 ? 0.5 : 0)) * angle;
      let r = this._r * (i % 2 === 1 ? 0.3 : 1);
      let x = this._x + this.p5.cos(a) * r;
      let y = this._y + this.p5.sin(a) * r;
      this.p5.vertex(x, y);
    }
    this.p5.endShape(this.p5.CLOSE);
  }

  draw(ctx) {
    ctx.save();
    this.p5.blendMode(this.p5.ADD);
    let n = this.p5.noise(this._x * 0.1, this._y * 0.1, this._a * 0.1);
    let c = this.p5.color(this._c);
    ctx.shadowBlur = this._r * 0.8;
    ctx.shadowColor = `rgba(${c.levels[0]}, ${c.levels[1]}, ${c.levels[2]}, ${
      0.5 + n * 0.5
    })`;
    this.p5.fill(0);
    this.drawStar(ctx);
    this.p5.fill(this._c);
    this.drawStar(ctx);
    this.p5.blendMode(this.p5.BLEND);
    ctx.restore();
  }

  // Method to check if the star is clicked and set 'clicked' property
  checkClick() {
    if (
      this.p5.mouseX >= this._x - this._r &&
      this.p5.mouseX <= this._x + this._r &&
      this.p5.mouseY >= this._y - this._r &&
      this.p5.mouseY <= this._y + this._r
    ) {
      this.clicked = true; // Set clicked to true if the star is clicked
    } else {
      this.clicked = false; // Set clicked to false if the star is not clicked
    }
  }
}
