const getFlowerFeatures = flowerType => {
    const featuresMap = {
      133: {
        nbPetals: 7,
        petalLength: 25.8, //58, //26.109936035020404,
        heartRadius: 5.88, //4.932703372387952,
        XfactorUp: 0.5131985894999227,
        XfactorDown: 0.19724296516825207,
        YfactorUp: 0.4002376074987005,
        YfactorDown: 0.3876678219825799,
      },
      132: {
        nbPetals: 7,
        petalLength: 26.529981384744733,
        heartRadius: 4.487056020691823,
        XfactorUp: 1.7513393853390076,
        YfactorUp: 0.2736014659744576,
        XfactorDown: 0.18218734585967952,
        YfactorDown: 0.46497036227942257,
      },
      131: {
        nbPetals: 7,
        petalLength: 25.8, //58, //26.109936035020404,
        heartRadius: 5.88, //4.932703372387952,
        XfactorUp: 0.5131985894999227,
        XfactorDown: 0.19724296516825207,
        YfactorUp: 0.4002376074987005,
        YfactorDown: 0.3876678219825799,
      },
    };
    return featuresMap[flowerType];
  };
  export class flowerGenus7treeCanvas {
    /**
       * 
       flowerParam = {
            nbPetals: 7,
            petalLength: 25.8, //58, //26.109936035020404,
            heartRadius: 5.88, //4.932703372387952,
            XfactorUp: 0.5131985894999227,
            XfactorDown: 0.19724296516825207,
            YfactorUp: 0.4002376074987005,
            YfactorDown: 0.3876678219825799,
            color: [p5.random(360), 255, p5.random(160, 255), p5.random(100, 160)],
          };
       */
    constructor(p5, treeCanvas, flowerParams, flowerType) {
      this.p5 = p5;
      this.treeCanvas = treeCanvas
      //stroke(0);
      this.ON_TREE = 1;
      this.FALLING = 2;
      this.ON_GROUND = 3;
      this.pos = this.p5.createVector(0, 0);
      this.features = getFlowerFeatures(flowerType);
      this.nbPetals = this.features.nbPetals;
      this.petalLength = this.features.petalLength; //25.8;
      this.heartRadius = this.features.heartRadius; //5.88;
      this.XfactorUp = this.features.XfactorUp; //0.5131985894999227;
      this.YfactorUp = this.features.YfactorUp; //0.4002376074987005;
      this.XfactorDown = this.features.XfactorDown; //0.19724296516825207;
      this.YfactorDown = this.features.YfactorDown; //0.3876678219825799;
      this.theta = this.p5.random(this.p5.TWO_PI);
      this.thetaSpeed =
        this.p5.random(1.5) * (this.p5.random(1) < 0.5 ? 0.0088 : -0.0088);
      this.hasTouchedGround = false;
      this.speed = this.p5.createVector(0, 0);
      this.state = this.ON_TREE;
      this.color = [
        flowerParams.fc.h,
        flowerParams.fc.s,
        flowerParams.fc.b,
        0.358,
      ];
    }
  
    update(x, y) {
      this.pos.x = x;
      this.pos.y = y;
      this.treeCanvas.push();
      this.treeCanvas.translate(this.pos.x, this.pos.y);
      // this.theta += this.thetaSpeed;
      this.treeCanvas.rotate(this.theta);
  
      //petals
      //stroke(0);
      //this.p5.noFill();
      this.treeCanvas.fill(this.color[0], this.color[1], this.color[2], this.color[3]);
      this.treeCanvas.stroke(this.color[0], this.color[1], this.color[2], this.color[3]);
      for (let i = 0; i < this.nbPetals; i++) {
        this.treeCanvas.rotate(this.p5.TWO_PI / this.nbPetals);
        this.treeCanvas.beginShape();
        this.treeCanvas.vertex(0, 0);
        this.treeCanvas.bezierVertex(
          -this.petalLength * this.XfactorDown,
          -this.petalLength * this.YfactorDown,
          -this.petalLength * (1 - this.XfactorUp),
          -this.petalLength * (1 - this.YfactorUp),
          0,
          -this.petalLength
        );
        this.treeCanvas.bezierVertex(
          this.petalLength * (1 - this.XfactorUp),
          -this.petalLength * (1 - this.YfactorUp),
          this.petalLength * this.XfactorDown,
          -this.petalLength * this.YfactorDown,
          0,
          0
        );
        this.treeCanvas.endShape();
      }
  
      //fill(200, 200, 200, 50);
      this.treeCanvas.noStroke();
      for (let i = 0; i < this.nbPetals; i++) {
        this.treeCanvas.rotate(this.p5.TWO_PI / this.nbPetals);
        this.treeCanvas.beginShape();
        this.treeCanvas.vertex(0, 0);
        this.treeCanvas.bezierVertex(
          -this.petalLength * this.XfactorDown,
          -this.petalLength * this.YfactorDown,
          -this.petalLength * (1 - this.XfactorUp),
          -this.petalLength * (1 - this.YfactorUp),
          0,
          -this.petalLength
        );
        this.treeCanvas.bezierVertex(
          this.petalLength * (1 - this.XfactorUp),
          -this.petalLength * (1 - this.YfactorUp),
          this.petalLength * this.XfactorDown,
          -this.petalLength * this.YfactorDown,
          0,
          0
        );
        this.treeCanvas.endShape();
      }
  
      //heart
      this.treeCanvas.fill(20, 15, 12);
      this.treeCanvas.stroke(0);
      this.treeCanvas.ellipse(0, 0, this.heartRadius, this.heartRadius);
  
      this.treeCanvas.pop();
    }
  }
  