import { Link as ReactLink } from 'react-router-dom';
import { Box, Text, Link } from '@chakra-ui/react';
const BackLink = ({ magicflowerId }) => {
  return (
    <Box style={{ marginTop: '58px' }}>
      <Text fontSize="xs">
        Back to{' '}
        <Link
          color="teal.500"
          as={ReactLink}
          to={`/magicflowers/magicflower/${magicflowerId}`}
        >
          Magic Flowers NFT #{magicflowerId}
        </Link>
      </Text>
    </Box>
  );
};

export default BackLink;
