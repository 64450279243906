import { Link as ReactLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Link, VStack, Box, Wrap, WrapItem, Text } from '@chakra-ui/react';
import getAllTokensCreatedBy from './elements/getAllTokensCreatedBy';
import DetailButton from './elements/DetailButton';

function AllTokensCreatedBy({ provider, isErc1155, addr, isArtist=false }) {
  const [tokensCreated, setTokensCreated] = useState([]);

  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    const allTokensCreated = await getAllTokensCreatedBy(provider, isErc1155, addr);

    // console.log('allTokensCreated:', allTokensCreated);
    setTokensCreated(allTokensCreated);
  };

  const getTokenLink = (t) => {
    if (isArtist) {
      return `/tokens/tokensbyuser/${t.tokenContractAddress}/${t.tokenId}/${addr}`
    } else {
      return `/tokens/${t.tokenContractAddress}/${t.tokenId}`
    }
  }

  return (
    <VStack>
      <Wrap spacing={4}>
        {tokensCreated.length > 0 &&
          tokensCreated.map((t, key) => (
            <WrapItem key={key}>
              <Box
                maxW="sm"
                textAlign="center"
                style={{
                  display: 'grid',
                  minHeight: '200px',
                  border: '8px solid',
                  padding: '1rem',
                  borderImage:
                    'linear-gradient(45deg, turquoise, greenyellow) 1',
                  marginTop: '20px',
                }}
              >
                <VStack spacing={3}>
                  <Box>
                    <Box display="flex" alignItems="baseline">
                      <img
                        src={t.metadata.image}
                        alt={t.metadata.image}
                        style={{ width: '400px' }}
                      />
                    </Box>

                    {t.category === 'Music' && (
                      <div>
                        <audio controls>
                          <source src={t.musicUrl} />
                        </audio>
                      </div>
                    )}
                  </Box>

                  <Box>
                    <Text style={{ fontFamily: 'Lobster' }} fontSize="2xl">
                      {t.metadata.name}
                    </Text>
                  </Box>
                  <Link
                    as={ReactLink}
                    to={getTokenLink(t)}
                  >
                    <DetailButton />{' '}
                  </Link>
                </VStack>
              </Box>
            </WrapItem>
          ))}
      </Wrap>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default AllTokensCreatedBy;
