import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Box,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import Games from '../../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import GameAdminModeForm from '../../../../formComponents/GameAdminModeForm';
import { REACT_APP_GAMES_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';
const UpdateGameAdmin = ({ gameId }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { approveAdmin, setApproveAdmin } = useState('1');
  const { newAdmin, setNewAdmin } = useState('');
  const { loading, setLoading } = useState(false);

  const updateAdmin = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const games = new ethers.Contract(
        REACT_APP_GAMES_ADDRESS,
        Games.abi,
        signer
      );

      let transactionList;
      const _approve = approveAdmin == '1' ? true : false;

      transactionList = await games.updateGameAdmin(gameId, newAdmin, _approve);

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="green"
          size="lg"
          variant="outline"
          mr={3}
        >
          Update Game Admin
        </Button>
      );
    } else {
      return (
        <Button
          onClick={updateAdmin}
          colorScheme="green"
          size="lg"
          variant="outline"
          mr={3}
        >
          Update Game Admin
        </Button>
      );
    }
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="green">
        Update Game Admin
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Game Admin</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <GameAdminModeForm
              approveAdmin={approveAdmin}
              setApproveAdmin={setApproveAdmin}
            />
            <Box mt="20px" mb="20px">
              <FormControl isRequired>
                <FormLabel as="legend">Admin Account</FormLabel>
                <Input
                  value={newAdmin}
                  onChange={e => setNewAdmin(e.target.value)}
                  placeholder="Game Admin Account"
                  size="sm"
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            <RenderButton />
            <Button colorScheme="blue" variant="ghost" onClick={onClose} ml={3}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateGameAdmin;
