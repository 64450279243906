import React, { useState, useEffect } from 'react';
import { useParams, Link as ReactLink } from 'react-router-dom';
import {
  Link,
  VStack,
  Box,
  Text,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@chakra-ui/react';

import LeasingMarketPic from '../../../../resources/images/games/magicflowers/leasing-market.png';
import Fence from '../../../../resources/images/magicGallery/fence.png';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import GameFooter from '../../../../components/views/GameFooter';
import ListMagicFlowerForLease from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/ListMagicFlowerForLease';
import getParentPlantLeaseList from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getParentPlantLeaseList';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import convertUnixTime from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/utils/convertUnixTime';

const ParentPlantLeasingMarket = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const [leaseList, setLeaseList] = useState([]);

  useEffect(() => {
    getItems();
  }, []);
  const getItems = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const _leaseList = await getParentPlantLeaseList(provider);
    console.log('_leaseList:', _leaseList);
    if (_leaseList.length > 0) {
      setLeaseList(_leaseList);
    }
  };

  const getSexType = num => {
    if (num === 1) {
      return 'Male';
    } else if (num === 2) {
      return 'Female';
    } else if (num === 3) {
      return 'Hermaphrodite';
    } else {
      return '';
    }
  };

  return (
    <VStack>
      <MagicFlowerTopGrid />
      <VStack spacing={6}>
        <img
          src={LeasingMarketPic}
          style={{ width: '285px', height: 'auto' }}
        />
        <ListMagicFlowerForLease />

        {leaseList.length > 0 &&
          leaseList.map((lease, i) => (
            <Card
              key={i}
              w="358px"
              style={{
                display: 'grid',
                minHeight: '200px',
                border: '8px solid',
                padding: '1rem',
                borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
                marginTop: '20px',
              }}
            >
              <CardHeader>
                {' '}
                <Text>Magic Flower Id: {lease.magicflowerId}</Text>
              </CardHeader>

              <img></img>
              <Text>
                Plant Type: {lease.metadata.plantType === 1 ? 'Tree' : 'Plant'}
              </Text>
              <Text>
                Birth Date: {convertUnixTime(Number(lease.metadata.birthdate))}
              </Text>
              <Text>{getSexType(lease.metadata.sex)}</Text>
              <Text>Total Offsprings: {lease.metadata.totalOffsprings} </Text>
              <Text>Leasing price: {lease.price} MATIC</Text>
              <CardFooter>
                <Link
                  color="teal.500"
                  as={ReactLink}
                  to={`/magicflowers/leasingmarket/${lease.magicflowerId}`}
                >
                  <Button colorScheme="green" size="lg" variant="outline">
                    Details
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          ))}
      </VStack>

      <Box mt={8} mb={8}>
        <img src={Fence} style={{ width: '100%', height: 'auto' }} />
      </Box>
      <Box></Box>
      <div style={{ marginTop: '58px' }}>
        <GameFooter />
      </div>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
};

export default ParentPlantLeasingMarket;
