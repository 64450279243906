function convertUnixTime(unixTimestamp) {
    // Convert Unix timestamp to milliseconds
    const milliseconds = unixTimestamp * 1000;
  
    // Create a new Date object
    const dateObject = new Date(milliseconds);
  
    // Options for date formatting
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    };
  
    // Format the date
    const formattedDate = dateObject.toLocaleDateString('en-US', options);
  
    return formattedDate;
  }

  export default convertUnixTime
  
  // Example usage:
  // const unixTimestamp = 1626535200; // Replace with your Unix timestamp
  // const formattedDate = unixTimeToReadable(unixTimestamp);
  // console.log(formattedDate); // Output example: Jul 17, 2021, 10:00:00 AM PDT
  