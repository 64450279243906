import { useEffect, useState } from 'react';
import {
  VStack,
  HStack,
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
} from '@chakra-ui/react';
import { GiButterfly } from 'react-icons/gi';
import { ethers } from 'ethers';
import ITokenURI from '../../../../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import Collections from '../../../../../contracts/APIs/CollectionsInterface.sol/CollectionsInterface.json';
import getTokenPriceHistory from './getTokenPriceHistory';
import getTokenInfo from './getTokenInfo';
import RenderTokenInfo from './RenderTokenInfo';
import SellAuctionNFT from '../../../../actions/NFTs/userActions/SellAuctionNFT';
import getCurrentListingForToken from './getCurrentListingForToken';
import UserCollectionAction from '../../../../actions/NFTs/userActions/collection/UserCollectionAction';
import AddGameIntegrationByUser from '../../../../actions/NFTs/userActions/gameIntegration/AddGameIntegrationByUser';
import isTokenOwner from '../../../../utils/isTokenOwner';
import UpdateGameIntegrationByUser from '../../../../actions/NFTs/userActions/gameIntegration/UpdateGameIntegrationByUser';
import getErc1155TokenBalance from './getErc1155TokenBalance';
import getCollectionInfo from './getCollectionInfo';
import { REACT_APP_COLLECTIONS_ADDRESS } from '../../../cozyGames/constants/ContractAddress';
// import RemoveIntegratedGameByUser from '../../../actions/NFTs/userActions/gameIntegration/RemoveIntegratedGameByUser';
// import RemoveGameFeaturesByUser from '../../../actions/NFTs/userActions/gameIntegration/RemoveGameFeaturesByUser';

function RenderToken({
  provider,
  tokenContractAddress,
  tokenId,
  useCase,
  user,
}) {
  const [item, setItem] = useState({});
  const [itemPriceHistory, setItemPriceHistory] = useState([]);
  const [itemCollection, setItemCollection] = useState({});
  const [currentListing, setCurrentListing] = useState({});
  const [isTokenCreator, setIsTokenCreator] = useState(false);
  const [tokenOwner, setTokenOwner] = useState(false);
  const [tokenCopies, setTokenCopies] = useState(1);

  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    const tokenContract = new ethers.Contract(
      tokenContractAddress,
      ITokenURI.abi,
      provider
    );
    const tokenInfo = await getTokenInfo(tokenContract, tokenId);
    // console.log('tokenInfo:', tokenInfo);
    const priceList = await getTokenPriceHistory(
      provider,
      tokenContractAddress,
      tokenId
    );
    // console.log('priceList:', priceList);
    const collectionsContract = new ethers.Contract(
      REACT_APP_COLLECTIONS_ADDRESS,
      Collections.abi,
      provider
    );
    const collectionInfo = await getCollectionInfo(
      collectionsContract,
      tokenContractAddress,
      tokenId
    );
    // console.log('collectionInfo:', collectionInfo);
    const _currentListing = await getCurrentListingForToken(
      provider,
      tokenContractAddress,
      tokenId
    );
    // console.log('_currentListing:', _currentListing);
    setItem(tokenInfo);
    setItemPriceHistory(priceList);
    setItemCollection(collectionInfo);
    // setCurrentMarketplaceId(_currentListing.currentMarketplaceId);
    // setCurrentAuctionId(_currentListing.currentAuctionId);
    setCurrentListing(_currentListing);
    if (useCase == 'TokenByUser') {
      if (tokenInfo.artist == user) {
        setIsTokenCreator(true);
      }
      const _isTokenOwner = await isTokenOwner(
        provider,
        tokenId,
        tokenContractAddress,
        user
      );
      if (_isTokenOwner) {
        setTokenOwner(true);
        if (tokenInfo.tokenType === 'Erc1155') {
          const _tokenCopies = await getErc1155TokenBalance(
            provider,
            tokenId,
            tokenContractAddress,
            user
          );
          setTokenCopies(_tokenCopies);
        }
      }
    }
  };

  const RenderTokenOwnerActions = ({ useCase, item }) => {
    return (
      <Box marginTop={6}>
        <VStack>
          <HStack spacing={6}>
            <Icon as={GiButterfly} color="#439cfb" />
            <Icon as={GiButterfly} color="green.500" />
            <Icon as={GiButterfly} color="#f187fb" />
          </HStack>
          <Box
            style={{
              display: 'grid',
              minHeight: '200px',
              border: '8px solid',
              padding: '1rem',
              borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
              marginTop: '20px',
            }}
          >
            <Box textAlign="center">
              <Text
                fontSize={36}
                color="green.300"
                style={{ fontFamily: 'Lobster' }}
              >
                Token Owner Actions
              </Text>
            </Box>
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab>Selling</Tab>
                <Tab>Game Integration</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <HStack>
                    <Icon as={GiButterfly} color="green.500" />
                    <SellAuctionNFT
                      tokenType={item.tokenType}
                      useCase={useCase}
                      tokenContract={item.tokenContractAddress}
                      tokenId={item.tokenId}
                    />{' '}
                  </HStack>
                </TabPanel>
                <TabPanel>
                  <HStack>
                    <Icon as={GiButterfly} color="green.500" />
                    <AddGameIntegrationByUser
                      tokenType={item.tokenType}
                      tokenContract={item.tokenContractAddress}
                      tokenId={item.tokenId}
                    />
                  </HStack>

                  <HStack>
                    <Icon as={GiButterfly} color="green.500" />
                    <UpdateGameIntegrationByUser
                      tokenContract={item.tokenContractAddress}
                      tokenId={item.tokenId}
                    />
                  </HStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </VStack>
      </Box>
    );
  };

  const RenderTokenCreatorActions = () => {
    return (
      <Box
        marginTop={6}
        style={{
          display: 'grid',
          minHeight: '200px',
          border: '8px solid',
          padding: '1rem',
          borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
          marginTop: '20px',
        }}
      >
        {' '}
        <VStack>
          <Box textAlign="center">
            <Text
              fontSize={36}
              color="green.300"
              style={{ fontFamily: 'Lobster' }}
            >
              Token Creator Actions
            </Text>
          </Box>
          <UserCollectionAction
            tokenContract={tokenContractAddress}
            tokenId={tokenId}
          />
        </VStack>
      </Box>
    );
  };

  return (
    <VStack>
      <RenderTokenInfo
        provider={provider}
        item={item}
        currentListing={currentListing}
        itemPriceHistory={itemPriceHistory}
        itemCollection={itemCollection}
        user={user}
      />

      {tokenOwner && item.tokenType === 'Erc1155' && (
        <VStack>
          <HStack spacing={6}>
            <Icon as={GiButterfly} color="#439cfb" />
            <Icon as={GiButterfly} color="green.500" />
            <Icon as={GiButterfly} color="#f187fb" />
          </HStack>
          <Text
            fontSize={26}
            color="green.300"
            style={{ fontFamily: 'Lobster' }}
          >
            Total copies owned: {tokenCopies}
          </Text>
        </VStack>
      )}
      {tokenOwner && <RenderTokenOwnerActions useCase={useCase} item={item} />}
      {isTokenCreator && tokenOwner && <RenderTokenCreatorActions />}
    </VStack>
  );
}

export default RenderToken;
