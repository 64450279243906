import { useState, useEffect } from 'react';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import MagicFlowerTree from '../../../../components/sketches/games/magicFlowersSketches/plants/MagicFlowerTree';
import {
  Link,
  VStack,
  Box,
  Text,
  Wrap,
  WrapItem,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { GiTwirlyFlower, GiFlowerPot, GiButterfly } from 'react-icons/gi';
import SearchMagicFlowers from '../../../../components/views/SearchMagicFlowers';
import { Link as ReactLink } from 'react-router-dom';
import MintMagicFlowerPlantButton from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/MintMagicFlowerButton';
import MarketplaceGif from '../../../../resources/images/games/magicflowers/magicflowers-marketplace.gif';
import RevivalHeavenGif from '../../../../resources/images/games/magicflowers/magicflower2.gif';
import HybridGif from '../../../../resources/images/games/magicflowers/hybrid.gif';
import AuctionGif from '../../../../resources/images/games/magicflowers/magicflowers-auctionhouse.gif';
import LeaseMarketGif from '../../../../resources/images/games/magicflowers/magicflowers-leasemarket.gif';
import MyMagicFlowersGif from '../../../../resources/images/games/magicflowers/allmymagicflowers.gif';
import DemoGif from '../../../../resources/images/games/magicflowers/DemoMagicFlower.gif';
import GameFooter from '../../../../components/views/GameFooter.js';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid.js';
import HybridizeMagicFlowerButton from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/HybridizeMagicFlowerButton.js';
import PreludeMagicFlowers from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/PreludeMagicFlowers.js';
import MainFeatures from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MainFeatures.js';
import HowToPlay from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/HowToPlay.js';
import LeaseMagicFlower from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/LeaseMagicFlower.js';
import getProgenitorsRevived from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getProgenitorsRevived.js';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import MagicFlowers from '../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider.js';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../../components/views/cozyGames/constants/ContractAddress.js';
function MagicFlowersNFT() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const [showSketch, setShowSketch] = useState(false);
  const [disableRadio, setDisableRadio] = useState({
    1: false,
    2: false,
    3: false,
  });

  useEffect(() => {
    getItems();
  }, []);
  const setToTrue = key => {
    setDisableRadio(prevState => ({
      ...prevState,
      [key]: true,
    }));
  };

  const getItems = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const magicFlowers = new ethers.Contract(
      REACT_APP_MAGIC_FLOWERS_ADDRESS,
      MagicFlowers.abi,
      provider
    );

    const _totalProgenitorsRevived = await getProgenitorsRevived(magicFlowers);
    // console.log('_totalProgenitorsRevived:', _totalProgenitorsRevived);
    if (_totalProgenitorsRevived.male >= 1000) {
      setToTrue(1);
    }
    if (_totalProgenitorsRevived.female >= 1000) {
      setToTrue(2);
    }
    if (_totalProgenitorsRevived.hermaphrodite >= 1000) {
      setToTrue(3);
    }
    // setTotalPlantsRevived(_totalProgenitorsRevived);
  };

  const plantFeatures = {
    plantSeed: Math.floor(Math.random() * 100000) + 1,
    plantType: Math.floor(Math.random() * 2) + 1,
    removeDefaultFairy: false,
  };

  const handleShowSketch = () => {
    if (showSketch) {
      setShowSketch(false);
    } else {
      setShowSketch(true);
    }
  };

  const RenderShowSketchButton = () => {
    const _label = showSketch ? 'Hide' : 'View';
    return (
      <Tooltip
        hasArrow
        placement="top-end"
        label="Click to view a demo of how Magic Flowers might look after revival. Note: These are not minted plants, but a visual preview. If you mint or revive a Magic Flower plant, its appearance might differ from this demo."
      >
        <Box
          as="button"
          colorScheme="green"
          variant="outline"
          size="lg"
          onClick={handleShowSketch}
        >
          <img src={DemoGif} alt="Magic Flowers" />
          <Text color="green.300" fontSize="xl" as="b">
            {' '}
            {_label} Magic Flowers Demo
          </Text>
        </Box>
      </Tooltip>
    );
  };

  const RenderSketch = () => {
    return (
      <ReactP5Wrapper
        sketch={MagicFlowerTree}
        plantFeatures={plantFeatures}
        genes={{}}
        magicflowerId={Math.floor(Math.random() * 100000) + 1}
        userDefaultBgs={[]}
        isDemo={true}
      />
    );
  };

  const getToUrl = isMarketplace => {
    const toUrl = isMarketplace
      ? '/magicflowers/marketplace'
      : '/magicflowers/auctions';
    return toUrl;
  };

  return (
    <>
      {showSketch && <RenderSketch />}
      <Box>
        <MagicFlowerTopGrid />
      </Box>
      <VStack mt={2}>
        <Box textAlign="center" mt={6}>
          <VStack spacing={6}>
            <Text
              color="green.300"
              fontSize={[36, 42, 58]}
              style={{ fontFamily: 'Lobster' }}
            >
              Magic Flowers
            </Text>

            <Wrap>
              <WrapItem>
                <PreludeMagicFlowers />
              </WrapItem>
              <WrapItem>
                {' '}
                <RenderShowSketchButton />
              </WrapItem>
            </Wrap>

            {/* <img src={PaperInfo} alt="Magic Flowers Info on Paper" /> */}
            <Wrap spacing={6}>
              <WrapItem>
                <MainFeatures />
              </WrapItem>
              <WrapItem>
                <HowToPlay />
              </WrapItem>
            </Wrap>

            <Box style={{ marginTop: '30px', marginBottom: '30px' }}>
              <Wrap spacing={6}>
                <WrapItem>
                  <VStack>
                    <Icon as={GiTwirlyFlower} w={8} h={8} color="green.500" />
                    <MintMagicFlowerPlantButton RadioStatus={disableRadio} />
                  </VStack>
                </WrapItem>
                <WrapItem>
                  <VStack>
                    <Icon as={GiButterfly} w={8} h={8} color="green.500" />
                    <HybridizeMagicFlowerButton />
                  </VStack>
                </WrapItem>
                <WrapItem>
                  <VStack>
                    <Icon as={GiFlowerPot} w={8} h={8} color="green.500" />
                    <LeaseMagicFlower />
                  </VStack>
                </WrapItem>
              </Wrap>
            </Box>

            <Box>
              <SearchMagicFlowers />
            </Box>
            <Link as={ReactLink} to="/magicflowers/allmagicflowers">
              <Box>
                <Text
                  color="green.400"
                  fontSize="2xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  All Revived & Hybridized Magic Flowers
                </Text>
              </Box>
            </Link>

            <VStack>
              <Link as={ReactLink} to="/magicflowers/allmymagicflowers">
                <Text
                  color="green.400"
                  fontSize="2xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  My Blooming Collection
                </Text>
              </Link>
              <Box>
                <img src={MyMagicFlowersGif} alt="Magic Flowers Marketplace" />
              </Box>
            </VStack>

            <Wrap spacing={10} mb="30px">
              <WrapItem>
                <VStack>
                  <Link as={ReactLink} to="/magicflowers/revive">
                    <img src={RevivalHeavenGif} alt="Revival Heaven" />
                    <Text
                      color="green.400"
                      fontSize="2xl"
                      style={{ fontFamily: 'Lobster' }}
                    >
                      Revival Heaven
                    </Text>
                  </Link>
                </VStack>
              </WrapItem>
              <WrapItem>
                <VStack>
                  <Link as={ReactLink} to="/magicflowers/hybridize">
                    <img src={HybridGif} alt="Hybrid Paradize" />
                    <Text
                      color="green.400"
                      fontSize="2xl"
                      style={{ fontFamily: 'Lobster' }}
                    >
                      Hybrid Paradise
                    </Text>
                  </Link>
                </VStack>
              </WrapItem>
            </Wrap>

            <Wrap spacing={10} mb="30px">
              <WrapItem>
                <VStack>
                  <Box>
                    <img src={MarketplaceGif} alt="Magic Flowers Marketplace" />
                  </Box>
                  <Link as={ReactLink} to={getToUrl(true)}>
                    <Text
                      color="green.400"
                      fontSize="2xl"
                      style={{ fontFamily: 'Lobster' }}
                    >
                      Marketplace
                    </Text>
                  </Link>
                </VStack>
              </WrapItem>
              <WrapItem>
                <VStack>
                  <Box>
                    <img src={AuctionGif} alt="Door and Squirrel" />
                  </Box>
                  <Link as={ReactLink} to={getToUrl(false)}>
                    <Text
                      color="green.400"
                      fontSize="2xl"
                      style={{ fontFamily: 'Lobster' }}
                    >
                      Auction House
                    </Text>
                  </Link>
                </VStack>
              </WrapItem>
              <WrapItem>
                <VStack>
                  <Box>
                    <img src={LeaseMarketGif} alt="Round Door" />
                  </Box>
                  <Link as={ReactLink} to="/magicflowers/leasingmarket">
                    <Text
                      color="green.400"
                      fontSize="2xl"
                      style={{ fontFamily: 'Lobster' }}
                    >
                      Lease Market
                    </Text>
                  </Link>
                </VStack>
              </WrapItem>
            </Wrap>
          </VStack>
        </Box>
        <Box
          boxShadow="2xl"
          p="3"
          rounded="md"
          mt="18px"
          color="white"
          style={{
            backgroundImage:
              'radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
          }}
        >
          🔔 <strong>Polygon Amoy Testnet Environment</strong>: Please use
          testnet MATIC. Real MATIC will be lost.
        </Box>
        <div style={{ marginTop: '28px' }}>
          <GameFooter />
        </div>
      </VStack>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default MagicFlowersNFT;
