import { ethers } from 'ethers';
import axios from 'axios';
import ArtistCollections from '../../../../contracts/APIs/CollectionsInterface.sol/CollectionsInterface.json';
import { getCidUrlNftstorage } from '../../../utils/helpers';

const getAllCollectionsBy = async (provider, _user, allDetails) => {
  const artistCollections = new ethers.Contract(
    process.env.REACT_APP_ARTIST_COLLECTIONS_ADDRESS,
    ArtistCollections.abi,
    provider
  );

  let totalCollections = await artistCollections.getAllCollectionsBy(_user);
  totalCollections = totalCollections.map(num => Number(num.toString()));
  //   console.log('totalCollections:', totalCollections);

  let _allCollections = [];
  //   let allCollectionNames = [];
  //   let collection;
  let collectionId,
    collectionName,
    collectionUrl,
    _imageUrl,
    collectionImageUrl,
    collectionDescription,
    collectionOtherInfo;

  let _metadata;

  for (let i = 0; i < totalCollections.length; i++) {
    let _cData = await artistCollections.getCollection(totalCollections[i]);
    // console.log('_cData:', _cData);

    collectionId = totalCollections[i];
    collectionName = _cData._collectionName;

    if (allDetails) {
      const _cid = _cData._collectionUrl;
      collectionUrl = getCidUrlNftstorage(_cid);
      _metadata = await axios.get(collectionUrl);
      // console.log('metadata:', _metadata.data);
      collectionImageUrl = _metadata.data.collectionImageUrl;

      collectionDescription = _metadata.data.description;
      collectionOtherInfo = _metadata.data.otherInfo;
      const collectionTokenContract = _cData._tokenContracts;
      let tokens = _cData._allTokenIds;
      // console.log('_tokens:', tokens);

      _allCollections.push({
        collectionId,
        collectionName,
        collectionImageUrl,
        collectionTokenContract,
        collectionDescription,
        collectionOtherInfo,
        tokens,
      });
    } else {
      _allCollections.push({
        collectionId,
        collectionName,
      });
    }
  }

  // console.log('allCollections:', _allCollections);
  return _allCollections;
};

export default getAllCollectionsBy;
