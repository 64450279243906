import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; 

function PreludeMagicFlowers() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        as="button"
        onClick={onOpen}
        colorScheme="green"
        variant="outline"
        size="lg"
      >
        <img src='https://res.cloudinary.com/df9jvvwyw/image/upload/v1719625815/magicnftvalley_budken.gif' alt="Magic Forest" />
        <Text color="green.300" fontSize="xl" as="b">
          Where It All Began
        </Text>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Prelude</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mt="18px"></Box>
            <Player
              playsInline
              poster=""
              src="https://res.cloudinary.com/dmtl2otiy/video/upload/v1721473471/prelude-2_zqwxgs.mp4"
            />
            <Box mt="18px"></Box>
            <Text fontSize='lg' mb='10px'>
              {' '}
              In the tranquil Eternal Garden, 3,000 enchanted plants flourish,
              each infused with unique ancient magic. Some plants exhibit traits
              akin to females, others to males, and the rest as hermaphrodites,
              all contributing to the garden's harmony.
            </Text>
            <Text fontSize='lg' mb='10px'>
              A rare cosmic wind, imbued with space radiation, triggered a
              metamorphosis in every plant. Their genetic makeup transformed,
              and traditional reproduction ceased, leaving hybridization as the
              sole method for propagation.
            </Text>
            <Text fontSize='lg' mb='10px'>
              {' '}
              All original progenitor plants fell into hibernation due to the
              energy influence. The only way to revive them and restore their
              eternal life is by minting each one on the blockchain.{' '}
            </Text>
            <Text>
              Come to revive the progenitor plants and restore their eternal
              life through minting, or enrich biodiversity through
              hybridization. Bring back life, peace, and vitality to the Eternal
              Garden!
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PreludeMagicFlowers;
