import React, { useState } from 'react';
import '../../styles/Fonts.css';
import {
  Container,
  Text,
  VStack,
  Box,
  Icon,
  Link,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
import { AiTwotoneMail } from 'react-icons/ai';
import SendMail from '../../resources/images/animals/animalmail.gif';
import Hedgehog from '../../resources/images/animals/hedgehog-overview.gif';
import Tiger from '../../resources/images/animals/tiger2.gif';
import RenderProjectTimeline from '../../components/views/aboutPage/RenderProjectTimeline';
import RenderAboutCozyGames from '../../components/views/aboutPage/RenderAboutCozyGames';
function About() {
  const [activeBox, setActiveBox] = useState('valley');

  const handleBoxClick = box => {
    setActiveBox(box);
  };

  const RenderContact = () => {
    return (
      <Box maxW={['sm', 'md', 'xl']} p={6}>
        <VStack>
          <Text
            color="green.400"
            fontSize="3xl"
            style={{ fontFamily: 'Lobster' }}
          >
            Have a question, suggestion, or just want to say hello? Drop us a
            line—we'd love to hear from you!
          </Text>
          <Link href="mailto:support@cozygames.xyz">
            <Icon as={AiTwotoneMail} boxSize="88px" color="green.300" />
          </Link>
          <Text color="green.400">support@cozygames.xyz</Text>
        </VStack>
      </Box>
    );
  };
  const BoxContent = () => {
    switch (activeBox) {
      case 'valley':
        return <RenderAboutCozyGames />;
      // case 'flower':
      //   return <RenderAboutMagicFlowers />;
      case 'timeline':
        return <RenderProjectTimeline />;
      case 'contact':
        return <RenderContact />;
      default:
        return <RenderAboutCozyGames />;
    }
  };
  return (
    <VStack>
      <HomeIcon />
      {/* <Box boxSize="md">
        <Image
          src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718649600/about_xko9x7.png"
          alt="about"
        />
      </Box> */}
      <Box>
        <Text
          color="green.400"
          fontSize={[33, 47, 58]}
          style={{ fontFamily: 'Lobster' }}
        >
          About
        </Text>
      </Box>
      <Wrap spacing={8}>
        <WrapItem>
          <Box as="button" onClick={() => handleBoxClick('valley')}>
            <img src={Hedgehog} alt="Hedgehog and Butterfly" />
          </Box>
        </WrapItem>

        <WrapItem>
          <Box as="button" onClick={() => handleBoxClick('timeline')}>
            <img src={Tiger} alt="Project Timeline" />
          </Box>
        </WrapItem>
        <WrapItem>
          <Box as="button" onClick={() => handleBoxClick('contact')}>
            <img src={SendMail} alt="Animal sending mail" />
          </Box>
        </WrapItem>
      </Wrap>
      <Container>
        <VStack spacing={6}>
          {/* <RenderTabs /> */}
          <BoxContent />
        </VStack>
      </Container>
      {/* <img
        src={CampaignImage}
        alt="Join Our Indiegogo Campaign"
        style={{ width: '358px', height: 'auto' }}
      /> */}
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
          @import url('https://fonts.googleapis.com/css?family=Roboto');
        `}
      </style>
    </VStack>
  );
}

export default About;
