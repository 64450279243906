// Game inside NFT and NFT inside Game
import { useParams, Link as ReactLink } from 'react-router-dom';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import {
  VStack,
  Box,
  Button,
  HStack,
  List,
  ListItem,
  ListIcon,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Link,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { IoIosFlower } from 'react-icons/io';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { convertTimestampToDate } from '../../../../components/utils/helpers';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import getMagicFlowerDetail from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getMagicFlowerDetail';
import GameFooter from '../../../../components/views/GameFooter';
import MagicFlowerTree from '../../../../components/sketches/games/magicFlowersSketches/plants/MagicFlowerTree';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import RenderMagicFlowerMeta from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/RenderMagicFlowerMeta';
import LeaseMagicFlower from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/LeaseMagicFlower';

function MagicFlowerLease() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === ' ' || event.key === 'Spacebar') {
        event.preventDefault(); // Prevent default behavior (scrolling)
        // Your game logic to shoot or perform actions here
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const { magicflowerId } = useParams();
  // console.log('magicflowerId:', magicflowerId);
  const [magicflowerLink, setMagicflowerLink] = useState('');

  const [magicFlowerOwner, setMagicFlowerOwner] = useState('');
  // const [genes, setGenes] = useState({});
  const [allGenes, setAllGenes] = useState([]);
  const [plantFeatures, setPlantFeatures] = useState({});
  const [evolvingPoints, setEvolvingPoints] = useState([]);
  const [displayTimePoint, setDisplayTimePoint] = useState(0);

  const [meta, setMeta] = useState({});
  const [userDefault, setUserDefault] = useState({});
  const [userDefaultBgs, setUserDefaultBgs] = useState([]);
  const [leaseDetail, setLeaseDetail] = useState({});

  const [showSketch, setShowSketch] = useState(false);

  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);

    const data = await getMagicFlowerDetail(provider, magicflowerId);
    // console.log('data:', data);

    setMagicFlowerOwner(data.magicFlowerOwner);
    setUserDefault(data.userDefault);
    setUserDefaultBgs(data.userDefaultBgs);
    setMeta(data.metadata);
    setPlantFeatures(data.plantFeatures);
    setAllGenes(data.allGenes);
    setDisplayTimePoint(data.displayTimePoint);
    setEvolvingPoints(data.evolvingPoints);

    setLeaseDetail(data.lease);

    const _link = `/magicflowers/magicflower/${magicflowerId}`;
    setMagicflowerLink(_link);
  };

  const handleShowSketch = () => {
    if (showSketch) {
      setShowSketch(false);
    } else {
      setShowSketch(true);
    }
  };

  const RenderShowSketchButton = () => {
    const _label = showSketch ? 'Hide' : 'View';
    return (
      <Button
        colorScheme="green"
        variant="outline"
        size="lg"
        onClick={handleShowSketch}
      >
        {_label} Magic Flowers
      </Button>
    );
  };

  const RenderEvolvingPoints = () => {
    function generateRandomHexColor() {
      // Generate random integer between 0 and 16777215 (0xFFFFFF)
      var color = Math.floor(Math.random() * 16777215).toString(16);
      // Ensure that the hex color code is always 6 digits long
      return '#' + ('000000' + color).slice(-6);
    }
    if (Object.keys(allGenes).length > 0 && magicflowerId > 0) {
      return (
        <>
          <List spacing={3}>
            {allGenes.map((p, key) => {
              return (
                <ListItem key={key}>
                  <ListIcon
                    as={IoIosFlower}
                    w={6}
                    h={6}
                    color={generateRandomHexColor}
                  />
                  <Button
                    variant="outline"
                    onClick={() => setDisplayTimePoint(key)}
                  >
                    History at {convertTimestampToDate(p.timestamp)}
                  </Button>
                  {/* <Icon as={GiBackwardTime} w={6} h={6} color='teal.500'/> */}
                </ListItem>
              );
            })}
          </List>
        </>
      );
    }
  };

  const EvolvingHistoryButton = () => {
    return (
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box textAlign="center">
                <Text
                  color="green.300"
                  fontSize="2xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Details
                </Text>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab>Plant Details</Tab>
                <Tab>Description</Tab>
                <Tab>Plant Spirit</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <RenderMagicFlowerMeta
                    meta={meta}
                    leaseDetail={leaseDetail}
                  />
                </TabPanel>
                <TabPanel>
                  <VStack>
                    <Box>{meta.name}</Box>
                    <Box>{meta.description}</Box>
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <VStack>
                    <Box>{meta.spiritName}</Box>
                    <Box>{meta.spiritDescription}</Box>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box textAlign="center">
                <Text
                  color="green.300"
                  fontSize="2xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Evolving History
                </Text>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <RenderEvolvingPoints />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  const RenderSketch = () => {
    return (
      <ReactP5Wrapper
        sketch={MagicFlowerTree}
        magicflowerId={magicflowerId}
        plantFeatures={plantFeatures}
        genes={allGenes[displayTimePoint]}
        userDefault={userDefault}
        userDefaultBgs={userDefaultBgs}
      />
    );
  };

  return (
    <>
      {showSketch && magicflowerId > 0 && <RenderSketch />}
      {/* {magicflowerId > 0 && <RenderMagicFlowerSketch flowerName={meta.name} />} */}

      <VStack spacing={8} mt={6}>
        <MagicFlowerTopGrid />
        <Text
          color="green.400"
          fontSize={['xl', '2xl', '3xl', '4xl']}
          style={{ fontFamily: 'Lobster' }}
        >
          Lease Magic Flower NFT
        </Text>
        <Link as={ReactLink} to={magicflowerLink}>
          <Text color="green.400" fontSize={['md', 'lg', 'xl', '2xl']}>
            Magic Flower ID: {magicflowerId}
          </Text>
        </Link>
        <RenderShowSketchButton />
        <LeaseMagicFlower specificMagicflowerId={magicflowerId} />
        <Text>Lease Price: {leaseDetail.leasePrice} MATIC</Text>

        <HStack spacing={5}>
          <Box>
            <EvolvingHistoryButton />
          </Box>
        </HStack>

        <div style={{ marginTop: '58px' }}>
          <GameFooter />
        </div>
      </VStack>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default MagicFlowerLease;
