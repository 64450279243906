import { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Text, Box, Link, VStack } from '@chakra-ui/react';
import { HomeIcon } from '../elements/MiscDisplays';

import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import GallerySpotLight from './GallerySpotLight';
import { TextOnImage1 } from '../elements/TextOnImages';
import FeaturedScene from './FeaturedScene';
import AddGameModal from '../../../actions/NFTs/gameIntegration/AddGameModal';
import getFeaturedItems from './getFeaturedItems';

const FeaturedGalleryScene = ({
  featurePage,
  gameId = 0,
  gameName = '',
  gamePosterUrl = ''
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const [featuredItems, setFeaturedItems] = useState({});
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const _featuredItems = await getFeaturedItems(
      provider,
      featurePage,
      gameId
    );
    // console.log('_featuredItems:', _featuredItems);
    if (
      typeof _featuredItems !== 'undefined' ||
      Object.keys(_featuredItems).length > 0
    ) {
      setFeaturedItems(_featuredItems);
    }

    setIsLoading(false);
  };

  const RenderText = () => {
    // const _showText = showText();

    return (
      <Text
        color="teal.500"
        fontSize={[36, 42, 58]}
        style={{ fontFamily: 'Lobster' }}
      >
        {labels[featurePage]}
      </Text>
    );
  };

  const RenderGameName = () => {
    if (gameId > 0 && gameName.length > 0) {
      const gameLink = `/gameList/${gameId}`;
      return (
        <Text color="teal.500" fontSize={28} style={{ fontFamily: 'Lobster' }}>
          -- for{' '}
          <Link as={ReactLink} to={gameLink}>
            {gameName}
          </Link>
        </Text>
      );
    }
  };

  const textOnImage = {
    marketplace: 'Create/Sell NFTs',
    auctionHouse: 'Create/Auction NFTs',
    gameNfts: 'Create/Sell NFTs',
    marketplaceGame: 'Create/Sell NFTs',
    auctionHouseGame: 'Create/Auction NFTs',
  };

  const labels = {
    marketplace: 'Marketplace',
    auctionHouse: 'Auction House',
    gameNfts: 'Game NFTs',
    marketplaceGame: 'Marketplace',
    auctionHouseGame: 'Auction House',
    gameList: 'Game Showcase',
  };

  const RenderAction = () => {
    if (featurePage === 'gameList') {
      return <AddGameModal useStyle="Fancy" />;
    } else {
      return (
        <Link as={ReactLink} to="/dreamstudio">
          <Box boxSize="280px">
            <TextOnImage1 t={textOnImage[featurePage]} />
          </Box>
        </Link>
      );
    }
  };
  return (
    <>
      {!loading && (
        <FeaturedScene
          label={labels[featurePage]}
          featurePage={featurePage}
          featuredItems={featuredItems}
          gamePosterUrl={gamePosterUrl}
        />
      )}
      <VStack mt="10px">
        <HomeIcon />
        <RenderText />
        <RenderGameName />
        <RenderAction />
        <GallerySpotLight
          featurePage={featurePage}
          featuredItems={featuredItems}
        />
      </VStack>
    </>
  );
};

export default FeaturedGalleryScene;
