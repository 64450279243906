import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Text,
  Input,
  HStack,
  Alert,
  Link,
  InputGroup,
  Icon,
  InputLeftElement,
  InputRightAddon,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { MdFindInPage } from 'react-icons/md';
import { RiArrowGoBackFill } from 'react-icons/ri';

export default function SearchMagicFlowers() {
  const [magicFlowersNftId, setMagicFlowersNftId] = useState(null);

  const handleMagicFlowersNftId = event =>
    setMagicFlowersNftId(event.target.value);

  const SearchMagicNftId = () => {
    return (
      <>
        <InputGroup size="sm">
          <InputLeftElement children={<SearchIcon color="green.300" />} />

          <Input
            value={magicFlowersNftId}
            onChange={handleMagicFlowersNftId}
            placeholder="Magic Flowers NFT Id"
            size="sm"
          />
        </InputGroup>

        {magicFlowersNftId > 0 && (
          <Alert status="success" variant="solid">
            <Link
              as={ReactLink}
              to={`/magicflowers/magicflower/${magicFlowersNftId}`}
            >
              <HStack spacing={[8, 40, 80]}>
                <Icon as={MdFindInPage} />
                <Text>Magic Flowers NFT Id: {magicFlowersNftId}</Text>
                <Icon as={RiArrowGoBackFill} />
              </HStack>
            </Link>
          </Alert>
        )}
      </>
    );
  };

  return <SearchMagicNftId />;
}
