import Mandala from '../sketchElements/Mandala.js';
import Dice from '../sketchElements/dice/Dice.js';

function EvolveOutcome(p5, plantSpirit) {
  console.log('plantSpirit:', plantSpirit)
  let mandalaFlower;
  let mandalaCanvas;

  // fairy
  let fairy;
  let hasPlantSpirit = false

  // dice
  let diceSize = 58;
  let dices = [];
  let diceCanvases = [];
  let diceX = [];

  let outcomes = [0, 0, 0];
  let count = 0;

  let rolling;

  p5.preload = () => {
    if (plantSpirit.length > 0) {
      fairy = p5.loadImage(plantSpirit);
    }
  };

  p5.updateWithProps = props => {
    if (props.outcomes) {
      outcomes = props.outcomes;
    }
    if (props.rolling) {
      rolling = props.rolling;
      count = 0;
    }
    if (plantSpirit.length > 0) {
      fairy = p5.loadImage(plantSpirit);
      // plantSpirit.resize(0, 188);
      hasPlantSpirit = true;
      // plantSpiritAspectRatio = plantSpirit.width / plantSpirit.height;
      // desiredHeightPlantSpirit =
      //   desiredWidthPlantSpirit / plantSpiritAspectRatio;

      // plantSpiritY = p5.height - desiredHeightPlantSpirit
    }
  };

  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight);
    mandalaCanvas = p5.createGraphics(p5.windowWidth, p5.windowHeight);
    mandalaFlower = new Mandala(p5, mandalaCanvas);
    mandalaFlower.initiate();
    mandalaCanvas.clear();
    // if (plantSpirit.length > 0) {
    //   fairy.resize(0, p5.height / 3.88);
    // }

    // dice
    for (let i = 0; i < 3; i++) {
      let diceCanvas = p5.createGraphics(diceSize * 3, diceSize * 2);
      diceCanvas.noLoop();
      diceCanvas.frameRate(10);
      let dice = new Dice(p5, diceCanvas, diceSize);
      diceCanvas.clear();
      diceCanvases.push(diceCanvas);
      dices.push(dice);
    }

    let x1 = 0 + 20;
    let x2 = p5.width / 2 - (diceSize * 3) / 2;
    let x3 = p5.width - diceSize * 3 - 20;
    diceX.push(x1);
    diceX.push(x2);
    diceX.push(x3);
    rolling = false;
  };

  p5.draw = () => {
    // brightNight, sunrise, brightMorning, afternoon, evening, darkNight
    //drawScene(p5, 18);
    p5.background(0, 188);
    mandalaCanvas.clear();
    // console.log('frameCount:', p5.frameCount);
    mandalaFlower.bloom();
    // Calculate the center position
    let centerX = p5.width / 2;
    let centerY = p5.height / 2;

    p5.image(mandalaCanvas, 0, 0);

    if (hasPlantSpirit) {
      // Calculate the position considering the image size
      fairy.resize(0, 158)
      let imgCenterX = centerX - 79
      let imgCenterY = centerY - 79;
      p5.image(fairy, imgCenterX, imgCenterY);
    }

    count += 1;

    // Dice
    if (rolling) {
      if (count <= 158) {
        for (let i = 0; i < 3; i++) {
          dices[i].roll();
        }
      } else {
        for (let i = 0; i < 3; i++) {
          dices[i].win(outcomes[i]);
        }
      }
    } else {
      let constantNums = [1, 3, 6];
      for (let i = 0; i < 3; i++) {
        dices[i].win(constantNums[i]);
      }
    }

    for (let i = 0; i < 3; i++) {
      p5.image(diceCanvases[i], diceX[i], p5.height - diceCanvases[i].height);
    }
  };
}

export default EvolveOutcome;
