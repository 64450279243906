import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  FormLabel,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import { FaInfoCircle } from 'react-icons/fa';
import MagicFlowers from '../../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { useNavigate } from 'react-router-dom';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';

const MintMagicFlowerPlantButton = ({
  RadioStatus = {
    1: false,
    2: false,
    3: false,
  },
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [plantType, setPlantType] = useState('1');
  const [value, setValue] = useState('1');
  const [disableRadio, setDisableRadio] = useState(RadioStatus);
  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('signer:', signer);

    try {
      const magicFlowers = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_ADDRESS,
        MagicFlowers.abi,
        signer
      );

      const mintingFee = magicFlowers.getMagicFlowersMintingFee(false); // 0.5 matic

      const transaction = await magicFlowers.mintTree(
        Number(plantType),
        Number(value),
        {
          value: mintingFee,
        }
      );

      await transaction.wait();

      // Create a promise to wait for the event
      const eventPromise = new Promise((resolve, reject) => {
        magicFlowers.once(
          'Revived',
          (magicflowerId, magicflowerOwner, event) => {
            // console.log(`Plant revived by: ${magicflowerOwner}`);
            // console.log(`Plant ID: ${magicflowerId}`);
            // console.log(`Event: ${event}`);
            resolve(magicflowerId); // Resolve the promise with the plant ID
          }
        );

        // Set a timeout to reject the promise if the event is not received in a reasonable time
        setTimeout(() => {
          reject(new Error('Event timeout'));
        }, 60000); // 60 seconds timeout, adjust as needed
      });

      // Wait for the event to be captured
      const magicflowerId = await eventPromise;

      const navigateUrl = `/magicflowers/magicflower/${magicflowerId}`;

      setLoading(false);
      setTimeout(() => {
        navigate(navigateUrl);
      }, 100);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Revive
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Revive
        </Button>
      );
    }
  };

  const RenderInfo = () => {
    return (
      <Popover>
        <PopoverTrigger>
          <Icon as={FaInfoCircle} w={6} h={6} color="green.500" />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>About Plant Sex</PopoverHeader>
          <PopoverBody>
            There are a total of 3000 progenitor plants: 1000 males, 1000
            females, and 1000 hermaphrodites. If all plants of a specific sex
            have been revived, that option will be greyed out and unavailable
            for selection.
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <>
      <>
        <Button
          onClick={onOpen}
          size="lg"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Revive Magic Flowers
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Revive Magic Flowers <RenderInfo />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box mb="20px">
                <FormControl>
                  <FormLabel>I would like to revive:</FormLabel>
                  <RadioGroup
                    onChange={setPlantType}
                    value={plantType}
                    colorScheme="green"
                  >
                    <Stack direction="row">
                      <Radio value="1">Magic Flower Tree</Radio>
                      <Radio value="2">Magic Flower Plant</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mb="20px">
                <FormControl>
                  <FormLabel>Select the sex of the plant :</FormLabel>

                  <RadioGroup
                    onChange={setValue}
                    value={value}
                    colorScheme="green"
                  >
                    <Stack direction="row">
                      <Radio value="1" isDisabled={disableRadio['1']}>
                        Male
                      </Radio>
                      <Radio value="2" isDisabled={disableRadio['2']}>
                        Female
                      </Radio>
                      <Radio value="3" isDisabled={disableRadio['3']}>
                        Hermaphrodite
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Box>
            </ModalBody>

            <ModalFooter>
              <RenderButton />
              <Button
                variant="ghost"
                colorScheme="blue"
                ml={4}
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </>
  );
};

export default MintMagicFlowerPlantButton;
