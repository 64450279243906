import MagicFlowersSupport from '../../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import { ethers } from 'ethers';
import isTokenOwner from '../../../../utils/isTokenOwner';
import getImageUrl from '../../../../utils/getImageUrl';
import { REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS } from '../../constants/ContractAddress';

const getDefaultData = async (provider, magicflowerId, magicFlowerOwner) => {
  const features = [
    'PlantSpirit',
    'Butterflies',
    'Stone',
    'BackgroundDeepNight',
    'BackgroundDawn',
    'BackgroundMorning',
    'BackgroundAfternoon',
    'BackgroundEvening',
    'BackgroundNight',
    'MusicMagic',
    'MusicEvolve',
  ];

  const magicFlowersSupport = new ethers.Contract(
    REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
    MagicFlowersSupport.abi,
    provider
  );

  let _defaultData =
    await magicFlowersSupport.getMagicFlowerUserDefaultImagesInBatch(
      magicflowerId,
      features
    );

  // console.log('_defaultData:', _defaultData);
  let defaultData = {};

  for (let i = 0; i < _defaultData.length; i++) {
    const tokenId = Number(_defaultData[i].tokenId.toString());
    let _image = '';
    if (tokenId > 0) {
      const _isTokenOwner = await isTokenOwner(
        provider,
        tokenId,
        _defaultData[i].tokenContract,
        magicFlowerOwner
      );

      if (_isTokenOwner) {
        _image = await getImageUrl(
          provider,
          _defaultData[i].tokenId,
          _defaultData[i].tokenContract
        );
      }
    }
    let _featureInfo = {
      imageUrl: _image
    };
    defaultData[features[i]] = _featureInfo;
  }

  // console.log('defaultData:', defaultData);

  return defaultData;
};

export default getDefaultData;
