import {
  VStack,
  Box,
  Wrap,
  WrapItem,
  Link,
  Text,
  Icon,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
import { FaStarAndCrescent, FaGem, FaEthereum, FaStar } from 'react-icons/fa';
import { GiRainbowStar, GiStarFormation, GiTwirlyFlower } from 'react-icons/gi';
import 'normalize.css';
import JoinMembership from '../../components/actions/NFTs/membership/JoinMembership';
import Campaign from '../../components/views/cozyGames/misc/Campaign';

function MembershipPage() {
  const GreenGradient =
    'linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147))';
  // 'radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )';

  const TierDescription = [
    {
      line1: 'Basic',
      line2: '20 Matic (One Time Fee)',
      line3: 'Duration: 1 month',
      tier: 1,
      icon: FaStar,
    },
    {
      line1: 'Standard',
      line2: '40 Matic (One Time Fee)',
      line3: 'Duration: 3 months',
      tier: 2,
      icon: FaStar,
    },
    {
      line1: 'Premium',
      line2: '60 Matic (One Time Fee)',
      line3: 'Duration: 6 months',
      tier: 3,
      icon: FaStar,
    },
    {
      line1: 'Elite',
      line2: '80 Matic (One Time Fee)',
      line3: 'Duration: 1 year',
      tier: 4,
      icon: FaGem,
    },
    {
      line1: 'Platinum',
      line2: '120 Matic (One Time Fee)',
      line3: 'Duration: 2 years',
      tier: 5,
      icon: FaGem,
    },
    {
      line1: 'Diamond',
      line2: '150 Matic (One Time Fee)',
      line3: 'Duration: 3 years',
      tier: 6,
      icon: FaGem,
    },
  ];

  const isDoubleIcons = _tier => {
    if (_tier === 2 || _tier === 3 || _tier === 5 || _tier === 6) {
      return true;
    }
    return false;
  };
  const isTrippleIcons = _tier => {
    if (_tier === 3 || _tier === 6) {
      return true;
    }
    return false;
  };

  const MembershipTiers = () => {
    return (
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          {TierDescription.map((_tier, i) => (
            <Tab key={i}>
              <Text as="b">{_tier.line1}</Text>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {TierDescription.map((tier, idx) => (
            <TabPanel key={idx}>
              <VStack>
                <Box
                  boxShadow="2xl"
                  p="6"
                  rounded="md"
                  borderRadius="md"
                  bgGradient={GreenGradient}
                  color="white"
                  mt="5px"
                >
                  <VStack>
                    <Box>
                      <Text
                        as="b"
                        fontSize="2xl"
                        style={{ fontFamily: 'Lobster' }}
                      >
                        {tier.line1}
                      </Text>
                    </Box>
                    <Box>
                      <HStack spacing={3}>
                        <Icon as={tier.icon} w={4} h={4} color="yellow.200" />
                        {isDoubleIcons(tier.tier) && (
                          <Icon as={tier.icon} w={4} h={4} color="yellow.200" />
                        )}
                        {isTrippleIcons(tier.tier) && (
                          <Icon as={tier.icon} w={4} h={4} color="yellow.200" />
                        )}
                      </HStack>
                    </Box>
                    <Box>
                      <Text as="b" fontSize="xl">
                        {tier.line2}
                      </Text>
                    </Box>
                    <Box>
                      <Text as="b" fontSize="xl">
                        {tier.line3}
                      </Text>
                    </Box>
                    {/* <Box>
                      <JoinMembership membershipTier={tier.tier} />
                    </Box> */}
                  </VStack>
                </Box>
              </VStack>
            </TabPanel>
          ))}
          <TabPanel>
            <p>one!</p>
          </TabPanel>
          <TabPanel>
            <p>two!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  };

  const RenderMembershipBenefits = () => {
    return (
      <Box
        bgGradient="radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% )"
        color="white"
        width={['358px', '458px', '580px']}
        boxShadow="2xl"
        p={8}
        rounded="md"
        borderRadius="md"
      >
        <VStack spacing={3}>
          <Text fontSize="3xl" as="b" style={{ fontFamily: 'Lobster' }}>
            Membership Benefits
          </Text>
          <Box>
            <Icon as={FaEthereum} w={6} h={6} color="yellow.200" />

            <Text fontSize="xl" as="b" ml="8px">
              50% disounct on Platform Fees for for any sale, auction, or lease
              created by a member. This discount applies across all
              Marketplaces, Auction Houses, and the Magic Flowers Leasing
              Market.
            </Text>
          </Box>
          <Box mb="18px">
            <Icon as={GiTwirlyFlower} w={6} h={6} color="yellow.200" />
            <Text fontSize="xl" as="b" ml="8px">
              50% discount on reviving and hybridization fees for the Magic
              Flowers game.
            </Text>
            <Text fontSize="lg"></Text>
          </Box>

          {/* <JoinMembership /> */}
        </VStack>
      </Box>
    );
  };
  return (
    <>
      <VStack spacing={6}>
        <HomeIcon />
        <Box>
          <HStack spacing={3}>
            <Icon as={GiStarFormation} w={6} h={6} color="yellow.200" />
            <Text
              color="green.300"
              fontSize={[30, 42, 58]}
              style={{ fontFamily: 'Lobster' }}
              // bgGradient="linear-gradient( 109.6deg,  rgba(247,253,166,1) 11.2%, rgba(128,255,221,1) 57.8%, rgba(255,128,249,1) 85.9% )"
              // bgClip="text"
              fontWeight="extrabold"
            >
              Membership
            </Text>
            <Icon as={GiStarFormation} w={6} h={6} color="yellow.200" />
          </HStack>
        </Box>
        {/* 
        <JoinMembership /> */}

        <HStack spacing="88px">
          {/* <Icon as={FaGem} w={6} h={6} color="yellow.200" /> */}
          <Icon as={FaStarAndCrescent} w={8} h={8} color="yellow.200" />
          {/* <Icon as={FaGem} w={6} h={6} color="yellow.200" /> */}
        </HStack>

        <RenderMembershipBenefits />
        {/* 
        <Box
          bgGradient="radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% )"
          color="black"
          width="400px"
          boxShadow="2xl"
          p={6}
          rounded="md"
          borderRadius="md"
        >
          <VStack>
            <Text fontSize="3xl" as="b" style={{ fontFamily: 'Lobster' }}>
              Membership Benefits
            </Text>
            <List fontSize="xl">
              <ListItem>
                <ListIcon as={FaHeart} color="green.500" />
                Receive a <Text as="b">50% disounct on Platform Fees </Text>
                for any sale or auction initiated by a member
              </ListItem>
              <ListItem>
                <ListIcon as={FaHeart} color="green.500" />
                Receive a{' '}
                <Text as="b">20% discount on any Magic Flower NFT </Text> minted
                by a member, available until the 10,000 NFT cap is reached.
              </ListItem>
            </List>
          </VStack>
        </Box> */}

        {/* <RenderMembershipTiers /> */}
        {/* <JoinButton /> */}
        <Icon as={GiRainbowStar} w={8} h={8} color="yellow.200" />
        <MembershipTiers />

        <Campaign />
      

        {/* <Box>
          <Link as={ReactLink} to="/faq">
            <Text as="i" color="green.300">
              Membership FAQ
            </Text>
          </Link>
        </Box> */}
      </VStack>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default MembershipPage;
