import { ReactP5Wrapper } from '@p5-wrapper/react';
import SketchMarketplace from '../../../sketches/SketchMarketplace';
import SketchLuckyCat from '../../../sketches/SketchLuckyCat';
import SketchPiggy from '../../../sketches/SketchPiggy';

const FeaturedScene = ({
  label,
  featurePage,
  featuredItems,
  gamePosterUrl,
}) => {
  const RenderFeaturedScene = () => {
    // console.log('featuredItems:', featuredItems);
    if (
      featurePage === 'marketplaceGame' ||
      featurePage === 'auctionHouseGame'
    ) {
      // return <Player playsInline poster={gamePosterUrl} src={gameVideoUrl} />;
      return <></>
    } else if (featurePage === 'gameList') {
      return <ReactP5Wrapper sketch={SketchLuckyCat} />;
    } else if (featurePage === 'gameNfts') {
      return <ReactP5Wrapper sketch={SketchPiggy} />;
    } else {
      return (
        <ReactP5Wrapper
          sketch={p5 => SketchMarketplace(p5, label, '')}
          featurePage={featurePage}
        />
      );
    }
  };

  return <RenderFeaturedScene />;
};

export default FeaturedScene;
