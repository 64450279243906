export function drawSnow2(p5, snowflakeCanvas, snowflakes) {
  snowflakeCanvas.push();
  snowflakeCanvas.clear();
  snowflakeCanvas.pop();
  p5.push();
  snowflakes.addParticle();
  snowflakes.run();
  p5.image(snowflakeCanvas, 0, 0);
  p5.pop();
}

// pink snow
class Particle {
  constructor(p5, canvas) {
    this.p5 = p5;
    this.canvas = canvas;
    this.location = this.canvas.createVector(
      this.canvas.random(this.p5.width),
      -0.88
    );
    this.velocity = this.canvas.createVector(
      this.canvas.random(-0.5, 0.5),
      this.canvas.random(-2, 2)
    );
    this.acceleration = this.canvas.createVector(
      0,
      0.018 * this.canvas.random(0.5, 1.0)
    );
    this.lifespan = this.canvas.random(1000, 1588.0);
    this.mass = this.canvas.random(0.88, 1.58);
    this.diam = this.mass * 5.0;
  }

  run() {
    this.update();
    this.display();
  }

  update() {
    this.velocity.add(this.acceleration);
    this.location.add(this.velocity);
    this.lifespan -= 1.0;
  }

  display() {
    this.canvas.push();
    this.canvas.fill(255, 200, 200, this.lifespan); // lifespan
    this.canvas.ellipse(this.location.x, this.location.y, this.diam, this.diam);
    this.canvas.pop();
  }

  isDead() {
    if (this.location.y > this.p5.height) {
      return true;
    } else {
      return false;
    }
  }
}

export class Snowflakes2 {
  constructor(p5, canvas, location) {
    this.p5 = p5;
    this.canvas = canvas;
    this.origin = location.copy();
    this.particles = [];
  }

  addParticle() {
    this.particles.push(new Particle(this.p5, this.canvas));
  }

  run() {
    for (let i = this.particles.length - 1; i >= 0; i--) {
      let p = this.particles[i];
      p.run();
      if (p.isDead()) {
        this.particles.splice(i, 1);
      }
    }
  }
}
