import { useParams } from 'react-router-dom';
import {
  VStack,
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import AllValleyItemsCreatedBy from '../../../components/views/NFTs/AllValleyItemsCreatedBy';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
// import HighlightsBase from '../../../components/views/NFTs/myPages/HighlightsBase';
function SellerShop({ useCase = '' }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const { shopOwnerAddress } = useParams();
  // console.log('shopOwnerAddress:', shopOwnerAddress);

  // const [ownerAddr, setOwnerAddr] = useState('');

  // useEffect(() => {
  //   setOwnerAddr(shopOwnerAddress);
  // }, []);

  const DisplayList = () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const isSeller = address === shopOwnerAddress ? true : false;
    const RenderMarketplaceSales = () => {
      return (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="green"
            isLazy
            align="center"
          >
            <TabList>
              <Tab>ERC721</Tab>
              <Tab>ERC1155</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <AllValleyItemsCreatedBy
                  provider={provider}
                  addr={shopOwnerAddress}
                  isERC1155={false}
                  isAuction={false}
                />
              </TabPanel>
              <TabPanel>
                <AllValleyItemsCreatedBy
                  provider={provider}
                  addr={shopOwnerAddress}
                  isERC1155={true}
                  isAuction={false}
                  isSeller={isSeller}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      );
    };
    return (
      <Tabs isFitted variant="enclosed" isLazy size="lg">
        <TabList mb="1em">
          <Tab>Marketplace Sales</Tab>
          <Tab>Auctions</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <RenderMarketplaceSales />
          </TabPanel>
          <TabPanel>
            <AllValleyItemsCreatedBy
              provider={provider}
              addr={shopOwnerAddress}
              isAuction={true}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  };

  return (
    <>
      {/* <HighlightsBase
        addr={shopOwnerAddress}
        pageName="Shop"
        useCase={useCase}
      /> */}
      <VStack spacing={8}>
        <Box textAlign="center" alignItems="baseline">
          <Text
            color="green.400"
            fontSize={[36, 42, 68]}
            style={{ fontFamily: 'Lobster' }}
          >
            Shop
          </Text>
          <Text color="green.400">{shopOwnerAddress}</Text>
        </Box>
        {/* <Box textAlign="center">
        <Text
          color="green.400"
          fontSize={[36, 42, 68]}
          style={{ fontFamily: 'Lobster' }}
        >
          Shop
        </Text>
        <Text color="green.400">{shopOwnerAddress}</Text>
      </Box> */}
        <DisplayList />

        <style jsx>
          {`
            @import url('https://fonts.googleapis.com/css?family=Lobster');
          `}
        </style>
      </VStack>
    </>
  );
}

export default SellerShop;
