let palette = ['#0b132b', '#fed656', '#fe56d2'];
export class ShootingStar1 {
  constructor(p5) {
    this.p5 = p5;
    this._x = p5.random(p5.width);
    this._y = p5.random(p5.height);
    this._r = p5.random(8, 18);
    this._vx = this._r * -this.p5.random(0.02, 0.04);
    this._vy = this._r * this.p5.random(0.035, 0.045);
    this._a = p5.random(p5.TWO_PI);
    this._va = this.p5.PI * this.p5.random(-0.003, 0.003);
    this._c = palette[p5.random() < 0.85 ? 1 : 2];
  }

  update() {
    this._x += this._vx;
    this._y += this._vy;
    this._a += this._va;

    if (this._x < -10) {
      this._x = this.p5.width + this._r;
      this._vx = this._r * -this.p5.random(0.02, 0.04);
      this._vy = this._r * this.p5.random(0.088, 0.158);
      this._c = palette[this.p5.random() < 0.9 ? 1 : 2];
    }
    if (this._y > this.p5.height + this._r) {
      this._y = -this._r;
      this._vx = this._r * -this.p5.random(0.02, 0.04);
      this._vy = this._r * this.p5.random(0.035, 0.045);
      this._c = palette[this.p5.random() < 0.9 ? 1 : 2];
    }
  }

  drawStar() {
    let N = 4;
    let angle = this.p5.TWO_PI / (N * 2);
    this.p5.beginShape();
    for (let i = 0; i < N * 2; i++) {
      let a = this._a + (i - (N % 2 === 1 ? 0.5 : 0)) * angle;
      let r = this._r * (i % 2 === 1 ? 0.3 : 1);
      let x = this._x + this.p5.cos(a) * r;
      let y = this._y + this.p5.sin(a) * r;
      this.p5.vertex(x, y);
    }
    this.p5.endShape(this.p5.CLOSE);
  }

  draw(ctx) {
    ctx.save();
    this.p5.blendMode(this.p5.ADD);
    let n = this.p5.noise(this._x * 0.1, this._y * 0.1, this._a * 0.1);
    let c = this.p5.color(this._c);
    ctx.shadowBlur = this._r * 0.8;
    ctx.shadowColor = `rgba(${c.levels[0]}, ${c.levels[1]}, ${c.levels[2]}, ${
      0.5 + n * 0.5
    })`;
    this.p5.fill(0);
    this.drawStar(ctx);
    this.p5.fill(this._c);
    this.drawStar(ctx);
    this.p5.blendMode(this.p5.BLEND);
    ctx.restore();
  }
}

export function drawShootingStar(p5, shootingStar) {
  p5.push();
  shootingStar.update();
  shootingStar.draw(p5.drawingContext);
  p5.pop();
}
