import { ReactP5Wrapper } from '@p5-wrapper/react';
import MagicFlowerTree from '../../../../components/sketches/games/magicFlowersSketches/plants/MagicFlowerTree';
import { useEffect, useState } from 'react';
import {
  Link,
  VStack,
  Box,
  Button,
  Text,
  Wrap,
  WrapItem,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import MagicFlowersAbi from '../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import MagicFlowersSupport from '../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import { Link as ReactLink } from 'react-router-dom';
import SkyFlower from '../../../../resources/images/games/magicflowers/flower.png';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import getMagicFlowerDetail from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getMagicFlowerDetail';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import DetailButton from '../../../../components/views/NFTs/elements/DetailButton';
import MintMagicFlowerPlantButton from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/MintMagicFlowerButton';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import GameFooter from '../../../../components/views/GameFooter';
import HybridizeMagicFlowerButton from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/HybridizeMagicFlowerButton';
import ListMagicFlowerForLease from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/ListMagicFlowerForLease';
import {
  REACT_APP_MAGIC_FLOWERS_ADDRESS,
  REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
} from '../../../../components/views/cozyGames/constants/ContractAddress';
function AllMyMagicFlowers() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    // Call getItem when address changes
    getItem();

    // Specify address as a dependency
  }, [address]);

  const [allTokensOwned, setAllTokensOwned] = useState([]);

  const [hideQuickView, setHideQuickView] = useState(false);
  const [magicflowerId, setMagicflowerId] = useState(0);
  const [plantIndex, setPlantIndex] = useState(null);
  const [plantOwner, setPlantOwner] = useState('');
  const [allGenes, setAllGenes] = useState([]);
  const [plantFeatures, setPlantFeatures] = useState({});
  const [evolvingPoints, setEvolvingPoints] = useState([]);
  const [displayTimePoint, setDisplayTimePoint] = useState(0);

  const [meta, setMeta] = useState({});
  const [userDefault, setUserDefault] = useState({});
  const [userDefaultBgs, setUserDefaultBgs] = useState([]);

  const [magicflowersValidLease, setMagicflowersValidLease] = useState([]);
  const [showValidLease, setShowValidLease] = useState(false);

  const getItem = async () => {
    // Check if address is defined before making the call
    if (address) {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      // const signer = await provider.getSigner();
      // console.log('signer:', signer);
      // const addr = await signer.getAddress();
      // console.log('addr:', addr);

      setPlantOwner(address);

      const _tokenAddr = REACT_APP_MAGIC_FLOWERS_ADDRESS;
      const tokenContract = new ethers.Contract(
        _tokenAddr,
        MagicFlowersAbi.abi,
        provider
      );

      const _allTokens = await tokenContract.allTokenOf(address);
      const tokens = _allTokens.map(num => Number(num.toString()));
      console.log('tokens:', tokens);

      // const _allMyLeasedMagicFlowers = await getAllLeasedMagicFlowers()
      // console.log('_allMYLeasedMagicFlowers:', _allMyLeasedMagicFlowers)

      setAllTokensOwned(tokens);
    }
  };

  const getPlantDetail = async magicflowerId => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);

    const data = await getMagicFlowerDetail(provider, magicflowerId);
    console.log('data:', data);
    setMagicflowerId(magicflowerId);

    setUserDefault(data.userDefault);
    setUserDefaultBgs(data.userDefaultBgs);
    setMeta(data.meta);
    setPlantFeatures(data.plantFeatures);
    setAllGenes(data.allGenes);
    setDisplayTimePoint(data.displayTimePoint);
    setEvolvingPoints(data.evolvingPoints);
  };

  const getValidLeases = async () => {
    // Check if address is defined before making the call
    if (address) {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      // const signer = await provider.getSigner();
      // console.log('signer:', signer);
      // const addr = await signer.getAddress();
      // console.log('addr:', addr);

      const magicflowersSupport = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
        MagicFlowersSupport.abi,
        provider
      );

      const _allTokens =
        await magicflowersSupport.getAllMagicFlowersWithLease();
      const tokens = _allTokens.map(num => Number(num.toString()));
      console.log('tokens:', tokens);
      let _allValidLeases = [];
      for (let i = 0; i < tokens.length; i++) {
        let validUsage = await magicflowersSupport.isValidRenting(
          tokens[i],
          address
        );
        validUsage = Number(validUsage.toString());
        _allValidLeases.push({
          magicflowerId: tokens[i],
          validUsage,
        });
      }
      setMagicflowersValidLease(_allValidLeases);
      setShowValidLease(true);
    }
  };

  const RenderValidLeaseButton = () => {
    return (
      <Button onClick={getValidLeases} colorScheme="green" variant="outline">
        Magic Flowers with Valid Leases
      </Button>
    );
  };

  const RenderValidLeases = () => {
    if (magicflowersValidLease.length > 0) {
      return (
        <Wrap>
          {magicflowersValidLease.map((lease, i) => (
            <WrapItem key={i}>
              <Box>
                <VStack>
                  <Text>Magic Flower Id: {lease.magicflowerId}</Text>
                  <Text>Valid Usage Remaining: {lease.validUsage}</Text>
                </VStack>
              </Box>
            </WrapItem>
          ))}
        </Wrap>
      );
    }
  };

  const getQuickView = async (key, magicflowerId) => {
    console.log('key:', key);

    if (hideQuickView) {
      setHideQuickView(false);
    }

    setPlantIndex(key);

    await getPlantDetail(magicflowerId);
  };

  const QuickViewButton = ({ idx, magicflowerId }) => {
    return (
      <Button
        colorScheme="green"
        variant="outline"
        onClick={() => getQuickView(idx, magicflowerId)}
      >
        Quick View
      </Button>
    );
  };

  const ShowAllMyTokens = () => {
    return (
      <Wrap spacing={6}>
        {allTokensOwned.length &&
          allTokensOwned.map((t, key) => (
            <WrapItem key={key}>
              <Box>
                <VStack>
                  <Box sx={basicBoxStyles} filter="grayscale(10%)">
                    <VStack>
                      <Text fontSize="sm" as="i">
                        NFT ID:
                      </Text>
                      <Text
                        fontSize="md"
                        as="b"
                        style={{ fontFamily: 'Lobster' }}
                      >
                        {t}
                      </Text>
                      <Link
                        as={ReactLink}
                        to={`/magicflowers/magicflower/${t}`}
                      >
                        <DetailButton label="Enter" />
                      </Link>
                    </VStack>
                  </Box>

                  <Popover>
                    <PopoverTrigger>
                      <Button colorScheme="green">Quick Actions</Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverHeader>Magic Flower Id: {t}</PopoverHeader>
                        <PopoverCloseButton />
                        <PopoverBody>
                          <VStack spacing={6}>
                            {' '}
                            <QuickViewButton idx={key} magicflowerId={t} />{' '}
                            <Link
                              as={ReactLink}
                              to={`/magicflowers/owneractions/${t}`}
                            >
                              <Button colorScheme="green" variant="outline">
                                Dream Studio
                              </Button>
                            </Link>
                            <Link
                              as={ReactLink}
                              to={`/magicflowers/gallery/${t}`}
                            >
                              <Button colorScheme="green" variant="outline">
                                Magic Garden
                              </Button>
                            </Link>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </VStack>
              </Box>
            </WrapItem>
          ))}
      </Wrap>
    );
  };

  const basicBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: '218px',
    color: 'white',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
    px: 4,
    background: `url(${SkyFlower}) center/cover no-repeat`,
  };

  const RenderSketch = () => {
    return (
      <ReactP5Wrapper
        sketch={MagicFlowerTree}
        magicflowerId={magicflowerId}
        plantFeatures={plantFeatures}
        genes={allGenes[displayTimePoint]}
        userDefault={userDefault}
        userDefaultBgs={userDefaultBgs}
      />
    );
  };

  return (
    <VStack spacing={8}>
      {plantIndex !== null && !hideQuickView && (
        <VStack>
          <RenderSketch />
          <Text
            color="green.300"
            fontSize={[16, 18]}
            style={{ fontFamily: 'Lobster' }}
          >
            Magic Flowers ID: {magicflowerId}
          </Text>
          <Box>
            <Button
              colorScheme="green"
              variant="outline"
              onClick={() => setHideQuickView(true)}
            >
              Hide Quick View
            </Button>
          </Box>
        </VStack>
      )}
      <MagicFlowerTopGrid />
      <VStack mt={3}>
        <Text
          color="green.300"
          fontSize={[36, 42, 58]}
          style={{ fontFamily: 'Lobster' }}
        >
          All My Magic Flowers
        </Text>

        <Text
          color="green.300"
          fontSize={[16, 18]}
          style={{ fontFamily: 'Lobster' }}
        >
          {plantOwner}
        </Text>

        {allTokensOwned.length > 0 && (
          <Box mt="30px">
            <ShowAllMyTokens />
          </Box>
        )}
        <Box style={{ marginTop: '30px', marginBottom: '30px' }}>
          <Wrap spacing={6}>
            <WrapItem>
              <MintMagicFlowerPlantButton />
            </WrapItem>
            <WrapItem>
              {' '}
              <HybridizeMagicFlowerButton />
            </WrapItem>
            <WrapItem>
              <ListMagicFlowerForLease />
            </WrapItem>
          </Wrap>
        </Box>
        <Box>
          <RenderValidLeaseButton />
        </Box>

        {showValidLease && <RenderValidLeases />}

        <div style={{ marginTop: '58px' }}>
          <GameFooter />
        </div>
      </VStack>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default AllMyMagicFlowers;
