import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import MintTokensBase from '../../../NFTs/mintTokensBase';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';

function SellMagicflowers({ magicflowerId, variantType = 'outline' }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button colorScheme="green" variant={variantType} onClick={onOpen}>
        Sell / Auction
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sell / Auction</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MintTokensBase
              tokenType="Erc721"
              specificGameTokenId={0}
              noMinting={true}
              tokenId={magicflowerId}
              tokenContract={REACT_APP_MAGIC_FLOWERS_ADDRESS}
              useCase="TokenByUser"
              label="Sell / Auction "
              onlyIntegration={false}
              isMagicflowers={true}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SellMagicflowers;
