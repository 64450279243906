import React from 'react';
import { Box, VStack, Container } from '@chakra-ui/react';
import { TextOnWoodFrame2 } from '../../../../components/views/NFTs/elements/TextOnImages';

import MagicFlowersSection from '../../../../components/views/faqs/MagicFlowersSection';

// import NavBreadCrumbGame from '../../../components/views/NavBreadCrumbs/NavBreadCrumbGame';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import GameFooter from '../../../../components/views/GameFooter';

function MagicFlowersFAQ() {
  return (
    <VStack>
      <MagicFlowerTopGrid />
      <Container>
        <VStack>
          <Box w="385px" textAlign="center">
            <TextOnWoodFrame2 t="FAQ" />
          </Box>
        </VStack>
        <Box mt="18px">
          <MagicFlowersSection />
        </Box>
      </Container>
      <div style={{ marginTop: '58px' }}>
        <GameFooter />
      </div>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default MagicFlowersFAQ;
