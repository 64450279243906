// import Img1Url from '../../resources/images/roses.jpeg';
// import Img2Url from '../../resources/images/almond_blossom.jpeg';
// import Img3Url from '../../resources/images/poppies.jpeg';
// import Img4Url from '../../resources/images/skeleton.jpeg';
// import Img5Url from '../../resources/images/starry_night_2.jpeg';
// import Img6Url from '../../resources/images/starry_night.jpeg';
// import Img7Url from '../../resources/images/Sunflowers.jpeg';
import Vehicle from './Vehicle';

function SketchMarketplace(p5, label, tokenUri) {
  const Img1Url = 'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718570720/roses_mmbxgi.jpg'
  const Img2Url = 'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718570755/almond_blossom_ryeu6f.jpg'
  const Img3Url = 'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718570797/poppies_oqvtxl.jpg'
  const Img4Url = 'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718570849/skeleton_dw8uqj.jpg'
  const Img5Url = 'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718570881/starry_night_2_vloup0.jpg'
  const Img6Url = 'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718570907/starry_night_up6vls.jpg'
  const Img7Url = 'https://res.cloudinary.com/df9jvvwyw/image/upload/v1718570935/Sunflowers_iokmz8.jpg'
  let img;
  let x, y;
  let z = -600;
  let zvel = 30;
  let imgUrls = [Img1Url, Img2Url, Img3Url, Img4Url, Img5Url, Img6Url, Img7Url];

  // text
  let font;
  let vehicles = [];

  p5.preload = () => {
    //img = p5.loadImage(imgUrl);

    if (tokenUri.length > 0) {
      img = p5.loadImage(tokenUri);
    } else {
      let n = p5.int(p5.random(0, imgUrls.length));
      img = p5.loadImage(imgUrls[n]);
    }

    font = p5.loadFont('Lobster-Regular.ttf');
  };

  p5.setup = () => {
    const isNarrowScreen = window.innerWidth < window.innerHeight;
    const canvasHeight = isNarrowScreen
      ? window.innerWidth
      : window.innerHeight;
    p5.createCanvas(window.innerWidth, canvasHeight, p5.WEBGL);
    if (img.width >= img.height) {
      if (isNarrowScreen) {
        img.resize(p5.width / 1.18, 0);
      } else {
        img.resize(p5.width / 2.58, 0);
      }
    } else {
      img.resize(0, p5.height / 1.58);
    }


    p5.imageMode(p5.CENTER);
    x = 0;
    y = p5.height;

    //text
    let points = font.textToPoints(label, -p5.width / 4, p5.height / 2.2, 108, {
      sampleFactor: '0.25',
    });

    for (let i = 0; i < points.length; i++) {
      let pt = points[i];
      let vehicle = new Vehicle(p5, pt.x, pt.y);
      vehicles.push(vehicle);
    }
  };

  p5.draw = () => {
    p5.background(0, 1);
    // p5.background(25, 31, 45)
    p5.ambientLight(200);
    p5.pointLight(100, 100, 100, p5.width / 2, -300, 100);
    p5.pointLight(100, 100, 100, -p5.width / 2, -300, 100);
    if (p5.frameCount > 60) {
      z += zvel;
      zvel *= 0.95;
    }
    if (z > 250) {
      z -= zvel;
      zvel *= -1;
    }
    p5.translate(0, 0, z);
    x = p5.lerp(x, p5.mouseX - p5.width / 2, 0.1);
    y = p5.lerp(y, p5.mouseY - p5.height / 2, 0.1);
    p5.rotateY((1.2 * x) / p5.width);
    p5.rotateX((1.2 * y) / -p5.height);
    p5.image(img, 0, 0);
    p5.translate(x, y, 0);
    checkKeys();

    // text
    for (let i = 0; i < vehicles.length; i++) {
      let v = vehicles[i];
      v.behaviors();
      v.update();
      v.show();
    }
  };

  const checkKeys = () => {
    if (p5.keyIsDown(p5.UP_ARROW)) {
      zvel += 1;
    }
    if (p5.keyIsDown(p5.DOWN_ARROW)) {
      zvel -= 1;
    }
  };
}

export default SketchMarketplace;
