import {
  VStack,
  Box,
  Text,
  HStack,
  Grid,
  GridItem,
  Icon,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  IconButton,
} from '@chakra-ui/react';
import {
  FaInfoCircle,
  FaHourglassHalf,
  FaLeaf,
  FaCamera,
} from 'react-icons/fa';
import { GiFlowers, GiMushroomHouse } from 'react-icons/gi';
import { ColorModeSwitcher } from '../../../../ColorModeSwitcher';
import ConnectButton from '../../../../walletConnections/ConnectButton.js';
import MagicFlowerMusic from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerMusic.js';

function RenderMagicFlowerSketch({ flowerName = '' }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function SketchInfoModal() {
    return (
      <>
        <IconButton
          onClick={onOpen}
          variant="outline"
          colorScheme="green"
          aria-label="Info"
          fontSize="20px"
          icon={<FaInfoCircle />}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>How to Play</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box p="5px">
                <RenderSketchInfo />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" variant="outline" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  const RenderSketchInfo = () => {
    return (
      <List spacing={6}>
        <ListItem>
          <HStack>
            <Icon as={FaHourglassHalf} color="green.500" />
            <Text>
              Press the <strong>c</strong> key to open the control panel and
              adjust the time of day.
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <Icon as={FaLeaf} color="green.500" />
            <Text>
              To evolve your magic flower, find and click on the star that fell
              yesterday.
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <Icon as={GiFlowers} color="green.500" />
            <Text>
              To manage NFT integration in your Magic Flowers, click on the
              Magic Garden. This is where your integrated NFTs are stored and
              displayed.
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <Icon as={GiMushroomHouse} color="green.500" />
            <Text>
              To unleash your creativity and explore new possibilities, click on
              the Dream Studio.
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <Icon as={FaCamera} color="green.500" />
            <Text>
              Press <strong>s</strong> to save an image of the current state of
              your Magic Flower NFT, preserving the unique moment you are
              viewing.
            </Text>
          </HStack>
        </ListItem>
      </List>
    );
  };

  const RenderTitle = () => {
    if (flowerName.length > 0) {
      return (
        <Box textAlign="center" alignItems="baseline">
          <Text
            fontSize={['md', 'xl', '2xl', '3xl']}
            color="green.300"
            style={{ fontFamily: 'Lobster' }}
          >
            {flowerName}
          </Text>
        </Box>
      );
    } else {
      return (
        <Box textAlign="center" alignItems="baseline">
          <Text
            fontSize={['md', 'xl', '2xl', '3xl']}
            color="green.300"
            style={{ fontFamily: 'Lobster' }}
          >
            Magic Flowers
          </Text>
        </Box>
      );
    }
  };

  return (
    <>
      <VStack mt={3}>
        <Grid templateColumns="1fr 1fr auto 1fr 1fr" gap={4} w="full">
          <GridItem colStart={1} colEnd={2} h="10">
            <SketchInfoModal />
          </GridItem>

          <GridItem colStart={3} colEnd={4} h="10">
            <MagicFlowerMusic song="https://res.cloudinary.com/df9jvvwyw/video/upload/v1722219423/fur-elise_mk4mad.mp3" />
          </GridItem>

          <GridItem colStart={5} colEnd={6} h="10" justifySelf="flex-end">
            <HStack justifyContent="flex-end" w="full">
              <ColorModeSwitcher />
              <ConnectButton />
            </HStack>
          </GridItem>
        </Grid>
        <RenderTitle />
      </VStack>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default RenderMagicFlowerSketch;
