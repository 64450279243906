import { ethers } from 'ethers';
import MarketplaceERC721 from '../../../../../contracts/APIs/MarketplaceERC721Interface.sol/MarketplaceERC721Interface.json';
import MarketplaceERC1155 from '../../../../../contracts/APIs/MarketplaceERC1155Interface.sol/MarketplaceERC1155Interface.json';
import AuctionHouse from '../../../../../contracts/APIs/AuctionHouseInterface.sol/AuctionHouseInterface.json';
import Collections from '../../../../../contracts/APIs/CollectionsInterface.sol/CollectionsInterface.json';
import Helper from '../../../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import { getValleyItemInfo } from './getValleyItemInfo';
import { convertTimestampToDate } from '../../../../utils/helpers';
import getTokenContract from '../../../../utils/getTokenContract';
import { getIntegratedGames } from '../token/getIntegratedGames';
import getCollectionInfo from '../token/getCollectionInfo';
import {
  REACT_APP_AUCTION_HOUSE_ADDRESS,
  REACT_APP_MARKETPLACE_ERC1155_ADDRESS,
  REACT_APP_MARKETPLACE_ERC721_ADDRESS,
  REACT_APP_HELPER_ADDRESS,
  REACT_APP_COLLECTIONS_ADDRESS
} from '../../../cozyGames/constants/ContractAddress';
const getValleyItem = async (provider, itemId, tokenType, saleType) => {
  // console.log('itemId:', itemId);
  // console.log('tokenType:', tokenType);
  // console.log('saleType:', saleType);

  let marketplace;
  let auctionHouse;

  if (saleType === 'Marketplace') {
    if (tokenType === 'Erc721') {
      marketplace = new ethers.Contract(
        REACT_APP_MARKETPLACE_ERC721_ADDRESS,
        MarketplaceERC721.abi,
        provider
      );
    } else if (tokenType === 'Erc1155') {
      marketplace = new ethers.Contract(
        REACT_APP_MARKETPLACE_ERC1155_ADDRESS,
        MarketplaceERC1155.abi,
        provider
      );
    }
  } else if (saleType === 'Auction') {
    auctionHouse = new ethers.Contract(
      REACT_APP_AUCTION_HOUSE_ADDRESS,
      AuctionHouse.abi,
      provider
    );
  }

  const helper = new ethers.Contract(
    REACT_APP_HELPER_ADDRESS,
    Helper.abi,
    provider
  );

  let itemInfo, priceList, collectionInfo;

  let data;
  let allComplimentaryTokens = [];

  if (saleType === 'Marketplace') {
    data = await marketplace.getValleyItems([itemId]);
  } else if (saleType === 'Auction') {
    data = await auctionHouse.getValleyItems([itemId]);
  }
  // console.log('data:', data);
  let d = data[0];
  // console.log('d:', d);
  const allCtokenContracts = d.complimentaryTokenContracts;
  // console.log('allCtokenContracts:', allCtokenContracts);
  for (let i = 0; i < allCtokenContracts.length; i++) {
    let _complimentaryTokens = await marketplace.getComplimentaryTokens(
      allCtokenContracts[i],
      itemId
    );

    let complimentaryTokens = {
      cTokenContract: allCtokenContracts[i],
      isERC1155: _complimentaryTokens.isERC1155,
      cTokenIds: _complimentaryTokens.tokenIds.map(x => Number(x.toString())),
      cAmounts: _complimentaryTokens.amounts.map(x => Number(x.toString())),
    };

    allComplimentaryTokens.push(complimentaryTokens);
  }
  // console.log('allComplimentaryTokens:', allComplimentaryTokens);

  const _tokenAddr = d.nftContract;
  // console.log('_tokenAddr:', _tokenAddr);
  const tokenContract = getTokenContract(_tokenAddr, provider);

  itemInfo = await getValleyItemInfo(d, tokenContract, saleType, tokenType);
  // console.log('itemInfo:', itemInfo.newItem);

  const _gamesInfo = await getIntegratedGames(
    provider,
    d.nftContract,
    d.tokenId.toNumber(),
    d.seller
  );
  // console.log('_gamesInfo:', _gamesInfo);

  itemInfo.newItem.gameIdsIntegrated = _gamesInfo.gameIds;
  itemInfo.newItem.gameNamesIntegrated = _gamesInfo.gameNames;
  itemInfo.newItem.allIntegratedGameNames = _gamesInfo.allIntegratedGameNames;
  itemInfo.newItem.allComplimentaryTokens = allComplimentaryTokens;
  itemInfo.newItem.allCtokenContracts = allCtokenContracts;
  // console.log('itemInfo:', itemInfo.newItem);

  const priceHistory = await helper.getItemByTokenId(
    tokenContract.address,
    Number(d.tokenId)
  );
  if (priceHistory.length > 0) {
    //console.log('price history:', priceHistory[0].length);

    if (priceHistory[0].length > 0) {
      priceList = await Promise.all(
        priceHistory[0].map(async p => {
          const priceSold = ethers.utils.formatUnits(
            p.priceSold.toString(),
            'ether'
          );
          const timestampSold = convertTimestampToDate(
            p.timestampSold.toString()
          );

          return {
            priceSold,
            timestampSold,
          };
        })
      );
      //console.log('priceList:', priceList);
      //   setItemPriceHistory(priceList);
    }
  }

  const collectionsContract = new ethers.Contract(
    REACT_APP_COLLECTIONS_ADDRESS,
    Collections.abi,
    provider
  );

  collectionInfo = await getCollectionInfo(
    collectionsContract,
    _tokenAddr,
    d.tokenId
  );
  // await getTokenCollection(
  //   provider,
  //   _tokenAddr,
  //   Number(d.tokenId)
  // );

  const res = {
    itemInfo,
    priceList,
    collectionInfo,
  };

  // console.log('res:', res);

  return res;
};

export default getValleyItem;
