import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { VStack, Box, Tooltip, Wrap, WrapItem, Text } from '@chakra-ui/react';
import getValleyItem from './getValleyItem';
import MarketplaceItemErc1155 from './ValleyItemDetails/MarketplaceItemErc1155';
import AuctionItemErc721 from './ValleyItemDetails/AuctionItemErc721';
import { RenderImageType, RenderInfoAccordion } from '../ShowItem';
import BuyNFTerc721 from '../../../../actions/NFTs/marketplace/BuyNFTerc721';
import RenderGamesIntegrated from '../token/RenderGamesIntegrated';
import RenderComplimentaryTokens from './ValleyItemDetails/RenderComplimentaryTokens';
import RenderAttributes from './ValleyItemDetails/RenderAttributes';
import { TextOnPaper } from '../TextOnImages';
import RenderValleyItem from './RenderValleyItem';
import { formatDigits } from '../../../../utils/helpers';
import Sold from '../../../../../resources/images/Sold.png';

function ValleyItem({ provider, tokenType, saleType }) {
  const { itemId } = useParams();
  // console.log('itemId:', itemId);
  // console.log('saleType:', saleType);
  // console.log('provider:', provider)

  const [item, setItem] = useState({});
  const [metadata, setMetadata] = useState({});
  const [itemPriceHistory, setItemPriceHistory] = useState([]);
  const [itemCollection, setItemCollection] = useState({});

  const [loading, setLoading] = useState(true); // Initialize loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getItem();
        console.log('res:', res);
        // console.log('metadata:', res.itemInfo.newItem.metadata);

        setItem(res.itemInfo.newItem);
        setMetadata(res.itemInfo.newItem.metadata);
        setItemPriceHistory(res.priceList);
        setItemCollection(res.collectionInfo);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after data is loaded
      }
    };

    fetchData();
  }, []);

  const getItem = async () => {
    const res = await getValleyItem(provider, itemId, tokenType, saleType);
    return res;
  };

  const RenderItemInfo = () => {
    // console.log('metadata:', metadata);
    return (
      <VStack spacing={5}>
        <Box textAlign="center" alignItems="baseline">
          <Tooltip label={'ID: ' + item.itemId} aria-label="A tooltip">
            <Text
              fontSize={[24, 36, 48]}
              color="teal.500"
              style={{ fontFamily: 'Lobster' }}
            >
              {metadata.name}
            </Text>
          </Tooltip>
        </Box>

        <Box>
          <RenderImageType item={item} />
        </Box>

        <Box
          textAlign="center"
          style={{ marginTop: '25px', fontWeight: 'bold', fontSize: '28px' }}
        >
          {item.isSold ? (
            <img src={Sold} style={{ width: '218px', height: 'auto' }} />
          ) : (
            <BuyNFTerc721
              itemId={item.itemId}
              itemPrice={item.price}
              gameIdsIntegrated={item.gameIdsIntegrated}
            />
          )}
        </Box>

        <Box textAlign="center" alignItems="baseline" as="i">
          Price: {formatDigits(item.price)} MATIC
        </Box>

        <Wrap>
          <WrapItem>
            <RenderGamesIntegrated
              provider={provider}
              tokenContract={item.tokenContractAddress}
              tokenId={item.tokenId}
              player={item.seller}
            />
          </WrapItem>
          <WrapItem>
            <RenderComplimentaryTokens
              itemId={item.itemId}
              allCtokenContracts={item.allCtokenContracts}
              saleType={saleType}
              tokenType={tokenType}
            />
          </WrapItem>
        </Wrap>

        <RenderAttributes attributes={item.attributes} />

        <RenderInfoAccordion
          item={item}
          itemCollection={itemCollection}
          itemPriceHistory={itemPriceHistory}
          useCase="ValleyItem"
        />
      </VStack>
    );
  };

  const RenderItemErc721 = () => {
    if (item.isCancelled) {
      return (
        <Box mt={8}>
          <TextOnPaper t="Cancelled" />
        </Box>
      );
    } else {
      return (
        <>
          <RenderValleyItem
            category={item.category}
            image={metadata.image}
            animation_url={metadata.animation_url}
          />

          <RenderItemInfo
            item={item}
            metadata={metadata}
            itemCollection={itemCollection}
            itemPriceHistory={itemPriceHistory}
          />
        </>
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Render a loading indicator while data is being fetched
  }

  return (
    <VStack mt="10px">
      {saleType === 'Marketplace' && tokenType === 'Erc1155' && (
        // <RenderItemErc1155 item={item} itemCollection={itemCollection} />
        <MarketplaceItemErc1155
          provider={provider}
          item={item}
          metadata={metadata}
          itemCollection={itemCollection}
          itemPriceHistory={itemPriceHistory}
          tokenType="Erc1155"
          saleType="Marketplace"
        />
      )}
      {saleType === 'Marketplace' && tokenType === 'Erc721' && (
        <>
          <RenderItemErc721
            item={item}
            metadata={metadata}
            itemCollection={itemCollection}
            itemPriceHistory={itemPriceHistory}
          />
        </>
      )}
      {saleType === 'Auction' && (
        <AuctionItemErc721
          provider={provider}
          item={item}
          metadata={metadata}
          itemCollection={itemCollection}
          itemPriceHistory={itemPriceHistory}
          tokenType="Erc721"
          saleType="Auction"
        />
      )}

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default ValleyItem;
