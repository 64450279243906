class Blade {
    constructor(p5, canvas, segments) {
      this.p5 = p5;
      this.canvas = canvas;
      this.segments = segments;
      this.angle = this.p5.random(-20, 20);
      this.num = 0;
    }

    branch(len) {
      len *= 0.79;
      this.canvas.strokeWeight(this.p5.map(len, 1, this.segments, 0.1, 4));
      this.canvas.line(0, 0, 0, -len);
      this.canvas.translate(0, -len);
      if (len > 5) {
        this.canvas.push();
        this.canvas.rotate(
          this.p5.radians(this.angle + this.p5.sin(len + this.num))
        );
        this.branch(len);
        this.canvas.pop();
      }
      this.num += 0.05;
    }
  }

  class Grass {
    constructor(p5, canvas, loc, scale, windFactor) {
      this.p5 = p5;
      this.canvas = canvas;
      this.loc = loc;
      this.scale = scale;
      this.windFactor = windFactor
      this.blades = [];
      let am = Math.floor(this.canvas.random(12, 36));
      for (let i = 0; i < am; i++) {
        this.blades.push(
          new Blade(this.p5, this.canvas, this.p5.random(8, 90) * this.scale)
        );
      }
    }

    draw() {
      for (let i = 0; i < this.blades.length; i++) {
        let b = this.blades[i];
        this.canvas.push();
        this.canvas.translate(this.loc.x, this.loc.y);

        // Apply wind effect to the grass movement based on windFactor
        let angleOffset = this.p5.map(this.windFactor, 0, 1, -10, 10) * this.windFactor;
        let angle = this.p5.radians(b.angle) + angleOffset;

        this.canvas.rotate(angle);
        b.branch(b.segments);
        this.canvas.pop();
      }
    }
  }

export default Grass