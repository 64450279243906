import { moon, moon1, moon2 } from './moon';
import { sky, skyPlant1 } from './sky/sky';
import { skyDawn, skyDawn1 } from './sky/skyDawn';
import { skyAfternoon, skyAfternoon1 } from './sky/skyAfternoon';
export function drawBgScene(p5, canvas, h, plant) {
  canvas.clear();
  _drawBgScene(p5, canvas, h, plant);
  p5.push();
  p5.image(canvas, 0, 0);
  p5.pop();
}

export function drawBgSceneDeepNight(p5, canvas) {
  // if (0 <= h && h < 3)
  canvas.clear();
  canvas.background(0, 0, 102);
  moon2(p5, canvas);
  p5.push();
  p5.image(canvas, 0, 0);
  p5.pop();
}

export function drawBgSceneDawn(p5, canvas) {
  // if (3 <= h && h < 6)
  canvas.clear();
  canvas.background(255);
  canvas.push();
  skyDawn1(p5, canvas); // plant1
  canvas.pop();
  canvas.push();
  canvas.drawingContext.shadowColor = p5.random(['#ee8959']);
  canvas.drawingContext.shadowBlur = 180;
  canvas.fill('red');
  canvas.noStroke();
  canvas.circle(canvas.width / 1.18, 58, 100);
  canvas.pop();
  p5.push();
  p5.image(canvas, 0, 0);
  p5.pop();
}
export function drawBgSceneMorning(p5, canvas) {
  // if (6 <= h && h < 11)
  canvas.clear();
  canvas.background(255);
  canvas.push();
  skyPlant1(
    p5,
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    p5.color(255),
    p5.color(0, 0, 255)
  );
  p5.push();
  p5.image(canvas, 0, 0);
  p5.pop();

  // todo : rainbow or bubbles
}
export function drawBgSceneAfternoon(p5, canvas) {
  // if (11 <= h && h < 16)
  canvas.clear();
  canvas.push();
  skyAfternoon1(p5, canvas);
  canvas.pop();
  p5.push();
  p5.image(canvas, 0, 0);
  p5.pop();
  // todo: add light rain or various clouds
}
export function drawBgSceneEvening(p5, canvas) {
  //if (16 <= h && h < 20)
  canvas.clear();
  canvas.background(0, 0, 179);
  p5.push();
  p5.image(canvas, 0, 0);
  p5.pop();
}
export function drawBgSceneNight(p5, canvas) {
  // if (20 <= h && h < 24)
  canvas.clear();
  canvas.background(0, 0, 153);
  p5.push();
  p5.image(canvas, 0, 0);
  p5.pop();
}
export function _drawBgScene(p5, canvas, h, plant) {
  //let h = second();

  if (0 <= h && h < 3) {
    canvas.background(0, 0, 102);
    moon2(p5, canvas);
  } else if (3 <= h && h < 6) {
    canvas.background(255);
    canvas.push();
    skyDawn1(p5, canvas); // plant1
    canvas.pop();
    canvas.push();
    canvas.drawingContext.shadowColor = p5.random(['#ee8959']);
    canvas.drawingContext.shadowBlur = 180;
    canvas.fill('red');
    canvas.noStroke();
    canvas.circle(canvas.width / 1.18, 58, 100);
    canvas.pop();
  } else if (6 <= h && h < 11) {
    canvas.background(255);
    canvas.push();
    skyPlant1(
      p5,
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      p5.color(255),
      p5.color(0, 0, 255)
    );

    // todo : rainbow or bubbles
  } else if (11 <= h && h < 16) {
    canvas.push();
    skyAfternoon1(p5, canvas);
    canvas.pop();
    // todo: add light rain or various clouds
  } else if (16 <= h && h < 20) {
    canvas.background(0, 0, 179);
  } else if (20 <= h && h < 24) {
    if (plant === 3) {
      canvas.background(0, 0, 77); // for plant3
    } else {
      canvas.background(0, 0, 153); // for plant2
    }
  }

  // let valleyWidth = canvas.width;

  // canvas.image(valley, 0, 230, valleyWidth, 0)
}
