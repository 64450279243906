import React from 'react';
import { Text, VStack, Box, Link } from '@chakra-ui/react';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
import { Link as ReactLink } from 'react-router-dom';
const JsonCreator = () => {
  return (
    <VStack spacing={8} mb="58px">
      <HomeIcon />
      <Text
        color="green.400"
        fontSize={['xl', '2xl', '3xl', '4xl']}
        style={{ fontFamily: 'Lobster' }}
      >
        Json Creator
      </Text>
      <Box
        w="318px"
        textAlign="center"
        p="18px"
        boxShadow="xl"
        rounded="md"
        bgGradient="radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% )"
      >
        <Link as={ReactLink} to="/jsoncreator/nft">
          <Text fontSize="xl">Create NFT Meta</Text>
        </Link>
      </Box>
      <Box
        w="318px"
        textAlign="center"
        p="18px"
        boxShadow="xl"
        rounded="md"
        bgGradient="linear-gradient( 109.6deg,  rgba(62,161,219,1) 11.2%, rgba(93,52,236,1) 100.2% )"
      >
        <Link as={ReactLink} to="/jsoncreator/collection">
          <Text fontSize="xl">Create Collection Meta</Text>
        </Link>
      </Box>
      <Box
        w="318px"
        textAlign="center"
        p="18px"
        boxShadow="xl"
        rounded="md"
        bgGradient="radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% )"
      >
        <Link as={ReactLink} to="/jsoncreator/gamescompatible">
          <Text fontSize="xl">Create Games Compatible Meta</Text>
        </Link>
      </Box>
    </VStack>
  );
};

export default JsonCreator;
