import React, { useState, useEffect } from 'react';
import {
  Link,
  Box,
  Text,
  Stack,
  VStack,
  Grid,
  RadioGroup,
  Radio,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useMultiStyleConfig,
  useTab,
  Button,
} from '@chakra-ui/react';
import ValleyItemList from '../../../components/views/NFTs/elements/valleyItems/ValleyItemList';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
import ValleyItemListBase from '../../../components/views/NFTs/elements/valleyItems/ValleyItemListBase';
import FeaturedGalleryScene from '../../../components/views/NFTs/featured/FeaturedGalleryScene';

function Marketplace() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const [value, setValue] = useState('1');

  const DisplayList = ({ tokenType }) => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);

    if (value === '1') {
      return (
        <ValleyItemList
          provider={provider}
          saleType="Marketplace"
          tokenType={tokenType}
        />
      );
    } else if (value === '2') {
      return (
        <ValleyItemListBase
          provider={provider}
          saleType="Marketplace"
          tokenType={tokenType}
          marketplaceIds={[]}
          auctionIds={[]}
          byUser={false}
          userAddr=""
          forGame={true}
          forExhibition={false}
        />
      );
    }
    // else if (value === '3') {
    //   return (
    //     <ValleyItemListMagicflowers
    //       provider={provider}
    //       saleType="Marketplace"
    //     />
    //   );
    // }
    else {
      return (
        <ValleyItemList
          provider={provider}
          saleType="Marketplace"
          tokenType={tokenType}
        />
      );
    }
  };

  const CustomTab = React.forwardRef((props, ref) => {
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <Button __css={styles.tab} {...tabProps}>
        <Box as="span" mr="2">
          {isSelected ? '😎' : '😐'}
        </Box>
        {tabProps.children}
      </Button>
    );
  });

  const RenderTabs = () => {
    if (value == '3') {
      return <DisplayList tokenType="Erc721" />;
    } else {
      return (
        <>
          <Tabs isFitted isLazy colorScheme="green">
            <TabList mb="1em">
              <CustomTab>ERC721</CustomTab>
              <CustomTab>ERC1155</CustomTab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <DisplayList tokenType="Erc721" />
              </TabPanel>
              <TabPanel>
                <DisplayList tokenType="Erc1155" />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      );
    }
  };

  return (
    <Box textAlign="center">
      <FeaturedGalleryScene label="Marketplace" featurePage="marketplace" />
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          {/* <HomeIcon /> */}
          <VStack spacing={3}>
            {/* <Search /> */}

            <Box>
              <RadioGroup onChange={setValue} value={value}>
                <Stack direction="row">
                  <Radio value="1" colorScheme="green">
                    Marketplace
                  </Radio>
                  <Radio value="2" colorScheme="green">
                    NFTs for Games on Marketplace
                  </Radio>
                  <Radio value="3" colorScheme="green">
                    Magic Flowers NFTs on Marketplace
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>

            <RenderTabs />
          </VStack>
        </Grid>
      </Box>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default Marketplace;
