import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Radio,
  RadioGroup,
  Stack,
  Input,
} from '@chakra-ui/react';
import MarketplaceERC721 from '../../../../contracts/APIs/MarketplaceERC721Interface.sol/MarketplaceERC721Interface.json';
import MarketplaceERC1155 from '../../../../contracts/APIs/MarketplaceERC1155Interface.sol/MarketplaceERC1155Interface.json';
import { REACT_APP_MARKETPLACE_ERC1155_ADDRESS, REACT_APP_MARKETPLACE_ERC721_ADDRESS } from '../../../views/cozyGames/constants/ContractAddress';

const BuyNFTbase = ({
  itemId,
  itemPrice,
  isErc721,
  gameIdsIntegrated = [],
}) => {
  // console.log('itemId:', itemId);
  // console.log('itemPrice:', itemPrice);
  // console.log('isErc721:', isErc721);
  // console.log('gameIdsIntegrated:', gameIdsIntegrated);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [newBranch, setNewBranch] = useState('');
  const [value, setValue] = useState('1');
  const [amount, setAmount] = useState(0);

  const handleAmount = value => {
    // console.log('value:', value);
    setAmount(value);
  };

  const handleGameBranch = e => {
    setNewBranch(e.target.value);
  };

  const buy = async () => {
    setLoading(true);
    // const web3Modal = new Web3Modal(process.env.REACT_APP_PROJ_ADDRESS);
    // const connection = await web3Modal.connect();
    // const provider = new ethers.providers.Web3Provider(connection);
    // const signer = provider.getSigner();
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('signer:', signer)
    const MarketplaceAbi = isErc721
      ? MarketplaceERC721.abi
      : MarketplaceERC1155.abi;
    const MarketplaceAddress = isErc721
      ? REACT_APP_MARKETPLACE_ERC721_ADDRESS
      : REACT_APP_MARKETPLACE_ERC1155_ADDRESS;
    try {
      const marketplace = new ethers.Contract(
        MarketplaceAddress,
        MarketplaceAbi,
        signer
      );

      // console.log('marketplace address:', marketplace.address);

      let transferGalleryOwnership = false;
      const idsLength = gameIdsIntegrated.length;
      if (idsLength > 0 && gameIdsIntegrated[idsLength - 1] > 0) {
        transferGalleryOwnership = true;
      }
      const gameId = transferGalleryOwnership
        ? gameIdsIntegrated[idsLength - 1]
        : 0;
      const updateBranch = value === '2' ? true : false;

      const _helperParam = {
        transferGalleryOwnership,
        gameId,
        updateBranch,
        newBranch,
      };
      // console.log('_helperParam:', _helperParam);

      let transaction;
      if (isErc721) {
        transaction = await marketplace.buy(itemId, _helperParam, {
          value: ethers.utils.parseEther(itemPrice),
        });
      } else {
        const _payment = await marketplace.getPriceByAmountErc1155(
          itemId,
          amount
        );
        transaction = await marketplace.buy(itemId, amount, _helperParam, {
          value: _payment,
        });
      }

      await transaction.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="green"
          size="lg"
          mr={3}
        >
          Buy
        </Button>
      );
    } else {
      return (
        <Button onClick={buy} colorScheme="green" size="lg" mr={3}>
          Buy
        </Button>
      );
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        as="button"
        size="xl"
        p={4}
        color="white"
        fontWeight="bold"
        borderRadius="xl"
        bgGradient="linear(to-r, #439cfb, #f187fb)"
        _hover={{
          bgGradient: 'linear(to-r, teal.300, green.300)',
        }}
      >
        Buy
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Buy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!isErc721 && (
              <Box style={{ marginBottom: '20px' }}>
                <FormControl isRequired>
                  <FormLabel as="legend">Amount:</FormLabel>

                  <NumberInput
                    min={0}
                    value={amount}
                    onChange={e => handleAmount(e)}
                    placeholder="Amount"
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Box>
            )}
            <Box style={{ marginBottom: '20px' }}>
              <FormControl>
                <FormLabel as="legend">Update Game Branch:</FormLabel>
                <RadioGroup onChange={setValue} value={value}>
                  <Stack direction="row">
                    <Radio value="1">No</Radio>
                    <Radio value="2">Yes</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Box>

            {value === '2' && (
              <Box style={{ marginBottom: '20px' }}>
                <FormControl>
                  <FormLabel as="legend">New Game Branch:</FormLabel>

                  <Input
                    name="gameBranch"
                    value={newBranch}
                    onChange={e => handleGameBranch(e)}
                    placeholder="New Game Branch"
                    size="sm"
                  />
                </FormControl>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <RenderButton />
            <Button mr={3} onClick={onClose} size="md">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BuyNFTbase;
