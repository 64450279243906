const getK = flowerType => {
  const kMap = {
    115: 5,
    116: 7,
    117: 8,
    118: 9,
    119: 10,
    120: 11,
    121: 12,
    122: 13,
    123: 14,
    124: 15,
    125: 16,
    126: 17,
    127: 18,
    128: 19,
  };

  return kMap[flowerType];
};
export class FlowerGenus3 {
  constructor(p5, canvas, flowerType) {
    this.p5 = p5;
    this.canvas = canvas;
    this.longitude = 10.8;
    this.theta = this.p5.radians(30);
    this.theta2 = this.p5.radians(30);
    this.k = getK(flowerType); //7; // k: 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19.
    this.fch = this.p5.int(this.canvas.random(360));
    this.sch = this.p5.int(this.canvas.random(360));
    this.ellipseSize = this.p5.int(this.canvas.random(this.longitude / 3) + 5);
  }

  display() {
    this.canvas.push();
    this.canvas.translate(this.canvas.width / 2, this.canvas.height / 2);
    let forLimit = this.p5.floor(this.p5.TWO_PI / 0.01);
    for (let i = 1; i < forLimit; i++) {
      this.theta2 += 0.06;
      if (this.theta2 >= this.p5.TWO_PI) this.theta2 = 0;
      this.theta += 0.01;
      if (this.theta >= this.p5.TWO_PI) this.theta = 0;

      let r = this.longitude * this.p5.cos(this.k * this.theta);
      let x = r * this.p5.cos(this.theta2);
      let y = r * this.p5.sin(this.theta2);
      this.canvas.strokeWeight(0.2588);

      this.canvas.stroke(this.fch, 100, 100);
      this.canvas.line(x, y, 2 * x, 2 * y);
    }
    this.canvas.fill(this.sch, 100, 100);
    this.canvas.ellipse(0, 0, this.ellipseSize, this.ellipseSize);
    this.canvas.pop();
  }
}
