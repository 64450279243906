import { ethers } from 'ethers';
import Helper from '../../../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import { convertTimestampToDate } from '../../../../utils/helpers';
import { REACT_APP_HELPER_ADDRESS } from '../../../cozyGames/constants/ContractAddress';
const getTokenPriceHistory = async (
  provider,
  tokenContractAddress,
  tokenId
) => {
  const helper = new ethers.Contract(
    REACT_APP_HELPER_ADDRESS,
    Helper.abi,
    provider
  );

  let priceList;

  const priceHistory = await helper.getItemByTokenId(
    tokenContractAddress,
    Number(tokenId)
  );
  if (priceHistory.length > 0) {
    //console.log('price history:', priceHistory[0].length);

    if (priceHistory[0].length > 0) {
      priceList = await Promise.all(
        priceHistory[0].map(async p => {
          const priceSold = ethers.utils.formatUnits(
            p.priceSold.toString(),
            'ether'
          );
          const timestampSold = convertTimestampToDate(
            p.timestampSold.toString()
          );

          return {
            priceSold,
            timestampSold,
          };
        })
      );
      //console.log('priceList:', priceList);
    }
  }

  return priceList;
};

export default getTokenPriceHistory;
