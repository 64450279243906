import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Box,
  Text,
  Button,
  VStack,
  Tooltip,
} from '@chakra-ui/react';
import { Player } from 'video-react';
import UpdateUserDefaultButton from '../../../../actions/cozyGames/cozyGame1/magicFlowers/UpdateUserDefaultButton';

const RenderDefaultGallery = ({ magicflowerId, obj }) => {
  if (!obj || typeof obj !== 'object') {
    return null;
  }

  const removeDefault = keyToRemove => {
    delete obj[keyToRemove];
  };

  const RenderImages = () => {
    if (Object.keys(obj).length > 0) {
      return Object.keys(obj).map((key, i) => {
        if (obj[key].imageUrl.length > 0) {
          return (
            <Box key={i} mt={6}>
              <VStack>
                {/* <div style={{ marginLeft: '20px' }}><RenderObject obj={obj[key]} /></div> */}
                <Button
                  colorScheme="green"
                  variant="outline"
                  size="sm"
                  onClick={() => removeDefault(key)}
                >
                  <Tooltip
                    label="Remove from default"
                    placement="top"
                    aria-label="A tooltip"
                  >
                    Remove
                  </Tooltip>
                </Button>
                <figure className="wave">
                  {obj[key].category == 'Video' ? (
                    <Player playsInline src={obj[key].imageUrl} />
                  ) : (
                    <img src={obj[key].imageUrl} alt={key} />
                  )}

                  <figcaption>{key}</figcaption>
                </figure>
              </VStack>
            </Box>
          );
        }
      });
    }
  };

  const RenderGallery = () => {
    if (Object.keys(obj).length > 0) {
      return (
        <Card>
          <CardBody>
            <Box>
              <Text
                style={{ fontFamily: 'Lobster' }}
                fontSize={['2xl', '3xl', '4xl', '5xl']}
                color="green.500"
                align="center"
              >
                Default Artworks
              </Text>
            </Box>
            <RenderImages />
          </CardBody>
          <CardFooter>
            <Box alignItems="center">
              {/* <Button variant="outline" colorScheme="green">
                Update Default Artworks
              </Button> */}
              <UpdateUserDefaultButton
                magicflowerId={magicflowerId}
                userDefault={obj}
              />
            </Box>
          </CardFooter>
        </Card>
      );
    } else {
      return <></>;
    }
  };

  return <RenderGallery />;
};

export default RenderDefaultGallery;
