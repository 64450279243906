import { Outlet } from 'react-router-dom';
import NavMenu from './NavMenu';

const Layout = ({ isGameRoute }) => {
  if (isGameRoute) {
    return (
      <>
        <Outlet />
      </>
    );
  } else {
    return (
      <>
        <NavMenu isGameRoute={isGameRoute} />
        <Outlet />
      </>
    );
  }
};

export default Layout;
