import Fairy from '../../resources/images/spaceBeings/fairy.png';
import Unicorn from '../../resources/images/spaceBeings/unicorn2.png';
import Witch from '../../resources/images/spaceBeings/witch.png';
import Witch2 from '../../resources/images/spaceBeings/witch2.png';
import Penguin from '../../resources/images/spaceBeings/penguin.png';
import Man from '../../resources/images/spaceBeings/man.png';
import Rat from '../../resources/images/spaceBeings/rat.png';
import Cat from '../../resources/images/spaceBeings/cat.png';
import Superman from '../../resources/images/spaceBeings/superman.png';
import Vehicle from './Vehicle';
function SketchGame(p5) {
  let stars = [];
  let numOfStars = 1888;
  let z = 0;

  let imgUrls = [
    Fairy,
    Unicorn,
    Witch,
    Witch2,
    Penguin,
    Man,
    Rat,
    Cat,
    Superman,
  ];

  let imgs = [];
  let imgIndex, img, imgX, imgY, imgXvel, imgYvel;

  // text
  let font;
  let vehicles = [];

  p5.preload = () => {
    for (let i = 0; i < imgUrls.length; i++) {
      let _img = p5.loadImage(imgUrls[i]);
      imgs.push(_img);
    }

    font = p5.loadFont('Lobster-Regular.ttf');
  };
  p5.setup = () => {
    p5.createCanvas(window.innerWidth, window.innerHeight, p5.WEBGL);
    p5.noCursor();

    for (let i = 0; i < numOfStars; i++) {
      stars.push(
        new Star(
          p5.random(-p5.width, p5.width),
          p5.random(-p5.width, p5.width),
          p5.random(-p5.width, p5.width)
        )
      );
    }

    imgX = -p5.width / 2.5;
    imgY = p5.height / 2;
    imgXvel = 5;
    imgYvel = 5;

    imgIndex = p5.int(p5.random(0, imgs.length));
    img = imgs[imgIndex];
    img.resize(0, p5.height / 3.8);

    //text
    let points = font.textToPoints(
      'Cozy Games XYZ',
      -p5.width / 4,
      p5.height / 2.2,
      88,
      {
        sampleFactor: '0.25',
      }
    );

    for (let i = 0; i < points.length; i++) {
      let pt = points[i];
      let vehicle = new Vehicle(p5, pt.x, pt.y);
      vehicles.push(vehicle);
    }
  };

  p5.draw = () => {
    p5.background(0);
    // p5.orbitControl();

    p5.rotateY(z);
    p5.rotateZ(z);
    z = z + 0.001;

    for (let i = 0; i < numOfStars; i++) {
      stars[i].show();
    }

    p5.image(img, imgX, imgY);
    imgX += imgXvel;
    imgY -= imgYvel;

    // text
    if (p5.frameCount > 18) {
      for (let i = 0; i < vehicles.length; i++) {
        let v = vehicles[i];
        v.behaviors();
        v.update();
        v.show();
      }
    }
  };

  class Star {
    constructor(starX, starY, starZ) {
      this.x = starX;
      this.y = starY;
      this.z = starZ;
      this.s = p5.random(2, 5);
    }

    show() {
      p5.push();
      p5.translate(this.x, this.y, this.z);
      p5.noSmooth();

      p5.fill(255);

      p5.noStroke();

      if (this.rand === 0) {
        p5.box(this.s);
      } else {
        p5.sphere(this.s);
      }

      p5.pop();
    }
  }
}

export default SketchGame;
