import { Link as ReactLink } from 'react-router-dom';
import { Link, Box, VStack, Text } from '@chakra-ui/react';
import { formatDigits } from '../../../../utils/helpers';
import SellerActions from '../../../../actions/NFTs/userActions/SellerActions';
import { RenderImageType } from '../ShowItem';
import DetailButton from '../DetailButton';
import { Player } from 'video-react';

const RenderValleyItems = props => {
  // console.log('props:', props);
  const stringToArray = str => {
    return str
      .split(',')
      .map(item => item.trim())
      .filter(item => item !== '');
  };

  const getToLink = (item, _tokenType) => {
    let toLink;
    if (item.saleType === 'Auction') {
      toLink = `/auctions/${item.valleyId}`;
    } else {
      if (_tokenType === 'Erc721') {
        toLink = `/marketplace/erc721/${item.valleyId}`;
      } else {
        toLink = `/marketplace/erc1155/${item.valleyId}`;
      }
    }
    return toLink;
  };

  // const RenderSkybox = ({ item }) => {
  //   if (item.image.length > 0) {
  //     return (
  //       <Box
  //         display="flex"
  //         alignItems="baseline"
  //         style={{ overflow: 'hidden' }}
  //       >
  //         <img
  //           src={item.image}
  //           alt={item.image}
  //           className="hover-zoom"
  //           style={{ width: '400px' }}
  //         />
  //       </Box>
  //     );
  //   } else {
  //     const images = stringToArray(item.tokenUri);
  //     console.log('images:', images);
  //     return (
  //       <Box
  //         display="flex"
  //         alignItems="baseline"
  //         style={{ overflow: 'hidden' }}
  //       >
  //         <img
  //           src={images[1]}
  //           alt={item.tokenUri}
  //           className="hover-zoom"
  //           style={{ width: '400px' }}
  //         />
  //       </Box>
  //     );
  //   }
  // };
  // const RenderImage = ({ item }) => {
  //   if (item.category === 'Music') {
  //     return (
  //       <VStack>
  //         <Box>
  //           <Text
  //             style={{ fontFamily: 'Lobster' }}
  //             fontSize="2xl"
  //             color="green.300"
  //           >
  //             Music
  //           </Text>
  //         </Box>
  //         {item.net.length > 0 && (
  //           <Box
  //             display="flex"
  //             alignItems="baseline"
  //             style={{ overflow: 'hidden' }}
  //           >
  //             <img
  //               src={item.image}
  //               alt={item.image}
  //               className="hover-zoom"
  //               style={{ width: '400px' }}
  //             />
  //           </Box>
  //         )}
  //         <audio controls>
  //           <source src={item.tokenUri} />
  //         </audio>
  //       </VStack>
  //     );
  //   } else if (item.category === '3DModel') {
  //     return (
  //       <VStack>
  //         {item.image.length > 0 && (
  //           <Box
  //             display="flex"
  //             alignItems="baseline"
  //             style={{ overflow: 'hidden' }}
  //           >
  //             <img
  //               src={item.image}
  //               alt={item.image}
  //               className="hover-zoom"
  //               style={{ width: '400px' }}
  //             />
  //           </Box>
  //         )}
  //       </VStack>
  //     );
  //   } else if (item.category === 'Video') {
  //     return (
  //       <Player playsInline poster={item.image} src={item.animation_url} />
  //     );
  //   } else {
  //     if (item.image.length > 0) {
  //       return (
  //         <Box
  //           display="flex"
  //           alignItems="baseline"
  //           style={{ overflow: 'hidden' }}
  //         >
  //           <img
  //             src={item.image}
  //             alt={item.image}
  //             className="hover-zoom"
  //             style={{ width: '400px' }}
  //           />
  //         </Box>
  //       );
  //     } else {
  //       return (
  //         <Box
  //           display="flex"
  //           alignItems="baseline"
  //           style={{ overflow: 'hidden' }}
  //         >
  //           <img
  //             src={item.image}
  //             alt={item.image}
  //             className="hover-zoom"
  //             style={{ width: '400px' }}
  //           />
  //         </Box>
  //       );
  //     }
  //   }
  // };
  const RenderImage = ({ item }) => {
    if (item.metadata.image.length > 0) {
      return (
        <Box
          display="flex"
          alignItems="baseline"
          style={{ overflow: 'hidden' }}
        >
          <img
            src={item.metadata.image}
            alt={item.metadata.image}
            className="hover-zoom"
            style={{ width: '400px' }}
          />
        </Box>
      );
    }
  };

  const RenderItem = ({ item, key, byUser }) => {
    return (
      <Box
        w="400px"
        style={{
          display: 'grid',
          minHeight: '200px',
          border: '8px solid',
          padding: '1rem',
          borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
          marginTop: '20px',
        }}
      >
        <Box>
          <RenderImage item={item} />
        </Box>

        <Box
          textAlign="center"
          alignItems="baseline"
          style={{ marginTop: '15px' }}
        >
          <Text style={{ fontFamily: 'Lobster' }} fontSize="2xl">
            {item.metadata.name}
          </Text>
        </Box>

        {item.saleType === 'Marketplace' && (
          <Box textAlign="center" alignItems="baseline">
            <Text fontSize="sm" as="i">
              {formatDigits(item.price)} MATIC
            </Text>
          </Box>
        )}

        {item.saleType === 'Auction' && (
          <Box textAlign="center" alignItems="baseline">
            <Text fontSize="sm" as="i">
              Starting Price: {item.startingPrice} MATIC
            </Text>
          </Box>
        )}

        <Box>
          <RenderImageType item={item} />
        </Box>

        <Box mt={3}>
          <Link
            key={key}
            as={ReactLink}
            to={
              getToLink(item, props.tokenType)
              // item.saleType === 'Auction'
              //   ? `/auctions/${item.valleyId}`
              //   : `/marketplace/${item.valleyId}`
            }
          >
            <DetailButton label="" />
          </Link>
        </Box>

        {/* {item.allCompatibleGameNames.length > 0 && (
          <Box textAlign="center">
            <Text as="i" fontSize="sm">
              Compatible with {item.allCompatibleGameNames}
            </Text>
          </Box>
        )} */}

        {item.games.gameNames.length > 0 && (
          <Box textAlign="center">
            <Text as="i" fontSize="sm">
              Integrated in {item.games.gameNames}
            </Text>
          </Box>
        )}

        <Box
          textAlign="center"
          alignItems="baseline"
          style={{ fontWeight: 'bold', fontSize: '28px' }}
        >
          <Text as="i">{item.isSold ? 'Sold' : ''}</Text>
        </Box>

        {byUser && (
          <Box mt={3}>
            <SellerActions
              itemId={item.valleyId}
              isMarketplace={item.saleType === 'Auction' ? false : true}
              tokenContract={item.tokenContract}
              tokenId={item.tokenId}
              tokenType={props.tokenType}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <VStack>
      {props.items.length &&
        props.items.map((item, key) =>
          item.isCancelled === true ? null : (
            <RenderItem item={item} key={key} byUser={props.byUser} />
          )
        )}
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
};

export default RenderValleyItems;
