import { ethers } from 'ethers';
import MagicNFTerc721 from '../../../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import getTokenInfo from './token/getTokenInfo';
import { REACT_APP_MAGIC_NFT_ERC721_ADDRESS } from '../../cozyGames/constants/ContractAddress';

const getAllTokensOwnedBy = async provider => {
  const signer = provider.getSigner();
  const signer_address = await signer.getAddress();
  // console.log('signer:', signer_address);

  const tokenContract = new ethers.Contract(
    REACT_APP_MAGIC_NFT_ERC721_ADDRESS,
    MagicNFTerc721.abi,
    provider
  );

  const _allTokens = await tokenContract.allTokenOf(signer_address);
  const tokens = _allTokens.map(num => Number(num.toString()));
  console.log('tokens:', tokens);
  let allTokens = [];

  for (let i = 0; i < tokens.length; i++) {
    const tokenId = tokens[i];
    const _tokenInfo = await getTokenInfo(tokenContract, tokenId);
    allTokens.push(_tokenInfo);
  }
  // console.log('allTokens:', allTokens);
  //   setAllTokensOwned(allTokens);
  return allTokens;
};

export default getAllTokensOwnedBy;
