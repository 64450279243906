import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../../../../styles/WaveFrame.css';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import { ethers } from 'ethers';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { VStack, Text, Button, HStack } from '@chakra-ui/react';
import { GiButterfly } from 'react-icons/gi';
import EvolveOutcome from '../../../../components/sketches/games/magicFlowersSketches/plants/EvolveOutcome';
import MagicFlowersSupport from '../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import DiceOutcomeBoard from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/DiceOutcomeBoard';
import getMagicFlowerDetail from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getMagicFlowerDetail';
import GameFooter from '../../../../components/views/GameFooter';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import MagicFlowerMusic from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerMusic';
import { REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS } from '../../../../components/views/cozyGames/constants/ContractAddress';
const PlantEvolvingHistory = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const { magicflowerId } = useParams();
  // console.log('magicflowerId:', magicflowerId);

  const [plantName, setPlantName] = useState('');
  const [plantSpirit, setPlantSpirit] = useState('');

  useEffect(() => {
    getLastOutcomes();
  }, []);

  const [showOutcome, setShowOutcome] = useState(false);
  const [lastDiceOutcomes, setLastDiceOutcomes] = useState([]);
  const [outcomeString, setOutcomeString] = useState('');
  const [gamePlay, setGamePlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rolling, setRolling] = useState(false);
  const handleShowOutcome = () => {
    if (showOutcome) {
      setShowOutcome(false);
    } else {
      setShowOutcome(true);
    }
  };

  const evolvePlant = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const tokenContract = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
        MagicFlowersSupport.abi,
        signer
      );

      const transaction = await tokenContract.evolve(magicflowerId);

      let tx = await transaction.wait();

      // console.log('Transaction', tx);

      await getLastOutcomes();
      setRolling(true);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEvolveTreeClick = async () => {
    setGamePlay(true);
    await evolvePlant();
    setGamePlay(false);
  };

  const EvolveTreeButton = () => {
    if (loading === true) {
      return (
        <>
          <Button
            isLoading
            loadingText="Evolving"
            size="2xl"
            p={4}
            colorScheme="green"
            variant="outline"
            fontWeight="bold"
            borderRadius="xl"
            style={{
              top: '88%',
            }}
          >
            Evolve
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="2xl"
            p={4}
            colorScheme="green"
            variant="outline"
            fontWeight="bold"
            borderRadius="xl"
            onClick={handleEvolveTreeClick}
            style={{
              top: '88%',
            }}
          >
            <Text fontSize="2xl">Evolve</Text>
          </Button>
        </>
      );
    }
  };

  const getLastOutcomes = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);

    const data = await getMagicFlowerDetail(provider, magicflowerId);
    // console.log('magic flower data:', data);

    // setPlantName(data.meta.name);
    if (Object.keys(data.userDefault).length > 0) {
      if (
        'PlantSpirit' in data.userDefault &&
        data.userDefault['PlantSpirit'].imageUrl.length > 0
      ) {
        setPlantSpirit(data.userDefault['PlantSpirit'].imageUrl);
      }
    }

    if (data.lastDiceOutcomes.length > 0) {
      setLastDiceOutcomes(data.lastDiceOutcomes);
      getOutcome(data.lastDiceOutcomes[3]);
    }
  };

  const getOutcome = _lastDiceOutcome => {
    const _bad =
      _lastDiceOutcome == 4 || _lastDiceOutcome == 13 || _lastDiceOutcome == 14;
    let _outcome = _bad
      ? 'Unfortuantely, the last evovling history of your plant has been erased.'
      : 'Congratulations! You plant just evolved!';

    setOutcomeString(_outcome);
  };

  const RenderShowOutcomeButton = () => {
    const _title = showOutcome
      ? 'Hide Last Evovle Outcome'
      : 'Last Evovle Outcome';
    return (
      <HStack>
        <Button
          leftIcon={<GiButterfly w={8} h={8} color="green.500" />}
          rightIcon={<GiButterfly w={8} h={8} color="green.500" />}
          onClick={handleShowOutcome}
          variant="ghost"
          colorScheme="green"
        >
          <Text fontSize="lg" as="i">
            {' '}
            {_title}{' '}
          </Text>
        </Button>
      </HStack>
    );
  };

  return (
    <>
      <div
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
          }}
        >
          <ReactP5Wrapper
            sketch={p5 => EvolveOutcome(p5, plantSpirit)}
            outcomes={lastDiceOutcomes}
            rolling={rolling}
          />
        </div>
      </div>

      <VStack spacing={3} mt="5px">
        <MagicFlowerTopGrid />
        <EvolveTreeButton />
        <RenderShowOutcomeButton />
        {showOutcome && (
          <DiceOutcomeBoard
            lastDiceOutcome={lastDiceOutcomes[3]}
            t={outcomeString}
            magicflowerId={magicflowerId}
            magicflowerName={plantName}
          />
        )}
        <MagicFlowerMusic song="https://res.cloudinary.com/df9jvvwyw/video/upload/v1722216834/happy-guitar_q7e8vg.mp3" />
        <div style={{ marginTop: '58px' }}>
          <GameFooter />
        </div>
      </VStack>
    </>
  );
};

export default PlantEvolvingHistory;
