import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import MagicFlowers from '../../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import { makeMagicFlowerMetaUrl } from '../../../../../components/utils/mintHelpers';
import MagicFlowerMetaForm from '../../../../formComponents/MagicFlowerMetaForm';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';

const UpdateMagicFlowerMeta = ({
  magicflowerId,
  variantType = 'outline',
  fancy = false,
}) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [metadata, setMetadata] = useState('');

  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const magicFlowers = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_ADDRESS,
        MagicFlowers.abi,
        signer
      );

      const transaction = await magicFlowers.updateMeta(
        magicflowerId,
        metadata
      );

      await transaction.wait();

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          size="lg"
          as="button"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Update metadata
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          size="lg"
          as="button"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Update Metadata
        </Button>
      );
    }
  };

  const RenderModalButton = () => {
    if (fancy) {
      return (
        <Button
          size="lg"
          as="button"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
          onClick={onOpen}
        >
          Update Metadata
        </Button>
      );
    } else {
      return (
        <Button colorScheme="green" variant={variantType} onClick={onOpen}>
          Update Metadata
        </Button>
      );
    }
  };

  return (
    <>
      <>
        <RenderModalButton />

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Magic Flower NFT Metadata</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <MagicFlowerMetaForm
                metadata={metadata}
                setMetadata={setMetadata}
              />
            </ModalBody>

            <ModalFooter>
              <RenderButton mr={6} />
              <Button colorScheme="blue" variant="ghost" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
};

export default UpdateMagicFlowerMeta;
