import {
  Box,
  Link,
  Tabs,
  TabList,
  TabPanel,
  VStack,
  Tab,
  TabPanels,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { GiSunflower } from 'react-icons/gi';

import { HomeIcon } from '../../../../components/views/NFTs/elements/MiscDisplays';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import MintTokensBase from '../../../../components/actions/NFTs/mintTokensBase';
import GameFooter from '../../../../components/views/GameFooter';

const MintAndAddToGallery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const magicflowerId = searchParams.get('data');
  console.log('magicflowerId:', magicflowerId);

  return (
    <div>
      <VStack>
        <HomeIcon />
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList>
            <Tab>Mint Erc721 and Integrate In Magic Flowers</Tab>
            <Tab>Mint Erc1155 and Integrate In Magic Flowers</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box>
                <MintTokensBase tokenType="Erc721" specificGameTokenId={1} />
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <MintTokensBase tokenType="Erc1155" specificGameTokenId={1} />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <HStack>
          <Icon as={GiSunflower} w={8} h={8} color="yellow" />
          <Icon as={GiSunflower} w={8} h={8} color="yellow" />
          <Icon as={GiSunflower} w={8} h={8} color="yellow" />
        </HStack>
        <Box mt="30px">
          <Text fontSize="sm" as="i">
            Back to{' '}
            <Link
              color="teal.500"
              as={ReactLink}
              to={`/magicflowers/magicflower/${magicflowerId}`}
            >
              Magic Flowers NFT #{magicflowerId}
            </Link>
          </Text>
        </Box>
        <div style={{ marginTop: '58px' }}>
          <GameFooter />
        </div>
      </VStack>
    </div>
  );
};

export default MintAndAddToGallery;
