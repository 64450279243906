import React from 'react';
import { Link as ReactLink, useParams } from 'react-router-dom';
import {
  Box,
  Text,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  List,
  ListItem,
  ListIcon,
  Container,
  Icon,
  Button,
} from '@chakra-ui/react';
import { IoIosFlower } from 'react-icons/io';
import { GiSunflower } from 'react-icons/gi';
import AddGalleryMagicFlowers from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/AddGalleryMagicFlowers';
import SellMagicflowers from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/SellMagicflowers';
import UpdateMagicFlowerMeta from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/UpdateMagicFlowerMeta';
import GameFooter from '../../../../components/views/GameFooter';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid.js';
function MagicFlowerOwnerActions() {
  const { magicflowerId } = useParams();
  // console.log('magicflowerId:', magicflowerId);
  const MintAndAddToGalleryButton = () => {
    return (
      <Link
        as={ReactLink}
        to={`/magicflowers/mintgallery?data=${magicflowerId}`}
      >
        <Button colorScheme="green" variant="ghost">
          Mint and Integrate in Gallery
        </Button>
      </Link>
    );
  };
  return (
    <Box>
      <img
        src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718573535/artstudio7_vsdw3b.jpg"
        style={{
          width: '100%',
          height: 'auto',
        }}
        alt="Art Studio"
      />
      <Box>
        <MagicFlowerTopGrid />
      </Box>
      <Container style={{ marginTop: '58px' }}>
        <VStack spacing={8}>
          <Box>
            <Text
              color="green.300"
              fontSize={[30, 42, 58]}
              style={{ fontFamily: 'Lobster' }}
            >
              Dream Studio
            </Text>
          </Box>
          <Box>
            <Text
              color="green.300"
              fontSize={26}
              style={{ fontFamily: 'Lobster' }}
            >
              Magic Flowers NFT #{magicflowerId}
            </Text>
          </Box>
          <Icon as={GiSunflower} w={8} h={8} color="yellow" />
          {/* <HStack>
           
            <Icon as={GiSunflower} w={8} h={8} color="yellow" />
            <Icon as={GiSunflower} w={8} h={8} color="yellow" />
          </HStack> */}
          <Tabs
            variant="soft-rounded"
            colorScheme="green"
            size="lg"
            align="center"
          >
            <TabList>
              <Tab>Add Gallery</Tab>
              <Tab>Update Metadata</Tab>
              <Tab>Sell / Acution </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <List spacing={2}>
                  <ListItem>
                    <ListIcon as={IoIosFlower} color="green.500" />
                    <AddGalleryMagicFlowers />
                  </ListItem>
                  <ListItem>
                    <ListIcon as={IoIosFlower} color="green.500" />
                    <MintAndAddToGalleryButton />
                  </ListItem>
                </List>
              </TabPanel>
              <TabPanel>
                <VStack spacing={6}>
                  <Icon as={GiSunflower} w={8} h={8} color="yellow" />

                  <UpdateMagicFlowerMeta
                    magicflowerId={magicflowerId}
                    variantType="outline"
                  />
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack spacing={6}>
                  <Icon as={GiSunflower} w={8} h={8} color="yellow" />
                  <SellMagicflowers
                    magicflowerId={magicflowerId}
                    variantType="outline"
                  />
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
          {/* <Box>
            <Text color="green.300" fontSize="xl">
              Add NFTs to Magic Flowers NFT #{magicflowerId}
            </Text>
          </Box>

          <Wrap>
            <WrapItem>
              <AddGalleryMagicFlowers />
            </WrapItem>
            <WrapItem>
              <MintAndAddToGalleryButton />
            </WrapItem>
          </Wrap> */}

          <Box mt={6}>
            <Text fontSize="sm">
              Back to{' '}
              <Link
                color="teal.500"
                as={ReactLink}
                to={`/magicflowers/magicflower/${magicflowerId}`}
              >
                Magic Flowers NFT #{magicflowerId}
              </Link>
            </Text>
          </Box>
          <div style={{ marginTop: '58px' }}>
            <GameFooter />
          </div>
        </VStack>
      </Container>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default MagicFlowerOwnerActions;
