export const system = {
  angle_min: 20,
  angle_max: 25.7,
  axiom: 'X',
  rules: [
    [
      'X',
      [
        'F[+X]F[-X]+X',
        'F[+X][-X]FX',
        'F[-X][+X]FX',
        'F[+X]F[-X]+Y',
        'F[-X]F[-X]+Y',
        'F[+Y]F[-X]+X',
        'F[-Y]F[-X]+X',
        'F[+X]F[-Y]+X',
        'F[-X]F[-Y]+X',
        'F[+Y]F[-Y]+X',
        'F[-Y]F[-Y]+X',
        'F[+X]F[-Y]+Y',
        'F[-X]F[-Y]+Y',
        'F[+Y]F[-X]+Y',
        'F[-Y]F[-X]+Y',
        'F[+X][-X]F[-X]+X',
        'F[+X][-X]F[-X]+Y',
        'F[+Y][-X]F[-X]+X',
        'F[+X][-Y]F[-X]+X',
        'F[+X][-X]F[-Y]+X',
      ],
    ],
    [
      'Y',
      [
        'F+[+Y]-Y',
        'F-[Y]+Y',
        'F-[[Y]+Y]+F[+FY]-Y',
        'F-[[Y]+Y]+F[+FY]-[Y]+Y',
        'F-[Y]+F[+Y]-Y',
        'F-[Y]+F[+Y]-[[Y]-Y]',
        'F-[Y]+F[+Y]-[[Y]-[Y]+Y]',
        'F-[[Y]-Y]+F[+FY]-[Y]+Y',
        'F-[[Y]-Y]+F[+FY]-[Y]+F-[Y]+Y',
        'F-[Y]+F[+Y]-[[Y]-Y]+F-[Y]+F[+Y]-[Y]',
        'F-[[X]-X]+F[+FX]-Y',
        'F+[[X]-X]-F[+FX]-Y',
        'F+[[X]-X]-F[-FX]+Y',
        'F-[[X]-X]+F[-FX]+Y',
        'F-[[X]-X]+F[+FY]-X',
        'F+[[X]-X]-F[+FY]-X',
        'F+[[X]-X]-F[-FY]+X',
        'F-[[X]-X]+F[-FY]+X',
      ],
    ],
    ['F', ['FF', 'FF', 'FF', 'FF', 'F']],
  ],
};
