import {
  Box,
  Text,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  UnorderedList,
  Image,
} from '@chakra-ui/react';
import { FaImages } from 'react-icons/fa';
import { RiAuctionFill, RiPlantFill } from 'react-icons/ri';
import HowToPlayImg from '../../../../../resources/images/games/magicflowers/gameFeatures/9.png';
const HowToPlay = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  function ViewMore() {
    return (
      <>
        <Box as="button" onClick={onOpen} colorScheme="green">
          <Box boxSize="185px">
            <Image src={HowToPlayImg} alt="How to Play" />
          </Box>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>How To Play</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Connect Wallet</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text style={{ textAlign: 'left' }}>
                      Ensure your wallet is connected by clicking the 'Connect
                      Wallet' button in the top right corner. Select your
                      desired wallet from the dropdown menu. Once connected,
                      your wallet balance and address will be displayed.
                      Connection is facilitated through WalletConnect, a trusted
                      protocol for security.
                    </Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Revive an Original Progenitor Plant</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          Revive:
                        </Text>{' '}
                        Revive an original progenitor plant from a total of only
                        3,000 available. Choose between a tree type or a plant
                        type, and select the plant's sex—male, female, or
                        hermaphrodite. Each sex type has a cap of 1,000 plants.
                        You can only select a sex as long as the cap for that
                        sex has not been reached.
                      </ListItem>
                      <ListItem>
                        {' '}
                        <Text as="b" color="teal.500">
                          {' '}
                          Ownership
                        </Text>
                        : You own the Magic Flower plant you mint. You can use
                        it for hybridization, lease it to others, or evolve it.
                      </ListItem>
                      <ListItem>
                        {' '}
                        <Text as="b" color="teal.500">
                          {' '}
                          Limited Supply
                        </Text>
                        : There are only 3,000 original progenitor plants
                        available in total.{' '}
                      </ListItem>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          {' '}
                          Hybridization & Offspring
                        </Text>
                        : Only original progenitor plants and vigors can produce
                        offspring. Use your plant for hybridization, lease it,
                        or evolve it.{' '}
                      </ListItem>
                      <ListItem>
                        {' '}
                        <Text as="b" color="teal.500">
                          {' '}
                          Evolving
                        </Text>
                        : An original progenitor plant can be fast evolved once
                        before producing offspring. Each offspring produced
                        grants an additional chance for fast evolving.
                        Occasionally, a progenitor plant may gain limitless fast
                        evolving ability due to random mutation.
                      </ListItem>
                    </UnorderedList>
                    <Text style={{ textAlign: 'left' }}></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Produce a New Plant by Hybridization</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          Hybridization:
                        </Text>{' '}
                        Hybridize two parent plants to produce a new plant,
                        known as a hybrid. You will own the hybrid produced.
                      </ListItem>
                      <ListItem>
                        {' '}
                        <Text as="b" color="teal.500">
                          {' '}
                          Limitless Fast Evolving
                        </Text>
                        : Hybrids are mostly sterile but possess the ability for
                        indefinite fast evolving.
                      </ListItem>
                      <ListItem>
                        {' '}
                        <Text as="b" color="teal.500">
                          {' '}
                          Vigor
                        </Text>
                        A hybrid might become a vigor due to random mutation
                        during evolving.
                      </ListItem>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          {' '}
                          Requirements for Parent Plants
                        </Text>
                        : Parent plants used for hybridization must be either
                        progenitor plants or vigors. Two parent plants must be
                        of different sexes, though a hermaphrodite can pair with
                        a male, female, or another hermaphrodite. Hybridization
                        utilizes genes from any parent’s evolving history. To
                        hybridize a new plant, you need to own or lease the
                        parent plants.
                      </ListItem>
                    </UnorderedList>
                    <Text style={{ textAlign: 'left' }}></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Customize a Magic Flower Plant</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          Customization:
                        </Text>{' '}
                        View all your Magic Flower plants on the 'All My Magic
                        Flowers' page. Click on any plant to access its
                        dedicated page. Customize it by adding a plant spirit,
                        favorite animal (like your pet), or any other image. You
                        can also customize the background or butterflies
                        depending on your plant's level. Refer to the Magic
                        Flowers FAQ for detailed instructions.
                      </ListItem>
                    </UnorderedList>
                    <Text style={{ textAlign: 'left' }}></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Evolve a Magic Flower Plant</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          Evolving:
                        </Text>{' '}
                        From the 'All My Magic Flowers' page, navigate to the
                        individual page of a Magic Flower plant you own. Find
                        the star that fell from yesterday. Clicking on it will
                        take you to a place where you can rapidly evolve your
                        plant into a new form. Fast evolving might occasionally
                        trigger a mutation, transforming a hybrid into a vigor.
                        While hybrids have limitless fast evolving ability,
                        progenitor plants have limited fast evolving capacity.
                      </ListItem>
                    </UnorderedList>
                    <Text style={{ textAlign: 'left' }}></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>
                          Lease Progenitor Plant or Vigor for Hybridization
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          Leasing:
                        </Text>{' '}
                        If you want to hybridize a new plant but don’t own an
                        Original Progenitor Plant or Vigor, you can lease a
                        plant with reproductive ability from the Magic Flowers
                        Leasing Market. The leasing price is set by the owner of
                        the Magic Flower plant being leased and is displayed as
                        a fee for one-time use. You can order multiple uses as
                        needed.
                      </ListItem>
                    </UnorderedList>
                    <Text style={{ textAlign: 'left' }}></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>
                          List a Progenitor Plant or Vigor for Leasing
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          List for Leasing:
                        </Text>{' '}
                        If you own a progenitor plant or a vigor, you can lease
                        it to others for hybridizing a new plant. Leasing price
                        is for one-time use by default. Leasing your plant
                        brings you extra cash without impacting you. To lease
                        your plant, list it in the Magic Flowers Leasing Market.
                      </ListItem>
                    </UnorderedList>
                    <Text style={{ textAlign: 'left' }}></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Sell a Magic Flower Plant</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          Selling:
                        </Text>{' '}
                        All Magic Flowers plants are unique ERC721 NFTs. You can
                        sell your Magic Flower NFT in the marketplace or the
                        auction house.
                      </ListItem>
                    </UnorderedList>
                    <Text style={{ textAlign: 'left' }}></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Buy a Magic Flower Plant</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      <ListItem>
                        <Text as="b" color="teal.500">
                          Buying:
                        </Text>{' '}
                        Another way to own a Magic Flower NFT is by purchasing
                        it in the marketplace or auction house, instead of
                        minting a progenitor plant or hybridizing a new plant.
                      </ListItem>
                    </UnorderedList>
                    <Text style={{ textAlign: 'left' }}></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  return <ViewMore />;
};

export default HowToPlay;
