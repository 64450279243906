import { useEffect, useState } from 'react';
import {
  Button,
  Box,
  VStack,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Text,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { GiTwirlyFlower } from 'react-icons/gi';
import { FaInfoCircle } from 'react-icons/fa';
import { ethers } from 'ethers';
import MagicFlowers from '../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { useNavigate } from 'react-router-dom';
import GameFooter from '../../../../components/views/GameFooter.js';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid.js';
import {
  REACT_APP_MAGIC_FLOWERS_ADDRESS,
  REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
} from '../../../../components/views/cozyGames/constants/ContractAddress';
function RevivePlant() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  //   console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [plantType, setPlantType] = useState('1');
  const [value, setValue] = useState('1');
  const [disableRadio, setDisableRadio] = useState({
    1: false,
    2: false,
    3: false,
  });
  const [totalPlantsRevived, setTotalPlantsRevived] = useState({
    male: 0,
    female: 0,
    hermaphrodite: 0,
  });

  useEffect(() => {
    getItems();
  }, []);

  const setToTrue = key => {
    setDisableRadio(prevState => ({
      ...prevState,
      [key]: true,
    }));
  };

  const getItems = async () => {
    const _totalProgenitorsRevived = await getTotalProgenitorPlantsRevived();
    // console.log('_totalProgenitorsRevived:', _totalProgenitorsRevived);
    if (_totalProgenitorsRevived.male >= 1000) {
      setToTrue(1);
    }
    if (_totalProgenitorsRevived.female >= 1000) {
      setToTrue(2);
    }
    if (_totalProgenitorsRevived.hermaphrodite >= 1000) {
      setToTrue(3);
    }
    setTotalPlantsRevived(_totalProgenitorsRevived);
  };

  const getTotalProgenitorPlantsRevived = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const magicFlowers = new ethers.Contract(
      REACT_APP_MAGIC_FLOWERS_ADDRESS,
      MagicFlowers.abi,
      provider
    );
    const _totalPlantsRevived =
      await magicFlowers.totalProgenitorPlantsRevived();
    const totalPlantsRevived = {
      male: Number(_totalPlantsRevived._male.toString()),
      female: Number(_totalPlantsRevived._female.toString()),
      hermaphrodite: Number(_totalPlantsRevived._hermaphrodite.toString()),
    };

    return totalPlantsRevived;
  };

  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('signer:', signer);

    try {
      const magicFlowers = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_ADDRESS,
        MagicFlowers.abi,
        signer
      );

      const mintingFee = await magicFlowers.getMagicFlowersMintingFee(false); // 0.5 matic

      const transaction = await magicFlowers.mintTree(
        Number(plantType),
        Number(value),
        {
          value: mintingFee,
        }
      );

      await transaction.wait();

      // Create a promise to wait for the event
      const eventPromise = new Promise((resolve, reject) => {
        magicFlowers.once(
          'Revived',
          (magicflowerId, magicflowerOwner, event) => {
            console.log(`Plant revived by: ${magicflowerOwner}`);
            console.log(`Plant ID: ${magicflowerId}`);
            console.log(`Event: ${event}`);
            resolve(magicflowerId); // Resolve the promise with the plant ID
          }
        );

        // Set a timeout to reject the promise if the event is not received in a reasonable time
        setTimeout(() => {
          reject(new Error('Event timeout'));
        }, 60000); // 60 seconds timeout, adjust as needed
      });

      // Wait for the event to be captured
      const magicflowerId = await eventPromise;

      const navigateUrl = `/magicflowers/magicflower/${magicflowerId}`;

      setLoading(false);

      navigate(navigateUrl);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const RenderInfo = () => {
    return (
      <Popover>
        <PopoverTrigger>
          <Icon as={FaInfoCircle} w={6} h={6} color="green.500" />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>About Plant Sex</PopoverHeader>
          <PopoverBody>
            There are a total of 3000 progenitor plants: 1000 males, 1000
            females, and 1000 hermaphrodites. If all plants of a specific sex
            have been revived, that option will be greyed out and unavailable
            for selection.
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Revive
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          size="lg"
          colorScheme="green"
          variant="outline"
        >
          Revive
        </Button>
      );
    }
  };

  return (
    <>
      <Box>
        <MagicFlowerTopGrid />
      </Box>
      <VStack mt={2} spacing={6}>
        <Text
          color="green.300"
          fontSize={[36, 42, 58]}
          style={{ fontFamily: 'Lobster' }}
        >
          Revive Magic Flowers
        </Text>

        {loading && (
          <img
            src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1722176501/revive_d1p6ma.gif"
            alt="Magic Forest"
          />
        )}

        {!loading && (
          <img
            src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1722176398/deadforest_bkspyt.gif"
            alt="Dead Forest"
          />
        )}
        <Card boxShadow="2xl" rounded="md">
          <CardHeader>
            <VStack mb="20px">
              <Icon as={GiTwirlyFlower} w={8} h={8} color="green.500" />{' '}
            </VStack>
            <HStack>
              <Text fontSize="xl"> Select the plant you wish to revive:</Text>
              <RenderInfo />{' '}
            </HStack>
          </CardHeader>
          <CardBody>
            <Box>
              <Box mb="20px">
                <FormControl>
                  <FormLabel>Choose the type of plant:</FormLabel>
                  <RadioGroup
                    onChange={setPlantType}
                    value={plantType}
                    colorScheme="green"
                  >
                    <Stack direction="row">
                      <Radio value="1">Magic Flower Tree</Radio>
                      <Radio value="2">Magic Flower Plant</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Select the sex of the plant :</FormLabel>

                  <RadioGroup
                    onChange={setValue}
                    value={value}
                    colorScheme="green"
                  >
                    <Stack direction="row">
                      <Radio value="1" isDisabled={disableRadio['1']}>
                        Male
                      </Radio>
                      <Radio value="2" isDisabled={disableRadio['2']}>
                        Female
                      </Radio>
                      <Radio value="3" isDisabled={disableRadio['3']}>
                        Hermaphrodite
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </CardBody>
          <CardFooter>
            <RenderButton />
          </CardFooter>
        </Card>
        <Card></Card>

        <div style={{ marginTop: '58px' }}>
          <GameFooter />
        </div>
      </VStack>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default RevivePlant;
