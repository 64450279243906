import { ethers } from 'ethers';
import axios from 'axios';
import Games from '../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import { getCidUrlNftstorage } from '../../../utils/helpers';
import { REACT_APP_GAMES_ADDRESS } from '../../cozyGames/constants/ContractAddress';

const getAllGamesOwnedBy = async (provider, _user) => {
  const games = new ethers.Contract(
    REACT_APP_GAMES_ADDRESS,
    Games.abi,
    provider
  );

  const _allGames = await games.allGamesOf(_user);
  const allGames = _allGames.map(num => Number(num.toString()));
  // console.log('allGames:', allGames);
  let allGamesOwned = [];

  for (let i = 0; i < allGames.length; i++) {
    const gameId = allGames[i];
    const gameInfo = await games.getGameProperties(gameId);
    const _url = getCidUrlNftstorage(gameInfo.gameUrl);
    let metadata = await axios.get(_url);
    // console.log('metadata:', metadata.data);
    metadata = metadata.data;

    let gameName = metadata.name;
    let posterUrl = metadata.posterUrl;
    let videoUrl = metadata.videoUrl;
    // let imageUrl = metadata.imageUrl;

    allGamesOwned.push({
      gameId,
      // imageUrl,
      posterUrl,
      videoUrl,
      gameName,
    });
  }
  // console.log('allGamesOwned:', allGamesOwned);
  return allGamesOwned;
};

export default getAllGamesOwnedBy;
