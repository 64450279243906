import { useParams } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import Collections from '../../../contracts/APIs/CollectionsInterface.sol/CollectionsInterface.json';
import {
  Link,
  VStack,
  Box,
  Wrap,
  Text,
  Button,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { parseBigNum2DArray } from '../../../components/utils/helpers';
import getTokenURLandTokenType from '../../../components/utils/getTokenURLandTokenType';
import StickyNote from '../../../resources/images/frames/stickyNotes/stickyNote5.png';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
import { HomeIcon } from '../../../components/views/NFTs/elements/MiscDisplays';
import getTokenContract from '../../../components/utils/getTokenContract';
import { REACT_APP_COLLECTIONS_ADDRESS } from '../../../components/views/cozyGames/constants/ContractAddress'
function Collection() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const { collectionId } = useParams();

  const [item, setItem] = useState({});
  const [collectionMetadata, setCollectionMetadata] = useState({});
  const [tokensInCollection, setTokensInCollection] = useState([]);
  const [showDescription, setShowDescription] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log('collectionId:', collectionId);
    getItem(collectionId);
  }, [collectionId]);

  const getItem = async _collectionId => {
    await getCollection(_collectionId);
  };

  const getCollection = async _collectionId => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    // const signer = provider.getSigner();

    const collectionsContract = new ethers.Contract(
      REACT_APP_COLLECTIONS_ADDRESS,
      Collections.abi,
      provider
    );

    const _collection = await collectionsContract.getCollection(_collectionId);
    // console.log('collection:', _collection);
    const collectionName = _collection._collectionName;
    let isCreatorCollection,
      collectionId,
      collectionMetadata,
      collectionTokenContracts,
      collectionOwner;
    let _tokensInCollection = [];
    if (_collection._isDeleted) {
      // console.log('isDeleted:', isDeleted);
    } else {
      isCreatorCollection = _collection._isCreatorCollection;

      const _collectionMetadata = await axios.get(_collection._collectionUrl);
      collectionMetadata = _collectionMetadata.data;
      // console.log('collectionMetadata:', collectionMetadata);

      collectionTokenContracts = _collection._tokenContracts;
      collectionOwner = _collection._collectionOwner;
      let allTokenIds = _collection._allTokenIds;
      // console.log('allTokenIds:', allTokenIds);
      allTokenIds = parseBigNum2DArray(allTokenIds);
      // console.log('allTokenIds:', allTokenIds);

      for (let m = 0; m < collectionTokenContracts.length; m++) {
        let tokenContract = getTokenContract(
          collectionTokenContracts[m],
          provider
        );
        let _tokenIds = allTokenIds[m];
        for (let i = 0; i < _tokenIds.length; i++) {
          const tokenId = _tokenIds[i];
          let tokenType;
          const _res = await getTokenURLandTokenType(tokenContract, tokenId);
          // console.log('_res:', _res);
          let _tokenUri = _res.cid;
          tokenType = _res.tokenType;

          // console.log('tokenUri:', _tokenUri);
          const metadata =
            _tokenUri.length > 0 ? await axios.get(_tokenUri) : {};
          // console.log('metadata:', metadata.data);

          _tokensInCollection.push({
            tokenContract,
            tokenId,
            metadata: metadata.data,
          });
        }
      }

      // console.log('tokensInCollection:', tokensInCollection);
    }

    setItem({
      isCreatorCollection,
      collectionId,
      collectionOwner,
      collectionName,
    });
    setCollectionMetadata(collectionMetadata);
    setTokensInCollection(_tokensInCollection);
  };

  const handleShowDescription = () => {
    if (showDescription) {
      setShowDescription(false);
    } else {
      setShowDescription(true);
    }
  };

  const DescriptionFrame = () => {
    const _title = showDescription ? 'Hide Description' : 'Description';
    return (
      <Box as="button" onClick={handleShowDescription}>
        <div
          style={{ position: 'relative', textAlign: 'center', color: 'white' }}
        >
          <img src={StickyNote} style={{ width: '100%' }} />

          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '47%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <VStack>
              <Text
                color="teal.500"
                fontSize="4xl"
                style={{ fontFamily: 'Lobster' }}
              >
                {_title}
              </Text>
            </VStack>
          </div>
        </div>
      </Box>
    );
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="green"
          variant="outline"
          size="md"
          mr={3}
        >
          Buy NFT
        </Button>
      );
    } else {
      return (
        <Button colorScheme="green" variant="outline" size="md" mr={3}>
          Buy NFT
        </Button>
      );
    }
  };

  const RenderCollectionType = () => {
    const _text = item.isCreatorCollection
      ? 'Creator Collection'
      : 'Seller Collection';
    return (
      <>
        <Text as="i" color="teal.500" fontSize="lg">
          {_text}
        </Text>
        <Text as="i" color="teal.500">
          by {item.collectionOwner}
        </Text>
      </>
    );
  };

  return (
    <VStack>
      <HomeIcon />
      <img
        src={collectionMetadata.image}
        alt={collectionMetadata.image}
        style={{ width: '250px' }}
      />
      <Box textAlign="center" alignItems="baseline">
        <Tooltip
          label={'collection ID: ' + collectionId}
          aria-label="A tooltip"
        >
          <Text
            fontSize={[36, 42, 68]}
            color="teal.500"
            style={{ fontFamily: 'Lobster' }}
          >
            {item.collectionName}
          </Text>
        </Tooltip>
      </Box>

      <RenderCollectionType />

      <Box>
        <DescriptionFrame />
      </Box>

      {showDescription && (
        <Box
          textAlign="center"
          alignItems="baseline"
          style={{
            padding: '10px',
            border: 'dashed 5px hsla(120, 100%, 60%, 1)',
          }}
        >
          <VStack>
            <Box>{collectionMetadata.description}</Box>
          </VStack>
        </Box>
      )}

      <Text
        fontSize={[24, 36, 42]}
        color="teal.500"
        style={{ fontFamily: 'Lobster' }}
      >
        Tokens in Collection
      </Text>
      <Wrap spacing={4}>
        {tokensInCollection.length &&
          tokensInCollection.map((token, key) => (
            <Link
              key={key}
              as={ReactLink}
              to={`/tokens/${token.tokenContract.address}/${token.tokenId}`}
            >
              <Box
                textAlign="center"
                maxW="sm"
                style={{
                  display: 'grid',
                  minHeight: '200px',
                  border: '8px solid',
                  padding: '1rem',
                  borderImage:
                    'linear-gradient(45deg, turquoise, greenyellow) 1',
                  marginTop: '20px',
                }}
              >
                <Image
                  src={token.metadata.image}
                  wrapped
                  ui={false}
                  style={{ width: '250px' }}
                />
                {token.category === 'Music' && (
                  <div>
                    <audio controls>
                      <source src={token.metadata.animation_url} />
                    </audio>
                  </div>
                )}
                <Text fontSize="2xl" style={{ fontFamily: 'Lobster' }}>
                  {token.metadata.name}
                </Text>
              </Box>
            </Link>
          ))}
      </Wrap>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default Collection;
