class Dice {
  constructor(p5, canvas, diceSize) {
    this.p5 = p5;
    this.canvas = canvas;
    this.diceSize = diceSize;
    this.side = 0;
  }
  drawDice() {
    // Clear diceCanvas
    this.canvas.background(0, 0);

    // Dice
    this.canvas.noStroke();
    this.canvas.fill(0, 0, 153);
    this.canvas.rectMode(this.p5.CENTER);
    this.canvas.rect(
      this.canvas.width / 2,
      this.canvas.height / 2,
      this.diceSize,
      this.diceSize,
      this.diceSize / 5
    );

    // Dots
    this.canvas.fill(102, 255, 102);

    if (this.side == 1 || this.side == 3 || this.side == 5)
      this.canvas.ellipse(
        this.canvas.width / 2,
        this.canvas.height / 2,
        this.diceSize / 5,
        this.diceSize / 5
      );
    if (
      this.side == 2 ||
      this.side == 3 ||
      this.side == 4 ||
      this.side == 5 ||
      this.side == 6
    ) {
      this.canvas.ellipse(
        this.canvas.width / 2 - this.diceSize / 4,
        this.canvas.height / 2 - this.diceSize / 4,
        this.diceSize / 5,
        this.diceSize / 5
      );
      this.canvas.ellipse(
        this.canvas.width / 2 + this.diceSize / 4,
        this.canvas.height / 2 + this.diceSize / 4,
        this.diceSize / 5,
        this.diceSize / 5
      );
    }
    if (this.side == 4 || this.side == 5 || this.side == 6) {
      this.canvas.ellipse(
        this.canvas.width / 2 - this.diceSize / 4,
        this.canvas.height / 2 + this.diceSize / 4,
        this.diceSize / 5,
        this.diceSize / 5
      );
      this.canvas.ellipse(
        this.canvas.width / 2 + this.diceSize / 4,
        this.canvas.height / 2 - this.diceSize / 4,
        this.diceSize / 5,
        this.diceSize / 5
      );
    }
    if (this.side == 6) {
      this.canvas.ellipse(
        this.canvas.width / 2,
        this.canvas.height / 2 - this.diceSize / 4,
        this.diceSize / 5,
        this.diceSize / 5
      );
      this.canvas.ellipse(
        this.canvas.width / 2,
        this.canvas.height / 2 + this.diceSize / 4,
        this.diceSize / 5,
        this.diceSize / 5
      );
    }
  }
  win(winNum) {
    this.side = winNum;
    this.drawDice();
  }

  roll() {
    this.side = this.p5.int(this.p5.random(1, 7));
    this.drawDice();
  }
}

export default Dice;
