const getDegree = flowerType => {
  const degreeMap = {
    109: 15,
    110: 30,
    111: 45,
    112: 60,
    113: 75,
    114: 90,
  };

  return degreeMap[flowerType];
};
export function drawFlowerGenus2(p5, x, y, p, flowerType) {
  const scaleFactor = 0.68;
  p5.push();
  p5.noStroke();
  p5.translate(x, y);
  p5.scale(scaleFactor);
  const degree = getDegree(flowerType); //15;
  p5.fill(p.fc.h, p.fc.s, p.fc.b, 0.388);
  for (let i = 0; i < 360; i += degree) {
    p5.ellipse(20, 0, 40, degree / 2);
    p5.rotate(degreesToRadians(degree));
  }

  p5.fill(p.sc.h, p.sc.s, p.sc.b, 0.88);
  p5.ellipse(0, 0, 15 * scaleFactor, 15 * scaleFactor);
  p5.pop();
}

export function drawFlowerGenus2_treeCanvas(p5, treeCanvas, x, y, p, flowerType) {
  const scaleFactor = 0.68;
  treeCanvas.push();
  treeCanvas.noStroke();
  treeCanvas.translate(x, y);
  treeCanvas.scale(scaleFactor);
  const degree = getDegree(flowerType); //15;
  treeCanvas.fill(p.fc.h, p.fc.s, p.fc.b, 0.388);
  for (let i = 0; i < 360; i += degree) {
    treeCanvas.ellipse(20, 0, 40, degree / 2);
    treeCanvas.rotate(degreesToRadians(degree));
  }

  treeCanvas.fill(p.sc.h, p.sc.s, p.sc.b, 0.88);
  treeCanvas.ellipse(0, 0, 15 * scaleFactor, 15 * scaleFactor);
  treeCanvas.pop();
}

function degreesToRadians(degrees) {
  var radians = degrees * (Math.PI / 180);
  return radians;
}
