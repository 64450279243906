export const kFactors = {
  flower1: [5, 3],
  flower2: [6, 3],
  flower3: [7, 3],
  flower4: [8, 3],
  flower5: [9, 3],
  flower6: [10, 3],
  flower7: [11, 3],
  flower8: [12, 3],
  flower9: [13, 3],
  flower10: [14, 3],
  flower11: [15, 3],
  flower12: [16, 3],
  flower13: [17, 3],
  flower14: [18, 3],
  flower15: [19, 3],
  flower16: [20, 3],
  flower17: [6, 4],
  flower18: [7, 4],
  flower19: [8, 4],
  flower20: [9, 4],
  flower21: [10, 4],
  flower22: [11, 4],
  flower23: [12, 4],
  flower24: [13, 4],
  flower25: [14, 4],
  flower26: [15, 4],
  flower27: [16, 4],
  flower28: [17, 4],
  flower29: [18, 4],
  flower30: [19, 4],
  flower31: [20, 4],
  flower32: [6, 5],
  flower33: [7, 5],
  flower34: [8, 5],
  flower35: [9, 5],
  flower36: [10, 5],
  flower37: [11, 5],
  flower38: [12, 5],
  flower39: [13, 5],
  flower40: [14, 5],
  flower41: [15, 5],
  flower42: [16, 5],
  flower43: [17, 5],
  flower44: [18, 5],
  flower45: [19, 5],
  flower46: [20, 5],
  flower47: [7, 6],
  flower48: [8, 6],
  flower49: [9, 6],
  flower50: [10, 6],
  flower51: [11, 6],
  flower52: [12, 6],
  flower53: [13, 6],
  flower54: [14, 6],
  flower55: [15, 6],
  flower56: [16, 6],
  flower57: [17, 6],
  flower58: [18, 6],
  flower59: [19, 6],
  flower60: [20, 6],
  flower61: [11, 7],
  flower62: [12, 7],
  flower63: [13, 7],
  flower64: [14, 7],
  flower65: [15, 7],
  flower66: [16, 7],
  flower67: [17, 7],
  flower68: [18, 7],
  flower69: [19, 7],
  flower70: [20, 7],
  flower71: [15, 8],
  flower72: [18, 8],
  flower73: [19, 8],
  flower74: [15, 9],
  flower75: [17, 9],
  flower76: [15, 10],
  flower77: [16, 10],
  flower78: [19, 10],
  flower79: [20, 1],
  // flower80: [19, 1],
  flower80: [4, 2],
  flower81: [3, 2],
  flower82: [18, 1],
  // flower82: [17, 1],
  flower83: [16, 1],
  flower84: [15, 1],
  flower85: [14, 1],
  flower86: [13, 1],
  flower87: [12, 1],
  flower88: [11, 1],
  flower89: [10, 1],
  flower90: [9, 1],
  flower91: [8, 1],
  flower92: [7, 1],
  flower93: [6, 1],
  flower94: [5, 1],
  flower95: [4, 1],
  flower96: [3, 1],
  flower97: [2, 1],
  flower98: [20, 2],
  flower99: [19, 2],
  flower100: [18, 2],
  flower101: [17, 2],
  flower102: [16, 2],
  flower103: [12, 2],
  flower104: [11, 2],
  flower105: [10, 2],
  flower106: [8, 2],
  flower107: [7, 2],
  flower108: [6, 2],
};
