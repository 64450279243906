import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  HStack,
  theme,
  VStack,
  Text,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import ScrollToTop from './components/views/ScrollToTop';
import Footer from './components/views/Footer';
import CreatorInfo from './components/views/CreatorInfo';
import Layout from './components/views/Layout';
import Home from './pages/Home.js';
import Marketplace from './pages/NFTs/marketplace/Marketplace';
import AuctionHouse from './pages/NFTs/auctions/AuctionHouse';
import Auction from './pages/NFTs/auctions/Auction';
import DreamMintingStudio from './pages/NFTs/actions/DreamMintingStudio';
import MintErc721 from './pages/NFTs/actions/mintErc721';
import MintErc1155 from './pages/NFTs/actions/mintErc1155';
import ListErc721 from './pages/NFTs/actions/listErc721';
import ListErc1155 from './pages/NFTs/actions/listErc1155';
import CollectionAction from './pages/NFTs/actions/collectionAction';
import FeaturingAction from './pages/NFTs/actions/featuringAction';
import GameAction from './pages/NFTs/actions/gameAction.js';
import User from './pages/NFTs/personage/User';
import Contact from './pages/misc/Contact';
import NoPage from './pages/misc/NoPage.js';
import GameNFTs from './pages/NFTs/gameNfts/GameNFTs';
import AddGame from './pages/NFTs/gameNfts/AddGame';
import IntegrateTokenInGame from './pages/NFTs/gameNfts/IntegrateTokenInGame.js';
import MagicFlowersNFT from './pages/cozyGames/cozyGame1/magicFlowers/MagicFlowersNFT';
import MagicFlower from './pages/cozyGames/cozyGame1/magicFlowers/MagicFlower';
import AllMyMagicFlowers from './pages/cozyGames/cozyGame1/magicFlowers/AllMyMagicFlowers.js';
import MintAndAddToGallery from './pages/cozyGames/cozyGame1/magicFlowers/mintAndAddToGallery';
import Collection from './pages/NFTs/collection/Collection';
// import Exhibition from './pages/NFTs/exhibition/Exhibition';
// import AllExhibitions from './pages/NFTs/exhibition/AllExhibitions';
import TokenByUser from './pages/NFTs/token/TokenByUser';
import TokenGeneral from './pages/NFTs/token/TokenGeneral';
import Artist from './pages/NFTs/personage/Artist';
import SellerShop from './pages/NFTs/personage/SellerShop';
import About from './pages/misc/About';
import FAQ from './pages/misc/FAQ';
import TermsAndConditions from './pages/misc/TermsAndConditions';
import MintAndIntegrate from './pages/NFTs/gameNfts/MintAndIntegrate.js';
import IntegrateTokensForGame from './pages/NFTs/gameNfts/IntegrateTokensForGame.js';
import PlantEvolvingHistory from './pages/cozyGames/cozyGame1/magicFlowers/PlantEvolvingHistory.js';
import MyGallery from './pages/cozyGames/cozyGame1/magicFlowers/MyGallery.js';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import ConnectButton from './walletConnections/ConnectButton.js';
import MarketplaceItemErc1155Page from './pages/NFTs/marketplace/MarketplaceItemErc1155Page.js';
import MarketplaceItemErc721Page from './pages/NFTs/marketplace/MarketplaceItemErc721Page.js';
import MyArtistPage from './pages/NFTs/personage/MyArtistPage.js';
import MySellerShopPage from './pages/NFTs/personage/MySellerShop.js';
import MagicFlowerOwnerActions from './pages/cozyGames/cozyGame1/magicFlowers/MagicFlowerOwnerActions.js';
import AdminPage from './pages/misc/AdminPage';
import MagicFlowerTrial from './pages/cozyGames/cozyGame1/magicFlowers/MagicFlowerTrial.js';
import MembershipPage from './pages/misc/MembershipPage.js';
import NavBreadCrumb from './components/views/NavBreadCrumb.js';
import SereneNFTbazaar from './pages/SereneNFTbazaar.js';
import BlockchainGames from './pages/BlockchainGames.js';
import DigitalGames from './pages/DigitalGames.js';
import AllCozyGames from './pages/AllCozyGames.js';
import AboutMagicFlowers from './pages/cozyGames/cozyGame1/magicFlowers/AboutMagicFlowers.js';
import MagicFlowersFAQ from './pages/cozyGames/cozyGame1/magicFlowers/MagicFlowersFAQ.js';
import CollectionJsonCreator from './pages/misc/CollectionJsonCreator.js';
import NFTmetaJsonCreator from './pages/misc/NFTmetaJsonCreator.js';
import JsonCreator from './pages/misc/JsonCreator.js';
import ParentPlantLeasingMarket from './pages/cozyGames/cozyGame1/magicFlowers/ParentPlantLeasingMarket.js';
import MagicFlowerLease from './pages/cozyGames/cozyGame1/magicFlowers/MagicFlowerLease.js';
import MarketplaceCozyGame from './pages/cozyGames/nftSales/MarketplaceCozyGame.js';
import AuctionHouseCozyGame from './pages/cozyGames/nftSales/AuctionHouseCozyGame.js';
import GamesCompatibleMetaJsonCreator from './pages/misc/GamesCompatibleMetaJsonCreator.js';
import RevivePlant from './pages/cozyGames/cozyGame1/magicFlowers/RevivePlant.js';
import HybridizePlants from './pages/cozyGames/cozyGame1/magicFlowers/HybridizePlants.js';
import AllMagicFlowers from './pages/cozyGames/cozyGame1/magicFlowers/AllMagicFlowers.js';

// 1. Get projectId
const projectId = '8ac02dccb5232937b92c2defd5ca5fc5'; //process.env.REACT_APP_WEB3_PROJECT_ID;

// 2. Set chains
const mainnet = {
  chainId: 80002,
  name: 'Local',
  currency: 'MATIC',
  explorerUrl: 'https://www.oklink.com/amoy',
  rpcUrl: 'https://rpc-amoy.polygon.technology/',
};

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'http://localhost:3000/', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function AppLocation() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isGameRoute = location.pathname.startsWith('/magicflowers');
  const showWallet =
    location.pathname === '/user' ||
    location.pathname.startsWith('/marketplace') ||
    location.pathname.startsWith('/auctions') ||
    location.pathname.startsWith('/dreamstudio') ||
    location.pathname.startsWith('/create') ||
    location.pathname.startsWith('/mypage') ||
    location.pathname.startsWith('/tokens') ||
    location.pathname.startsWith('/membership') ||
    location.pathname.startsWith('/collections') ||
    location.pathname.startsWith('/shops') ||
    location.pathname.startsWith('/artists');
  // const isMagicflowerIdRoute =
  //   location.pathname.startsWith('/magicflowers/') && !isMagicflowersRoute;
  // const isMagicflowerIdRoute =
  //   location.pathname.startsWith('/magicflowers') && !isMagicflowersRoute;
  return (
    <ChakraProvider theme={theme}>
      {/* <RouteComponent justifySelf="flex-end"
      style={{
        position: 'absolute',
        top: '0%',
        left: '0%',
      }}/> */}

      <Routes>
        <Route path="/" element={<Layout isGameRoute={isGameRoute} />}>
          <Route index element={<Home />} />
          {/* <Route path='/:id' element={<Game />} /> */}
          {/* <Route path="dicenft" element={<DiceNfts />} /> */}
          <Route path="blockchaingames" element={<BlockchainGames />} />
          <Route path="digitalgames" element={<DigitalGames />} />
          <Route path="allcozygames" element={<AllCozyGames />} />
          <Route path="nftbazaar" element={<SereneNFTbazaar />} />
          <Route path="marketplace" element={<Marketplace />} />
          <Route
            path="/marketplace/erc721/:itemId"
            element={<MarketplaceItemErc721Page />}
          />
          <Route
            path="/marketplace/erc1155/:itemId"
            element={<MarketplaceItemErc1155Page />}
          />
          <Route path="auctions" element={<AuctionHouse />} />
          <Route path="/auctions/:itemId" element={<Auction />} />
          <Route path="/dreamstudio" element={<DreamMintingStudio />} />
          <Route path="/create/mintErc721" element={<MintErc721 />} />
          <Route path="/create/listErc721" element={<ListErc721 />} />
          <Route path="/create/mintErc1155" element={<MintErc1155 />} />
          <Route path="/create/listErc1155" element={<ListErc1155 />} />
          <Route path="/create/gameintegration" element={<GameAction />} />
          <Route
            path="/create/collectionaction"
            element={<CollectionAction />}
          />
          <Route path="/create/featuringaction" element={<FeaturingAction />} />
          <Route path="/gamenfts" element={<GameNFTs />} />
          <Route path="/gamenfts/addgame" element={<AddGame />} />

          <Route
            path="/magicflowers/marketplace"
            element={<MarketplaceCozyGame />}
          />
          <Route
            path="/magicflowers/auctions"
            element={<AuctionHouseCozyGame />}
          />
          <Route
            path="/gamelist/addgallery"
            element={<IntegrateTokenInGame />}
          />
          <Route path="/gamelist/mintgallery" element={<MintAndIntegrate />} />
          <Route
            path="/gamelist/integratetokens"
            element={<IntegrateTokensForGame />}
          />
          <Route path="/magicflowers" element={<MagicFlowersNFT />} />
          <Route
            path="/magicflowers/allmymagicflowers"
            element={<AllMyMagicFlowers />}
          />
          <Route
            path="/magicflowers/allmagicflowers"
            element={<AllMagicFlowers />}
          />
          <Route path="/magicflowers/revive" element={<RevivePlant />} />

          <Route path="/magicflowers/hybridize" element={<HybridizePlants />} />

          <Route
            path="/magicflowers/magicflower/:magicflowerId"
            element={<MagicFlower />}
          />

          <Route
            path="/magicflowers/gallery/:magicflowerId"
            element={<MyGallery />}
          />
          <Route
            path="/magicflowers/history/:magicflowerId"
            element={<PlantEvolvingHistory />}
          />
          <Route
            path="/magicflowers/owneractions/:magicflowerId"
            element={<MagicFlowerOwnerActions />}
          />

          <Route
            path="/magicflowers/mintgallery"
            element={<MintAndAddToGallery />}
          />

          <Route
            path="/magicflowers/magicflowerstrial"
            element={<MagicFlowerTrial />}
          />

          <Route
            path="/magicflowers/leasingmarket"
            element={<ParentPlantLeasingMarket />}
          />

          <Route
            path="/magicflowers/leasingmarket/:magicflowerId"
            element={<MagicFlowerLease />}
          />

          <Route path="/magicflowers/about" element={<AboutMagicFlowers />} />
          <Route path="/magicflowers/faq" element={<MagicFlowersFAQ />} />

          <Route path="user" element={<User />} />
          <Route path="/artists/:artistAddress" element={<Artist />} />
          <Route
            path="/mypage/artists/:artistAddress"
            element={<MyArtistPage />}
          />
          <Route path="/shops/:shopOwnerAddress" element={<SellerShop />} />
          <Route
            path="/mypage/shops/:shopOwnerAddress"
            element={<MySellerShopPage />}
          />
          <Route path="/collections/:collectionId" element={<Collection />} />
          {/* <Route
            path="/collections/creator/:collectionOwner/:tokenContractAddress/:tokenId"
            element={<CreatorCollection />}
          />
          <Route
            path="/collections/seller/:collectionOwner/:tokenContractAddress/:tokenId"
            element={<SellerCollection />}
          /> */}
          {/* <Route path="/exhibitions" element={<AllExhibitions />} />
          <Route path="/exhibitions/:exhibitionId" element={<Exhibition />} /> */}
          <Route
            path="/tokens/:tokenContractAddress/:tokenId"
            element={<TokenGeneral />}
          />
          <Route
            path="/tokens/tokensbyuser/:tokenContractAddress/:tokenId/:user"
            element={<TokenByUser />}
          />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="termsandconditions" element={<TermsAndConditions />} />
          <Route path="membership" element={<MembershipPage />} />

          <Route path="admin" element={<AdminPage />} />
          <Route path="jsoncreator" element={<JsonCreator />} />
          <Route path="jsoncreator/nft" element={<NFTmetaJsonCreator />} />
          <Route
            path="jsoncreator/collection"
            element={<CollectionJsonCreator />}
          />
          <Route
            path="jsoncreator/gamescompatible"
            element={<GamesCompatibleMetaJsonCreator />}
          />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>

      {!isGameRoute && (
        <Box
          style={{
            position: 'absolute',
            top: '0%',
            right: '0%',
          }}
        >
          <HStack spacing={3}>
            <ColorModeSwitcher justifySelf="flex-end" />

            {showWallet && <ConnectButton />}
          </HStack>
        </Box>
      )}

      {/* <ColorModeSwitcher
        justifySelf="flex-end"
        style={{
          position: 'absolute',
          top: '0%',
          right: '18%',
        }}
      /> */}
      <ScrollToTop />
      <VStack>
        {!isGameRoute && (
          <Box mb="8px" mt="58px">
            <NavBreadCrumb />
          </Box>
        )}
        {!isGameRoute && (
          <Box mb="8px">
            <CreatorInfo />
          </Box>
        )}

        <Box>
          {location.pathname != '/user' &&
            !location.pathname.startsWith('/gamelist/') && <Footer />}
        </Box>
      </VStack>

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </ChakraProvider>
  );
}

function App() {
  return (
    <Router>
      <AppLocation />
    </Router>
  );
}

export default App;
