import { Text, VStack, Box } from '@chakra-ui/react';

function RenderAboutMagicFlowers() {
  return (
    <VStack spacing={6}>
      <Box>
        <Text
          color="green.400"
          fontSize={[26, 36, 47]}
          style={{ fontFamily: 'Lobster' }}
        >
          About Magic Flowers
        </Text>
      </Box>
      <Box>
        <Text color="green.400" fontSize={['md', 'lg', '2xl']}>
          -- Cozy Game NFTs
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          In a world overwhelmed by constant noise and chaos, moments of true
          peace can seem like precious gems. That's where Magic Flowers NFT
          steps in – offering a serene sanctuary from the hustle and bustle of
          daily life. It's more than just a tranquil escape; it's an invitation
          to rediscover the beauty and boundless potential within every moment.
        </Text>
      </Box>
      <img
        src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370407/lotus_gmssar.gif"
        alt="lotus"
      />
      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          An Evolving NFT Experience
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          Magic Flowers NFT transcends the ordinary – it's a portal to a realm
          where tranquility intertwines with excitement, and digital art takes
          on a life of its own. Picture owning a tree, gracefully rooted amidst
          the ever-changing landscape of the digital world. But here's the
          enchanting twist – this tree isn't static. It's a canvas for endless
          surprises and growth, evolving into something truly extraordinary with
          each passing moment. With Magic Flowers, every interaction is an
          adventure, as your tree blossoms and transforms in unforeseen ways.
        </Text>
      </Box>
      <img
        src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370630/mandala_svhouw.gif"
        alt="mandala"
      />
      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Seamless Marketplace Integration and Platform for Creativity
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          Yet, Magic Flowers NFT offers more than just aesthetic bliss; it's a
          haven for unleashing creativity and self-expression. Our integrated
          dream minting studio, marketplace, and auction house not only simplify
          the journey of crafting, selling, acquiring, and auctioning Magic
          Flowers but also provide a fertile ground for nurturing your artistic
          spirit.
        </Text>
      </Box>
      <img
        src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370758/butterfly_ox0ftc.gif"
        alt="Butterflies"
      />
      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Embark on the Digital Odyssey
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          So, why not join us on this digital odyssey? Welcome to Magic Flowers
          – where the magic is real, the changes are permanent, and the
          permanent changes are magical! Experience the tranquility, embrace the
          excitement, and be a part of something truly extraordinary.
        </Text>
      </Box>
      <img
        src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370825/cat1_edquhz.gif"
        alt="magic flowers and cat"
      />
      <Box>
        <Text fontSize="lg" as="b">
          Ready to embark on this journey with us? Support our Indiegogo
          campaign now!
        </Text>
      </Box>
    </VStack>
  );
}

export default RenderAboutMagicFlowers;
