import ListIntegrateTokensBase from '../../../NFTs/ListIntegrateTokensBase';
import { ethers } from 'ethers';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
const AddGalleryMagicFlowers = () => {
  const zeroAddress = ethers.constants.AddressZero;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button colorScheme="green" variant="ghost" onClick={onOpen}>
        Integrate Tokens in Gallery
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Integrate Tokens in Gallery</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ListIntegrateTokensBase
              tokenType="Erc721"
              specificGameTokenId={1}
              useCase=""
              label="Add Gallery"
              tokenContract={zeroAddress}
              tokenId={0}
              onlyIntegration={true}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddGalleryMagicFlowers;
