import { Link as ReactLink } from 'react-router-dom';
import {
  Box,
  Text,
  VStack,
  HStack,
  TabList,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  Wrap,
  WrapItem,
  Link,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  FaTwitter,
  FaFacebookF,
  FaYoutube,
  FaPinterestP,
  FaInstagram
} from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const RenderMarketplaceIdsForToken = props => {
  return (
    <>
      <Box textAlign="center" alignItems="baseline">
        Maketplace Ids:
      </Box>

      <Wrap spacing={4}>
        {props.marketplaceIds.map((item, key) => (
          <WrapItem key={key}>
            <Link as={ReactLink} to={`/marketplace/${item}`}>
              <Box
                style={{
                  display: 'grid',
                  border: '8px solid',
                  padding: '0.5rem',
                  borderImage:
                    'linear-gradient(45deg, turquoise, greenyellow) 1',
                }}
              >
                {item}
              </Box>
            </Link>
          </WrapItem>
        ))}
      </Wrap>
    </>
  );
};

export const RenderAuctionIdsForToken = props => {
  return (
    <>
      <Box textAlign="center" alignItems="baseline">
        Auction Ids:
      </Box>

      <Wrap spacing={4}>
        {props.auctionIds.map((auctionId, key) => (
          <WrapItem key={key}>
            <Link as={ReactLink} to={`/auctions/${auctionId}`}>
              <Box
                style={{
                  display: 'grid',
                  border: '8px solid',
                  padding: '0.5rem',
                  borderImage:
                    'linear-gradient(45deg, turquoise, greenyellow) 1',
                }}
              >
                {auctionId}
              </Box>
            </Link>
          </WrapItem>
        ))}
      </Wrap>
    </>
  );
};

export const SocialMediaIcons = props => {
  return (
    <Wrap>
      <WrapItem>
        <Box>
          <Link href={props.item.twitter} isExternal>
            <Icon as={FaTwitter} color="#1DA1F2" />
          </Link>
        </Box>
      </WrapItem>
      <WrapItem>
        <Box>
          <Link href={props.item.facebook} isExternal>
            <Icon as={FaFacebookF} color="#4267B2" />
          </Link>
        </Box>
      </WrapItem>
      <WrapItem>
        <Box>
          <Link href={props.item.youtube} isExternal>
            <Icon as={FaYoutube} color="#FF0000" />
          </Link>
        </Box>
      </WrapItem>
      <WrapItem>
        <Box>
          <Link href={props.item.pinterest} isExternal>
            <Icon as={FaPinterestP} />
          </Link>
        </Box>
      </WrapItem>
      <WrapItem>
        <Box>
          <Link href={props.item.instagram} isExternal>
            <Icon as={FaInstagram} color="#833AB4" />
          </Link>
        </Box>
      </WrapItem>
    </Wrap>
  );
};

export const RenderItemName = ({ name }) => {
  return (
    <Text
      fontSize={[24, 36, 48]}
      color="teal.500"
      style={{ fontFamily: 'Lobster' }}
    >
      {name}
    </Text>
  );
};

export const RenderTokenInfoAccording = props => {
  return (
    <VStack>
      {' '}
      <Accordion allowMultiple>
        <RenderDescriptionAccording item={props.item} />
        <RenderTokenIdAccording item={props.item} />
        {props.itemCollection &&
          props.itemCollection.creatorCollection.collectionId > 0 && (
            <RenderCollectionAccording
              collection={props.itemCollection.creatorCollection}
            />
          )}
        {props.itemCollection &&
          props.itemCollection.sellerCollection.collectionId > 0 && (
            <RenderCollectionAccording
              collection={props.itemCollection.sellerCollection}
              isCreator={false}
            />
          )}
        <RenderOriginalCreatorAccording item={props.item} />
        <RenderPriceHistoryAccording
          itemPriceHistory={props.itemPriceHistory}
        />
        <RenderTagsAccording item={props.item} />
      </Accordion>
    </VStack>
  );
};

export const RenderInfoAccordion = props => {
  return (
    <Accordion allowMultiple>
      <RenderMetadataAccording
        item={props.item}
        itemCollection={props.itemCollection}
        itemPriceHistory={props.itemPriceHistory}
      />
    </Accordion>
  );
};

export const RenderMetadataAccording = props => {
  return (
    <>
      <RenderDescriptionAccording item={props.item} />

      <RenderGamesAssociatedAccording
        gamesAssociated={props.item.gameNamesIntegrated}
        isIntegration={true}
      />
      {/* <RenderTokenAttributesAccording item={props.item} /> */}
      <RenderTokenIdAccording item={props.item} />
      {props.itemCollection &&
        props.itemCollection.creatorCollection.collectionId > 0 && (
          <RenderCollectionAccording
            collection={props.itemCollection.creatorCollection}
          />
        )}
      {props.itemCollection &&
        props.itemCollection.sellerCollection.collectionId > 0 && (
          <RenderCollectionAccording
            collection={props.itemCollection.sellerCollection}
            isCreator={false}
          />
        )}
      <RenderOriginalCreatorAccording item={props.item} />
      <RenderSellerAccording item={props.item} />
      <RenderPriceHistoryAccording itemPriceHistory={props.itemPriceHistory} />
    </>
  );
};

const RenderGamesAssociatedAccording = ({ gamesAssociated, isIntegration }) => {
  const _label = isIntegration ? 'Games Integrated' : 'Games Compatible';
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Box display="flex" alignItems="baseline">
              <Text
                fontSize={24}
                color="teal.500"
                style={{ fontFamily: 'Lobster' }}
              >
                {_label}
              </Text>
            </Box>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <VStack>
          <Box textAlign="center" alignItems="baseline">
            <Text as="i">{gamesAssociated}</Text>
          </Box>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

const RenderTagsAccording = props => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Box display="flex" alignItems="baseline">
              <Text
                fontSize={24}
                color="teal.500"
                style={{ fontFamily: 'Lobster' }}
              >
                Tags
              </Text>
            </Box>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <VStack>
          <Box textAlign="center" alignItems="baseline">
            <Text as="i">{props.item.tags}</Text>
          </Box>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

const RenderDescriptionAccording = props => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Box display="flex" alignItems="baseline">
              <Text
                fontSize={24}
                color="teal.500"
                style={{ fontFamily: 'Lobster' }}
              >
                Description
              </Text>
            </Box>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <VStack>
          <Box textAlign="center" alignItems="baseline">
            {props.item.metadata.description}
          </Box>
          {/* <Box>
            <HStack spacing={4}>
              <Box textAlign="center" alignItems="baseline">
                <Link href={props.item.externalNftUrl} isExternal>
                  {props.item.externalNftUrl} <ExternalLinkIcon mx="2px" />
                </Link>
              </Box>
              <Box>
                <Link href={props.item.youtube} isExternal>
                  <Icon as={FaYoutube} color="#FF0000" />
                </Link>
              </Box>
            </HStack>
          </Box> */}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

const RenderTokenIdAccording = props => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Box display="flex" alignItems="baseline">
              <Text
                fontSize={24}
                color="teal.500"
                style={{ fontFamily: 'Lobster' }}
              >
                Token Id & Contract
              </Text>
            </Box>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Tabs colorScheme="green">
          <TabList mb="1em">
            <Tab>Token ID</Tab>
            <Tab>Token Contract</Tab>
            <Tab>Token Type</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <HStack spacing={8}>
                <Box textAlign="center">
                  <Text
                    fontSize={24}
                    color="green.300"
                    style={{ fontFamily: 'Lobster' }}
                  >
                    {props.item.tokenId}
                  </Text>
                </Box>
                <Box>
                  <CopyToClipboard text={props.item.tokenId}>
                    <Button variant="outline" size="xs">
                      Copy
                    </Button>
                  </CopyToClipboard>
                </Box>
              </HStack>
              <Link
                as={ReactLink}
                to={`/tokens/${props.item.tokenContractAddress}/${props.item.tokenId}`}
              >
                <Box textAlign="center">
                  <Text
                    fontSize={24}
                    color="green.300"
                    style={{ fontFamily: 'Lobster' }}
                  >
                    View Token
                  </Text>
                </Box>
              </Link>
            </TabPanel>
            <TabPanel>
              <Link href="https://chakra-ui.com" isExternal>
                {props.item.tokenContractAddress} <ExternalLinkIcon mx="2px" />
              </Link>
              <CopyToClipboard text={props.item.tokenContractAddress}>
                <Button variant="outline" size="xs">
                  Copy
                </Button>
              </CopyToClipboard>
            </TabPanel>
            <TabPanel>
              <HStack spacing={8}>
                <Box textAlign="center">
                  <Text fontSize={20}>{props.item.tokenType}</Text>
                </Box>
              </HStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </AccordionPanel>
    </AccordionItem>
  );
};

const RenderCollectionAccording = ({ collection, isCreator = true }) => {
  console.log('collection:', collection);
  const _title = isCreator ? 'Creator' : 'Seller';
  return (
    <>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Box display="flex" alignItems="baseline">
                <Text
                  fontSize={24}
                  color="teal.500"
                  style={{ fontFamily: 'Lobster' }}
                >
                  {_title} Collection
                </Text>
              </Box>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Wrap spacing={4}>
            <WrapItem>
              <Box textAlign="center" alignItems="baseline">
                This token is part of collection
                {' ' + collection.collectionName}
              </Box>
            </WrapItem>
            <WrapItem>
              <Link
                as={ReactLink}
                to={`/collections/${collection.collectionId}`}
              >
                <Box textAlign="center" alignItems="baseline">
                  (view collection)
                </Box>
              </Link>
            </WrapItem>
          </Wrap>
        </AccordionPanel>
      </AccordionItem>
    </>
  );
};

const RenderOriginalCreatorAccording = props => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Box display="flex" alignItems="baseline">
              <Text
                fontSize={24}
                color="teal.500"
                style={{ fontFamily: 'Lobster' }}
              >
                Original Creator
              </Text>
            </Box>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Box textAlign="center" alignItems="baseline">
          <Text as="i">{props.item.artist}</Text>
          <Link as={ReactLink} to={`/artists/${props.item.artist}`}>
            <Box textAlign="center" alignItems="baseline">
              <Text color="green.300">
                View all NFTs created by this artist
              </Text>
            </Box>
          </Link>
          <Box>
            <Link href={props.item.externalArtistUrl} isExternal>
              <Text as="i">More info on this artist:</Text>
              <ExternalLinkIcon mx="2px" />
            </Link>
          </Box>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

const RenderSellerAccording = props => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Box display="flex" alignItems="baseline">
              <Text
                fontSize={24}
                color="teal.500"
                style={{ fontFamily: 'Lobster' }}
              >
                Seller
              </Text>
            </Box>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Box textAlign="center" alignItems="baseline">
          <Text as="i">Seller: {props.item.seller}</Text>
          <Link as={ReactLink} to={`/shops/${props.item.seller}`}>
            <Box textAlign="center" alignItems="baseline">
              <Text color="green.300">View seller shop</Text>
            </Box>
          </Link>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

const RenderPriceHistoryAccording = props => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Box display="flex" alignItems="baseline">
              <Text
                fontSize={24}
                color="teal.500"
                style={{ fontFamily: 'Lobster' }}
              >
                NFT Price History
              </Text>
            </Box>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        {props.itemPriceHistory && props.itemPriceHistory.length > 0 ? (
          <Box style={{ border: '5px dotted green', borderRadius: '5px' }}>
            <Box textAlign="center">
              <Text>NFT price history</Text>
            </Box>
            {props.itemPriceHistory &&
              props.itemPriceHistory.map((p, key) => (
                <Box key={key} maxW="sm">
                  <HStack>
                    <Box>price: {p.priceSold} MATIC</Box>
                    <Box>date: {p.timestampSold}</Box>
                  </HStack>
                </Box>
              ))}
          </Box>
        ) : (
          <Box></Box>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export const RenderMetadataTabs = props => {
  return (
    <>
      <Tabs variant="soft-rounded" colorScheme="green" isLazy size="sm">
        <TabList>
          <Tab>Description</Tab>
          {/* <Tab>Other Info</Tab> */}
          <Tab>Token Details</Tab>
          {/* <Tab>Collection</Tab> */}
          <Tab>Artist</Tab>
          <Tab>Seller</Tab>
          <Tab>Price History</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack>
              <Box textAlign="center" alignItems="baseline">
                {props.item.metadata.description}
              </Box>
              {/* <Box textAlign="center" alignItems="baseline">
                <Link href={props.item.externalNftUrl} isExternal>
                  {props.item.externalNftUrl} <ExternalLinkIcon mx="2px" />
                </Link>
              </Box> */}
              {/* <Box>
                <SocialMediaIcons item={props.item} />
              </Box> */}
            </VStack>
          </TabPanel>
          {/* <TabPanel>
            <Box textAlign="center" alignItems="baseline">
              {props.item.otherInfo}
            </Box>
          </TabPanel> */}
          <TabPanel>
            <Tabs colorScheme="green">
              <TabList mb="1em">
                <Tab>Token ID</Tab>
                <Tab>Token Contract</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Link
                    as={ReactLink}
                    to={`/tokens/${props.item.tokenContractAddress}/${props.item.tokenId}`}
                  >
                    <Box textAlign="center">
                      <Text
                        fontSize={24}
                        color="green.300"
                        style={{ fontFamily: 'Lobster' }}
                      >
                        {props.item.tokenId}
                      </Text>
                      <Text
                        fontSize={24}
                        color="green.300"
                        style={{ fontFamily: 'Lobster' }}
                      >
                        View Token
                      </Text>
                    </Box>
                  </Link>
                </TabPanel>
                <TabPanel>
                  <Link href="https://chakra-ui.com" isExternal>
                    {props.item.tokenContractAddress}{' '}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </TabPanel>
          {/* 
          <TabPanel>
            {props.itemCollection && props.itemCollection.collectionId > 0 && (
              <Wrap spacing={4}>
                <WrapItem>
                  <Box textAlign="center" alignItems="baseline">
                    This token is part of collection
                    {' ' + props.itemCollection.collectionName}
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Link
                    as={ReactLink}
                    to={`/collections/${props.itemCollection.collectionId}`}
                  >
                    <Box textAlign="center" alignItems="baseline">
                      (view collection)
                    </Box>
                  </Link>
                </WrapItem>
              </Wrap>
            )}
          </TabPanel> */}

          <TabPanel>
            <Box textAlign="center" alignItems="baseline">
              <Text as="i">{props.item.artist}</Text>
              <Link as={ReactLink} to={`/artists/${props.item.artist}`}>
                <Box textAlign="center" alignItems="baseline">
                  <Text color="green.300">
                    View all NFTs created by this artist
                  </Text>
                </Box>
              </Link>
              <Box>
                <Link href={props.item.externalArtistUrl} isExternal>
                  <Text as="i">More info on this artist:</Text>
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box textAlign="center" alignItems="baseline">
              <Text as="i">Seller: {props.item.seller}</Text>
              <Link as={ReactLink} to={`/shops/${props.item.seller}`}>
                <Box textAlign="center" alignItems="baseline">
                  <Text color="green.300">View seller shop</Text>
                </Box>
              </Link>
            </Box>
          </TabPanel>

          <TabPanel>
            {props.itemPriceHistory && props.itemPriceHistory.length > 0 ? (
              <Box style={{ border: '5px dotted green', borderRadius: '5px' }}>
                <Box textAlign="center">
                  <Text>NFT price history</Text>
                </Box>
                {props.itemPriceHistory &&
                  props.itemPriceHistory.map((p, key) => (
                    <Box key={key} maxW="sm">
                      <HStack>
                        <Box>price: {p.priceSold} MATIC</Box>
                        <Box>date: {p.timestampSold}</Box>
                      </HStack>
                    </Box>
                  ))}
              </Box>
            ) : (
              <Box></Box>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export const RenderImageType = ({ item }) => {
  let _title = '';
  if (item.category === 'Music') {
    _title = 'Music';
  } else if (item.category === '3DModel') {
    _title = '3D Model';
  } else if (item.category === '3DTexture') {
    _title = '3D Environment Textures';
  }

  return (
    <Box>
      <Text as="i" fontSize="xs" color="teal.500">
        {_title}
      </Text>
    </Box>
  );
};
