import { useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Box, IconButton, Button, Icon } from '@chakra-ui/react';
import { FaMusic } from 'react-icons/fa';

const MagicFlowerMusic = ({ song, boxWidth = '358px' }) => {
  const [showMusic, setShowMusic] = useState(false);
  const handleShowMusic = () => {
    if (showMusic) {
      setShowMusic(false);
    } else {
      setShowMusic(true);
    }
  };
  const MusicButton = () => {
    const title = showMusic ? 'Hide' : '';
    return (
      <Button
        rightIcon={<FaMusic />}
        colorScheme="green"
        variant="ghost"
        onClick={handleShowMusic}
      >
        {title}
      </Button>
    );
  };
  return (
    <>
      <MusicButton />
      {showMusic && (
        <Box w={boxWidth}>
          <ReactAudioPlayer src={song} autoPlay controls controlsList={"nodownload"} />
        </Box>
      )}
    </>
  );
};

export default MagicFlowerMusic;
