class Mandala {
    constructor(p5, canvas) {
        this.p5 = p5
      this.canvas = canvas;
      this.cX = canvas.width / 2.0;
      this.cY = canvas.height / 2.0;
      this.angle = 0;
      this.x = 0;
      this.y = 0;
      this.i = 0;
      this.nSegment = 60;
      this.mode = Math.floor(Math.random() * 3) + 1;
    }
  
    initiate() {
      this.canvas.stroke(200);
      this.canvas.noFill();
      this.canvas.background(0, 0); 
  
    }
  
    bloom() {
    this.canvas.background(0, 0); 
    //   this.canvas.fill(0, 20);
      this.canvas.noStroke();
      this.canvas.rect(0, 0, this.canvas.width, this.canvas.height);
      if (this.mode == 1) {
        this.drawWind();
      }
      if (this.mode == 2) {
        this.drawEarth();
      }
      if (this.mode == 3) {
        this.drawWater();
      }
      if (this.mode == 4) {
        this.drawFire();
      }
    }
  
    drawWind() {
      for (let i = 0; i < 3; i++) {
        this.drawAir(9 * (i + 1), 50 * i + this.p5.sin(this.p5.frameCount / 20.0) * 30.0);
        this.drawLine(18 * (i + 1), 50 * i + this.p5.sin(this.p5.frameCount / 20.0) * 30.0);
        this.drawParticle(18 * (i + 1), 80 * i + this.p5.sin(this.p5.frameCount / 20.0) * 30.0);
      }
    }
  
    drawAir(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle + this.p5.sin(this.p5.frameCount / 50.0));
        this.canvas.fill(40 + 40 * this.p5.sin(this.p5.frameCount / 25.0), 10);
        this.canvas.strokeWeight(1);
        this.canvas.stroke(100, 100, 120, 255 * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.rect(
          0,
          0,
          100 * this.p5.sin(this.p5.frameCount / 25.0),
          100 + 75 * this.p5.sin(this.p5.frameCount / 25.0)
        );
        this.canvas.pop();
      }
    }
  
    drawParticle(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle + i * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.stroke(150);
        this.canvas.strokeWeight(1 + 1 * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.point(
          150 * this.p5.sin(this.p5.frameCount / 50.0),
          100 * this.p5.sin(this.p5.frameCount / 50.0)
        );
        this.canvas.pop();
      }
    }
  
    drawLine(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle - this.p5.sin(this.p5.frameCount / 50.0));
        this.canvas.stroke(100, 150, 200 + this.p5.sin(this.p5.frameCount / 25.0), 50);
        this.canvas.strokeWeight(1);
        this.canvas.line(
          0,
          0,
          100 + 50 * -this.p5.sin(this.p5.frameCount / 25.0),
          80 * -this.p5.sin(this.p5.frameCount / 25.0)
        );
        this.canvas.pop();
      }
    }
  
    drawEarth() {
      for (let i = 0; i < 3; i++) {
        this.drawBranch(18 * (i + 1), 80 * i);
        this.drawLeaves(18 * (i + 1), 100 * i + 50 * this.p5.sin(this.p5.frameCount / 50.0));
      }
    }
  
    drawBranch(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle + this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.fill(128, 96, 77, 10);
        this.canvas.stroke(128, 96, 77);
        this.canvas.strokeWeight(3 - 2 * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.line(
          0,
          100 * this.p5.sin(this.p5.frameCount / 50.0),
          100 * this.p5.sin(this.p5.frameCount / 50.0),
          50 * this.p5.sin(this.p5.frameCount / 50.0)
        );
        this.canvas.stroke(150, 150, 50);
        this.canvas.point(
          150 * this.p5.sin(this.p5.frameCount / 25.0),
          100 * this.p5.sin(this.p5.frameCount / 25.0)
        );
        this.canvas.pop();
      }
    }
  
    drawLeaves(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle + this.p5.sin(this.p5.frameCount / 50.0));
        this.canvas.fill(128, 150, 77, 10);
        this.canvas.stroke(150, 150, 90, 10);
        this.canvas.strokeWeight(1);
        this.canvas.ellipse(
          0,
          0,
          40 * this.p5.sin(this.p5.frameCount / 50.0),
          100 * this.p5.sin(this.p5.frameCount / 50.0)
        );
        this.canvas.pop();
      }
    }
  
    drawWater() {
      for (let i = 0; i < 3; i++) {
        this.drawSplash(
          18 * (i + 1),
          80 * this.p5.sin(this.p5.frameCount / 50.0) * i + this.p5.sin(this.p5.frameCount / 20.0) * 50.0
        );
        this.drawWaves(
          18 * (i + 1),
          100 * this.p5.sin(this.p5.frameCount / 25.0) * i + this.p5.sin(this.p5.frameCount / 20.0) * 25.0
        );
      }
    }
  
    drawSplash(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle + this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.fill(100, 100, 200 + 50 * -this.p5.sin(this.p5.frameCount / 25.0), 10);
        this.canvas.strokeWeight(1);
        this.canvas.stroke(
          150,
          150,
          200 - 50 * this.p5.sin(this.p5.frameCount / 25.0),
          100 - 100 * this.p5.sin(this.p5.frameCount / 25.0)
        );
        this.canvas.ellipse(
          0,
          0,
          200 * this.p5.sin(this.p5.frameCount / 25.0),
          50 * this.p5.sin(this.p5.frameCount / 25.0)
        );
        this.canvas.stroke(130, 130, 200 + 50 * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.strokeWeight(3 + 3 * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.point(
          100 * this.p5.sin(this.p5.frameCount / 50.0),
          100 * this.p5.sin(this.p5.frameCount / 50.0)
        );
        this.canvas.pop();
      }
    }
  
    drawWaves(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle + this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.fill(0, 80 * this.p5.sin(this.p5.frameCount / 25.0), 150, 10);
        this.canvas.strokeWeight(1);
        this.canvas.stroke(
          150,
          150,
          200 + 50 * this.p5.sin(this.p5.frameCount / 25.0),
          100 + 100 * this.p5.sin(this.p5.frameCount / 25.0)
        );
        this.canvas.ellipse(
          0,
          0,
          150 * this.p5.sin(this.p5.frameCount / 50.0),
          50 * this.p5.sin(this.p5.frameCount / 50.0)
        );
        this.canvas.pop();
      }
    }
  
    drawFire() {
      for (let i = 0; i < 4; i++) {
        this.drawFlames(
          9 * (i + 1),
          80 * this.p5.sin(this.p5.frameCount / 50.0) * i + this.p5.sin(this.p5.frameCount / 20.0) * 30.0
        );
        this.drawFade(
          9 * (i + 1),
          100 * this.p5.sin(this.p5.frameCount / 50.0) * i + this.p5.sin(this.p5.frameCount / 20.0) * 30.0
        );
        this.drawSpark(
          18 * (i + 1),
          70 * this.p5.sin(this.p5.frameCount / 50.0) * i + this.p5.sin(this.p5.frameCount / 20.0) * 30.0
        );
      }
    }
  
    drawFlames(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(i + this.angle + this.p5.sin(this.p5.frameCount / 50.0));
        this.canvas.fill(150, 80 * this.p5.sin(this.p5.frameCount / 25.0), 0, 10);
        this.canvas.strokeWeight(0.5);
        this.canvas.stroke(150, 100 * this.p5.sin(this.p5.frameCount / 25.0), 0);
        this.canvas.ellipse(
          0,
          0,
          150 * this.p5.sin(this.p5.frameCount / 25.0),
          100 + 75 * this.p5.sin(this.p5.frameCount / 25.0)
        );
        this.canvas.pop();
      }
    }
  
    drawSpark(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle + i * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.stroke(255, 255, 0, 255 * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.strokeWeight(1 + 1 * this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.point(
          150 * this.p5.sin(this.p5.frameCount / 50.0),
          100 * this.p5.sin(this.p5.frameCount / 50.0)
        );
        this.canvas.pop();
      }
    }
  
    drawFade(n, r) {
      for (let i = 0; i < n; i++) {
        this.angle = (i * (this.p5.PI * 2.0)) / n;
        this.x = this.cX + r * this.p5.cos(this.angle);
        this.y = this.cY + r * this.p5.sin(this.angle);
        this.canvas.push();
        this.canvas.translate(this.x, this.y);
        this.canvas.rotate(this.angle + this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.fill(0, 80 * -this.p5.sin(this.p5.frameCount / 25.0));
        this.canvas.noStroke();
        this.canvas.rect(
          0,
          0,
          100 * this.p5.sin(this.p5.frameCount / 25.0),
          100 * this.p5.sin(this.p5.frameCount / 25.0)
        );
        this.canvas.pop();
      }
    }
  }

  export default Mandala;