import { useState, useEffect } from 'react';
import { VStack, Text } from '@chakra-ui/react';
import Helper from '../../../contracts/APIs/HelperInterface.sol/HelperInterface.json';
import { ethers } from 'ethers';
import ValleyItemListByUser from './elements/valleyItems/ValleyItemListByUser';
import {
  REACT_APP_HELPER_ADDRESS,
  REACT_APP_AUCTION_HOUSE_ADDRESS,
  REACT_APP_MARKETPLACE_ERC1155_ADDRESS,
  REACT_APP_MARKETPLACE_ERC721_ADDRESS
} from '../cozyGames/constants/ContractAddress';
// All marketplace sales and auctions created by props.userAddr
const AllValleyItemsCreatedBy = ({
  provider,
  isAuction,
  isERC1155,
  addr,
  isSeller = false,
}) => {
  const [marketplaceIds, setMarketplaceIds] = useState([]);
  const [auctionIds, setAuctionIds] = useState([]);
  useEffect(() => {
    getItems();
  }, []);
  const getItems = async () => {
    const helper = new ethers.Contract(
      REACT_APP_HELPER_ADDRESS,
      Helper.abi,
      provider
    );
    // if (isAuction) {
    //   auctionHouse = new ethers.Contract(
    //     REACT_APP_AUCTION_HOUSE_ADDRESS,
    //     AuctionHouse.abi,
    //     provider
    //   );
    // } else {
    //   marketplace = new ethers.Contract(
    //     process.env.REACT_APP_MARKETPLACE_ADDRESS,
    //     Marketplace.abi,
    //     provider
    //   );
    // }
    let _marketplaceIds = [],
      _auctionIds = [];

    if (isAuction) {
      _auctionIds = await helper.getSalesCreatedBy(
        addr,
        REACT_APP_AUCTION_HOUSE_ADDRESS
      );
      if (_auctionIds.length > 0) {
        _auctionIds = _auctionIds.map(auctionId =>
          Number(auctionId.toString())
        );
        // console.log('_auctionIds:', _auctionIds);
        setAuctionIds(_auctionIds);
      }
    } else {
      let _saleContract = isERC1155
        ? REACT_APP_MARKETPLACE_ERC1155_ADDRESS
        : REACT_APP_MARKETPLACE_ERC721_ADDRESS;
      _marketplaceIds = await helper.getSalesCreatedBy(addr, _saleContract);
      if (_marketplaceIds.length > 0) {
        _marketplaceIds = _marketplaceIds.map(marketplaceId =>
          Number(marketplaceId.toString())
        );
        // console.log('_marketplaceIds:', _marketplaceIds);
        setMarketplaceIds(_marketplaceIds);
      }
    }
  };
  const ShowTitle = () => {
    const title = isAuction
      ? 'Auctions'
      : isERC1155
      ? 'Marketplace ERC1155 Sales'
      : 'Marketplace ERC721 Sales';

    return (
      <Text fontSize={30} color="green.400" style={{ fontFamily: 'Lobster' }}>
        {title}
      </Text>
    );
  };

  const RenderItems = () => {
    if (isAuction) {
      return (
        <ValleyItemListByUser
          provider={provider}
          saleType="Auction"
          marketplaceIds={marketplaceIds}
          auctionIds={auctionIds}
          userAddr={addr}
          isSeller={isSeller}
        />
      );
    } else {
      const tokenType = isERC1155 ? 'Erc1155' : 'Erc721';
      return (
        <ValleyItemListByUser
          provider={provider}
          tokenType={tokenType}
          saleType="Marketplace"
          marketplaceIds={marketplaceIds}
          auctionIds={auctionIds}
          userAddr={addr}
          isSeller={isSeller}
        />
      );
    }
  };

  return (
    <VStack>
      <ShowTitle />
      <RenderItems />
    </VStack>
  );
};

export default AllValleyItemsCreatedBy;
