import { Plant1, Branche } from '../sketchElements/plant/plant1';
import { Plant2 } from '../sketchElements/plant/plant2';
import {
  drawBgSceneDeepNight,
  drawBgSceneDawn,
  drawBgSceneMorning,
  drawBgSceneAfternoon,
  drawBgSceneEvening,
  drawBgSceneNight,
} from '../sketchElements/scenes/bgScene';
import { Firefly } from '../sketchElements/scenes/firefly';
import { FlowerGenus3 } from '../sketchElements/flowers/flowerGenus3';
import { FlowerGenus4treeCanvas } from '../sketchElements/flowers/flowerGenus4treeCanvas';
import { flowerGenus7treeCanvas } from '../sketchElements/flowers/flowerGenus7treeCanvas';
import { FlowerGenus8plant1 } from '../sketchElements/flowers/flowerGenus8.js';
import Butterflies from '../../../../../resources/images/animals/butterflies1.gif';

import Flakes from '../../../../../resources/images/misc/flakes32.png';
import { ShootingStar1 } from '../sketchElements/stars/shootingstar1';
import { ShootingStar2 } from '../sketchElements/stars/shootingstar2';
import { Star1, addStars } from '../sketchElements/stars/star1';
import Grass from '../sketchElements/grass/Grass.js';
import '../../../../../styles/Button86.css';
import getH from '../sketchElements/utils/getH.js';
import drawCloud from '../sketchElements/scenes/cloud.js';
import {
  Snowflakes2,
  drawSnow2,
} from '../sketchElements/scenes/snow/pinkSnow.js';
import drawFireflies from '../sketchElements/scenes/drawFireflies.js';
import drawStars from '../sketchElements/stars/drawStars.js';
import { setupSnow1 } from '../sketchElements/scenes/snow/whiteSnow.js';
import { ControlPanel } from '../sketchElements/controlPanel/ControlPanel.js';
import Stone1 from '../../../../../resources/images/games/magicflowers/stone/stone1.png';
import Stone2 from '../../../../../resources/images/games/magicflowers/stone/stone2.png';
import Stone3 from '../../../../../resources/images/games/magicflowers/stone/stone3.png';
import Stone4 from '../../../../../resources/images/games/magicflowers/stone/stone4.png';
import Stone5 from '../../../../../resources/images/games/magicflowers/stone/stone5.png';
import Stone6 from '../../../../../resources/images/games/magicflowers/stone/stone6.png';
import Stone7 from '../../../../../resources/images/games/magicflowers/stone/stone7.png';
import Stone8 from '../../../../../resources/images/games/magicflowers/stone/stone8.png';
import Stone9 from '../../../../../resources/images/games/magicflowers/stone/stone9.png';
import Stone10 from '../../../../../resources/images/games/magicflowers/stone/stone10.png';
import Stone11 from '../../../../../resources/images/games/magicflowers/stone/stone11.png';
import Stone12 from '../../../../../resources/images/games/magicflowers/stone/stone12.png';
import Stone13 from '../../../../../resources/images/games/magicflowers/stone/stone13.png';
import Stone14 from '../../../../../resources/images/games/magicflowers/stone/stone14.png';
import Stone15 from '../../../../../resources/images/games/magicflowers/stone/stone15.png';
import Stone16 from '../../../../../resources/images/games/magicflowers/stone/stone16.png';
import Stone17 from '../../../../../resources/images/games/magicflowers/stone/stone17.png';
import Stone18 from '../../../../../resources/images/games/magicflowers/stone/stone18.png';
import Stone19 from '../../../../../resources/images/games/magicflowers/stone/stone19.png';
import Stone20 from '../../../../../resources/images/games/magicflowers/stone/stone20.png';
import Stone21 from '../../../../../resources/images/games/magicflowers/stone/stone21.png';
import Stone22 from '../../../../../resources/images/games/magicflowers/stone/stone22.png';
import Stone23 from '../../../../../resources/images/games/magicflowers/stone/stone23.png';
import Stone24 from '../../../../../resources/images/games/magicflowers/stone/stone24.png';
import Stone25 from '../../../../../resources/images/games/magicflowers/stone/stone25.png';
import Stone26 from '../../../../../resources/images/games/magicflowers/stone/stone26.png';
import Stone27 from '../../../../../resources/images/games/magicflowers/stone/stone27.png';
import Stone28 from '../../../../../resources/images/games/magicflowers/stone/stone28.png';
import Stone29 from '../../../../../resources/images/games/magicflowers/stone/stone29.png';
import Stone30 from '../../../../../resources/images/games/magicflowers/stone/stone30.png';
import Stone31 from '../../../../../resources/images/games/magicflowers/stone/stone31.png';
import Stone32 from '../../../../../resources/images/games/magicflowers/stone/stone32.png';
import Stone33 from '../../../../../resources/images/games/magicflowers/stone/stone33.png';
import Stone34 from '../../../../../resources/images/games/magicflowers/stone/stone34.png';
import Stone35 from '../../../../../resources/images/games/magicflowers/stone/stone35.png';
import Stone36 from '../../../../../resources/images/games/magicflowers/stone/stone36.png';

function MagicFlowerTree(p5) {
  //PlantSpirit, Butterflies, BackgroundDawn, BackgroundMorning, BackgroundAfternoon, BackgroundEvening, BackgroundNight, BackgroundDeepNight, Background, BackgroundEvolve, BackgroundDawnFront, BackgroundMorningFront, BackgroundAfternoonFront, BackgroundEveningFront, BackgroundNightFront, BackgroundDeepNightFront, BackgroundFront, BackgroundEvolveFront, BackgroundMain, BackgroundMainFront
  let magicflowerId;
  let isDemo = false;
  let isOffchain = false;
  let controlPanel;

  // hour
  let isNaturalCycle = false;
  // brightNight, sunrise, brightMorning, afternoon, evening, darkNight

  let drawBgs = [
    drawBgSceneDeepNight,
    drawBgSceneDawn,
    drawBgSceneMorning,
    drawBgSceneAfternoon,
    drawBgSceneEvening,
    drawBgSceneNight,
  ];
  let bgDeepNight, bgDawn, bgMorning, bgAfternoon, bgEvening, bgNight;
  let bgs = [bgDeepNight, bgDawn, bgMorning, bgAfternoon, bgEvening, bgNight];

  // 2, 5, 8, 15, 18, 22
  let h = 88;
  // shooting star
  let shootingStar;
  let magicStar;
  // stars on left side
  let lStars = [];
  // stars on right side
  let rStars = [];
  let lStarsCanvas, rStarsCanvas;

  // fairy

  let plantSpirit;
  let hasPlantSpirit = false;
  let xPlantSpirit, yPlantSpirit;

  // butterflies
  let butterflies;
  let bx, by;
  let butterflyChance = Math.random();

  // fireflies
  let fireflies = [];
  let fireflyCanvas;

  // white snow -- snow1
  let snowCanvas;
  let snow = [];
  let zOff = 0;
  let spritesheet;
  let textures = [];

  // pink snow -- snow2
  let snowflakes = [];
  let snowflakeCanvas;

  let havePinkSnow = Math.random() < 0.8 ? true : false;
  let haveSnow = Math.random() > 0.2 ? true : false;

  // bg
  let sceneCanvas;

  // plant1
  let genes = {};
  let plantFeatures = {};
  let tree1;
  let tree2;
  let treeCanvas;
  let leafType = 1;
  // flowers on plant1
  let flowerType;
  let flower;
  let flowerParam = {};
  let flowerCanvas;

  let flowers = [];
  let randomFlower = 0;

  let stone;
  let stones = [
    Stone1,
    Stone2,
    Stone3,
    Stone4,
    Stone5,
    Stone6,
    Stone7,
    Stone8,
    Stone9,
    Stone10,
    Stone11,
    Stone12,
    Stone13,
    Stone14,
    Stone15,
    Stone16,
    Stone17,
    Stone18,
    Stone19,
    Stone20,
    Stone21,
    Stone22,
    Stone23,
    Stone24,
    Stone25,
    Stone26,
    Stone27,
    Stone28,
    Stone29,
    Stone30,
    Stone31,
    Stone32,
    Stone33,
    Stone34,
    Stone35,
    Stone36,
  ];

  // wind
  // 0.1 extremely strong wind
  // 0.08 very strong wind
  // 0.05 strong wind
  // 0.03 wind
  // 0.018 gentle wind
  // 0.01 very gentle wind
  // 0.008 extremely gentle wind
  // 0.0058 almost still
  let windFactor = 0.0188;
  // const windFactors = [0.0058, 0.0088, 0.0108, 0.0188, 0];
  // let windFactorIndex = 0;

  // grass
  let grassCanvas;
  let grass = [];
  let grassScale;

  // let plantSpiritX = 0;
  // let plantSpiritY = 0;
  // let desiredWidthPlantSpirit = 100;
  // let desiredHeightPlantSpirit = 0;

  // for testing
  const randomTestSeed = Math.floor(Math.random() * 10000);

  p5.preload = () => {
    preloadAll();
  };

  p5.setup = () => {
    setupAll();
  };

  const setupFlower = () => {
    if (flowerType >= 115 && flowerType <= 128) {
      // setupFlowerGenus3(FlowerGenus3, flowerType);
      flowerCanvas = p5.createGraphics(50, 50);
      flowerCanvas.randomSeed(genes.seed);
      flowerCanvas.noLoop();
      flower = new FlowerGenus3(p5, flowerCanvas, flowerType);
      flower.display();
    }

    if (flowerType === 129) {
      flower = new FlowerGenus4treeCanvas(p5, treeCanvas, 7, 12);
    }

    if (flowerType === 133 || flowerType === 131 || flowerType === 132) {
      flower = new flowerGenus7treeCanvas(
        p5,
        treeCanvas,
        flowerParam,
        flowerType
      );
    }

    if (flowerType >= 134 && flowerType <= 136) {
      // setupFlowerGenus8(FlowerGenus8);
      flowerCanvas = p5.createGraphics(800, 600);
      flowerCanvas.randomSeed(genes.seed);
      flowerCanvas.colorMode(p5.HSB, 360, 100, 100);
      flowerCanvas.noStroke();
      flowerCanvas.noLoop();
      flowerCanvas.clear();

      let numFlowers = plantFeatures.plantType === 1 ? 300 : 58;

      for (let i = 0; i < numFlowers; i++) {
        flowers.push(
          new FlowerGenus8plant1(
            p5,
            treeCanvas,
            flowerCanvas,
            flowerParam,
            flowerType,
            plantFeatures.plantType
          )
        );
      }

      randomFlower = p5.int(flowerCanvas.random(flowers.length));
    }

    if (genes.manualEvolveFlower) {
      flower = p5.loadImage(genes.flowerToken.imageUrl);
    }
  };

  p5.updateWithProps = props => {
    if (props.magicflowerId) {
      magicflowerId = props.magicflowerId;
    }
    if (props.plantFeatures) {
      plantFeatures = props.plantFeatures;
    }
    if (props.genes) {
      genes = props.genes;
    }

    if (props.userDefaultBgs.length > 0) {
      for (let i = 0; i < props.userDefaultBgs.length; i++) {
        if (
          Object.keys(props.userDefaultBgs[i]).length > 0 &&
          props.userDefaultBgs[i].imageUrl.length > 0
        ) {
          bgs[i] = p5.loadImage(props.userDefaultBgs[i].imageUrl);
        }
      }
    }

    if (props.userDefault) {
      // console.log('props.userDefault:', props.userDefault);
      if (
        props.userDefault['PlantSpirit'] &&
        props.userDefault['PlantSpirit'].imageUrl.length > 0
      ) {
        plantSpirit = p5.loadImage(props.userDefault['PlantSpirit'].imageUrl);

        hasPlantSpirit = true;
      }
    }

    if (props.isDemo) {
      isDemo = true;
    }
    if (props.isOffchain) {
      isOffchain = true;
    }
  };

  p5.keyPressed = () => {
    if (p5.key === 'c' || p5.key === 'C') {
      if (!controlPanel) {
        controlPanel = new ControlPanel(p5);
      }
      controlPanel.createControlPanel();
    }

    if (p5.key === 's' || p5.key === 'S') {
      p5.saveCanvas('magicflower', 'png');
    }
  };

  p5.draw = () => {
    // brightNight, sunrise, brightMorning, afternoon, evening, darkNight
    //drawScene(p5, 18);
    p5.background(0, 188);
    // console.log('frameCount:', p5.frameCount);
    if (controlPanel.selectTime) {
      h = getH(controlPanel.selectTime.selected(), p5);
      // console.log('h:', h);
    } else {
      h = p5.hour();
    }

    drawMagicFlowers();
  };

  p5.mousePressed = () => {
    // Check if the star is clicked
    magicStar.checkClick();

    // If the star is clicked, redirect to a link
    if (magicStar.clicked) {
      // Replace 'your-link-here' with the actual link
      if (isOffchain) {
        window.location.href = `/game/2/history`;
      } else {
        window.location.href = `/magicflowers/history/${magicflowerId}`;
      }
    }
    // const timeIndex = getTimeIndex(h);

    // if (vids[timeIndex]) {
    //   vids[timeIndex].loop();
    // }

    // Check if the mouse click is within the defined area around the image
    // if (
    //   p5.mouseX > plantSpiritX - 188 &&
    //   p5.mouseX < plantSpiritX + 188 &&
    //   p5.mouseY > plantSpiritY - 300 &&
    //   p5.mouseY < p5.height
    // ) {
    //   console.log('clicked');

    //   clicked = true;
    // }
  };

  p5.windowResized = () => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
  };

  function preloadAll() {
    spritesheet = p5.loadImage(Flakes);
    // fairy = p5.loadImage(Fairy1);
    butterflies = p5.loadImage(Butterflies);
    // font = p5.loadFont('Lobster-Regular.ttf');
  }

  function setupAll() {
    p5.createCanvas(p5.windowWidth, p5.windowHeight);
    xPlantSpirit = p5.random(100, p5.width - 100);

    if (isDemo) {
      p5.randomSeed(randomTestSeed);
    } else {
      p5.randomSeed(genes.seed);
    }

    // Control panel
    controlPanel = new ControlPanel(p5);

    flowerType = p5.int(p5.random(1, 137));

    flowerParam = {
      fc: {
        h: p5.int(p5.random(360)), //genes.flowerColor[0],
        s: p5.random(88, 100), //genes.flowerColor[1],
        b: p5.random(88, 100), //genes.flowerColor[2],
      },
      sc: {
        h: p5.int(p5.random(360)), //genes.flowerColor[3],
        s: p5.random(88, 100), //genes.flowerColor[4],
        b: p5.random(88, 100), //genes.flowerColor[5],
      },
    };

    // plant 1
    // treeCanvas = p5.createGraphics(p5.width, p5.height);
    // treeCanvas.frameRate(258);
    // treeCanvas.randomSeed(plantFeatures.plantSeed);
    // // treeCanvas.randomSeed(randomTestSeed);
    // treeCanvas.colorMode(p5.HSB, 360, 100, 100);
    // treeCanvas.background(0, 0);
    // p5.background(255);
    // // treeCanvas.frameRate(200);

    // treeCanvas.smooth(4);

    // treeCanvas.clear();
    // // flower
    // setupFlower();
    // let x = 0.5 * treeCanvas.width;
    // let y = p5.int(0.9988 * treeCanvas.height);
    // let start = p5.createVector(x, y);
    // // console.log('start:', start);
    // let f1 = treeCanvas.random(10, 108);
    // let f2 = treeCanvas.random(108, 138);
    // let maxlife = treeCanvas.random(58, 66);
    // tree1 = new Plant1(
    //   p5,
    //   treeCanvas,
    //   start,
    //   (f1 * start.y) / (p5.height - f2),
    //   genes,
    //   flowerParam,
    //   flowerType,
    //   maxlife
    // );

    // tree1.branches[0] = new Branche(
    //   p5,
    //   treeCanvas,
    //   flowerCanvas,
    //   tree1,
    //   start,
    //   maxlife * p5.sqrt(start.y / p5.height),
    //   0,
    //   1
    // );
    if (plantFeatures.plantType === 1) {
      treeCanvas = p5.createGraphics(p5.width, p5.height);
      treeCanvas.frameRate(258);
      treeCanvas.randomSeed(plantFeatures.plantSeed);
      // treeCanvas.randomSeed(randomTestSeed);
      treeCanvas.colorMode(p5.HSB, 360, 100, 100);
      treeCanvas.background(0, 0);
      p5.background(255);
      // treeCanvas.frameRate(200);

      treeCanvas.smooth(4);

      treeCanvas.clear();
      // flower
      setupFlower();
      let x = 0.5 * treeCanvas.width;
      let y = p5.int(0.9988 * treeCanvas.height);
      let start = p5.createVector(x, y);
      // console.log('start:', start);
      let f1 = treeCanvas.random(10, 108);
      let f2 = treeCanvas.random(108, 138);
      let maxlife = treeCanvas.random(58, 66);
      tree1 = new Plant1(
        p5,
        treeCanvas,
        start,
        (f1 * start.y) / (p5.height - f2),
        genes,
        flowerParam,
        flowerType,
        maxlife
      );

      tree1.branches[0] = new Branche(
        p5,
        treeCanvas,
        flowerCanvas,
        tree1,
        start,
        maxlife * p5.sqrt(start.y / p5.height),
        0,
        1
      );
    } else if (plantFeatures.plantType === 2) {
      treeCanvas = p5.createGraphics(p5.width, p5.height);
      treeCanvas.randomSeed(plantFeatures.plantSeed);
      // treeCanvas.randomSeed(randomTestSeed);
      treeCanvas.colorMode(p5.HSB, 360, 100, 100);
      treeCanvas.background(0, 0);
      p5.background(255);

      treeCanvas.clear();
      //leaf type
      leafType = p5.floor(p5.random(1, 3));
      // flower
      setupFlower();
      tree2 = new Plant2(p5, treeCanvas, flowerCanvas, genes, flowerType);
      tree2.new_gen();
      // console.log('tree2:', tree2);
    }

    p5.background(255);
    p5.image(treeCanvas, 0, 0);

    // stone
    stone = p5.loadImage(stones[plantFeatures.stoneIndex]);

    if (isNaturalCycle || h == 88) {
      h = p5.hour();
    }
    lStarsCanvas = p5.createGraphics(p5.width / 5.8, p5.height / 5.8);
    rStarsCanvas = p5.createGraphics(p5.width / 5.8, p5.height / 5.8);
    fireflyCanvas = p5.createGraphics(p5.width, p5.height);
    fireflyCanvas.colorMode(p5.RGB);
    snowCanvas = p5.createGraphics(p5.width, p5.height);

    bx = p5.width / 3.58 + p5.random(-20, 50);
    by = p5.height / 5.88 + p5.random(0, 58.8);

    // snow

    snowflakeCanvas = p5.createGraphics(p5.width, p5.height);
    snowflakes = new Snowflakes2(
      p5,
      snowflakeCanvas,
      p5.createVector(p5.random(p5.width), -10)
    );

    // white snow
    setupSnow1(p5, spritesheet, textures, snow, snowCanvas);

    // bg
    sceneCanvas = p5.createGraphics(p5.width, p5.height);
    sceneCanvas.colorMode(p5.RGB);
    for (let i = 0; i < 18; i++) {
      fireflies.push(
        new Firefly(
          p5,
          fireflyCanvas,
          fireflyCanvas.width / 3.88,
          fireflyCanvas.width / 1.18,
          fireflyCanvas.height / 1.18,
          fireflyCanvas.height / 2.88
        )
      );
    }

    // shooting star
    shootingStar = new ShootingStar1(p5);
    magicStar = new ShootingStar2(p5);
    // stars
    addStars(p5, lStarsCanvas, 5, lStars, lStarsCanvas);
    addStars(p5, rStarsCanvas, 8, rStars, rStarsCanvas);

    // grass
    grassScale = 0.3; // Adjust the scale factor to shrink the grass
    grassCanvas = p5.createGraphics(p5.width, p5.height);
    grassCanvas.clear();
    grassCanvas.stroke(100, 150, 0, 255);
    for (let i = 0; i <= grassCanvas.width; i += 40) {
      grass.push(
        new Grass(
          p5,
          grassCanvas,
          p5.createVector(i, grassCanvas.height + 30),
          grassScale,
          windFactor
        )
      );
    }
    grassCanvas.frameRate(8);
  }

  function getTimeIndex(h) {
    if (h >= 0 && h < 3) {
      return 0;
    } else if (h >= 3 && h < 6) {
      return 1;
    } else if (h >= 6 && h < 11) {
      return 2;
    } else if (h >= 11 && h < 16) {
      return 3;
    } else if (h >= 16 && h < 20) {
      return 4;
    } else if (h >= 20 && h < 24) {
      return 5;
    } else {
      return 6;
    }
  }

  function drawBackground() {
    // deep night
    const timeIndex = getTimeIndex(h);

    if (bgs[timeIndex]) {
      p5.background(bgs[timeIndex]);
    } else {
      drawBgs[timeIndex](p5, sceneCanvas);
      if (timeIndex === 0) {
        // deep night
        drawStars(p5, lStarsCanvas, rStarsCanvas, lStars, rStars, shootingStar);
      }
      if (timeIndex === 3) {
        // afternoon
        drawCloud(p5);
      }
    }
  }

  function drawBackgroundFront() {
    const timeIndex = getTimeIndex(h);

    if (!bgs[timeIndex]) {
      p5.image(grassCanvas, 0, 0);
    }

    // snowflakes, pink snow
    if (havePinkSnow && timeIndex === 4 && !bgs[timeIndex]) {
      if (
        (plantFeatures.plantType === 1 && p5.frameCount >= 188) ||
        plantFeatures.plantType === 2
      ) {
        drawSnow2(p5, snowflakeCanvas, snowflakes);
      }
    }

    // fireflies
    if (timeIndex === 0 && !bgs[timeIndex]) {
      if (
        (plantFeatures.plantType === 1 && p5.frameCount >= 188) ||
        plantFeatures.plantType === 2
      ) {
        drawFireflies(p5, fireflyCanvas, fireflies);
      }
    }

    if (haveSnow && timeIndex === 5 && !bgs[timeIndex]) {
      if (
        (plantFeatures.plantType === 1 && p5.frameCount >= 218) ||
        plantFeatures.plantType === 2
      ) {
        snowCanvas.clear();

        // white snow
        zOff += 0.1;
        // console.log('snow:', snow)

        for (let flake of snow) {
          let xOff = flake.pos.x / p5.width;
          let yOff = flake.pos.y / p5.height;
          let wAngle = p5.noise(xOff, yOff, zOff) * p5.TWO_PI;
          let wind = p5.constructor.Vector.fromAngle(wAngle);
          wind.mult(0.1);

          flake.applyForce();
          flake.applyForce(wind);
          flake.update();
          flake.render();
        }

        p5.image(snowCanvas, 0, 0);
      }
    }

    if (
      (plantFeatures.plantType === 1 && p5.frameCount >= 188) ||
      plantFeatures.plantType === 2
    ) {
      // butterflyFly(p5, butterfly, butterflies);
      if (timeIndex === 4) {
        if (!havePinkSnow) {
          if (butterflyChance > 0.5) {
            p5.image(butterflies, bx, by);
          }
        }
      } else if (timeIndex === 5) {
        if (!haveSnow) {
          if (butterflyChance > 0.5) {
            p5.image(butterflies, bx, by);
          }
        }
      } else {
        if (butterflyChance > 0.5) {
          p5.image(butterflies, bx, by);
        }
      }
    }

    // if (h >= 20 && h < 24 && bgNightFrontVideo) {
    //   drawVideoBackground(videoCanvas, vid);
    //   p5.image(videoCanvas, 0, 0);
    // }

    // if (h >= 20 && h < 24 && bgNightFront) {
    //   p5.image(bgNightFront, 0, 0);
    // }
  }

  function drawMagicFlowers() {
    // bg scene
    drawBackground();

    // shooting star
    p5.push();
    // magicStar.update();
    magicStar.draw(p5.drawingContext);
    p5.pop();
    p5.push();
    stone.resize(180, 0);
    p5.pop();

    // plant1
    if (plantFeatures.plantType === 1) {
      p5.push();
      p5.image(stone, p5.width / 8, p5.height - stone.height + 30);
      p5.pop();
      treeCanvas.push();
      tree1.grow(flower, flowers);
      treeCanvas.pop();

      p5.push();
      p5.image(treeCanvas, 0, 0);
      p5.pop();
    } else {
      treeCanvas.clear();
      treeCanvas.colorMode(p5.HSB, 360, 100, 100);
      treeCanvas.randomSeed(magicflowerId);

      treeCanvas.push();
      // createTree(p5);
      tree2.display(flower, flowers);
      treeCanvas.pop();

      // angle = angleOrigin + windFactor * p5.sin(p5.frameCount / 50);
      tree2.wave(windFactor);

      p5.push();
      p5.image(treeCanvas, 0, 0);
      p5.pop();
      p5.push();
      p5.image(stone, p5.width / 2 - 90, p5.height - stone.height + 30);
      p5.pop();
    }

    // treeCanvas.push();
    // tree1.grow(flower, flowers);
    // treeCanvas.pop();

    // p5.push();
    // p5.image(treeCanvas, 0, 0);
    // p5.pop();

    // grass
    grassCanvas.clear();

    for (let i = 0; i < grass.length; i++) {
      let g = grass[i];
      g.draw();
    }

    // fairy

    if (hasPlantSpirit) {
      if (plantSpirit.height > plantSpirit.width) {
        plantSpirit.resize(0, 158);
      } else {
        plantSpirit.resize(158, 0);
      }

      yPlantSpirit = p5.height - plantSpirit.height + 8;

      p5.image(plantSpirit, xPlantSpirit, yPlantSpirit);
    }

    drawBackgroundFront();

    if (controlPanel.showControlPanel) {
      p5.push();
      p5.fill(100, 150, 0, 255);
      p5.rect(0, p5.height - 118, 200, 118, 20);
      p5.pop();
    }
  }
}

export default MagicFlowerTree;
