import { Link as ReactLink } from 'react-router-dom';
import {
  Link,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  IconButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

function NavMenuGame() {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon w={7} h={7} />}
        variant="outline"
      ></MenuButton>
      <MenuList>
        <MenuItem>
          <Link as={ReactLink} to="/">
            Home
          </Link>
        </MenuItem>
        <MenuItem>
          <Link as={ReactLink} to="/magicflowers">
            Magic Flowers
          </Link>
        </MenuItem>
        <MenuItem>
          <Link as={ReactLink} to="/magicflowers/about">
            About Game
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/magicflowers/allmymagicflowers">
            My Blooming Collection
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/magicflowers/revive">
            Revival Heaven
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/magicflowers/hybridize">
            Hybrid Paradise
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/magicflowers/marketplace">
            Marketplace
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/magicflowers/auctions">
            Auction House
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/magicflowers/leasingmarket">
            Leasing Market
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/magicflowers/faq">
            Game FAQ
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/user">
            User
          </Link>
        </MenuItem>

        <MenuItem>
          <Link as={ReactLink} to="/jsoncreator">
            Json Creator
          </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default NavMenuGame;
