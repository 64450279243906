import GameTokenGallery from '../../../../../contracts/APIs/GameTokenGalleryInterface.sol/GameTokenGalleryInterface.json';
import Games from '../../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import { ethers } from 'ethers';
import isTokenOwner from '../../../../utils/isTokenOwner';
import getImageUrlByTokenType from '../../../../utils/getImageUrlByTokenType';
import { getGameFeaturesByFeatureIds } from '../../../NFTs/elements/token/getIntegratedGames';
import {
  REACT_APP_GAMES_ADDRESS,
  REACT_APP_GAME_TOKEN_GALLERY_ADDRESS,
} from '../../constants/ContractAddress';
const filterOutRemoved = arr => {
  return arr.filter(item => !item.removed);
};

// function expandArray(arr) {
//   return arr.flatMap(item => item.features.map(feature => ({ feature, id: item.id })));
// }
const add2Arrays = (arr1, arr2) => {
  if (arr1.length > 0 && arr2.length > 0) {
    return arr1.concat(arr2);
  } else if (arr1.length === 0) {
    return arr2;
  } else if (arr2.length === 0) {
    return arr1;
  }
};

function expandArray(arr) {
  return arr.flatMap(item =>
    item.features.map(feature => ({
      ...item, // spread all other keys from the original object
      feature, // add the new 'feature' key
    }))
  );
}

const getGalleries = async (provider, _gameTokenId, _user, _branch) => {
  const games = new ethers.Contract(
    REACT_APP_GAMES_ADDRESS,
    Games.abi,
    provider
  );

  const allFeatures = await games.getAllFeaturesByGame(_gameTokenId);

  const gameTokenGallery = new ethers.Contract(
    REACT_APP_GAME_TOKEN_GALLERY_ADDRESS,
    GameTokenGallery.abi,
    provider
  );

  const galleryData = await gameTokenGallery.getGalleries(_user, _gameTokenId);
  // console.log('galleryData:', galleryData);
  const _galleryData = filterOutRemoved(galleryData);
  // console.log('_galleryData:', _galleryData);

  let _galleries = await Promise.all(
    _galleryData.map(async d => {
      // console.log('d:', d)
      const tokenContract = d.tokenContract;
      const tokenId = Number(d.tokenId.toString());
      const imageUrl = await getImageUrlByTokenType(
        tokenId,
        tokenContract,
        d.isErc1155
      );
      // console.log('imageData:', imageData)
      // const imageUrl = imageData.imageUrl;
      const category = 'Image';
      const _features = await getGameFeaturesByFeatureIds(
        games,
        Number(d.gameId.toString()),
        d.featuresExternal,
        d.featuresReserved
      );
      const features = add2Arrays(
        _features._featuresExternal,
        _features._featuresReserved
      );

      // console.log('features:', features);
      const isErc1155 = d.isErc1155;
      const branch = d.branch;
      // console.log('branch:', branch)

      const toTokenUrl = `/tokens/${d.tokenContract}/${tokenId}`

      const isOwnerOfToken = await isTokenOwner(
        provider,
        tokenId,
        d.tokenContract,
        _user
      );

      // console.log('isOwnerOfToken:', isOwnerOfToken)

      // Return null if the owner or branch doesn't match the given criteria
      if (!isOwnerOfToken || branch != _branch) return null;

      return {
        features,
        tokenContract,
        tokenId,
        imageUrl,
        category,
        isErc1155,
        branch,
        toTokenUrl
      };
    })
  );

  // console.log('_galleries:', _galleries);

  // Filter out null values (items where the owner or branch didn't match the given criteria)
  _galleries = _galleries.filter(item => item !== null);
  const expandedArray = expandArray(_galleries);

  // console.log('explandedArray:', expandedArray);
  const res = {
    allFeatures,
    galleries: expandedArray,
  };
  return res;
};

export default getGalleries;
