import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link as ReactLink } from 'react-router-dom';
import {
  Stack,
  VStack,
  Box,
  Button,
  Text,
  Card,
  CardBody,
  Image,
  Divider,
  CardFooter,
  Link,
} from '@chakra-ui/react';
import { Player } from 'video-react';
import { HomeIcon } from '../../../../components/views/NFTs/elements/MiscDisplays';
import Gallery1 from '../../../../resources/images/magicGallery/gallery1.png';
import DefaultArtwork1 from '../../../../resources/images/magicGallery/defaultArtwork/1.png';
import DefaultArtwork2 from '../../../../resources/images/magicGallery/defaultArtwork/2.png';
import AllArtwork1 from '../../../../resources/images/magicGallery/allArtwork/1.png';
import AllArtwork2 from '../../../../resources/images/magicGallery/allArtwork/2.png';
import getGalleries from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getGalleries';
import RenderDefaultGallery from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/RenderDefaultGallery';
import Fence from '../../../../resources/images/magicGallery/fence.png';
import BackLink from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/BackLink';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import getDefaultData from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getDefaultData';
import GameFooter from '../../../../components/views/GameFooter';
import AddGalleryButton from '../../../../components/actions/cozyGames/cozyGame1/magicFlowers/AddGalleryButton';
const MyGallery = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const { magicflowerId } = useParams();
  // console.log('magicflowerId:', magicflowerId);
  const location = useLocation();
  const data = location.state;
  // console.log('data:', data);
  const [allFeatures, setAllFeatures] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [showDefaultGallery, setShowDefaultGallery] = useState(false);
  const [showAllGalleries, setShowAllGalleries] = useState(false);
  const [addImages, setAddImages] = useState({
    Fairy: '',
    FairyDie: '',
    FairyFigintingEvil: '',
    ImageOnTop: '',
  });
  const [userDefault, setUserDefault] = useState({});
  const addToSketch = (_feature, _imageUrl) => {
    const updatedAddImages = { ...addImages };
    updatedAddImages[_feature] = _imageUrl;
    setAddImages(updatedAddImages);
  };

  const setAsDefault = (
    _feature,
    tokenContract,
    tokenId,
    category,
    isERC1155,
    imageUrl
  ) => {
    const _tokenDetails = {
      tokenContract,
      tokenId,
      category,
      isERC1155,
      imageUrl,
    };
    const updatedUserDefault = { ...userDefault };
    updatedUserDefault[_feature] = _tokenDetails;
    setUserDefault(updatedUserDefault);
  };

  useEffect(() => {
    getItems();
  }, []);
  const getItems = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const gameTokenId = 1;
    const _galleries = await getGalleries(
      provider,
      gameTokenId,
      data.magicFlowerOwner,
      magicflowerId.toString()
    );

    console.log('_galleries:', _galleries);
    setGalleries(_galleries.galleries);
    setAllFeatures(_galleries.allFeatures);
    const _userDefault = await getDefaultData(
      provider,
      magicflowerId,
      data.magicFlowerOwner
    );
    // console.log('_userDefault:', _userDefault)
    setUserDefault(_userDefault);
  };

  const RenderGalleryImage = ({ gallery }) => {
    if (gallery.category == 'Video') {
      return <Player playsInline src={gallery.imageUrl} />;
    } else {
      return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link as={ReactLink} to={gallery.toTokenUrl}>
            <Image
              src={gallery.imageUrl}
              alt={gallery.imageUrl}
              style={{ height: '158px' }}
              borderRadius="lg"
            />
          </Link>
        </Box>
      );
    }
  };

  const RenderGallery = () => {
    return (
      <>
        <VStack>
          {galleries.length > 0 &&
            galleries.map((gallery, index) => (
              <Box key={index}>
                <VStack>
                  <Card>
                    <VStack>
                      <CardBody>
                        <VStack>
                          <RenderGalleryImage gallery={gallery} />

                          <Text>Feature: {gallery.feature}</Text>
                        </VStack>
                      </CardBody>
                      <Divider />
                      <CardFooter>
                        <VStack spacing="2">
                          <Box>
                            <Button
                              variant="outline"
                              colorScheme="green"
                              size="sm"
                              onClick={() =>
                                addToSketch(gallery.feature, gallery.imageUrl)
                              }
                            >
                              Add to sketch
                            </Button>
                          </Box>

                          <Box>
                            <Button
                              variant="outline"
                              colorScheme="green"
                              size="sm"
                              onClick={() =>
                                setAsDefault(
                                  gallery.feature,
                                  gallery.tokenContract,
                                  gallery.tokenId,
                                  gallery.category,
                                  gallery.isERC1155,
                                  gallery.imageUrl
                                )
                              }
                            >
                              Set as default
                            </Button>
                          </Box>
                        </VStack>
                      </CardFooter>
                    </VStack>
                  </Card>
                </VStack>
              </Box>
            ))}
        </VStack>
      </>
    );
  };

  const handleShowDefault = () => {
    if (showDefaultGallery) {
      setShowDefaultGallery(false);
    } else {
      setShowDefaultGallery(true);
    }
  };

  const handleShowAllGalleries = () => {
    if (showAllGalleries) {
      setShowAllGalleries(false);
    } else {
      setShowAllGalleries(true);
    }
  };

  const RenderShowDefaultButton = () => {
    if (showDefaultGallery) {
      return (
        <Box as="button" onClick={handleShowDefault}>
          <img
            src={DefaultArtwork2}
            style={{ width: '218px', height: 'auto' }}
          />
        </Box>
      );
    } else {
      return (
        <Box as="button" onClick={handleShowDefault}>
          <img
            src={DefaultArtwork1}
            style={{ width: '218px', height: 'auto' }}
          />
        </Box>
      );
    }
  };

  const RenderShowAllGalleriesButton = () => {
    if (showAllGalleries) {
      return (
        <Box as="button" onClick={handleShowAllGalleries}>
          <img src={AllArtwork2} style={{ width: '218px', height: 'auto' }} />
        </Box>
      );
    } else {
      return (
        <Box as="button" onClick={handleShowAllGalleries}>
          <img src={AllArtwork1} style={{ width: '218px', height: 'auto' }} />
        </Box>
      );
    }
  };

  const RenderShowButtons = () => {
    return (
      <>
        <Stack direction="row" spacing={4} align="center">
          <RenderShowDefaultButton />
          <RenderShowAllGalleriesButton />
        </Stack>
      </>
    );
  };

  return (
    <VStack>
      <VStack spacing={6}>
        <HomeIcon />
        <img src={Gallery1} style={{ width: '400px', height: 'auto' }} />

        <RenderShowButtons />
      </VStack>

      {showDefaultGallery && (
        <Box mt={8}>
          <RenderDefaultGallery
            magicflowerId={magicflowerId}
            obj={userDefault}
          />
        </Box>
      )}

      {showAllGalleries && (
        <VStack spacing={6} mt={8}>
          <Text
            style={{ fontFamily: 'Lobster' }}
            fontSize={['2xl', '3xl', '4xl', '5xl']}
            color="green.500"
          >
            All Artworks
          </Text>
          <RenderGallery />
        </VStack>
      )}

      <Box mt={8} mb={8}>
        <img src={Fence} style={{ width: '100%', height: 'auto' }} />
      </Box>
      <VStack mt={8}>
        <AddGalleryButton magicflowerId={magicflowerId} />
        <BackLink magicflowerId={magicflowerId} />
      </VStack>
      <div style={{ marginTop: '58px' }}>
        <GameFooter />
      </div>
    </VStack>
  );
};

export default MyGallery;
