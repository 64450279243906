export class ControlPanel {
  constructor(p5) {
    this.p5 = p5;

    this.showControlPanel = false;
    this.hideButton = undefined;

    this.selectTime = undefined;

    this.vPos = {
      top: 118,
      mid: 108,
      bottom: 58,
    };
  }

  createControlPanel() {
    this.showControlPanel = true;
    if (!this.hideButton) {
      this.hideButton = this.p5.createButton('Hide Control Panel');
      this.hideButton.position(30, this.p5.height - 138);
      this.hideButton.addClass('button-86');
      console.log('Control panel created');
    }

    
    if (!this.selectTime) {
      this.createTimeOptions();
    } else {
      this.selectTime.show();
    }
   
    this.hideButton.mousePressed(() => {
      this.removeControlPanel();
    });
  }

  removeControlPanel() {
    if (this.showControlPanel) {
      this.showControlPanel = false;
      if (this.hideButton) {
        this.hideButton.remove();
        this.hideButton = undefined; // Set controlPanelButton to undefined
      }
    
      this.selectTime.hide();
    
      console.log('Control panel removed');
    }
  }

  createTimeOptions() {
    this.selectTime = this.p5.createSelect();
    this.selectTime.position(10, this.p5.height - this.vPos.bottom - 5);

    // Add color options.
    this.selectTime.option('Current Time');
    this.selectTime.option('Deep Night');
    this.selectTime.option('Dawn');
    this.selectTime.option('Morning');
    this.selectTime.option('Afternoon');
    this.selectTime.option('Evening');
    this.selectTime.option('Night');
    this.selectTime.style('width', '180px');
    this.selectTime.style('font-color', 'white');
    this.selectTime.style('background-color', 'blue');
    this.selectTime.style('border-radius', '10px');
    this.selectTime.style('padding', '0.2em');
    // selectTime.style('font-size', '20px');

    // Set the selected option to "red".
    this.selectTime.selected('24-Hour Natural Cycle');
  }


}
