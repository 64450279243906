import { useEffect, useState } from 'react';
import {
  Link,
  VStack,
  Box,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import MagicFlowers from '../../../../contracts/interfaces/IMagicFlowers.sol/IMagicFlowers.json';
import { Link as ReactLink } from 'react-router-dom';
import SkyFlower from '../../../../resources/images/games/magicflowers/flower.png';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import DetailButton from '../../../../components/views/NFTs/elements/DetailButton';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import GameFooter from '../../../../components/views/GameFooter';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../../components/views/cozyGames/constants/ContractAddress';
function AllMagicFlowers() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    // Call getItem when address changes
    getItem();

    // Specify address as a dependency
  }, []);

  const [allTokens, setAllTokens] = useState([]);

  const getItem = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const magicFlowers = new ethers.Contract(
      REACT_APP_MAGIC_FLOWERS_ADDRESS,
      MagicFlowers.abi,
      provider
    );
    let _allTokens = await magicFlowers.getCurrentTokenCounter();
    _allTokens = Number(_allTokens.toString());
    // Create an empty array
    let array = [];

    // Loop from 1 to n and push each number into the array
    for (let i = 1; i <= _allTokens; i++) {
      array.push(i);
    }
    // console.log('array:', array)
    setAllTokens(array);
  };

  const ShowAllTokens = () => {
    return (
      <Wrap spacing={6}>
        {allTokens.length &&
          allTokens.map((t, key) => (
            <WrapItem key={key}>
              <Box >
                <VStack>
                  <Box sx={basicBoxStyles} filter="grayscale(10%)">
                    <VStack>
                      <Text fontSize="sm" as="i">
                        NFT ID:
                      </Text>
                      <Text
                        fontSize="md"
                        as="b"
                        style={{ fontFamily: 'Lobster' }}
                      >
                        {t}
                      </Text>
                      <Link
                        as={ReactLink}
                        to={`/magicflowers/magicflower/${t}`}
                      >
                        <DetailButton label='Enter'/>
                      </Link>
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            </WrapItem>
          ))}
      </Wrap>
    );
  };

  const basicBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: '218px',
    color: 'white',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
    px: 4,
    background: `url(${SkyFlower}) center/cover no-repeat`,
  };

  return (
    <VStack spacing={6}>
      <MagicFlowerTopGrid />
      <VStack mt={3}>
        <Text
          color="green.300"
          fontSize={[24, 30, 36, 48]}
          style={{ fontFamily: 'Lobster' }}
        >
          All Revived & Hybridized Magic Flowers
        </Text>

        {allTokens.length > 0 && <ShowAllTokens />}

        <div style={{ marginTop: '58px' }}>
          <GameFooter />
        </div>
      </VStack>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default AllMagicFlowers;
