import { Box, Button, Text } from '@chakra-ui/react';
import convertUnixTime from './utils/convertUnixTime';
import getSexType from './utils/getSexType';
function RenderMagicFlowerMeta({ meta, leaseDetail }) {
  // console.log('meta:', meta)
  // console.log('leaseDetail:', leaseDetail)
  const RenderHybrid = () => {
    if (meta.isHybridization) {
      return <Text>Hybrid</Text>;
    } else {
      return <Text>Progenitor Plant</Text>;
    }
  };
  const MagicFlowerMetadata = () => {
    return (
      <>
        {Object.keys(meta).length > 0 && (
          <Box
            style={{
              display: 'grid',
              minHeight: '200px',
              border: '8px solid',
              padding: '1rem',
              borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
              marginTop: '20px',
            }}
          >
            <Text>Plant Type: {meta.plantType === 1 ? 'Tree' : 'Plant'}</Text>
            <RenderHybrid />
            <Text>Birth Date: {convertUnixTime(Number(meta.birthdate))}</Text>
            <Text>{getSexType(meta.sex)}</Text>
            <Text>Total Offsprings: {meta.totalOffsprings} </Text>
            {leaseDetail.hasLease && (
              <>
                <Text>Leasing price: {leaseDetail.leasePrice} MATIC</Text>
                <Button colorScheme="green" variant="outline">
                  Lease This Magic Flower Plant
                </Button>
              </>
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <MagicFlowerMetadata />

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default RenderMagicFlowerMeta;
