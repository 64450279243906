import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Card,
  CardBody,
  VStack,
  HStack,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { FaInfo } from 'react-icons/fa';
import { useState } from 'react';
import { ethers } from 'ethers';
import Games from '../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import {
  UrlPrefixWarning,
} from '../../../components/views/NFTs/elements/FormElements';
// import AddFeaturesForm from '../../../components/formComponents/AddFeaturesForm';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { REACT_APP_GAMES_ADDRESS } from '../../views/cozyGames/constants/ContractAddress';
import { Player } from 'video-react';
import SwitchForm from '../../formComponents/SwtichForm';
const AddGameBase = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [hasBranch, setHasBranch] = useState(false);
  const [gameFeaturesExternal, setGameFeaturesExternal] = useState('');
  const [gameFeaturesReserved, setGameFeaturesReserved] = useState('');
  const [posterUrl, setPosterUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [urlToGame, setUrlToGame] = useState('');
  const [trialUrl, setTrialUrl] = useState('');

  const [loading, setLoading] = useState(false);
  const create = async () => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      // let _cid = await makeGameMetaUrl(meta);

      //   console.log('_cid:', _cid);

      const games = new ethers.Contract(
        REACT_APP_GAMES_ADDRESS,
        Games.abi,
        signer
      );

      const _mintingFee = await games.getFee();
      //   console.log('_mintingFee', _mintingFee);
      let transactionList;

      const _gameFeaturesExternal = gameFeaturesExternal
        .split(',')
        .map(item => item.trim());

      const _gameFeaturesReserved = gameFeaturesReserved
        .split(',')
        .map(item => item.trim());

      transactionList = await games.addGame(
        name,
        description,
        posterUrl,
        videoUrl,
        urlToGame,
        trialUrl,
        _gameFeaturesExternal,
        _gameFeaturesReserved,
        hasBranch,
        {
          value: _mintingFee,
        }
      );

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="green"
          size="lg"
          variant="outline"
        >
          Add Game
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          colorScheme="green"
          size="lg"
          variant="outline"
        >
          Add Game
        </Button>
      );
    }
  };
  const handleGameFeaturesExternal = val => {
    setGameFeaturesExternal(val);
  };
  const handleGameFeaturesReserved = val => {
    setGameFeaturesReserved(val);
  };

  const FeatureInfoPopover = () => {
    return (
      <Popover>
        <PopoverTrigger>
          <IconButton
            variant="outline"
            colorScheme="green"
            aria-label="Get Info"
            size="xs"
            icon={<FaInfo boxSize={8} />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            Separate features with comma, for example: Butterfly, Background,
            Evil
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <VStack>
      <Card maxW="lg">
        <CardBody>
          <Box mb="20px">
            <FormControl isRequired>
              <FormLabel as="legend">
                Game Features Allowed for External Integration :
                <FeatureInfoPopover />
              </FormLabel>
              <Input
                value={gameFeaturesExternal}
                onChange={e => handleGameFeaturesExternal(e.target.value)}
                placeholder="Game Features Allowed for External Integration"
                size="lg"
              />
            </FormControl>
          </Box>
          <Box mb="20px">
            <FormControl isRequired>
              <FormLabel as="legend">
                Game Features Reserved for Internal Integration Only :
                <FeatureInfoPopover />
              </FormLabel>
              <Input
                value={gameFeaturesReserved}
                onChange={e => handleGameFeaturesReserved(e.target.value)}
                placeholder="Game Features Reserved for Internal Integration Only"
                size="lg"
              />
            </FormControl>
          </Box>
          <Box mb="20px">
            <SwitchForm
              item={hasBranch}
              setItem={setHasBranch}
              label="Game Has Branch"
            />
          </Box>
          <Box mb="20px">
            <FormControl>
              <FormLabel as="legend">Url to Game Poster:</FormLabel>

              <Input
                name="posterUrl"
                value={posterUrl}
                onChange={e => setPosterUrl(e.target.value)}
                placeholder="Url to Game Poster"
                size="sm"
              />
            </FormControl>
          </Box>
          <img src={posterUrl} />
          <Box style={{ marginBottom: '20px' }}>
            <FormControl>
              <FormLabel as="legend">
                Youtube Url to Game Introduction Video:
              </FormLabel>

              <Input
                name="videoUrl"
                value={videoUrl}
                onChange={e => setVideoUrl(e.target.value)}
                placeholder="Youtube Url to Game Introduction Video"
                size="sm"
              />
            </FormControl>
          </Box>
          {videoUrl.length > 0 && <Player playsInline src={videoUrl} />}
          <Box style={{ marginBottom: '20px' }}>
            <FormControl isRequired>
              <FormLabel as="legend">Game Name:</FormLabel>

              <Input
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Game name"
                size="sm"
              />
            </FormControl>
          </Box>
          <Box style={{ marginBottom: '20px' }}>
            <FormControl>
              <FormLabel as="legend">Game Description:</FormLabel>

              <Input
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Game description"
                size="sm"
              />
            </FormControl>
          </Box>
          {/* <Box style={{ marginBottom: '20px' }}>
            <FormControl>
              <FormLabel as="legend">
                Tags for Game:
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      variant="outline"
                      colorScheme="green"
                      aria-label="Get Info"
                      size="xs"
                      icon={<FaInfo boxSize={8} />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      Separate tags with comma, for example: fairy, beautiful,
                      romantic, ...
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </FormLabel>
              <Input
                name="tags"
                value={meta.tags}
                onChange={e => handleMeta('tags', e.target.value)}
                placeholder="Tags"
                size="sm"
              />
            </FormControl>
          </Box> */}
          <Box style={{ marginBottom: '20px' }}>
            <FormControl isRequired>
              <FormLabel as="legend">External Url for This Game:</FormLabel>

              <UrlPrefixWarning urlType="general" />

              <Input
                name="externalUrlToGame"
                value={urlToGame}
                onChange={e => setUrlToGame(e.target.value)}
                placeholder={'External url to game'}
                size="sm"
              />
            </FormControl>
          </Box>

          <Box style={{ marginBottom: '20px' }}>
            <FormControl>
              <FormLabel as="legend">NFT Trial Url for This Game:</FormLabel>
              <HStack>
                <InfoIcon />
                <Text as="i" fontSize="xs">
                  The NFT trial URL and the external URL must come from the same
                  domain. If they are not from the same domain, a warning will
                  be displayed for this game.
                </Text>
              </HStack>

              <Input
                name="trialUrl"
                value={trialUrl}
                onChange={e => setTrialUrl(e.target.value)}
                placeholder="Trial Url and the game url must come from the same domain "
                size="sm"
              />
            </FormControl>
          </Box>
          {/* <SocialMediaForm
            socialMediaFields={socialMediaFields}
            meta={meta}
            handleMeta={handleMeta}
          /> */}
        </CardBody>
      </Card>
      <RenderButton />
    </VStack>
  );
};

export default AddGameBase;
