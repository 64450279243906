import React from 'react';
import '../styles/Fonts.css';
import { Link as ReactLink } from 'react-router-dom';
import {
  VStack,
  Wrap,
  WrapItem,
  Link,
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import SketchGame from '../components/sketches/SketchGame';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import Rabbit from '../resources/images/animals/rabbit.gif';
import Hedgehog from '../resources/images/animals/hedgehog.gif';
import Campaign from '../components/views/cozyGames/misc/Campaign';
const Home = () => {
  return (
    <>
      <ReactP5Wrapper sketch={SketchGame} />

      <VStack spacing={6} mt={6}>
        <Box>
          <Text
            color="green.400"
            fontSize={[33, 47, 58]}
            style={{ fontFamily: 'Lobster' }}
          >
            Cozy Games XYZ
          </Text>
        </Box>
        <Box>
          <Text
            color="green.400"
            fontSize={[20, 22, 28]}
            style={{ fontFamily: 'Lobster' }}
          >
            Welcome to Cozy Games XYZ, your serene escape into the world of cozy
            gaming.
          </Text>
        </Box>
        <Wrap spacing={6}>
          <WrapItem>
            <Box>
              <VStack>
                <img src={Rabbit} alt="Rabbit" />
                <Text
                  color="green.400"
                  fontSize="3xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Cozy
                </Text>
              </VStack>
            </Box>
          </WrapItem>
          <WrapItem>
            <Box>
              <VStack>
                <img src={Hedgehog} alt="Hedgehog" />
                <Text
                  color="green.400"
                  fontSize="3xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Relaxing
                </Text>
              </VStack>
            </Box>
          </WrapItem>
          <WrapItem>
            <Box>
              <VStack>
                <img
                  src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1721917619/cat_w9sxfe.gif"
                  alt="Cat"
                />
                <Text
                  color="green.400"
                  fontSize="3xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Fun
                </Text>
              </VStack>
            </Box>
          </WrapItem>
        </Wrap>
        <Box>
          <Text
            color="green.400"
            fontSize={[26, 36, 47]}
            style={{ fontFamily: 'Lobster' }}
          >
            Game List
          </Text>
        </Box>
        <Tabs variant="soft-rounded" colorScheme="green" align="center">
          <TabList>
            <Tab>Cozy Blockchain Games</Tab>
            <Tab>Cozy Digital Games</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box
                w="400px"
                style={{
                  display: 'grid',
                  minHeight: '200px',
                  border: '8px solid',
                  padding: '1rem',
                  borderImage:
                    'linear-gradient(45deg, turquoise, greenyellow) 1',
                  marginTop: '20px',
                }}
              >
                <VStack>
                  <Link as={ReactLink} to="/magicflowers">
                    <img
                      src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370825/cat1_edquhz.gif"
                      alt="Magic Flowers NFT Game"
                    />
                  </Link>
                  <Text
                    color="green.400"
                    fontSize="2xl"
                    style={{ fontFamily: 'Lobster' }}
                  >
                    Magic Flowers NFT Game
                  </Text>
                </VStack>
              </Box>
              <Text
                color="green.400"
                fontSize="2xl"
                style={{ fontFamily: 'Lobster' }}
              >
                More games coming soon...
              </Text>
            </TabPanel>
            <TabPanel>
              <Text
                color="green.400"
                fontSize="2xl"
                style={{ fontFamily: 'Lobster' }}
              >
                Coming soon...
              </Text>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Campaign isDetailed={false} isBlueGradient={false} />
      </VStack>
    </>
  );
};

export default Home;
