import { Link as ReactLink } from 'react-router-dom';
import {
  Link,
  VStack,
  Box,
  Wrap,
  WrapItem,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';
function RenderCurrentListing({ currentListing, tokenType }) {
  // console.log('currentListing:', currentListing);
  // console.log('tokenType:', tokenType);

  const RenderLatestListing = ({ isMarketplace }) => {
    const saleIds = isMarketplace
      ? currentListing.saleIdsMarketplace
      : currentListing.saleIdsAuction;
    let _toUrl = '';
    let _label = '';
    if (tokenType === 'Erc721') {
      _toUrl = `/marketplace/erc721/${saleIds[saleIds.length - 1]}`;
      _label = 'Marketplace ERC721';
    } else if (tokenType === 'Erc1155') {
      _toUrl = `/marketplace/erc1155/${saleIds[saleIds.length - 1]}`;
      _label = 'Marketplace ERC1155';
    } else if (tokenType === 'Erc721') {
      _toUrl = `/auctions/${saleIds[saleIds.length - 1]}`;
      _label = 'Auction';
    }

    return (
      <Box
        p={4}
        color="white"
        fontWeight="bold"
        borderRadius="md"
        bgGradient="linear(to-r, #439cfb, #f187fb)"
        _hover={{
          bgGradient: 'linear(to-r, teal.300, green.300)',
        }}
      >
        <Link as={ReactLink} to={_toUrl}>
          <VStack>
            <Text fontSize="xl">Latest Listing</Text>

            <Text fontSize="2xl" style={{ fontFamily: 'Lobster' }}>
              #{saleIds[saleIds.length - 1]}
            </Text>
            <Text>{_label}</Text>
          </VStack>{' '}
        </Link>
      </Box>
    );
  };

  return (
    <>
      <Wrap>
        <WrapItem>
          {currentListing.saleIdsMarketplace.length > 0 && (
            <RenderLatestListing isMarketplace={true} />
          )}
        </WrapItem>
        <WrapItem>
          {currentListing.saleIdsAuction.length > 0 && (
            <RenderLatestListing isMarketplace={false} />
          )}
        </WrapItem>
      </Wrap>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default RenderCurrentListing;
