import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { VStack, Wrap, WrapItem, Link, Box, Text } from '@chakra-ui/react';
// import SketchPiggy from '../components/sketches/SketchPiggy';
// import { ReactP5Wrapper } from '@p5-wrapper/react';
import StudioGif from '../resources/images/games/magicflowers/dreamstudio.gif';
import MarketplaceGif from '../resources/images/games/magicflowers/marketplace.gif';
import AuctionGif from '../resources/images/games/magicflowers/auctionHouse.gif';
import { HomeIcon } from '../components/views/NFTs/elements/MiscDisplays';
const SereneNFTbazaar = () => {
  return (
    <>
      <VStack spacing={6}>
        <HomeIcon />
        <Box mb='20px'>
          <Text
            color="green.400"
            fontSize={[33, 47, 58]}
            style={{ fontFamily: 'Lobster' }}
          >
            Serene NFT bazaar
          </Text>
        </Box>

        <Wrap spacing={10} mb='30px'>
          <WrapItem>
            <VStack>
              <Box>
                <img src={MarketplaceGif} alt="Door and Cat" />
              </Box>
              <Link as={ReactLink} to="/marketplace">
                <Text
                  color="green.400"
                  fontSize="2xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Marketplace
                </Text>
              </Link>
            </VStack>
          </WrapItem>
          <WrapItem>
            <VStack>
              <Box>
                <img src={AuctionGif} alt="Door and Squirrel" />
              </Box>
              <Link as={ReactLink} to="/auctions">
                <Text
                  color="green.400"
                  fontSize="2xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Auction House
                </Text>
              </Link>
            </VStack>
          </WrapItem>
          <WrapItem>
            <VStack>
              <Box>
                <img src={StudioGif} alt="Round Door" />
              </Box>
              <Link as={ReactLink} to="/dreamstudio">
                <Text
                  color="green.400"
                  fontSize="2xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Dreamscape Studio
                </Text>
              </Link>
            </VStack>
          </WrapItem>
        </Wrap>
      </VStack>
    </>
  );
};

export default SereneNFTbazaar;
