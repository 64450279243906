import { useState } from 'react';
import { Button } from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import Games from '../../../../../contracts/APIs/GamesInterface.sol/GamesInterface.json';
import { REACT_APP_GAMES_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';
const CancelGame = ({ gameId }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);

  const cancelGame = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const games = new ethers.Contract(
        REACT_APP_GAMES_ADDRESS,
        Games.abi,
        signer
      );

      let transactionList;

      transactionList = await games.cancelGame(gameId);

      await transactionList.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="red"
          size="lg"
          variant="outline"
        >
          Cancel Game
        </Button>
      );
    } else {
      return (
        <Button
          onClick={cancelGame}
          colorScheme="red"
          size="lg"
          variant="outline"
        >
          Cancel Game
        </Button>
      );
    }
  };

  return (
    <div>
      <RenderButton />
    </div>
  );
};

export default CancelGame;
