import { HStack, Grid, GridItem } from '@chakra-ui/react';
import NavMenuGame from '../../../NavMenus/NavMenuGame';
import { HomeIcon } from '../../../NFTs/elements/MiscDisplays';
import { ColorModeSwitcher } from '../../../../../ColorModeSwitcher';
import ConnectButton from '../../../../../walletConnections/ConnectButton.js';
const MagicFlowerTopGrid = () => {
  return (
    <Grid templateColumns="1fr 1fr auto 1fr 1fr" gap={4} w="full">
      <GridItem colStart={1} colEnd={2} h="10">
        <HStack>
          <NavMenuGame />
        </HStack>
      </GridItem>

      <GridItem colStart={3} colEnd={4} h="10">
        <HomeIcon />
      </GridItem>

      <GridItem colStart={5} colEnd={6} h="10" justifySelf="flex-end">
        <HStack justifyContent="flex-end" w="full">
          <ColorModeSwitcher />
          <ConnectButton />
        </HStack>
      </GridItem>
    </Grid>
  );
};

export default MagicFlowerTopGrid;
