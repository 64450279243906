import { Icon, Text } from '@chakra-ui/react';
import { FaHeart, FaCanadianMapleLeaf } from 'react-icons/fa';
const CreatorInfo = () => {
  return (
    <Text
      style={{
        letterSpacing: '1px',
      }}
    >
      Made with <Icon as={FaHeart} w={5} h={5} color="red.500" /> in{' '}
      <Icon as={FaCanadianMapleLeaf} w={6} h={6} color="red.500" /> by Dream
      Lotus Studio
    </Text>
  );
};

export default CreatorInfo;
