import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Spinner, ListItem, List, Icon, HStack } from '@chakra-ui/react';
import { HomeIcon } from '../../components/views/NFTs/elements/MiscDisplays';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import AddGameModal from '../../components/actions/NFTs/gameIntegration/AddGameModal';
import { GiCutDiamond, GiButterfly } from 'react-icons/gi';
import MintMagicFlowerByAdmin from '../../components/actions/cozyGames/cozyGame1/magicFlowers/MintMagicFlowerByAdmin';
const AdminPage = () => {
  const { address } = useWeb3ModalAccount();
  console.log('address:', address);
  const { walletProvider } = useWeb3ModalProvider();
  console.log(process.env);
  const adminAddress = '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266'; //process.env.REACT_APP_ADMIN_ADDRESS

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof address !== 'undefined') {
      setIsLoading(false);
    }
  }, [address]);

  const RenderAdminPanel = () => {
    if (isLoading) {
      return (
        <Box textAlign="center" mt={10}>
          <Spinner size="xl" />
          <Text mt={4}>Loading...</Text>
        </Box>
      );
    } else {
      const lowercaseAddress = address ? address.toLowerCase() : ''; // Convert obtained address to lowercase

      if (lowercaseAddress === adminAddress.toLowerCase()) {
        return (
          <VStack>
            <HomeIcon />
            <Box textAlign="center">
              <Box style={{ marginBottom: '30px' }}>
                <Text
                  color="green.400"
                  fontSize={[36, 42, 68]}
                  style={{ fontFamily: 'Lobster' }}
                >
                  Admin
                </Text>
                <Text color="green.400">{address}</Text>
              </Box>

              <VStack>
                <List spacing={3}>
                  <ListItem>
                    <HStack>
                      <Icon as={GiCutDiamond} color="green.300" />
                      <AddGameModal />
                    </HStack>
                  </ListItem>

                  <ListItem>
                    <HStack>
                      <Icon as={GiCutDiamond} color="green.300" />
                      <MintMagicFlowerByAdmin />
                    </HStack>
                  </ListItem>
                </List>
              </VStack>
            </Box>
          </VStack>
        );
      } else {
        return (
          <Box textAlign="center" mt={10}>
            <HomeIcon />
            <Text mt={4}>Not Authorized</Text>
          </Box>
        );
      }
    }
  };

  return <RenderAdminPanel />;
};

export default AdminPage;
