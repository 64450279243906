export class Firefly {
  constructor(p5, canvas, x1, x2, y1, y2) {
    this.p5 = p5;
    this.canvas = canvas;
    this.locX = this.p5.random(x1, x2);
    this.locY = this.p5.random(y1, y2);
    this.directions = [-2, -1, 1, 2];
    this.radius = this.p5.random(20, 21);
    this.fireflySize = this.p5.random(2, 8);
    this.light = this.p5.random(50, 100);
    this.directionX = this.p5.random(this.directions);
    this.directionY = this.p5.random(this.directions);
    this.angle = 0;
    this.range = this.p5.random(0, 20);
    this.fireflyX =
      this.locX +
      this.p5.cos(this.angle) * this.radius * this.directionX +
      this.range;
    this.fireflyY =
      this.locY +
      this.p5.sin(this.angle) * this.radius * this.directionY +
      this.range;
    this.speed = 0.02;
  }

  show() {
    this.canvas.noStroke();
    this.canvas.fill(255, 245, 116, this.light);
    this.canvas.ellipse(
      this.fireflyX,
      this.fireflyY,
      this.fireflySize + 10,
      this.fireflySize + 10
    );
    this.canvas.fill(255, 245, 116);
    this.canvas.ellipse(
      this.fireflyX,
      this.fireflyY,
      this.fireflySize - 1,
      this.fireflySize - 1
    );
    this.angle += this.speed;
    this.fireflyX =
      this.locX +
      this.p5.cos(this.angle) * this.radius * this.directionX +
      this.range;
    this.fireflyY =
      this.locY +
      this.p5.sin(this.angle) * this.radius * this.directionY +
      this.range;
  }
}
