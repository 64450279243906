import React, { useState, useEffect } from 'react';
import {
  Link,
  Box,
  Text,
  Stack,
  VStack,
  Grid,
  RadioGroup,
  Radio,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useMultiStyleConfig,
  useTab,
  Button,
} from '@chakra-ui/react';
import { Link as ReactLink, useLocation, useParams } from 'react-router-dom';
import Search from '../../../components/searches/Search';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
import ValleyItemListBase from '../../../components/views/NFTs/elements/valleyItems/ValleyItemListBase';
import { zeroAddress } from '../../../components/utils/helpers';
import MarketplaceMagicFlowers from '../../../resources/images/games/magicflowers/MarketplaceMagicFlowers.gif';
import AuctionHouseMagicFlowers from '../../../resources/images/games/magicflowers/AuctionHouseMagicFlowers.gif';
import GallerySpotLight from '../../../components/views/NFTs/featured/GallerySpotLight';
import { TextOnImage1 } from '../../../components/views/NFTs/elements/TextOnImages';
import getFeaturedItems from '../../../components/views/NFTs/featured/getFeaturedItems';
import getGameTokenIdByCozyGameId from '../../../components/views/cozyGames/utils/getGameTokenIdByCozyGameId';
import MagicFlowerTopGrid from '../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import GameFooter from '../../../components/views/GameFooter';
import { REACT_APP_MAGIC_FLOWERS_ADDRESS } from '../../../components/views/cozyGames/constants/ContractAddress';
function SaleBaseCozyGame({ saleType }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  // const { cozyGameId } = useParams();
  // console.log('cozyGameId:', cozyGameId);
  const [gameTokenId, setGameTokenId] = useState(0);
  const [featuredItems, setFeaturedItems] = useState({});
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const _gameTokenId = getGameTokenIdByCozyGameId(1);
    setGameTokenId(_gameTokenId);
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const _featurePage =
      saleType === 'Marketplace' ? 'marketplaceGame' : 'auctionHouseGame';

    const _featuredItems = await getFeaturedItems(provider, _featurePage, 0);
    // console.log('_featuredItems:', _featuredItems);
    if (
      typeof _featuredItems !== 'undefined' ||
      Object.keys(_featuredItems).length > 0
    ) {
      setFeaturedItems(_featuredItems);
    }

    setIsLoading(false);
  };

  const [value, setValue] = useState('1');

  const DisplayList = ({ tokenType, saleType }) => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);

    return (
      <ValleyItemListBase
        provider={provider}
        tokenType={tokenType}
        saleType={saleType}
        marketplaceIds={[]}
        auctionIds={[]}
        byUser={false}
        userAddr=""
        forGame={false}
        forExhibition={false}
        fromSpecificContract={false}
        specificTokenContract={zeroAddress}
        forSpecificGame={true}
        specificGameTokenId={gameTokenId}
      />
    );
  };

  const CustomTab = React.forwardRef((props, ref) => {
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <Button __css={styles.tab} {...tabProps}>
        <Box as="span" mr="2">
          {isSelected ? '😎' : '😐'}
        </Box>
        {tabProps.children}
      </Button>
    );
  });

  const RenderImage = () => {
    if (saleType === 'Marketplace') {
      return (
        <img
          src={MarketplaceMagicFlowers}
          alt="Penguin with Marketplace Sign"
        />
      );
    } else {
      return (
        <img src={AuctionHouseMagicFlowers} alt="Cat with Auction House Sign" />
      );
    }
  };

  return (
    <>
      <Box>
        {' '}
        <MagicFlowerTopGrid />
      </Box>
      <VStack>
        <RenderImage />

        <Text
          color="teal.500"
          fontSize={[36, 42, 58]}
          style={{ fontFamily: 'Lobster' }}
        >
          {saleType == 'Marketplace' ? 'Marketplace' : 'Auction House'}
        </Text>
        <Text color="teal.500" fontSize={28} style={{ fontFamily: 'Lobster' }}>
          -- for{' '}
          <Link as={ReactLink} to="/magicflowers">
            Magic Flowers
          </Link>
        </Text>

        <Link as={ReactLink} to="/dreamstudio">
          <Box boxSize="280px">
            <TextOnImage1
              t={
                saleType === 'Marketplace'
                  ? 'Create/Sell NFTs'
                  : 'Create/Auction NFTs'
              }
            />
          </Box>
        </Link>

        <GallerySpotLight
          featurePage={
            saleType === 'Marketplace' ? 'marketplaceGame' : 'auctionHouseGame'
          }
          featuredItems={featuredItems}
        />
        <Box textAlign="center" fontSize="xl">
          <Grid minH="100vh" p={3}>
            <VStack spacing={3}>
              <Search />

              {gameTokenId == 1 && (
                <Box>
                  <RadioGroup onChange={setValue} value={value}>
                    <Stack direction="row">
                      <Radio value="1" colorScheme="green">
                        Galleries for Magic Flowers NFTs
                      </Radio>
                      <Radio value="2" colorScheme="green">
                        Magic Flowers NFTs
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
              )}

              <Tabs isFitted isLazy colorScheme="green">
                <TabList mb="1em">
                  <CustomTab>ERC721</CustomTab>
                  <CustomTab>ERC1155</CustomTab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <DisplayList tokenType="Erc721" saleType={saleType} />
                  </TabPanel>
                  <TabPanel>
                    <DisplayList tokenType="Erc1155" saleType={saleType} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </Grid>
          <div style={{ marginTop: '58px' }}>
            <GameFooter />
          </div>
        </Box>
      </VStack>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default SaleBaseCozyGame;
