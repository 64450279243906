import { Link as ReactLink } from 'react-router-dom';
import {
  Icon,
  Text,
  Link,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
} from '@chakra-ui/react';
import {
  GiBeveledStar,
  GiTwirlyFlower,
  GiLotusFlower,
  GiHighGrass,
  GiButterfly,
} from 'react-icons/gi';

const NavBreadCrumb = () => {
  return (
    <Box>
      <Breadcrumb
        separator={<Icon as={GiButterfly} w={6} h={6} color="green.300" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/">
              <Text as="i" fontSize="sm">
                Home
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/about">
              <Text as="i" fontSize="sm">
                About
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {/* 
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/dreamminting">
              <Text as="i" fontSize="sm">
                Dream Minting Studio
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem> */}

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/blockchaingames">
              <Text as="i" fontSize="sm">
                Cozy Blockchain Games
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/digitalgames">
              <Text as="i" fontSize="sm">
                Cozy Digital Games
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/magicflowers">
              <Text as="i" fontSize="sm">
                Magic Flowers
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/nftbazaar">
              <Text as="i" fontSize="sm">
                Serene NFT Bazaar
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/user">
              <Text as="i" fontSize="sm">
                User
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/membership">
              <Text as="i" fontSize="sm">
                Membership
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/faq">
              <Text as="i" fontSize="sm">
                FAQ
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
};

export default NavBreadCrumb;
