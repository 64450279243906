import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { ethers } from 'ethers';
import AuctionHouse from '../../../../contracts/APIs/AuctionHouseInterface.sol/AuctionHouseInterface.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import {
  REACT_APP_AUCTION_HOUSE_ADDRESS
} from '../../../views/cozyGames/constants/ContractAddress';
const CancelAuction = props => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  // console.log('props:', props.auctionId);

  const cancel = async () => {
    setLoading(true);
    // const web3Modal = new Web3Modal(process.env.REACT_APP_PROJ_ADDRESS);
    // const connection = await web3Modal.connect();
    // const provider = new ethers.providers.Web3Provider(connection);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('signer:', signer)
    // const signer_address = await signer.getAddress();

    try {
      const auctionHouse = new ethers.Contract(
        REACT_APP_AUCTION_HOUSE_ADDRESS,
        AuctionHouse.abi,
        signer
      );

      const transaction = await auctionHouse.cancelAuction(props.auctionId);

      await transaction.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="whatsapp"
          variant="outline"
          size="md"
          mr={3}
        >
          Claim Rewards
        </Button>
      );
    } else {
      return (
        <Button
          onClick={cancel}
          colorScheme="whatsapp"
          variant="outline"
          size="md"
          mr={3}
        >
          Cancel Auction
        </Button>
      );
    }
  };

  return (
    <>
      <Box>
        <RenderButton />
      </Box>
    </>
  );
};

export default CancelAuction;
