import { ethers } from 'ethers';

export const zeroAddress = ethers.constants.AddressZero;

export const getCidUrlNftstorage = cid => {
  return `https://${cid}.ipfs.nftstorage.link/`;
};

export const getCidUrlWeb3storage = imageUrl => {
  return `https://${imageUrl.cid}.ipfs.dweb.link/${imageUrl.fileName}`;
};

export const allTokenContracts = [process.env.REACT_APP_MAGIC_NFT_ADDRESS];

export const convertTimestamp = _timestamp => {
  const myTimeStamp = Number(_timestamp) * 1000;
  const newDate = new Date(myTimeStamp);

  const outDate =
    newDate.getMonth() +
    1 +
    '/' +
    newDate.getDate() +
    '/' +
    newDate.getFullYear();

  return outDate;
};

export const convertTimestampToDate = _timestamp => {
  const myTimeStamp = Number(_timestamp) * 1000;
  const newDate = new Date(myTimeStamp);

  const outDate =
    newDate.getMonth() +
    1 +
    '/' +
    newDate.getDate() +
    '/' +
    newDate.getFullYear() +
    ' ' +
    newDate.getHours() +
    ':' +
    newDate.getMinutes();

  return outDate;
};

export const formatDigits = num => {
  num = Number(num);
  return parseFloat(num.toFixed(8));
};

// Returns a random number between two integers, both inclusive
export const getRandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const extractIntegersFromString = str => {
  const regex = /\d+/g; // Matches one or more digits

  // Extract integers from the string
  const integers = str.match(regex);

  // Convert extracted strings to integers
  const result = integers ? integers.map(Number) : [];

  return result;
};

export function getElementsAtIndex(arr1, arr2) {
  // Create an empty array to store the result
  const result = [];

  // Iterate through arr2 to get the indices
  for (const index of arr2) {
    // Check if the index is valid
    if (index >= 0 && index < arr1.length) {
      // Push the element at the specified index into the result array
      result.push(arr1[index]);
    }
  }

  return result;
}

export function getTrueIndices(arr) {
  const trueIndices = [];

  for (let i = 0; i < arr.length; i++) {
    const subarray = arr[i];
    const subarrayIndices = [];

    for (let j = 0; j < subarray.length; j++) {
      if (subarray[j] === true) {
        subarrayIndices.push(j);
      }
    }

    trueIndices.push(subarrayIndices);
  }

  return trueIndices;
}

export const getTokenURL = async (tokenContract, tokenId) => {
  let _cid;

  try {
    _cid = await tokenContract.uri(tokenId);
  } catch (error) {
    // If ERC1155 `uri` function doesn't work, try ERC721's `tokenURI`
    try {
      _cid = await tokenContract.tokenURI(tokenId);
    } catch (fallbackError) {
      console.error('Failed to retrieve token URL for tokenId', tokenId);
      _cid = null;
    }
  }

  return _cid;
};



export const getTokenType = async (tokenContract, tokenId) => {
  // Check for ERC1155 interface ID
  const supportsERC1155 = await tokenContract.supportsInterface('0xd9b67a26');

  // Check for ERC721 interface ID
  const supportsERC721 = await tokenContract.supportsInterface('0x80ac58cd');

  if (supportsERC1155) {
    return 'ERC1155';
  } else if (supportsERC721) {
    return 'ERC721';
  } else {
    try {
      const uri = await tokenContract.uri(tokenId);
      if (uri) {
        return 'ERC1155';
      }
    } catch (error) {
      // If uri() fails, fall back to tokenURI() for ERC721
      try {
        const tokenUri = await tokenContract.tokenURI(tokenId);
        if (tokenUri) {
          return 'ERC721';
        }
      } catch (fallbackError) {
        console.error('Failed to retrieve token type for tokenId', tokenId);
      }
    }
    return 'Unknown';
  }
};

export const extractValuesByKey = (arr, key) => {
  return arr.map(obj => obj[key]);
};

export function areCIDsEqual(cid1, cid2) {
  // Remove leading and trailing whitespace from both input strings
  const trimmedCID1 = cid1.trim();
  const trimmedCID2 = cid2.trim();

  // Compare the trimmed CIDs
  return trimmedCID1 === trimmedCID2;
}

export const removeDuplicates = arr => {
  return Array.from(new Set(arr));
};

export const combineArrays = (arr1, arr2) => {
  const arr = arr1.concat(arr2);
  const combined = removeDuplicates(arr);
  return combined;
};

export const removeZeroFromArray = integerArray => {
  const filteredArray = integerArray.filter(number => number !== 0);
  return filteredArray;
};

export const convertArrayToString = stringArray => {
  return stringArray.join(', ');
};

export function replaceElement(arr1, arr2) {
  return arr1.map((element, index) => {
    if (element === '2') {
      return arr2[index];
    } else {
      return element;
    }
  });
}

async function checkIfLinksFromSameDomain(link1, link2) {
  try {
    const response1 = await fetch(link1);
    const response2 = await fetch(link2);

    // Get the hostnames from the response URLs
    const hostname1 = new URL(response1.url).hostname;
    const hostname2 = new URL(response2.url).hostname;

    // Compare the hostnames
    return hostname1 === hostname2;
  } catch (error) {
    // Handle errors here
    console.error('Error checking domains:', error);
    return false;
  }
}

export const format = val => val + '%';
export const parse = val => val.replace(/^\%/, '');

export const isCancelled = val => {
  return val.cancelled;
};

function parseIntIn2DArray(arr) {
  if (!Array.isArray(arr)) {
    throw new Error('Input is not an array.');
  }

  let transformedArray = [];
  for (let i = 0; i < arr.length; i++) {
    const subArray = arr[i];
    if (subArray.length === 0) {
      transformedArray.push([]);
    } else {
      transformedArray.push(subArray.map(element => parseInt(element, 10)));
    }
  }

  return transformedArray;
}

export const parseBigNum2DArray = arr => {
  let transformedArray = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].length === 0) {
      transformedArray.push(arr[i]);
    } else {
      transformedArray.push(arr[i].map(element => Number(element.toString())));
    }
  }

  return transformedArray;
};



export function toCamelCase(inputString) {
  return inputString
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

export function toTitleCase(inputString) {
  return inputString.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export function transformArrayWithSubarray(arr) {
  const transformedArr = arr.map(subarray => {
    return subarray.map(element => {
      // Transformation logic
      // For example, let's multiply each element by 2
      return Number(element.toString());
    });
  });

  return transformedArr;
}

export function areUrlsFromSameDomain(url1, url2) {
  const parsedUrl1 = new URL(url1);
  const parsedUrl2 = new URL(url2);

  return parsedUrl1.hostname === parsedUrl2.hostname;
}

// const handleMeta = (field, value) => {
//   // Create a new copy of the meta object
//   const updatedMeta = { ...meta };
//   updatedMeta[field] = value;

//   // Update the state with the new object
//   setMeta(updatedMeta);
// };

// arr1 is a bool array, arr2 is a string array of the same length
export const filterArrayByBool = (arr1, arr2) => {
  // if (arr1.length !== arr2.length) {
  //   throw new Error('Arrays must have the same length');
  // }

  const filteredArray = [];
  const filteredId = []

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] === true) {
      filteredArray.push(arr2[i]);
      filteredId.push(i+1)
    }
  }

  // const res = {
  //   filteredArray,
  //   filteredId
  // }

  return filteredId;
};

export const excludeElementsInArr2 = (arr1, arr2) => {
  // Use the filter method to create a new array
  // that includes elements from arr1 but excludes those present in arr2
  const result = arr1.filter(item => !arr2.includes(item));

  return result;
};

export const isString1InsideString2 = (str1, str2) => {
  // Remove commas and spaces, and convert both strings to lowercase
  const lowercaseStr1 = str1.toLowerCase().replace(/ /g, '');
  const lowercaseStr2 = str2.replace(/,/g, '').replace(/ /g, '').toLowerCase();

  // Check if str1 is inside str2
  return lowercaseStr2.includes(lowercaseStr1);
};

export const isNumInArray = (num, arr) => {
  return arr.includes(num);
};

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function getNames(arr) {
  return arr.map(item => item.name);
}

export function filterByNonEmptyFeatures(inputArray) {
  return inputArray.filter(item => item.features && item.features.length > 0);
}

export function splitObject(obj, keysToInclude) {
  // Extracting properties for the first object
  const firstObject = {};
  keysToInclude.forEach(key => {
    if (obj.hasOwnProperty(key)) {
      firstObject[key] = obj[key];
    }
  });

  // Extracting properties for the second object
  const secondObject = {};
  for (const key in obj) {
    if (!keysToInclude.includes(key)) {
      secondObject[key] = obj[key];
    }
  }

  return [firstObject, secondObject];
}

// // Example usage:
// const obj = {id: 1, name: 'a', description: 'hello', otherInfo: 'original', url: 'https://...'};
// const keysToInclude = ['id', 'name', 'description'];

// const [firstObject, secondObject] = splitObject(obj, keysToInclude);

// console.log(firstObject);
// console.log(secondObject);

export function splitArrayOfObjects(arrayOfObjects, keysToInclude) {
  // Initialize arrays to store the results
  const firstArray = [];
  const secondArray = [];

  // Loop through each object in the array
  arrayOfObjects.forEach(obj => {
    // Extracting properties for the first object
    const firstObject = {};
    keysToInclude.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        firstObject[key] = obj[key];
      }
    });

    // Extracting properties for the second object
    const secondObject = {};
    for (const key in obj) {
      if (!keysToInclude.includes(key)) {
        secondObject[key] = obj[key];
      }
    }

    // Push the objects into their respective arrays
    firstArray.push(firstObject);
    secondArray.push(secondObject);
  });

  // Return the arrays of objects
  return [firstArray, secondArray];
}

// Example usage:
// const arrayOfObjects = [
//   { id: 1, name: 'John', age: 25 },
//   { id: 2, name: 'Jane', age: 30 },
//   // ... more objects
// ];

// const keysToInclude = ['id', 'name'];

// const [firstArray, secondArray] = splitArrayOfObjects(arrayOfObjects, keysToInclude);

// console.log('First Array:', firstArray);
// console.log('Second Array:', secondArray);

export function removeKeys(obj, keysToRemove) {
  // Create a shallow copy of the original object
  const newObj = { ...obj };

  // Iterate through the keys to remove
  keysToRemove.forEach(key => {
    // Check if the key exists in the object before attempting to delete it
    if (key in newObj) {
      // Use the delete operator to remove the key and its associated value
      delete newObj[key];
    }
  });

  // Return the new object without the specified key-value pairs
  return newObj;
}

export function removeKey(obj, keyToRemove) {
  // Create a shallow copy of the original object
  const newObj = { ...obj };

  // Check if the key exists in the object before attempting to delete it
  if (keyToRemove in newObj) {
    // Use the delete operator to remove the key and its associated value
    delete newObj[keyToRemove];
  }

  // Return the new object without the specified key-value pair
  return newObj;
}

// // Example usage:
// let myObject = {
//   key1: 'value1',
//   key2: 'value2',
//   key3: 'value3'
// };

// let updatedObject = removeKey(myObject, 'key2');
// console.log(updatedObject); // { key1: 'value1', key3: 'value3' }

// // Original object remains unchanged
// console.log(myObject); // { key1: 'value1', key2: 'value2', key3: 'value3' }

export function mergeArrays(arr1, arr2) {
  return arr2
    .map(item2 => {
      const matchingItem1 = arr1.find(
        item1 => Number(item1.gameTokenId) === item2.gameTokenId
      );

      if (matchingItem1) {
        const { gameTokenId, gameName } = item2;
        const mergedItem = {
          gameTokenId,
          gameName,
          gameFeatures: matchingItem1.gameFeatures,
        };

        return mergedItem;
      }

      return null; // Handle cases where there is no matching item in arr1
    })
    .filter(Boolean); // Remove null values from the resulting array
}

// const arr1 = [
//   {
//     "seed": 0.35510482744326155,
//     "gameTokenId": 1,
//     "gameFeatures": [
//       { "name": "General", "fee": "3.0 Matic" }
//     ]
//   }
// ];

// const arr2 = [
//   { gameTokenId: 1, gameName: 'Magic Flowers' }
// ];

// const resultArray = mergeArrays(arr1, arr2);
// console.log(resultArray);

export function compareStrings(str1, str2) {
  const cleanStr1 = str1.replace(/\s/g, '').toLowerCase();
  const cleanStr2 = str2.replace(/\s/g, '').toLowerCase();

  return cleanStr1 === cleanStr2;
}

// // Example usage:
// const result = compareStrings("Hello World", "helloworld");
// console.log(result); // Output: true

export function isFeatureCompatible(arr, id, str) {
  const cleanStr = str.replace(/\s/g, '').toLowerCase();

  return arr.some(item => {
    const matchingId = item.gameTokenId === id;
    const matchingFeature = item.gameFeatures.some(feature => {
      const cleanFeatureName = feature.name.replace(/\s/g, '').toLowerCase();
      return cleanFeatureName === cleanStr;
    });

    return matchingId && matchingFeature;
  });
}

// // Example usage:
// const arr = [
//   {
//     gameTokenId: 1,
//     gameName: 'Magic Flowers',
//     gameFeatures: [{ name: 'General', fee: '1' }],
//   },
//   // ... other items in the array
// ];

// const idToCheck = 1;
// const strToCheck = 'General';

// const result = isItemMatching(arr, idToCheck, strToCheck);
// console.log(result); // Output: true

export function isFeatureIntegrated(arr, id, str) {
  const cleanStr = str.replace(/\s/g, '').toLowerCase();

  return arr.some(item => {
    const matchingId = item.gameTokenId === id;
    const matchingFeature = item.features.some(feature => {
      const cleanFeatureName = feature.replace(/\s/g, '').toLowerCase();
      return cleanFeatureName === cleanStr;
    });

    return matchingId && matchingFeature;
  });
}

// // Example usage:
// const arr = [
//   {
//     gameTokenId: 1,
//     gameName: 'M',
//     features: ['General'],
//   },
//   // ... other items in the array
// ];

// const idToCheck = 1;
// const strToCheck = 'General';

// const result = isItemMatching(arr, idToCheck, strToCheck);
// console.log(result); // Output: true

export function removeItemAt(arr, _idx) {
  if (_idx > -1 && _idx < arr.length) {
    arr.splice(_idx, 1);
  }
  return arr;
}

// Example usage:
// let myArray = [1, 2, 3, 4, 5];
// let indexToRemove = 2;
// removeItemAt(myArray, indexToRemove);
// console.log(myArray); // Output: [1, 2, 4, 5]

export const stringToArray = str => {
  let array = str.split(',').map(item => item.trim());
  return array;
};

export function removePartFromArray(arr, index, partToRemove) {
  // Splitting the string at the specified index into an array
  const parts = stringToArray(arr[index]);
  console.log('parts:', parts);

  if (parts.length > 1) {
    // Removing the specified part from the array
    const filteredParts = parts.filter(
      item => item !== partToRemove && item !== ''
    );
    console.log('filteredParts:', filteredParts);

    // Joining the array back into a string
    arr[index] = filteredParts.join(',');
  }

  return arr;
}

// Usage example:
// const arr = ['1,2', '2,3', ...];
// const modifiedArray = removePartFromArray(arr, 0, '2');
// console.log(modifiedArray); // Output: ['1', '2,3', ...]
