function drawStars(p5, lStarsCanvas, rStarsCanvas, lStars, rStars, shootingStar) {
    // stars
    lStarsCanvas.push();
    lStarsCanvas.clear();
    lStarsCanvas.pop();
    for (var i = 0; i < lStars.length; i++) {
      lStarsCanvas.push();
      lStars[i].display();
      lStars[i].twinkle();
      lStarsCanvas.pop();
    }
    rStarsCanvas.push();
    rStarsCanvas.clear();
    rStarsCanvas.pop();
    for (var i = 0; i < rStars.length; i++) {
      rStarsCanvas.push();
      rStars[i].display();
      rStars[i].twinkle();
      rStarsCanvas.pop();
    }
    p5.push();
    p5.image(lStarsCanvas, p5.width / 27, p5.height / 18);
    p5.pop();
    p5.push();
    p5.image(rStarsCanvas, p5.width / 1.28, p5.height / 18);
    p5.pop();
    // shooting star
    p5.push();
    shootingStar.update();
    shootingStar.draw(p5.drawingContext);
    p5.pop();
  }

  export default drawStars