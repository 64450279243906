import { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import MagicFlowersSupport from '../../../../../contracts/interfaces/IMagicFlowersSupport.sol/IMagicFlowersSupport.json';
import { REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS } from '../../../../views/cozyGames/constants/ContractAddress';

const UpdateUserDefaultButton = ({ magicflowerId, userDefault }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);

  const create = async () => {
    setLoading(true);

    // console.log('userDefault:', userDefault);

    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const magicFlowersSupport = new ethers.Contract(
        REACT_APP_MAGIC_FLOWERS_SUPPORT_ADDRESS,
        MagicFlowersSupport.abi,
        signer
      );

      let _features = [];
      let _imageTokenIds = [];
      let _imageTokenContracts = [];

      for (const key in userDefault) {
        if (userDefault[key].tokenId > 0) {
          _features.push(key);
          _imageTokenIds.push(userDefault[key].tokenId);
          _imageTokenContracts.push(userDefault[key].tokenContract);
        }
      }

      // console.log('_features:', _features);
      // console.log('_imageTokenIds:', _imageTokenIds);
      // console.log('_imageTokenContracts:', _imageTokenContracts);

      const transaction =
        await magicFlowersSupport.updateMagicFlowerUserDefautlImages(
          magicflowerId,
          _features,
          _imageTokenIds,
          _imageTokenContracts
        );

      await transaction.wait();

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          size="lg"
          as="button"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Update Default Artworkd
        </Button>
      );
    } else {
      return (
        <Button
          onClick={create}
          size="lg"
          as="button"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="xl"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
        >
          Update Default Artworkd
        </Button>
      );
    }
  };

  return (
    <>
      <Box align="center" mt={6}>
        <RenderButton />
      </Box>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
};

export default UpdateUserDefaultButton;
