import { Text, VStack, Box, ListItem, UnorderedList } from '@chakra-ui/react';

const RenderAboutCozyGames = () => {
  return (
    <VStack spacing={6}>
      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Cozy Games XYZ: An Arcade of Serenity
        </Text>
      </Box>

      <Box>
        <Text fontSize="2xl">
          Welcome to Cozy Games XYZ, your serene escape into the world of cozy
          gaming. Our arcade is thoughtfully designed to provide a relaxing and
          enjoyable experience for all ages. Here's what makes Cozy Games XYZ
          special:
        </Text>
      </Box>
      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          A Blend of Cozy Blockchain Games and Traditional Favorites
        </Text>
      </Box>
      <Box>
        <UnorderedList spacing={3}>
          <ListItem>
            <Text fontSize="2xl">
              <Text as="b">Cozy Blockchain Games</Text>: Our blockchain games
              harness the power of decentralized technology to offer unique and
              secure gaming experiences. Enjoy games that feature NFTs, allowing
              you to collect, trade, and own unique in-game assets.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="2xl">
              <Text as="b">Traditional Favorites</Text>: Our traditional games
              are designed to be easy to pick up and play, providing instant fun
              for everyone.
            </Text>
          </ListItem>
        </UnorderedList>
      </Box>

      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Key Features:
        </Text>
      </Box>
      <Box>
        <UnorderedList spacing={3}>
          <ListItem>
            <Text fontSize="2xl">
              <Text as="b">Peaceful Retreats</Text>:
            </Text>
            <Box fontSize="2xl">
              <Box>
                - <Text as="i">Nature-Inspired Themes</Text>: Immerse yourself
                in games that draw inspiration from nature, offering tranquil
                and calming visuals.
              </Box>
              <Box>
                - <Text as="i">Mindful Gameplay </Text>: Engage in gameplay that
                promotes relaxation and mindfulness, providing a peaceful
                retreat from the stresses of daily life.
              </Box>
            </Box>
          </ListItem>
          <ListItem>
            <Text fontSize="2xl">
              <Text as="b">Innovative Design</Text>:
            </Text>
            <Box fontSize="2xl">
              <Box>
                - <Text as="i">Web3 Integration</Text>: Seamlessly switch
                between traditional and blockchain versions of your favorite
                games.
              </Box>
            </Box>
          </ListItem>
        </UnorderedList>
      </Box>

      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Our Mission
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          At Cozy Games XYZ, our mission is to create a haven for cozy gamers,
          where every game is a delightful and serene experience. We believe in
          the power of gaming to bring joy and relaxation, and we are dedicated
          to providing a space where players can unwind and enjoy the simple
          pleasures of gaming. Cozy Games XYZ strives to provide a tranquil
          escape from the hustle and bustle of everyday life.
        </Text>
      </Box>

      <Box>
        <Text
          color="green.400"
          fontSize="3xl"
          style={{ fontFamily: 'Lobster' }}
        >
          Essence and Resolution of Cozy Games XYZ
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          "Cozy Games XYZ" is more than just a name—it embodies the soul of our
          website. 'X' represents mystery, 'Y' signifies yin and young, and 'Z'
          embodies Zen. Cozy Games XYZ means games that are cozy, mysterious yet
          complete as Yin and Yang, and meditative as Zen. This interpretation
          reflects our commitment to crafting games that offer both depth and
          tranquility, resonating with players seeking immersive and thoughtful
          experiences in the realm of cozy gaming.
        </Text>
      </Box>
      <Box>
        <Text fontSize="2xl">
          On the other hand, it also shows our resolution to strive to the end
          and the permanent nature of blockchain. Just as 'XYZ' marks the end of
          the alphabet, it signifies a journey towards completeness and enduring
          quality in our creations, reflecting the permanent nature of
          blockchain technology. This shows our resolution to craft games that
          not only endure but also innovate within the realm of blockchain,
          offering lasting enjoyment and value to our players.
        </Text>
      </Box>
      <img src='https://res.cloudinary.com/df9jvvwyw/image/upload/v1721917319/forest-round_mzouzo.gif' alt="Magic Forest" />
      <Box>
        <Text fontSize="2xl">
          Join us at Cozy Games XYZ and discover the perfect blend of innovation
          and tradition in the world of cozy gaming. Whether you're exploring
          the possibilities of blockchain technology or revisiting beloved
          classics, our arcade offers a unique and calming gaming experience
          like no other.
        </Text>
      </Box>
      {/* <img src={FutureGif} alt="Future" /> */}

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
          @import url('https://fonts.googleapis.com/css?family=Roboto');
        `}
      </style>
    </VStack>
  );
};

export default RenderAboutCozyGames;
