export function sky(p5, canvas, x, y, w, h, c1, c2) {
    canvas.push()
    // canvas.noFill();
    for (let i = y; i <= y + h; i++) {
      let inter = p5.map(i, y, y + h, 0, 1);
      let c = canvas.lerpColor(c1, c2, inter);
      canvas.stroke(c);
      canvas.line(x, i, x + w, i);
    }
    canvas.pop()
  }

//   sky(p5, canvas, 0, 0, width, height, color(135, 206, 235), color(255, 255, 192));

export function skyPlant1(p5, canvas, x, y, w, h, c1, c2) {
  canvas.push()
  canvas.noFill();
  for (let i = y; i <= y + h; i++) {
    let inter = p5.map(i, y, y + h, 0, 1);
    let c = canvas.lerpColor(c1, c2, inter);
    canvas.stroke(c);
    canvas.line(x, i, x + w, i);
  }

  canvas.pop()
}