import React from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react';
import {
  AccessAllMyMagicFlower,
  AccessHistory,
  AccessMagicFlower,
  CannotSeeNftAllArtworks,
  EvolvePlant,
  GalleryIntro,
  IntegrateNFTinMagicFlower,
  MagicFlowerNFTs,
  MintMagicFlowers,
  SellMyMagicFlower,
  SetNftAsDefault,
  UpdateMagicFlowerMeta,
} from './MagicFlowersFAQ';

function MagicFlowersSection() {
  return (
    <>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                1. What is a Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <MagicFlowerNFTs />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                2. Is there a cap for Magic Flowers NFTs?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            There is a cap of 3,000 progenitor plants: 1,000 males, 1,000
            females, and 1,000 hermaphrodites. Progenitor plants have limited
            fast-evolving abilities but can produce offspring. There is no cap
            on hybrids, which possess unlimited evolving abilities but cannot
            reproduce. Vigors, which have no cap, can only be created through
            rare mutations in hybrids. Vigors possess unlimited fast-evolving
            abilities and can also produce offspring.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                3. How to revive/hybridize a Magic Flower plant?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <MintMagicFlowers />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                4. What's the price of a Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Reviving a Magic Flower NFT costs just 5 MATIC, and hybridizing one
            costs 1 MATIC. However, if you’re purchasing a Magic Flower NFT from
            another owner, the price will be set by that owner.
            <br />
            <br />
            Please note that the game is currently live on the Polygon Amoy
            testnet for testing purposes. As such, fees are adjusted for testing
            and may not reflect the final fee structure.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                5. Is it possible to sell my Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Absolutely! You have the option to sell it on the marketplace or
            auction it in the Auction House.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                6. How to access a Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccessMagicFlower />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                7. How to access all my Magic Flowers NFTs?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccessAllMyMagicFlower />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                8. What components make up Magic Flower NFT metadata?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            The Magic Flower NFT metadata adheres to the standard ERC721 NFT
            metadata format.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                9. How to add/update metadata for my Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <UpdateMagicFlowerMeta />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                10. How to sell / auction my Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <SellMyMagicFlower />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                11. Can I obtain a single static image of a Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Magic Flowers transcend the realm of mere plants; they are living
            entities imbued with memories. These exceptional Magic Flower NFTs,
            categorized under ERC721, aren't confined to static imagery;
            instead, they unfold as intricate narratives with dynamic
            animations. However, if you prefer a snapshot of your NFT, you have
            the option. Simply navigate to your Magic Flower NFT page and click
            on 'c' to activate the control panel. From there, select the 'Save
            Image' button to capture a static rendition of your plant. You can
            capture images at any point during its animations or within any of
            its evolving history.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                12. How can I capture a video of a Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            To create a video of a Magic Flower NFT, you can utilize screen
            recording software on your computer. Simply start the screen
            recording process to capture its dynamic visuals and animations.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                13. How to evolve my Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <EvolvePlant />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                14. How can I access the evolutionary history of my Magic Flower
                NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccessHistory />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                15. What is `Magic Garden` on an individual Magic Flower NFT
                page?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <GalleryIntro />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                16. How to set an NFT as default image or other content for my
                Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <SetNftAsDefault />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                17. How to integrate existing NFT(s) in my Magic Flower NFT?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <IntegrateNFTinMagicFlower />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                18. I cannot see an NFT which has been integrated for my Magic
                Flower NFT in `All Artworks` in `Gallery`.
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <CannotSeeNftAllArtworks />
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                19. Are there any platform fees in the Marketplace, Auction
                House, and Magic Flowers Leasing Market?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Yes, there is a 2.5% transaction fee for successful sales in the
              Marketplace and Auction House, based on the final selling price.
              There is no fee for listing Magic Flowers in the Magic Flowers
              Leasing Market. However, a 2.5% transaction fee is applied to
              successful leases, based on the Leasing Price. Please note that
              only the seller is responsible for the transaction fee for
              successful sales, auctions, or leases of Magic Flowers.
            </Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                20. How do I create Metadata?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              To create metadata, you'll need to organize the image URL and
              related information in a JSON file. Then, store the JSON file in a
              trustworthy storage service to obtain its URL. If you're
              unfamiliar with creating a JSON file, visit the JSON Creator page
              where you can input the necessary information and download the
              file.
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default MagicFlowersSection;
