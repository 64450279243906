import { ethers } from 'ethers';
import axios from 'axios';
import ITokenURI from '../../../../../contracts/APIs/TokenInterface.sol/TokenInterface.json';
import MarketplaceERC721 from '../../../../../contracts/APIs/MarketplaceERC721Interface.sol/MarketplaceERC721Interface.json';
import MarketplaceERC1155 from '../../../../../contracts/APIs/MarketplaceERC1155Interface.sol/MarketplaceERC1155Interface.json';
import AuctionHouse from '../../../../../contracts/APIs/AuctionHouseInterface.sol/AuctionHouseInterface.json';
import { getIntegratedGames } from '../token/getIntegratedGames';
import { OpenseaMeta } from '../../../../utils/constants';
import {
  REACT_APP_AUCTION_HOUSE_ADDRESS,
  REACT_APP_MARKETPLACE_ERC1155_ADDRESS,
  REACT_APP_MARKETPLACE_ERC721_ADDRESS,
  REACT_APP_HELPER_ADDRESS,
  REACT_APP_MAGIC_FLOWERS_ADDRESS,
} from '../../../cozyGames/constants/ContractAddress';
const getAllValleyItems = async (
  provider,
  tokenType,
  saleType,
  marketplaceIds,
  auctionIds,
  forExhibition
) => {
  // const web3Modal = new Web3Modal(process.env.REACT_APP_PROJ_ADDRESS);
  // const connection = await web3Modal.connect();
  // const provider = new ethers.providers.Web3Provider(connection);
  // const signer = provider.getSigner();

  let marketplace;
  let auctionHouse;

  if (saleType === 'Marketplace') {
    if (tokenType === 'Erc721') {
      // console.log('Erc721');
      marketplace = new ethers.Contract(
        REACT_APP_MARKETPLACE_ERC721_ADDRESS,
        MarketplaceERC721.abi,
        provider
      );
    } else {
      // console.log(REACT_APP_MARKETPLACE_ERC1155_ADDRESS)
      // console.log('Erc1155');
      marketplace = new ethers.Contract(
        REACT_APP_MARKETPLACE_ERC1155_ADDRESS,
        MarketplaceERC1155.abi,
        provider
      );
    }
  }

  if (saleType === 'Auction') {
    auctionHouse = new ethers.Contract(
      REACT_APP_AUCTION_HOUSE_ADDRESS,
      AuctionHouse.abi,
      provider
    );
  }

  let data;

  if (saleType === 'Marketplace') {
    if (forExhibition) {
      data = await marketplace.getValleyItems(marketplaceIds);
    } else {
      data = await marketplace.getAllValleyItems();
      // console.log('data:', data);
    }
  } else if (saleType === 'Auction') {
    if (forExhibition) {
      data = await auctionHouse.getValleyItems(auctionIds);
    } else {
      data = await auctionHouse.getAllValleyItems();

      // console.log('data:', data);
    }
  }

  let newItems = await Promise.all(
    data.map(async d => {
      let amountErc1155, amountSoldErc1155;
      const _tokenAddr = d.nftContract;
      // const _abi = getAbi(d.nftContract);
      let isMagicFlowers =
        _tokenAddr === REACT_APP_MAGIC_FLOWERS_ADDRESS ? true : false;
      let tokenContract;
      if (isMagicFlowers) {
        tokenContract = new ethers.Contract(
          REACT_APP_HELPER_ADDRESS,
          ITokenURI.abi,
          provider
        );
      } else {
        tokenContract = new ethers.Contract(
          _tokenAddr,
          ITokenURI.abi,
          provider
        );
      }

      let _cid, _tokenUri;
      if (tokenType == 'Erc1155') {
        // _cid = await tokenContract.uri(Number(d.tokenId));
        try {
          _cid = await tokenContract.uri(Number(d.tokenId));
        } catch (error) {
          console.error('Error fetching token URI:', error);
          _cid = ''; // Assign an empty string in case of error
        }
        _tokenUri = _cid;
        // console.log('erc1155 _tokenUri:', _cid);
        amountErc1155 = d.sellingAmount;
        amountSoldErc1155 = d.amountSold;
        amountErc1155 = Number(amountErc1155.toString());
        // console.log('amountErc1155:', amountErc1155);
        amountSoldErc1155 = Number(amountSoldErc1155.toString());
        // console.log('amountSoldErc1155:', amountSoldErc1155);
      } else {
        // _cid = await tokenContract.tokenURI(Number(d.tokenId));
        try {
          _cid = await tokenContract.tokenURI(Number(d.tokenId));
        } catch (error) {
          console.error('Error fetching token URI:', error);
          _cid = ''; // Assign an empty string in case of error
        }
        _tokenUri = _cid;
      }

      // console.log('_tokenUri:', _tokenUri);
      let metadata, price, startingPrice, category;

      let games;

      // metadata = _tokenUri.length > 0 ? await axios.get(_tokenUri) : {};
      if (!d.isCancelled) {
        try {
          metadata = _tokenUri.length > 0 ? await axios.get(_tokenUri) : {};
        } catch (error) {
          // Handle the error gracefully
          console.error('Error fetching token metadata:', error);
          // You can assign a default value or handle the error in any appropriate way
          metadata = {}; // Assign an empty object in case of error
        }

        // console.log('metadata:', metadata.data);

        if (Object.keys(metadata).length > 0) {
          metadata = metadata.data;
        } else {
          metadata = OpenseaMeta;
        }
        category = d.category;

        if (saleType === 'Marketplace') {
          price = ethers.utils.formatUnits(d.price.toString(), 'ether');
        } else if (saleType === 'Auction') {
          startingPrice = ethers.utils.formatUnits(
            d.startingPrice.toString(),
            'ether'
          );
        }

        games = await getIntegratedGames(
          provider,
          d.nftContract,
          d.tokenId.toNumber(),
          d.seller
        );
      }

      // console.log('isCancelled:', typeof d.isCancelled);

      const res = {
        valleyId: d.itemId.toNumber(),
        isERC1155: d.isERC1155,
        amountErc1155,
        amountSoldErc1155,
        metadata,
        price,
        startingPrice,
        category,
        tokenId: d.tokenId.toNumber(),
        tokenContract: d.nftContract,
        seller: d.seller,
        owner: d.currentOwner,
        isSold: d.isSold,
        isCancelled: d.isCancelled,
        saleType: saleType,
        games,
      };

      // console.log('res:', res);

      return res;
    })
  );
  // console.log('newItems:', newItems);

  return newItems;
};

export default getAllValleyItems;
