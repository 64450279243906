const getSexType = num => {
    if (num === 1) {
      return 'Male';
    } else if (num === 2) {
      return 'Female';
    } else if (num === 3) {
      return 'Hermaphrodite';
    } else {
      return '';
    }
  };

  export default getSexType