import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import MagicFlowerTree from '../../../../components/sketches/games/magicFlowersSketches/plants/MagicFlowerTree';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import getNetworkProvider from '../../../../walletConnections/obtainProviders/getNetworkProvider';
import getMagicFlowerDetail from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/getMagicFlowerDetail';
import RenderMagicFlowerSketch from './RenderMagicFlowerSketch';
import getImageUrl from '../../../../components/utils/getImageUrl';
import GameFooter from '../../../../components/views/GameFooter';

function MagicFlowerTrial() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  // console.log('address:', address);
  // console.log('chainId:', chainId);
  // console.log('isConnected:', isConnected)
  const { walletProvider } = useWeb3ModalProvider();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tokenContract = urlParams.get('contract');
  const tokenId = urlParams.get('tokenId');
  const feature = urlParams.get('feature');
  const branch = urlParams.get('branch');
  // console.log('tokenContract:', tokenContract);
  // console.log('tokenId:', tokenId);
  // console.log('feature:', feature);
  // console.log('branch:', branch);
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === ' ' || event.key === 'Spacebar') {
        event.preventDefault(); // Prevent default behavior (scrolling)
        // Your game logic to shoot or perform actions here
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [magicflowerId, setMagicflowerId] = useState(0);
  const [allGenes, setAllGenes] = useState([]);
  const [plantFeatures, setPlantFeatures] = useState({
    plantType: 1,
    plantSeed: 1,
    removeDefaultFairy: false,
  });
  const [displayTimePoint, setDisplayTimePoint] = useState(0);
  const [userDefault, setUserDefault] = useState({});
  const [userDefaultBgs, setUserDefaultBgs] = useState([]);

  useEffect(() => {
    getItem();
  }, [branch]);

  const getItem = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const imageUrl = await getImageUrl(
      provider,
      Number(tokenId),
      tokenContract
    );

    // console.log('imageUrl:', imageUrl);

    const _magicflowerId = Number(branch);
    setMagicflowerId(_magicflowerId);

    const data = await getMagicFlowerDetail(provider, _magicflowerId);
    // console.log('data:', data);

    if (
      feature === 'PlantSpirit' ||
      feature === 'Butterflies' ||
      feature === 'Stone'
    ) {
      data.userDefault[feature].imageUrl = imageUrl;
    } else {
      data.userDefaultBgs[feature].imageUrl = imageUrl;
    }
    // console.log('data:', data);

    setUserDefault(data.userDefault);
    setUserDefaultBgs(data.userDefaultBgs);
    setPlantFeatures(data.plantFeatures);
    setAllGenes(data.allGenes);
    setDisplayTimePoint(data.displayTimePoint);
  };

  const RenderSketch = () => {
    // console.log('magicflowerId:', magicflowerId);
    if (magicflowerId > 0) {
      return (
        <ReactP5Wrapper
          sketch={MagicFlowerTree}
          magicflowerId={magicflowerId}
          plantFeatures={plantFeatures}
          genes={allGenes[displayTimePoint]}
          userDefault={userDefault}
          userDefaultBgs={userDefaultBgs}
        />
      );
    }
  };

  return (
    <>
      <RenderSketch />

      <RenderMagicFlowerSketch
        magicflowerId={magicflowerId}
        plantFeatures={plantFeatures}
        genes={allGenes[displayTimePoint]}
        userDefault={userDefault}
        userDefaultBgs={userDefaultBgs}
        flowerName=""
      />

      <div style={{ marginTop: '58px' }}>
        <GameFooter />
      </div>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </>
  );
}

export default MagicFlowerTrial;
