import { Container, VStack } from '@chakra-ui/react';
import RenderAboutMagicFlowers from '../../../../components/views/aboutPage/RenderAboutMagicFlowers';
import MagicFlowerTopGrid from '../../../../components/views/cozyGames/cozyGame1/magicFlowers/MagicFlowerTopGrid';
import GameFooter from '../../../../components/views/GameFooter';
const AboutMagicFlowers = () => {
  return (
    <VStack>
      <MagicFlowerTopGrid />
      <img
        src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1721917824/about-flower_j8e4ot.gif"
        alt="Heart Wood"
      />
      <Container>
        <RenderAboutMagicFlowers />
      </Container>
      <div style={{ marginTop: '58px' }}>
        <GameFooter />
      </div>
    </VStack>
  );
};

export default AboutMagicFlowers;
