import {
  Box,
  Text,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  Image,
} from '@chakra-ui/react';
import { FaImages } from 'react-icons/fa';
import { RiAuctionFill, RiPlantFill } from 'react-icons/ri';
import GameFeaturesImg from '../../../../../resources/images/games/magicflowers/gameFeatures/8.png';
const MainFeatures = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  function ViewMore() {
    return (
      <>
        <Box as="button" onClick={onOpen} colorScheme="green">
          <Box boxSize="185px">
            <Image src={GameFeaturesImg} alt="Game Features" />
          </Box>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Main Game Features</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Original Progenitor Plants</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text style={{ textAlign: 'left' }}>
                      3,000 plants divided among females, males, and
                      hermaphrodites. They can hybridize to produce offspring
                      but have limited fast evolving ability. These are minted
                      with a cap of 3,000.{' '}
                    </Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Hybridized Plants</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text style={{ textAlign: 'left' }}>
                      Born from hybridization, they are mostly sterile but
                      possess the ability of indefinite fast evolving.
                      Hybridization uses genes from any parent’s evolving
                      history.
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Vigors</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text style={{ textAlign: 'left' }}>
                      Rare hybrids with indefinite fast evolving and
                      reproductive abilities, contributing uniquely to the
                      garden’s biodiversity.
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Fast Evolving</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text style={{ textAlign: 'left' }}>
                      The ability to evolve rapidly into a new form. Fast
                      evolving might occasionally trigger mutation, transforming
                      a hybrid into a Vigor. 
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Mutation</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text style={{ textAlign: 'left' }}>
                      Hybridization can attract cosmic energy, occasionally
                      bestowing parent plants with unlimited fast evolving
                      abilities, or transforming newborn plants into vigors,
                      adding unpredictability to the garden's ecosystem.
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Levels</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text style={{ textAlign: 'left' }}>
                      A plant’s energy level is determined by its offspring and
                      evolving history. Higher levels unlock more customization
                      options.
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Unique NFTs</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text style={{ textAlign: 'left' }}>
                      Each Magic Flower plant—whether it is an original
                      progenitor plant, a hybrid, or a vigor—is a unique NFT
                      with a distinctive appearance and unique memories. Their
                      evolving histories, parentage, and any offspring produced
                      are recorded on the blockchain, preserving their entire
                      lineage.
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>Additional Magic Flowers Facilities</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={FaImages} color="green.500" />
                        <Text as="b" color="teal.500">
                          Magic Flowers Marketplace:{' '}
                        </Text>
                        An NFT marketplace for trading in-game assets and Magic
                        Flowers.
                      </ListItem>

                      <ListItem>
                        <ListIcon as={RiAuctionFill} color="green.500" />
                        <Text as="b" color="teal.500">
                          {' '}
                          Magic Flowers Auction House
                        </Text>
                        : An NFT auction house for bidding on in-game assets and
                        Magic Flowers.
                      </ListItem>
                      {/* You can also use custom icons from react-icons */}
                      <ListItem>
                        <ListIcon as={RiPlantFill} color="green.500" />
                        <Text as="b" color="teal.500">
                          {' '}
                          Magic Flowers Leasing Market
                        </Text>
                        : A marketplace where you can lease Original Parent
                        Plants and Vigors to produce new Magic Flowers.
                      </ListItem>
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  return <ViewMore />;
};

export default MainFeatures;
