import { VStack, Box, Text, Tooltip } from '@chakra-ui/react';
import { RenderItemName, RenderTokenInfoAccording } from '../ShowItem';
import RenderValleyItem from '../singleValleyItem/RenderValleyItem';
import RenderCurrentListing from './RenderCurrentListing';
import RenderGamesIntegrated from './RenderGamesIntegrated';

function RenderTokenInfo({
  provider,
  item,
  currentListing,
  itemPriceHistory,
  itemCollection,
  user,
}) {
  const RenderItemErc721 = props => {
    console.log('props:', props);
    return (
      <>
        <RenderValleyItem
          category={item.category}
          image={item.metadata.image}
          animation_url={item.metadata.animation_url}
        />

        <VStack>
          <Box textAlign="center" alignItems="baseline">
            <Tooltip label={'ID: ' + item.tokenId} aria-label="A tooltip">
              <Text
                fontSize={[24, 36, 48]}
                color="teal.500"
                style={{ fontFamily: 'Lobster' }}
              >
                {item.metadata.name}
              </Text>
            </Tooltip>
          </Box>

          {Object.keys(props.currentListing).length > 0 && (
            <Box style={{ marginTop: '30px', marginBottom: '30px' }}>
              <RenderCurrentListing
                currentListing={props.currentListing}
                tokenType="Erc721"
              />
            </Box>
          )}

          <RenderTokenInfoAccording
            item={props.item}
            itemCollection={props.itemCollection}
            itemPriceHistory={props.itemPriceHistory}
          />

          <RenderGamesIntegrated
            provider={provider}
            tokenContract={props.item.tokenContractAddress}
            tokenId={props.item.tokenId}
            player={props.user}
          />
        </VStack>
      </>
    );
  };

  const RenderItemErc1155 = props => {
    console.log('props:', props);

    return (
      <>
        <RenderValleyItem
          category={props.item.category}
          image={props.item.metadata.image}
          animation_url={props.item.metadata.animation_url}
        />
        <VStack>
          <Box textAlign="center" alignItems="baseline">
            <RenderItemName name={props.item.metadata.name} />
          </Box>
{/* 
          <Text color="teal.500" fontSize="sm" as="i">
            Total original supply: {props.item.originalSupply}
          </Text> */}

          {Object.keys(props.currentListing).length > 0 && (
            <RenderCurrentListing
              currentListing={props.currentListing}
              tokenType="Erc1155"
            />
          )}

          <RenderTokenInfoAccording
            item={props.item}
            itemCollection={props.itemCollection}
            itemPriceHistory={props.itemPriceHistory}
          />

          <RenderGamesIntegrated
            tokenContract={props.item.tokenContractAddress}
            tokenId={props.item.tokenId}
            player={props.user}
          />
        </VStack>
      </>
    );
  };

  return (
    <VStack>
      {item.tokenType === 'Erc1155' && (
        <RenderItemErc1155
          item={item}
          currentListing={currentListing}
          itemCollection={itemCollection}
          itemPriceHistory={itemPriceHistory}
          user={user}
        />
      )}
      {item.tokenType === 'Erc721' && (
        <RenderItemErc721
          item={item}
          currentListing={currentListing}
          itemCollection={itemCollection}
          itemPriceHistory={itemPriceHistory}
          user={user}
        />
      )}

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </VStack>
  );
}

export default RenderTokenInfo;
