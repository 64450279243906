import { drawFlowerGenus1_treeCanvas } from '../flowers/flowerGenus1';
import { drawFlowerGenus2_treeCanvas } from '../flowers/flowerGenus2';
import { drawFlowerGenus5_treeCanvas } from '../flowers/flowerGenus5';

export class Plant1 {
  constructor(
    p5,
    treeCanvas,
    start,
    coeff,
    genes,
    flowerParam,
    flowerType,
    maxlife
  ) {
    this.p5 = p5;
    this.treeCanvas = treeCanvas;
    this.branches = [];
    this.start = start;
    this.coeff = coeff;
    this.genes = genes;
    this.teinte = this.treeCanvas.random(0, 30); //this.plantFeatures.teinte;

    this.proba1 = this.treeCanvas.random(0.8, 1); //this.plantFeatures.proba1;
    this.proba2 = this.treeCanvas.random(0.8, 1); //this.plantFeatures.proba2;
    this.proba3 = this.treeCanvas.random(0.4, 0.5); //this.plantFeatures.proba3;
    this.proba4 = this.treeCanvas.random(0.4, 0.5); //this.plantFeatures.proba4;
    this.maxlife = maxlife; //this.plantFeatures.maxlife;
    this.rand = this.treeCanvas.random(0.58, 0.88); //this.plantFeatures.rand;

    this.flowerType = flowerType;

    this.flowerParam = flowerParam;
    this.fruitColor = {
      h: this.treeCanvas.random(360),
      s: this.treeCanvas.random(88, 100),
      b: this.treeCanvas.random(88, 100),
      a: this.treeCanvas.random(1),
    };

    this.leafColor = {
      h: this.treeCanvas.random(360),
      s: this.treeCanvas.random(38, 100),
      b: this.treeCanvas.random(58, 100),
      a: this.treeCanvas.random(1),
    };
    this.leafScale = 0.258;
    // console.log('this.fruitColor:', this.fruitColor);
  }
  grow(flower, flowers) {
    for (let i = 0; i < this.branches.length; i++) {
      let b = this.branches[i];
      if (b.alive) {
        b.age++;
        b.grow();
        if (!this.gameLost) {
          b.display(flower, flowers);
        }
      }
    }
  }
}

export class Branche {
  constructor(p5, treeCanvas, flowerCanvas, tree, start, stw, angle, gen) {
    this.p5 = p5;
    this.treeCanvas = treeCanvas;
    this.flowerCanvas = flowerCanvas;
    this.tree = tree;
    this.position = start; //start.copy();
    this.stw = stw;
    this.gen = gen;
    this.alive = true;
    this.age = 0;
    this.angle = angle;
    this.speed = p5.createVector(0, -3);
    this.genes = this.tree.genes;
    this.flowerParam = this.tree.flowerParam;
    this.flowerType = this.tree.flowerType;
    this.leafColor = this.tree.leafColor;

    this.rand = this.tree.rand;
    this.maxlife = this.tree.maxlife * this.rand; //this.p5.random(0.3, 0.8);
    // console.log('this.maxlife:', this.tree.maxlife)
    // console.log('ran:', ran)
    this.proba1 = tree.proba1;
    this.proba2 = tree.proba2;
    this.proba3 = tree.proba3;
    this.proba4 = tree.proba4;
    this.deviation = this.treeCanvas.random(0.18, 0.6);
  }

  grow = function () {
    let ran1 = this.treeCanvas.random(1);
    let ran2 = this.treeCanvas.random(1);
    let ran3 = this.treeCanvas.random(1);
    let ran4 = this.treeCanvas.random(1);
    let ran5 = this.treeCanvas.random(1);
    let ran6 = this.treeCanvas.random(0.2, 1);
    let ran7 = this.treeCanvas.random(0.7, 1.1);
    let ran8 = this.treeCanvas.random(0.2, 1);
    let ran9 = this.treeCanvas.random(0.7, 1.1);
    let ran10 = this.treeCanvas.random(0.5, 0.8);
    let ran11 = this.treeCanvas.random(0.2, 1);
    let ran12 = this.treeCanvas.random(0.5, 0.8);
    let ran13 = this.treeCanvas.random(0.2, 1);
    let ran14 = this.treeCanvas.random(-0.5, 0.5);
    if (
      this.age == this.p5.int(this.maxlife / this.gen) ||
      ran1 < 0.05 * this.gen
    ) {
      this.alive = false;
      if (this.stw > 0.2) {
        let brs = this.tree.branches;
        if (ran2 < this.proba1 / this.gen)
          brs.push(
            new Branche(
              this.p5,
              this.treeCanvas,
              this.flowerCanvas,
              this.tree,
              this.p5.createVector(this.position.x, this.position.y),
              this.stw * ran6,
              this.angle + ran7 * this.deviation,
              this.gen + 0.1
            )
          );
        if (ran3 < this.proba2 / this.gen)
          brs.push(
            new Branche(
              this.p5,
              this.treeCanvas,
              this.flowerCanvas,
              this.tree,
              this.p5.createVector(this.position.x, this.position.y),
              this.stw * ran8,
              this.angle - ran9 * this.deviation,
              this.gen + 0.1
            )
          );
        if (ran4 < this.proba3 / this.gen)
          brs.push(
            new Branche(
              this.p5,
              this.treeCanvas,
              this.flowerCanvas,
              this.tree,
              this.p5.createVector(this.position.x, this.position.y),
              this.stw * ran10,
              this.angle + ran11 * this.deviation,
              this.gen + 0.1
            )
          );
        if (ran5 < this.proba4 / this.gen)
          brs.push(
            new Branche(
              this.p5,
              this.treeCanvas,
              this.flowerCanvas,
              this.tree,
              this.p5.createVector(this.position.x, this.position.y),
              this.stw * ran12,
              this.angle - ran13 * this.deviation,
              this.gen + 0.1
            )
          );
      }
    } else {
      this.speed.x += ran14;
    }
  };
  display(flower, flowers) {
    let c = this.tree.coeff;
    let x0 = this.position.x;
    let y0 = this.position.y;
    //rotation of the branch segment around its origin
    this.position.x +=
      -this.speed.x * this.p5.cos(this.angle) +
      this.speed.y * this.p5.sin(this.angle);
    this.position.y +=
      this.speed.x * this.p5.sin(this.angle) +
      this.speed.y * this.p5.cos(this.angle);

    //light accent
    this.treeCanvas.strokeWeight(
      this.p5.map(this.age, 0, this.maxlife, this.stw, this.stw * 0.6)
    );

    this.treeCanvas.stroke(
      this.tree.teinte + this.age + 20 * this.gen,
      (150 * c * 100) / 255,
      ((200 + 20 * this.gen) * 100) / 255,
      15 * c
    ); //
    this.treeCanvas.line(
      x0 + 0.1 * this.stw,
      y0,
      this.position.x + 0.1 * this.stw,
      this.position.y
    );
    //darker tree
    this.treeCanvas.stroke(
      this.tree.teinte + this.age + 20 * this.gen,
      (100 * c * 100) / 255,
      ((50 + 20 * this.gen) * 100) / 255,
      15 * c
    ); //
    this.treeCanvas.strokeWeight(
      this.p5.map(this.age, 0, this.maxlife, this.stw, this.stw * 0.6)
    );
    this.treeCanvas.line(x0, y0, this.position.x, this.position.y);
    if (this.gen > 2.2 && this.p5.random(10000) >= 9818.88) {
      // console.log('this.gen:', this.gen);
      if (this.flowerType <= 108) {
        this.p5.push();
        drawFlowerGenus1_treeCanvas(
          this.p5,
          this.treeCanvas,
          this.position.x,
          this.position.y,
          this.flowerParam,
          this.flowerType
        );
        this.p5.pop();
      } else if (this.flowerType > 108 && this.flowerType < 115) {
        this.p5.push();
        drawFlowerGenus2_treeCanvas(
          this.p5,
          this.treeCanvas,
          this.position.x,
          this.position.y,
          this.flowerParam,
          this.flowerType
        );
        this.p5.pop();
      } else if (this.flowerType >= 115 && this.flowerType <= 128) {
        this.treeCanvas.image(
          this.flowerCanvas,
          this.position.x,
          this.position.y
        );
      } else if (this.flowerType === 129) {
        this.p5.push();
        this.flower.display(this.position.x, this.position.y);
        this.p5.pop();
      } else if (this.flowerType === 130) {
        this.p5.push();
        drawFlowerGenus5_treeCanvas(
          this.p5,
          this.treeCanvas,
          this.position.x,
          this.position.y,
          this.flowerParam
        );
        this.p5.pop();
      } else if (
        this.flowerType === 133 ||
        this.flowerType === 131 ||
        this.flowerType === 132
      ) {
        this.p5.push();
        flower.update(this.position.x, this.position.y);
        this.p5.pop();
      }
      if (this.flowerType >= 134 && this.flowerType <= 136) {
        let flowerIdx = this.p5.int(this.p5.random(flowers.length));
        // positions.push([this.position.x, this.position.y]);
        this.flowerCanvas.push();
        // console.log('flower:', flowers);

        flowers[flowerIdx].drawFlower(this.p5);
        this.flowerCanvas.pop();
        this.flowerCanvas.push();

        flowers[flowerIdx].spinFlower();

        this.flowerCanvas.pop();
        this.treeCanvas.push();

        this.treeCanvas.image(
          this.flowerCanvas,
          this.position.x,
          this.position.y,
          800 / 7.88,
          600 / 7.88
        );
        this.treeCanvas.pop();
      }

      if (this.flowerType === 8888) {
        this.treeCanvas.push();

        flower.resize(38, 0);

        this.treeCanvas.image(flower, this.position.x, this.position.y);
        this.treeCanvas.pop();
      }
    }

    // if (this.gen > 1.58 && this.treeCanvas.random(10) > 9.958) {
    //   this.treeCanvas.noStroke();
    //   this.treeCanvas.fill(this.tree.leafColor.h, this.tree.leafColor.s, this.tree.leafColor.b);
    //   this.treeCanvas.ellipse(
    //     this.position.x,
    //     this.position.y,
    //     this.treeCanvas.random(1*this.tree.leafScale, 20*this.tree.leafScale),
    //     this.treeCanvas.random(10*this.tree.leafScale, 50*this.tree.leafScale)
    //   );
    // }

    // fruits
    if (this.gen > 1.58) {
      if (this.p5.random(10) > 9.88) {
        let s = this.treeCanvas.random(5, 10);
        this.treeCanvas.push();
        this.treeCanvas.fill(
          this.tree.fruitColor.h,
          this.tree.fruitColor.s,
          this.tree.fruitColor.b,
          this.tree.fruitColor.a
        ); // fruitColor: from 0 to 360
        this.treeCanvas.noStroke();
        // treeCanvas.ellipse(x, y, 7, 7);
        this.treeCanvas.ellipse(this.position.x, this.position.y, s, s);
        this.treeCanvas.pop();
      } else if (this.p5.random(10) < 0.358) {
        this.treeCanvas.push();
        this.treeCanvas.noStroke();
        this.treeCanvas.fill(
          this.tree.leafColor.h,
          this.tree.leafColor.s,
          this.tree.leafColor.b
        );
        this.treeCanvas.ellipse(
          this.position.x - 1,
          this.position.y - 1,
          this.treeCanvas.random(
            1 * this.tree.leafScale,
            20 * this.tree.leafScale
          ),
          this.treeCanvas.random(
            10 * this.tree.leafScale,
            50 * this.tree.leafScale
          )
        );
        this.treeCanvas.pop();
      }
    }
  }
}
