import { VStack } from '@chakra-ui/react';
import CreatorInfo from './CreatorInfo';
import NavBreadCrumbGame from './NavBreadCrumbs/NavBreadCrumbGame';

const GameFooter = ({gameId}) => {
  return (
    <VStack>
      <NavBreadCrumbGame gameId={gameId}/>
      <CreatorInfo />
    </VStack>
  );
};

export default GameFooter;
