import React from 'react';
import {
  Box,
  useDisclosure,
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  ModalFooter,
  FormControl,
  FormLabel,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { ethers } from 'ethers';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import {
  REACT_APP_AUCTION_HOUSE_ADDRESS
} from '../../../views/cozyGames/constants/ContractAddress';
import AuctionHouse from '../../../../contracts/APIs/AuctionHouseInterface.sol/AuctionHouseInterface.json';

const PlaceBid = props => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const handleValue = e => setBidAmount(e.target.value);

  const placeBid = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // const signer_address = await signer.getAddress();

    try {
      const auctionHouse = new ethers.Contract(
        REACT_APP_AUCTION_HOUSE_ADDRESS,
        AuctionHouse.abi,
        signer
      );

      const transaction = await auctionHouse.placeBid(props.auctionId, {
        value: ethers.utils.parseEther(bidAmount.toString()),
      });

      await transaction.wait();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button
          isLoading
          loadingText="Submitting"
          colorScheme="whatsapp"
          size="md"
          mr={3}
        >
          Place Bid
        </Button>
      );
    } else {
      return (
        <Button onClick={placeBid} colorScheme="whatsapp" size="md" mr={3}>
          Place Bid
        </Button>
      );
    }
  };

  return (
    <>
      <Box>
        <Button
          onClick={onOpen}
          as="button"
          p={4}
          color="white"
          fontWeight="bold"
          borderRadius="md"
          bgGradient="linear(to-r, #439cfb, #f187fb)"
          _hover={{
            bgGradient: 'linear(to-r, teal.300, green.300)',
          }}
          size="xl"
        >
          <Text fontSize="xl">Place Bid</Text>
        </Button>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Place your Bid</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box style={{ marginBottom: '20px' }}>
                <FormControl isRequired>
                  <FormLabel as="legend">Bid amount:</FormLabel>

                  <Input
                    name="bidAmount"
                    value={bidAmount}
                    onChange={handleValue}
                    placeholder="Your bid amount"
                    size="sm"
                  />
                </FormControl>
              </Box>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={8}>
                <Box>
                  <RenderButton />
                </Box>
                <Box>
                  <Button onClick={onClose}>Cancel</Button>
                </Box>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
      <Box></Box>
    </>
  );
};

export default PlaceBid;
