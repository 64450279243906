import React, { useState } from 'react';
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Button,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { FaInfo } from 'react-icons/fa';
import '../../styles/DashedBorder.css';
import getImageUrlByTokenType from '../utils/getImageUrlByTokenType';
import { removeItemAt, removePartFromArray } from '../utils/helpers';
import {
  REACT_APP_MAGIC_NFT_ERC721_ADDRESS,
  REACT_APP_MAGIC_NFT_ERC1155_ADDRESS,
} from '../views/cozyGames/constants/ContractAddress';
const ComplimentaryTokensForm = ({
  index,
  token,
  meta,
  setMeta,
  newContractAddress,
  setNewContractAddress,
  newTokenIds,
  setNewTokenIds,
  isERC1155s,
  setIsERc1155s,
  amounts,
  setAmounts,
}) => {
  // console.log('meta:', meta);
  const [otherContractAddress, setOtherContractAddress] = useState(false);
  // const [newTokenIds, setNewTokenIds] = useState('');
  // const [isERC1155s, setIsERc1155s] = useState('1');
  // const [amounts, setAmounts] = useState('');
  const [displayImageUrl, setDisplayImageUrl] = useState('');
  const [doesVerify, setDoesVerify] = useState(false);
  const [verifyTokenId, setVerifyTokenId] = useState(0);
  const [verifyTokenContract, setVerifyTokenContract] = useState('');

  const handleNewContractChange = (index, event) => {
    const newMeta = [...meta];
    // newMeta[index].cTokenContracts.push(event.target.value);
    newMeta[index].cTokenContracts = [
      ...newMeta[index].cTokenContracts,
      event.target.value,
    ];
    setMeta(newMeta);
  };

  const handleIsErc1155Change = (index, selected) => {
    const newMeta = [...meta];
    // newMeta[index].cTokenContracts.push(event.target.value);
    newMeta[index].isCTokenErc1155s = [
      ...newMeta[index].isCTokenErc1155s,
      selected,
    ];
    setMeta(newMeta);
  };

  const handleNewTokenIdsChange = (index, event) => {
    const newMeta = [...meta];
    // newMeta[index].cTokenIds.push(event.target.value);
    newMeta[index].cTokenIds = [
      ...newMeta[index].cTokenIds,
      event.target.value,
    ];
    setMeta(newMeta);
  };

  const handleAmountChange = (index, event) => {
    const newMeta = [...meta];
    // newMeta[index].cTokenIds.push(event.target.value);
    newMeta[index].cTokenAmounts = [
      ...newMeta[index].cTokenAmounts,
      event.target.value,
    ];
    setMeta(newMeta);
  };

  const getIsErc1155 = num => {
    return num == '1' ? false : true;
  };

  const verifyToken = async (_tokenId, _tokenContractAddr, _num) => {
    const _isErc1155 = getIsErc1155(_num);
    const _imageUrl = await getImageUrlByTokenType(
      _tokenId,
      _tokenContractAddr,
      _isErc1155
    );
    const _tokenImageUrl = _imageUrl;
    // console.log('_tokenImageUrl:', _tokenImageUrl);
    // setTokenImageUrl(_tokenImageUrl);
    setDisplayImageUrl(_tokenImageUrl);
    setDoesVerify(true);
    setVerifyTokenContract(_tokenContractAddr);
    setVerifyTokenId(_tokenId);
  };

  const RenderVerifyTokenButton = ({ tokenId, tokenContractAddr, num }) => {
    return (
      <Button
        colorScheme="blue"
        onClick={() => verifyToken(tokenId, tokenContractAddr, num)}
      >
        Verify Token
      </Button>
    );
  };
  const RenderRemoveTokenButton = ({ token, index, partToRemove }) => {
    console.log('index:', index);
    console.log('partToRemove:', partToRemove);
    return (
      <Button
        colorScheme="pink"
        onClick={() =>
          removePartFromArray(token.cTokenIds, index, partToRemove)
        }
      >
        Remove Token
      </Button>
    );
  };

  const RenderRemoveContractButton = ({ token, index }) => {
    console.log('index:', index);
    return (
      <Button
        colorScheme="red"
        onClick={() => {
          removeItemAt(token.cTokenContracts, index);
          removeItemAt(token.cTokenIds, index);
          removeItemAt(token.cTokenAmounts, index);
          removeItemAt(token.isCTokenErc1155s, index);
        }}
      >
        Remove Contract
      </Button>
    );
  };

  const RenderRemoveAllContractsButton = ({ token }) => {
    return (
      <Button
        colorScheme="red"
        onClick={() => {
          token.cTokenContracts = [];
          token.cTokenIds = [];
          token.cTokenAmounts = [];
          token.isCTokenErc1155s = [];
        }}
      >
        Remove All Contracts
      </Button>
    );
  };

  const RenderPopoverText = ({ popText }) => {
    return (
      <Popover>
        <PopoverTrigger>
          <IconButton
            variant="outline"
            colorScheme="green"
            aria-label="Get Info"
            size="xs"
            icon={<FaInfo boxSize={8} />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>{popText}</PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  const stringToArray = str => {
    return str.split(',').map(item => item.trim());
  };

  const getTokenContractType = num => {
    return num == '1' ? 'ERC721' : 'ERC1155';
  };

  const handleContractAddress = e => {
    if (e == REACT_APP_MAGIC_NFT_ERC721_ADDRESS) {
      setNewContractAddress(REACT_APP_MAGIC_NFT_ERC721_ADDRESS);
    } else if (e == REACT_APP_MAGIC_NFT_ERC1155_ADDRESS) {
      setNewContractAddress(REACT_APP_MAGIC_NFT_ERC1155_ADDRESS);
    } else {
      setOtherContractAddress(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="baseline"
        mb="20px"
        style={{
          fontSize: '1.2rem',
          display: 'grid',

          border: '8px solid',
          padding: '1rem',
          borderWidth: '10px',
          borderImage: 'repeating-linear-gradient(45deg, turquoise, pink 4%) 1',
        }}
      >
        <>
          <Box>
            <VStack>
              <Text fontSize="lg" color="teal.500">
                Complimentary Tokens Added:
              </Text>
              {token.cTokenContracts.length == 0 && (
                <Text fontSize="md" color="teal.500" as="i">
                  None
                </Text>
              )}
              {token.cTokenContracts.length > 0 && (
                <RenderRemoveAllContractsButton token={token} />
              )}
              {token.cTokenContracts.length > 0 &&
                token.cTokenContracts.map((contract, contractIndex) => (
                  <Box
                    key={contractIndex}
                    bgGradient="linear(to-r, #84ffc9, #aab2ff, #eca0ff)"
                    boxShadow="xl"
                    p="2"
                    rounded="md"
                  >
                    <RenderRemoveContractButton
                      token={token}
                      index={contractIndex}
                    />
                    <Text fontSize="sm" color="blackAlpha.900">
                      Complimentary Token Contract (
                      {getTokenContractType(
                        token.isCTokenErc1155s[contractIndex]
                      )}
                      ) :
                    </Text>
                    <Text fontSize="sm" color="blackAlpha.900" as="i">
                      {contract.substring(0, 20)}...{' '}
                    </Text>
                    <Text fontSize="sm" color="blackAlpha.900">
                      Complimentary tokens from this contract:
                    </Text>
                    {/* <Text fontSize="sm" color="blackAlpha.900" as="i">
                        {token.cTokenIds[contractIndex]}
                      </Text> */}
                    {stringToArray(token.cTokenIds[contractIndex]).map(
                      (t, i) => (
                        <>
                          <Box>
                            <HStack>
                              <Text fontSize="sm" color="blackAlpha.900" as="i">
                                Token Id: {t}
                              </Text>
                              {token.isCTokenErc1155s[contractIndex] == '2' && (
                                <Text
                                  fontSize="sm"
                                  color="blackAlpha.900"
                                  as="i"
                                >
                                  Amount:{' '}
                                  {
                                    stringToArray(
                                      token.cTokenAmounts[contractIndex]
                                    )[i]
                                  }
                                </Text>
                              )}
                            </HStack>
                            <HStack>
                              <RenderVerifyTokenButton
                                tokenId={t}
                                tokenContractAddr={contract}
                                num={token.isCTokenErc1155s[contractIndex]}
                              />
                              <RenderRemoveTokenButton
                                token={token}
                                index={contractIndex}
                                partToRemove={t}
                              />
                            </HStack>

                            {doesVerify &&
                              t == verifyTokenId &&
                              contract == verifyTokenContract && (
                                <img
                                  src={displayImageUrl}
                                  style={{ width: '280px' }}
                                />
                              )}
                          </Box>
                        </>
                      )
                    )}
                  </Box>
                ))}
            </VStack>
          </Box>
          <Box mb="20px" mt="30px">
            <FormControl>
              <FormLabel as="legend">
                Complimentary Token Contract Address:
              </FormLabel>

              <RadioGroup
                defaultValue={REACT_APP_MAGIC_NFT_ERC721_ADDRESS}
                colorScheme="green"
                onChange={e => handleContractAddress(e)}
              >
                <Stack spacing={5} direction="row">
                  <Radio value={REACT_APP_MAGIC_NFT_ERC721_ADDRESS}>
                    Magic NFT Erc721 contract
                  </Radio>
                  <Radio value={REACT_APP_MAGIC_NFT_ERC1155_ADDRESS}>
                    Magic NFT Erc1155 contract
                  </Radio>
                  <Radio value="Other">Other contract</Radio>
                </Stack>
              </RadioGroup>

              {otherContractAddress && (
                <Input
                  name="cTokenContracts"
                  value={newContractAddress}
                  onChange={e => setNewContractAddress(e.target.value)}
                  placeholder="Complimentary Token Contract Address"
                  size="sm"
                />
              )}
            </FormControl>
          </Box>

          <Box mb="20px">
            <FormControl>
              <FormLabel as="legend">This contract is:</FormLabel>
              <RadioGroup
                defaultValue="1"
                onChange={selected => setIsERc1155s(selected)}
                value={isERC1155s}
              >
                <Stack spacing={5} direction="row">
                  <Radio colorScheme="green" value="1">
                    ERC721
                  </Radio>
                  <Radio colorScheme="green" value="2">
                    ERC1155
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Box>

          <Box style={{ marginBottom: '20px' }}>
            <FormControl>
              <FormLabel as="legend">
                Token Ids from this contract:
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      variant="outline"
                      colorScheme="green"
                      aria-label="Get Info"
                      size="xs"
                      icon={<FaInfo boxSize={8} />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      Separate tokenIds with comma, for example: 1, 2, 3, ...
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </FormLabel>
              <Input
                name="cTokenIds"
                value={newTokenIds}
                onChange={e => setNewTokenIds(e.target.value)}
                placeholder="Complimentary Token Ids from this conract"
                size="sm"
              />
            </FormControl>
          </Box>

          {isERC1155s == '2' && (
            <Box style={{ marginBottom: '20px' }}>
              <FormControl>
                <FormLabel as="legend">
                  Amounts of Tokens as complitary tokens:
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        variant="outline"
                        colorScheme="green"
                        aria-label="Get Info"
                        size="xs"
                        icon={<FaInfo boxSize={8} />}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Text fontSize="md">
                          Separate amount for each ERC1155 token with a comma,
                          for example: 1, 2, 3, ...
                        </Text>
                        <Text fontSize="md">
                          For an ERC1155 token, the amount is 1 or more.
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </FormLabel>
                <Input
                  name="cTokenAmounts"
                  value={amounts}
                  onChange={e => setAmounts(e.target.value)}
                  placeholder="Amount for each token"
                  size="sm"
                />
              </FormControl>
            </Box>
          )}

          <Button
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              if (newContractAddress.length > 0 && newTokenIds.length > 0) {
                handleNewContractChange(index, {
                  target: { value: newContractAddress },
                });
                handleIsErc1155Change(index, isERC1155s);
                handleNewTokenIdsChange(index, {
                  target: { value: newTokenIds },
                });
                handleAmountChange(index, {
                  target: { value: amounts },
                });
                setNewContractAddress('');
                setIsERc1155s('1');
                setNewTokenIds('');
                setAmounts('');
              }
            }}
          >
            Add Contract and Token Ids
          </Button>
        </>
      </Box>
    </>
  );
};

export default ComplimentaryTokensForm;
