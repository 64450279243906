function drawFireflies(p5, fireflyCanvas, fireflies) {
  fireflyCanvas.clear();
  fireflyCanvas.push();
  for (let i = 0; i < 10; i++) {
    fireflies[i].show(p5);
  }
  fireflyCanvas.pop();
  p5.push();
  p5.image(fireflyCanvas, 0, 0);
  p5.pop();
}

export default drawFireflies;
