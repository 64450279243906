let cloudX = 100;
function cloud(p5, x, y, w, h) {
  p5.push();
  // p5.fill(275, 23, 97, 18);
  p5.fill(255, 179, 193, 108);
  p5.noStroke();
  p5.ellipse(cloudX + x, y, w, h);
  p5.pop();
}

function drawCloud(p5) {
  p5.push();
  cloud(p5, 0, 108, 200 / 1.25, 100 / 1.25, cloudX);
  p5.pop();
  p5.push();
  cloud(p5, 100, 40, 200 / 1.25, 100 / 1.25, cloudX);
  p5.pop();
  cloudX += 0.18;
}

// export function drawCloud(p5, cloudX) {
//   p5.push();
//   cloud(p5, 0, 108, 200 / 1.25, 100 / 1.25, cloudX);
//   p5.pop();
//   p5.push();
//   cloud(p5, 100, 40, 200 / 1.25, 100 / 1.25, cloudX);
//   p5.pop();
// }
export default drawCloud;