export function drawFlowerGenus5(p5, x, y, flowerParam) {
    p5.noStroke();
    const flowerSize = p5.random(18, 28);
    const petalSize = flowerSize / 2;
    const spacing = petalSize / 2;

    p5.translate(x, y)
  
    p5.fill(flowerParam.fc.h, flowerParam.fc.s, flowerParam.fc.b, 160);
    p5.circle(-spacing, -spacing, petalSize);
    p5.circle(spacing, -spacing, petalSize);
    p5.circle(-spacing, spacing, petalSize);
    p5.circle(spacing, spacing, petalSize);
  
    p5.fill(flowerParam.sc.h, flowerParam.sc.s, flowerParam.sc.b, 240);
    p5.circle(0, 0, petalSize / 1.2);
  }

  export function drawFlowerGenus5_treeCanvas(p5, treeCanvas, x, y, flowerParam) {
    treeCanvas.noStroke();
    const flowerSize = treeCanvas.random(18, 28);
    const petalSize = flowerSize / 2;
    const spacing = petalSize / 2;

    treeCanvas.translate(x, y)
  
    treeCanvas.fill(flowerParam.fc.h, flowerParam.fc.s, flowerParam.fc.b, 160);
    treeCanvas.circle(-spacing, -spacing, petalSize);
    treeCanvas.circle(spacing, -spacing, petalSize);
    treeCanvas.circle(-spacing, spacing, petalSize);
    treeCanvas.circle(spacing, spacing, petalSize);
  
    treeCanvas.fill(flowerParam.sc.h, flowerParam.sc.s, flowerParam.sc.b, 240);
    treeCanvas.circle(0, 0, petalSize / 1.2);
  }
  