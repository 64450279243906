import React from 'react';
import '../styles/Fonts.css';
import { Link as ReactLink } from 'react-router-dom';
import { VStack, Wrap, WrapItem, Link, Box, Text } from '@chakra-ui/react';
import Bird from '../resources/images/animals/bird.gif';
import { HomeIcon } from '../components/views/NFTs/elements/MiscDisplays';
const BlockchainGames = () => {
  return (
    <>
      {/* <ReactP5Wrapper sketch={SketchPiggy} /> */}

      <VStack spacing={6}>
        <HomeIcon />
        <Box>
          <Text
            color="green.400"
            fontSize={[33, 47, 58]}
            style={{ fontFamily: 'Lobster' }}
          >
            Cozy Blockchain Games
          </Text>
        </Box>

        <Wrap spacing={6}>
          <WrapItem>
            <Box>
              <img
                src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1721917559/forest_y4hcsl.gif"
                alt="Forest"
              />
            </Box>
          </WrapItem>
          <WrapItem>
            <Box>
              <img src={Bird} alt="Bird" />
            </Box>
          </WrapItem>
        </Wrap>

        <Box>
          <Text
            color="green.400"
            fontSize={[26, 36, 47]}
            style={{ fontFamily: 'Lobster' }}
          >
            Game List
          </Text>
        </Box>

        <Box
          w="400px"
          style={{
            display: 'grid',
            minHeight: '200px',
            border: '8px solid',
            padding: '1rem',
            borderImage: 'linear-gradient(45deg, turquoise, greenyellow) 1',
            marginTop: '20px',
          }}
        >
          <VStack>
            <Link as={ReactLink} to="/magicflowers">
              <img
                src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370825/cat1_edquhz.gif"
                alt="Magic Flowers NFT Game"
              />
            </Link>
            <Text
              color="green.400"
              fontSize="2xl"
              style={{ fontFamily: 'Lobster' }}
            >
              Magic Flowers NFT Game
            </Text>
          </VStack>
        </Box>
        <Box mt={6}>
          <Text
            color="green.400"
            fontSize="2xl"
            style={{ fontFamily: 'Lobster' }}
          >
            More games coming soon...
          </Text>
        </Box>
        {/* <img
          src={CampaignImage}
          alt="Join Our Indiegogo Campaign"
          style={{ width: '358px', height: 'auto' }}
        /> */}
      </VStack>
    </>
  );
};

export default BlockchainGames;
