import React from 'react';
import SearchById from './SearchById';

const SearchMagicNFT = () => {
  return (
    <SearchById
      useCase={'tokens/' + process.env.REACT_APP_MAGIC_NFT_ADDRESS}
      _placeholder="Magic NFT Id"
      label="Search Magic NFT"
    />
  );
};

export default SearchMagicNFT;
