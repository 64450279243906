import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Checkbox,
  VStack,
  Card,
  CardBody,
  CardFooter,
} from '@chakra-ui/react';
import FeaturedItems from '../../../contracts/APIs/FeaturedItemsInterface.sol/FeaturedItemsInterface.json';
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import { FeaturingPages } from '../../utils/featuringHelpers';
import {
  capitalizeFirstLetter,
  convertTimestampToDate,
} from '../../utils/helpers';
import ChooseContractForm from '../../formComponents/ChooseContractForm';
import SetContractAddrForm from '../../formComponents/SetContractAddrForm';
import TokenIdFormSingle from '../../formComponents/TokenIdFormSingle';
import getNetworkProvider from '../../../walletConnections/obtainProviders/getNetworkProvider';
import {
  REACT_APP_MARKETPLACE_ERC721_ADDRESS,
  REACT_APP_FEATURED_ITEMS_ADDRESS,
  REACT_APP_GAMES_ADDRESS
} from '../../views/cozyGames/constants/ContractAddress';
function FeaturingItemBase({
  useCase = '',
  tokenContractByUser = '',
  tokenIdByUser = 0,
  gameIdByUser = 0,
  featuringPagesByUser = [],
}) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const initialAllFeaturingPages =
    featuringPagesByUser.length > 0 ? featuringPagesByUser : FeaturingPages;

  const initialContractAddr = REACT_APP_MARKETPLACE_ERC721_ADDRESS;
  const [singleContractAddr, setSingleContractAddr] =
    useState(initialContractAddr);
  const [tokenId, setTokenId] = useState(0);
  const [gameId, setGameId] = useState(0);
  const [featuringPages, setFeaturingPages] = useState(
    initialAllFeaturingPages
  );
  const [featuringPage, setFeaturingPage] = useState(featuringPages[0]);
  const [currentTimeslot, setCurrentTimeslot] = useState(0);
  const [timeslotOptions, setTimeslotOptions] = useState({});
  const [checkedItems, setCheckedItems] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [loading, setLoading] = useState(false);

  const [showAvailableOptions, setShowAvailableOptions] = useState(false);

  const getTimeslotOptions = async () => {
    const provider =
      address == undefined
        ? getNetworkProvider()
        : new ethers.providers.Web3Provider(walletProvider);
    const featuredItems = new ethers.Contract(
      REACT_APP_FEATURED_ITEMS_ADDRESS,
      FeaturedItems.abi,
      provider
    );
    const _currentTimeslotId = await featuredItems.getCurrentTimeslotId();
    const currentTimeslotId = Number(_currentTimeslotId.toString());
    // console.log('currentTimeslotId:', currentTimeslotId);
    setCurrentTimeslot(currentTimeslotId);

    const numOfIds = 6;

    const _timeslots = await featuredItems.timeSlotsStartTime(
      currentTimeslotId,
      numOfIds
    );

    let _timeslotIds = [currentTimeslotId];
    for (let i = 1; i < numOfIds; i++) {
      _timeslotIds.push(currentTimeslotId + i);
    }

    // console.log('_timeslotIds:', _timeslotIds);

    let allSlots = {};

    for (let i = 0; i < FeaturingPages.length; i++) {
      let _isSingleGame = false;
      if (
        featuringPage == 'marketplaceGame' ||
        featuringPage == 'auctionHouseGame'
      ) {
        _isSingleGame = true;
      }
      let _isFull = await featuredItems.areTimeslotsFull(
        FeaturingPages[i],
        gameId,
        _timeslotIds
      );
      //   console.log('_isFull:', _isFull);
      let slots = [];
      for (let j = 0; j < numOfIds; j++) {
        let slot = {
          timeslotId: _timeslotIds[j],
          startTime: convertTimestampToDate(Number(_timeslots[j].toString())),
          isFull: _isFull[j],
        };
        slots.push(slot);
      }
      allSlots[FeaturingPages[i]] = slots;
    }

    // console.log('allSlots:', allSlots);
    setTimeslotOptions(allSlots);
  };

  const getChosenTimeslotIds = arr => {
    let ids = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === true) {
        let _id = i > 0 ? currentTimeslot + i : currentTimeslot;
        ids.push(_id);
      }
    }
    return ids;
  };

  const featureIt = async () => {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    // console.log('provider:', provider);
    // const signer_address = await signer.getAddress();
    // console.log('signer:', signer_address);

    try {
      const featuredItemsContract = new ethers.Contract(
        REACT_APP_FEATURED_ITEMS_ADDRESS,
        FeaturedItems.abi,
        signer
      );

      //   console.log('FeaturedItems Contract:', featuredItemsContract.address);

      //   console.log('checkedItems:', checkedItems);

      const chosenTimeslotIds = getChosenTimeslotIds(checkedItems);
      //   console.log('chosenTimeslotIds:', chosenTimeslotIds);

      const featuringFee = await featuredItemsContract.getFeaturingFee(
        featuringPage,
        chosenTimeslotIds.length
      );

      let _isSingleGame = false;
      if (
        featuringPage == 'marketplaceGame' ||
        featuringPage == 'auctionHouseGame'
      ) {
        _isSingleGame = true;
      }

      let _singleContractAddr;

      if (featuringPage == 'gameList') {
        _singleContractAddr = REACT_APP_GAMES_ADDRESS;
      } else if (useCase == 'TokenByUser') {
        _singleContractAddr = tokenContractByUser;
      } else {
        _singleContractAddr = singleContractAddr;
      }

      // const _singleContractAddr =
      //   featuringPage == 'gameList'
      //     ? REACT_APP_GAMES_ADDRESS
      //     : singleContractAddr;
      let _gameId = gameIdByUser > 0 ? gameIdByUser : gameId;

      let _tokenId;
      if (featuringPage == 'gameList') {
        _tokenId = _gameId;
        _gameId = 0;
      } else if (useCase == 'TokenByUser') {
        _tokenId = tokenIdByUser;
      } else {
        _tokenId = tokenId;
      }

      // const _tokenId = featuringPage == 'gameList' ? gameId : tokenId;

      const transaction = await featuredItemsContract.addFeatured(
        _singleContractAddr,
        _tokenId,
        featuringPage,
        chosenTimeslotIds,
        _isSingleGame,
        _gameId,
        {
          value: featuringFee,
        }
      );

      await transaction.wait();
      setLoading(false);
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowAvailableOptions = async () => {
    setShowAvailableOptions(true);
    await getTimeslotOptions();
  };

  const RenderShowOptionsButton = () => {
    return (
      <Button
        colorScheme="green"
        variant="outline"
        onClick={handleShowAvailableOptions}
      >
        Check Newest Available Time Slots for Featuring NFT
      </Button>
    );
  };

  function RenderTimeslotOptions() {
    return (
      <>
        <Checkbox
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={e =>
            setCheckedItems([
              e.target.checked,
              e.target.checked,
              e.target.checked,
              e.target.checked,
              e.target.checked,
              e.target.checked,
            ])
          }
        >
          {featuringPage} Page
        </Checkbox>
        <Stack pl={6} mt={1} spacing={1}>
          <Checkbox
            isChecked={checkedItems[0]}
            onChange={e =>
              setCheckedItems([
                e.target.checked,
                checkedItems[1],
                checkedItems[2],
                checkedItems[3],
                checkedItems[4],
                checkedItems[5],
              ])
            }
            isDisabled={timeslotOptions[featuringPage][0].isFull} // isDisabled={false}
          >
            Timeslot #: {currentTimeslot} Start on:{' '}
            {timeslotOptions[featuringPage][0].startTime} (Current Timeslot)
          </Checkbox>
          <Checkbox
            isChecked={checkedItems[1]}
            onChange={e =>
              setCheckedItems([
                checkedItems[0],
                e.target.checked,
                checkedItems[2],
                checkedItems[3],
                checkedItems[4],
                checkedItems[5],
              ])
            }
            isDisabled={timeslotOptions[featuringPage][1].isFull}
          >
            Timeslot #: {currentTimeslot + 1}
          </Checkbox>
          <Checkbox
            isChecked={checkedItems[2]}
            onChange={e =>
              setCheckedItems([
                checkedItems[0],
                checkedItems[1],
                e.target.checked,
                checkedItems[3],
                checkedItems[4],
                checkedItems[5],
              ])
            }
            isDisabled={timeslotOptions[featuringPage][2].isFull}
          >
            Timeslot #: {currentTimeslot + 2}
          </Checkbox>

          <Checkbox
            isChecked={checkedItems[3]}
            onChange={e =>
              setCheckedItems([
                checkedItems[0],
                checkedItems[1],
                checkedItems[2],
                e.target.checked,
                checkedItems[4],
                checkedItems[5],
              ])
            }
            isDisabled={timeslotOptions[featuringPage][3].isFull}
          >
            Timeslot #: {currentTimeslot + 3}
          </Checkbox>
          <Checkbox
            isChecked={checkedItems[4]}
            onChange={e =>
              setCheckedItems([
                checkedItems[0],
                checkedItems[1],
                checkedItems[2],
                checkedItems[3],
                e.target.checked,
                checkedItems[5],
              ])
            }
            isDisabled={timeslotOptions[featuringPage][4].isFull}
          >
            Timeslot #: {currentTimeslot + 4}
          </Checkbox>
          <Checkbox
            isChecked={checkedItems[5]}
            onChange={e =>
              setCheckedItems([
                checkedItems[0],
                checkedItems[1],
                checkedItems[2],
                checkedItems[3],
                checkedItems[4],
                e.target.checked,
              ])
            }
            isDisabled={timeslotOptions[featuringPage][5].isFull}
          >
            Timeslot #: {currentTimeslot + 5}
          </Checkbox>
        </Stack>
      </>
    );
  }

  const RenderFeaturingPagesForm = () => {
    return (
      <Box style={{ marginBottom: '20px' }}>
        <FormControl isRequired>
          <FormLabel as="legend">Featured on:</FormLabel>
          <RadioGroup
            onChange={setFeaturingPage}
            value={featuringPage}
            colorScheme="green"
          >
            <Stack>
              {featuringPages.map((page, key) => (
                <Radio key={key} value={page}>
                  {capitalizeFirstLetter(page)} Page
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };

  const RenderButton = () => {
    if (loading === true) {
      return (
        <Button isLoading loadingText="Submitting" colorScheme="green">
          Submit
        </Button>
      );
    } else {
      return (
        <Button onClick={featureIt} colorScheme="green" size="lg">
          Submit
        </Button>
      );
    }
  };
  return (
    <Box>
      <VStack spacing={4}>
        <Card boxShadow="2xl" p="6" rounded="md">
          <CardBody>
            <Box>
              <Box>
                <RenderFeaturingPagesForm />
              </Box>

              {featuringPage != 'gameList' && useCase != 'TokenByUser' && (
                <>
                  <Box>
                    <ChooseContractForm
                      singleContractAddr={singleContractAddr}
                      setSingleContractAddr={setSingleContractAddr}
                    />

                    {singleContractAddr === 'Other Contract Address' && (
                      <SetContractAddrForm
                        singleContractAddr={singleContractAddr}
                        setSingleContractAddr={setSingleContractAddr}
                      />
                    )}
                  </Box>

                  <TokenIdFormSingle
                    tokenId={tokenId}
                    setTokenId={setTokenId}
                  />
                </>
              )}

              {(featuringPage == 'marketplaceGame' ||
                featuringPage == 'auctionHouseGame' ||
                featuringPage == 'gameList') &&
                gameIdByUser == 0 && (
                  <TokenIdFormSingle
                    tokenId={gameId}
                    setTokenId={setGameId}
                    label="Game Id"
                  />
                )}

              <Box>
                <FormControl isRequired>
                  <FormLabel as="legend">
                    Choose Timeslot: (Each timeslot lasts for 3 days (72 hours)){' '}
                  </FormLabel>
                </FormControl>
              </Box>
              <RenderShowOptionsButton />
              {showAvailableOptions &&
                Object.keys(timeslotOptions).length > 0 && (
                  <RenderTimeslotOptions />
                )}
            </Box>
          </CardBody>
          <CardFooter display="flex" justifyContent="center">
            <RenderButton />
          </CardFooter>
        </Card>
      </VStack>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
        `}
      </style>
    </Box>
  );
}

export default FeaturingItemBase;
