// white snow

export class Snowflake {
  constructor(p5, canvas, sx, sy, img) {
      this.p5 = p5 
      this.canvas = canvas
    let x = sx || this.canvas.random(this.canvas.width);
    let y = sy || this.p5.random(-100, -10);
    this.img = img;
    this.pos = p5.createVector(x, y);
    this.vel = p5.createVector(0, 0);
    this.acc = p5.createVector();
    this.angle = this.canvas.random(p5.TWO_PI);
    this.dir = this.canvas.random(1) > 0.5 ? 1 : -1;
    this.xOff = 0;
    this.r = this.getRandomSize();
    this.gravity = p5.createVector(0, 0.3);
  }

  applyForce() {
    // Parallax Effect hack
    let f = this.gravity.copy();
    f.mult(this.r);

    // let f = force.copy();
    // f.div(this.mass);
    this.acc.add(f);
  }

  randomize() {
    let x = this.canvas.random(this.canvas.width);
    let y = this.p5.random(-100, -10);
    this.pos = this.p5.createVector(x, y);
    this.vel = this.p5.createVector(0, 0);
    this.acc = this.p5.createVector();
    this.r = this.getRandomSize();
  }

  update() {
    this.xOff = this.p5.sin(this.angle * 2) * 2 * this.r;

    this.vel.add(this.acc);
    this.vel.limit(this.r * 0.2);

    if (this.vel.mag() < 1) {
      this.vel.normalize();
    }

    this.pos.add(this.vel);
    this.acc.mult(0);

    if (this.pos.y > this.p5.height + this.r) {
      this.randomize();
    }

    // Wrapping Left and Right
    if (this.pos.x < -this.r) {
      this.pos.x = this.p5.width + this.r;
    }
    if (this.pos.x > this.p5.width + this.r) {
      this.pos.x = -this.r;
    }

    this.angle += (this.dir * this.vel.mag()) / 200;
  }

  render() {
    // stroke(255);
    // strokeWeight(this.r);
    // point(this.pos.x, this.pos.y);
    this.canvas.push();
    this.canvas.translate(this.pos.x + this.xOff, this.pos.y);
    this.canvas.rotate(this.angle);
    this.canvas.imageMode(this.p5.CENTER);
    this.canvas.image(this.img, 0, 0, this.r, this.r);
    this.canvas.pop();
  }

  getRandomSize() {
      let r = this.p5.pow(this.p5.random(0, 1), 3);
      return this.p5.constrain(r * 32, 2, 32);
    }

  // offScreen() {
  //   return (this.pos.y > height + this.r);
  // }
}



export function setupSnow1(p5, spritesheet, textures, snow, snowCanvas) {
    
    for (let x = 0; x < spritesheet.width; x += 32) {
      for (let y = 0; y < spritesheet.height; y += 32) {
        let img = spritesheet.get(x, y, 32, 32);
        p5.image(img, x, y);
        textures.push(img);
      }
    }

    for (let i = 0; i < 400; i++) {
      let x = p5.random(p5.width);
      let y = p5.random(p5.height);
      let design = p5.random(textures);
      snow.push(new Snowflake(p5, snowCanvas, x, y, design));
    }
  }